<template>
    <transition name="fade">
        <div v-if="show" class="popup_box" style="display: block">
            <div class="popup delete_category">
                <span class="page_title">Редактирование остатка</span>
                <span class="close_popup" @click="close()"></span>
                <div class="inp_wrap">
                  <p>Количество:</p>
                  <input type="text" v-model="editableRemains.remains">
                </div>
              <button class="btn " @click="confirm()">Сохранить</button>
                <button class="btn empty" @click="close()">Отмена</button>

            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    show: Boolean,
    products: Array
  },
  computed: {
    ...mapState('product', ['editableRemains'])
  },
  methods: {
    ...mapActions('product', {
      updateRemains: 'updateProductInOrder'
    }),
    confirm () {
      this.updateRemains()
      this.close()
    },
    close () {
      this.$root.$emit('show_edit_remains', false)
    }
  }
}
</script>

<style scoped>

</style>
