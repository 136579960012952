<template>
  <section class="table_box calculations_sellers">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Выберите продавца
        <p v-if="invoice_users_pagination.from">Результат: {{invoice_users_pagination.from}} - {{invoice_users_pagination.to}} из {{invoice_users_pagination.total}}</p>
        </span>
      </div>
      <div class="wrap_btn flx">
        <div class="btn actions_btn empty" data-id="filters_box" :class="{active: show_invoice_user_filters}" @click="toggleInvoiceUserFilters">
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3333 1H1L6.33333 7.30667V11.6667L9 13V7.30667L14.3333 1Z" stroke="#F5A32A"
                  stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Фильтры</span>
          <span class="counter_filters" v-if="count_user_filters > 0">{{ count_user_filters }}</span>
        </div>
      </div>
      <calc-dropshipper-filters></calc-dropshipper-filters>
    </div>
    <div class="container has_table">
      <div class="table_body table_calculations_sellers">
        <div class="top_table flx">
          <div class="wrap_search flx">
            <button type="submit"></button>
            <input type="text" v-model="search" placeholder="Поиск..." id="search">
            <ul class="search_list_box">
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
            </ul>
          </div>
          <div class="pagination flx">
            <div class="flx">
              <p class="desctop_v">Строк:</p>
              <div class="select" id="sellers">
                <brew-select
                    class="select-pagination select-dropshipper-per_page"
                    style="margin-left:10px;width: 100px"
                    v-model="perPage"
                    :clearable="false"
                    :options="page_sizes"
                >
                </brew-select>
              </div>
            </div>
            <paginate
                style="margin-left: 20px"
                class="flx"
                v-model="page"
                :page-count="invoice_users_pagination.last_page"
                :page-range="3"
                :margin-pages="2"
                :prev-text="'<'"
                :next-text="'>'"
            >
            </paginate>
          </div>
        </div>
        <div class="bot_table">
          <headline :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
          <div class="row_list">
            <div class="row flx"
                 :rel="dropshipper.id"
                 v-for="dropshipper in invoice_users"
                 :key="'dropshipper-'+dropshipper.id"
                 @click="routeTo({ name: 'DropshipperOrders', params: { id: dropshipper.id }})"
            >
              <div class="id_seller">
                <router-link :to="{ name: 'DropshipperOrders', params: { id: dropshipper.id }}">{{ dropshipper.id }}</router-link>
              </div>
              <div class="login_phone_seller">
                <span v-if="dropshipper.phone">{{ dropshipper.phone | removeCodeCountry }}</span>
                <span v-if="dropshipper.login" class="gray">{{ dropshipper.login }}</span>
              </div>
              <div class="full_name_seller">
                <span>{{ dropshipper.name }}</span>
              </div>
              <div class="last_visit_seller">
                <span>{{ dropshipper.last_visited_at | onlyDate }}</span>
                <span class="grey">{{ dropshipper.last_visited_at | onlyTime }}</span>
              </div>
              <div class="calculations">
                <div class="item flx">
                  <span v-if="dropshipper.billing_info.total !== 0">{{  getOperationByBillingInfo(dropshipper.billing_info.total) }}:</span>
                  <span class="line" v-if="dropshipper.billing_info.total !== 0"> </span>
                  <p :class="getBillingInfoColor(dropshipper.billing_info.total)"><b>{{ dropshipper.billing_info.total | asPrice }}</b></p>
                </div>
              </div>
              <div class="request_seller flx">
                <div class="notice_wrap" v-if="dropshipper.deduction">
                  <div class="menu_drop" v-if="dropshipper.deduction_comment">
                    <div class="dropdown">
                      <div class="request_text">
                        <span>Коментарий:</span>
                        <p>{{dropshipper.deduction_comment}}</p>
                      </div>
                    </div>
                  </div>
                  <span class="notice_ico"> </span>
                </div>
                <router-link tag="span" v-if="dropshipper.deduction" :to="{ name: 'DropshipperOrders', params: { id: dropshipper.id }}"><b class="grey">от</b> {{dropshipper.deduction}}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { prices } from '../../../helpers/prices'
import { dates } from '../../../helpers/dates'
import { phones } from '../../../helpers/phones'
import helper from '../../../helpers/helper'
import CalcDropshipperFilters from './CalcDropshipperFilters'
import Headline from '../../Helpers/Headline'
import router from '../../../router'
export default {
  name: 'CalcDropshippers',
  mixins: [
    prices,
    dates,
    phones
  ],
  components: {
    CalcDropshipperFilters, Headline
  },
  data () {
    return {
      page_sizes: [10, 50, 100, 1000],
      timer: null,
      headers: [
        {
          title: 'ID',
          title_class: 'title',
          sortable: true,
          class: 'id_seller',
          value: 'id'
        },
        {
          title: 'Логин・телефон',
          class: 'login_phone_seller'
        },
        {
          title: 'ФИО',
          class: 'full_name_seller',
          sortable: true,
          value: 'name'
        },
        {
          title: 'Последний визит',
          class: 'last_visit_seller',
          sortable: true,
          value: 'last_visited_at'
        },
        {
          title: 'Расчёт',
          class: 'calculations',
          sortable: true,
          value: 'billing_info_total'
        },
        {
          title: 'Запросы',
          class: 'request_seller'
        }
      ]
    }
  },
  computed: {
    ...mapState('invoices', ['invoice_users', 'invoice_users_pagination', 'invoice_users_filters', 'show_invoice_user_filters']),
    ...mapGetters('invoices', ['count_user_filters']),
    page: {
      get: function () {
        return this.invoice_users_filters.page
      },
      set: function (value) {
        this.setInvoiceUsersPage(value)
        this.getInvoiceUsers()
      }
    },
    perPage: {
      'get' () {
        return this.invoice_users_filters.per_page
      },
      'set' (perPage) {
        this.setInvoiceUsersPerPage(perPage)
        this.setInvoiceUsersPage(1)
        this.getInvoiceUsers()
      }
    },
    search: {
      get: function () {
        return this.invoice_users_filters.search
      },
      set: function (value) {
        clearTimeout(this.timer)
        this.setInvoiceUsersPage(1)
        this.setInvoiceUsersSearch(value)
        this.timer = setTimeout(() => {
          this.getInvoiceUsers()
        }, 500)
      }
    },
    sortBy: {
      get () {
        return this.invoice_users_filters.order_by
      },
      set (val) {
        this.setInvoiceUsersOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.invoice_users_filters.order_direction
      },
      set (val) {
        this.setInvoiceUsersOrderDirection(val)
      }
    }
  },
  watch: {
    sortBy () {
      this.getInvoiceUsers()
    },
    sortDesc () {
      this.getInvoiceUsers()
    }
  },
  methods: {
    ...mapActions('invoices', ['getInvoiceUsers', 'toggleInvoiceUserFilters']),
    ...mapMutations('invoices', ['setInvoiceUsersPerPage', 'setInvoiceUsersPage', 'setInvoiceUsersSearch', 'setInvoiceUsersOrderBy', 'setInvoiceUsersOrderDirection']),
    ...mapMutations('invoices', ['setUserId']),
    getColorBalance (value) {
      return helper.getColorBalance(value)
    },
    getOperationByBillingInfo (value) {
      return helper.getOperationByBillingInfo(value)
    },
    getBillingInfoColor (value) {
      return helper.getBillingInfoColor(value)
    },
    routeTo (to) {
      router.push(to)
    }
  },
  created () {
    this.getInvoiceUsers()
  }
}
</script>

<style scoped>

</style>
