<template>
  <div class="box_shadow card_item">
    <span class="close" @click="remove()"> </span>
    <div class="row flx">
      <div class="inp_wrap color">
        <p>Цвет:</p>
        <div class="select" id="color">
          <i class="ti-angle-down"></i>
          <brew-select
              class="vue-select"
              v-model="color_data.color_id"
              label="name"
              :options="filteredColors"
              :reduce="item => item.id"
              clerable="false"
              :selectable="option => ! option.disabled"
              required
              @input="changeData"
              @change="changeData"
          >
          </brew-select>
        </div>
      </div>
      <div style="width: 50%">
        <div class="inp_wrap" style="margin-left: 10px;padding: 0;width: 100%">
          <p> Рисунки для цвета товара:</p>
          <input v-model="color_data.pictures" type="text" @change="changeData">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="inp_wrap" style="width: 100%">
        <p> Короткое название для Новой Почты:</p>
        <input v-model="color_data.short_name" type="text" @change="changeData">
      </div>
    </div>
    <div class="row">
      <p>Размеры и количество:</p>
      <div class="checkbox_wrap flx">
        <size-select
            v-for="(size,index) in sizes"
            :key="size.id"
            :product_size="getProductSize(size.id)"
            @change="setSizeDataItem"
            :index="index"
            :size="size"
            @check="addProductSize"
            @remove="removeSize"
        ></size-select>
      </div>
    </div>
    <div class="row">
      <p>Дата ожидаемой поставки:</p>
      <div class="checkbox_wrap flx">
        <date-picker
          v-model="color_data.wait_date"
          format="D.M.Y"
          type="date"
          value-type="YYYY-MM-DD"
          @change="changeData"
        />
      </div>
    </div>
    <div class="row">
      <p>Изображение товара:</p>
      <images-bar :product_images="product_color.images" @change_images="changeImages"/>
    </div>
    <div class="row price_box">
      <div class="inp_cont flx">
        <span class="page_subtitle">Стоимость</span>
        <div class="inp_wrap">
          <p>Мин. остаток:</p>
          <input type="text" placeholder="0">
        </div>
        <div class="inp_wrap">
          <p>Закупка:</p>
          <input type="number" placeholder="0.00 грн" v-model="color_data.purchase_price" @change="changeData">
        </div>
        <div class="inp_wrap">
          <p>Цена дроп:</p>
          <input type="number" placeholder="0.00 грн" v-model="color_data.retail_price" @change="changeData">
        </div>
        <div class="wrap flx">
          <div class="inp_wrap">
            <p>Скидка:</p>
            <input type="number" id="discount" placeholder="0" v-model.number="color_data.discount.value" @change="changeData">
          </div>
          <div class="select" id="discount_value">
            <brew-select
                class="select-discount-type vue-select"
                :class="{required:hasError('discount.type')}"
                :clearable="false"
                :options="discount_types"
                v-model="color_data.discount.type"
                @option:selected="changeData"
            ></brew-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SizeSelect from '@/components/Droppshipping/Products/SizeSelect'
import ImagesBar from '@/components/Droppshipping/Products/Images/ImagesBar'
import DatePicker from 'vue2-datepicker'

import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'CreateProductColors',
  components: { ImagesBar, SizeSelect, DatePicker },
  props: [
    'index',
    'product_color',
    'selected'
  ],
  data () {
    return {
      color_data: {
        id: this.product_color.id,
        color_id: this.product_color.color_id,
        purchase_price: this.product_color.purchase_price,
        retail_price: this.product_color.retail_price,
        short_name: this.product_color.short_name,
        sizes: this.product_color.sizes,
        images: [],
        discount: {
          value: this.product_color.discount ? this.product_color.discount.value : 0,
          type: this.product_color.discount ? this.product_color.discount.type : '%'
        },
        pictures: this.product_color.pictures,
        wait_date: this.product_color.wait_date
      },
      selected_size: null,
      discount_types: [
        '%', 'грн'
      ]
    }
  },
  computed: {
    ...mapState('color', ['colors']),
    ...mapState('size', ['sizes']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    filteredColors: {
      get: function () {
        return _.forEach(this.colors, (item) => {
          if (this.selected !== undefined) {
            item.disabled = _.includes(_.map(this.selected, 'color_id'), item.id)
          }

          return item
        })
      }
    },
    selectedSizeData () {
      if (this.selected_size) { return _.find(this.sizes, s => { return this.selected_size === s.id }) }
      return null
    },
    optionSizes () {
      return _.filter(this.sizes, s => { return !_.map(this.color_data.sizes, 'size_id').includes(s.id) })
    }
  },
  methods: {
    getProductSize (sizeId) {
      // console.log(sizeId)
      return _.find(this.color_data.sizes, { size_id: sizeId })
    },
    setSizeDataItem (data) {
      if (data) {
        const index = _.findIndex(this.color_data.sizes, { size_id: data.id })
        if (index >= 0) {
          this.$set(this.color_data.sizes, index, data.data)
        } else {
          data.data.size_enable = true
          this.color_data.sizes.push(data.data)
        }
        this.changeData()
      }
    },
    changeImages (data) {
      this.color_data.images = data
      this.changeData()
    },
    changeData () {
      return this.$emit('change', {
        index: this.index,
        data: this.color_data
      })
    },
    remove () {
      return this.$emit('remove', this.index)
    },
    addProductSize (sizeId) {
      if (!_.find(this.color_data.sizes, { size_id: sizeId })) {
        console.log(sizeId)
        this.color_data.sizes.push({
          size_id: sizeId,
          remains: 0
        })
        this.changeData()
      }
    },
    addSize () {
      if (this.selectedSizeData) {
        this.color_data.sizes.push({
          size_id: this.selectedSizeData.id,
          remains: 0,
          size: this.selectedSizeData
        })
      }

      this.selected_size = null
    },
    removeSize (id) {
      this.color_data.sizes = _.filter(this.color_data.sizes, s => { return id !== s.size_id })
      this.changeData()
    }
  }
}
</script>

<style scoped>

</style>
