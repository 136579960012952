<template>
  <div class="container has_table"                                                >
    <div class="add_item_wrap flx">
      <div class="filters_box">
        <span class="page_subtitle">Добавить товар</span>
        <div class="inp_inner">
          <div class="inp_wrap">
            <p>Категория:</p>
            <div class="select select-search" id="category">
              <brew-select
                  class="vue-select"
                  label="path_name"
                  v-model="product_filters.category_id"
                  :options="childCategories"
                  :reduce="item => item.id"
                  @option:selected="getProductList"
                  @option:cleared="getProductList"
              />
            </div>
          </div>
          <div class="inp_wrap">
            <p>Артикул:</p>
            <input type="text" id="category_product" name="filter_vendor_code" v-model="product_filters.vendor_code"
                   @keypress.enter="getProductList" @input="updateFilterInput">
          </div>
          <div class="inp_wrap">
            <p>Название:</p>
            <input type="text" id="name_add_product" name="filter_product_name" v-model="product_filters.name" @keypress.enter="getProductList" @input="updateFilterInput">
          </div>
          <div class="inp_wrap">
            <p>Сезон:</p>
            <div class="select select-search">
              <brew-select
                  class="vue-select"
                  label="name"
                  v-model="product_filters.season_id"
                  :options="seasons"
                  :reduce="item => item.id"
                  @option:selected="getProductList"
                  @option:cleared="getProductList"
              />
            </div>
          </div>
          <div class="inp_wrap">
            <p>Цена:</p>
            <input type="text" id="price_product" name="filter_product_price" v-model="product_filters.price" @keypress.enter="getProductList" @input="updateFilterInput">
          </div>
        </div>
        <div class="reset" @click="clearOrderItemFilter">Сбросить фильтры</div>
      </div>
      <div class="add_item_table">
        <div class="pagination flx w-100" style="justify-content: center;">
          <paginate
              style="margin-left: 20px"
              class="flx"
              v-model="page"
              :page-count="product_list_pagination.last_page"
              :page-range="3"
              :margin-pages="2"
              :prev-text="'<'"
              :next-text="'>'"
          >
          </paginate>
        </div>
        <div class="table_body">
          <headline :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
          <div class="row_list fix-scroll">
            <div class="row flx add-product-wrap" v-for="(item, index) in product_list" :key="index + '-product-color-size-'+item.product_color_size_id">
              <div class="add-product" v-if="!item.in_invoice"><button class="btn" :disabled="disabled_field" :class="{ disabled: disabled_field }"  @click="addInvoiceProduct(item)">Добавить в накладную</button></div>
              <div class="add-product" v-else><button class="btn btn-delete" :disabled="disabled_field" :class="{ disabled: disabled_field}" @click="delInvoiceProduct(item)">Удалить из накладной</button></div>
              <div class="id_prod">
                  <router-link :to="{ name: 'ShowProduct', params: { id: item.product_id } }">{{ item.product_color_size_id}}</router-link>
              </div>
              <div class="code_prod">
                <span class="orange"><b>{{ item.vendor_code }}</b></span>
              </div>
              <div class="name_prod">
                <span><b>{{ item.name }}</b></span>
              </div>
              <div class="category_prod">
                <span class="grey" v-if="item.category_name"><b>{{ item.category_name }}</b></span>
              </div>
              <div class="color_prod">
                <span v-if="item.color">{{ item.color }}</span>
              </div>
              <div class="size_prod">
                <span v-if="item.size">{{ item.size }}</span>
              </div>
            </div>
          </div>
<!--          <div class="row_list">-->
<!--            <div class="row flx">-->
<!--              <div class="img_prod">-->
<!--                <img src="images/product3.jpg" alt="product">-->
<!--              </div>-->
<!--              <div class="id_prod">-->
<!--                <a href="single_product.html">3981</a>-->
<!--              </div>-->
<!--              <div class="code_prod">-->
<!--                <span>S0417</span>-->
<!--              </div>-->
<!--              <div class="name_prod">-->
<!--                <span>Бомбер Brew - Leather с капюшоном  </span>-->
<!--              </div>-->
<!--              <div class="category_prod">-->
<!--                <span class="grey"><b>Штаны, кофты (по отдельности)</b></span>-->
<!--              </div>-->
<!--              <div class="color_prod">-->
<!--                <span>Изум.</span>-->
<!--              </div>-->
<!--              <div class="size_prod">-->
<!--                <span>S</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row flx">-->
<!--              <div class="img_prod">-->
<!--                <img src="images/product3.jpg" alt="product">-->
<!--              </div>-->
<!--              <div class="id_prod">-->
<!--                <a href="single_product.html">3982</a>-->
<!--              </div>-->
<!--              <div class="code_prod">-->
<!--                <span>S0417</span>-->
<!--              </div>-->
<!--              <div class="name_prod">-->
<!--                <span>Бомбер Brew - Leather с капюшоном  </span>-->
<!--              </div>-->
<!--              <div class="category_prod">-->
<!--                <span class="grey"><b>Штаны, кофты (по отдельности)</b></span>-->
<!--              </div>-->
<!--              <div class="color_prod">-->
<!--                <span>Изум.</span>-->
<!--              </div>-->
<!--              <div class="size_prod">-->
<!--                <span>XL</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row flx">-->
<!--              <div class="img_prod">-->
<!--                <img src="images/product3.jpg" alt="product">-->
<!--              </div>-->
<!--              <div class="id_prod">-->
<!--                <a href="single_product.html">3983</a>-->
<!--              </div>-->
<!--              <div class="code_prod">-->
<!--                <span>S0417</span>-->
<!--              </div>-->
<!--              <div class="name_prod">-->
<!--                <span>Бомбер Brew - Leather с капюшоном  </span>-->
<!--              </div>-->
<!--              <div class="category_prod">-->
<!--                <span class="grey"><b>Штаны, кофты (по отдельности)</b></span>-->
<!--              </div>-->
<!--              <div class="color_prod">-->
<!--                <span>Изум.</span>-->
<!--              </div>-->
<!--              <div class="size_prod">-->
<!--                <span>L</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row flx">-->
<!--              <div class="img_prod">-->
<!--                <img src="images/product3.jpg" alt="product">-->
<!--              </div>-->
<!--              <div class="id_prod">-->
<!--                <a href="single_product.html">3984</a>-->
<!--              </div>-->
<!--              <div class="code_prod">-->
<!--                <span>S0417</span>-->
<!--              </div>-->
<!--              <div class="name_prod">-->
<!--                <span>Бомбер Brew - Leather с капюшоном  </span>-->
<!--              </div>-->
<!--              <div class="category_prod">-->
<!--                <span class="grey"><b>Штаны, кофты (по отдельности)</b></span>-->
<!--              </div>-->
<!--              <div class="color_prod">-->
<!--                <span>Изум.</span>-->
<!--              </div>-->
<!--              <div class="size_prod">-->
<!--                <span>M</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapMutations, mapState } from 'vuex'
import helper from '../../../../helpers/helper'
import Headline from '../../../Helpers/Headline'

export default {
  name: 'AddInvoiceProducts',
  components: {
    Headline
  },
  data () {
    return {
      timer: null,
      headers: [
        {
          title: 'ID',
          sortable: true,
          class: 'id_prod',
          value: 'id'
        },
        {
          title: 'Артикул',
          class: 'code_prod',
          sortable: true,
          value: 'vendor_code'
        },
        {
          title: 'Название',
          class: 'name_prod',
          sortable: true,
          value: 'name'
        },
        {
          title: 'Категория',
          class: 'category_prod'
          //  TODO sort by category name
        },
        {
          title: 'Цвет',
          class: 'color_prod'
          //  TODO sort by color
        },
        {
          title: 'Размеры',
          class: 'size_prod'
          //  TODO sort by sizes
        }
      ]
    }
  },
  computed: {
    ...mapState('catalog', ['childCategories']),
    ...mapState('season', ['seasons']),
    ...mapState('incoming', ['product_list', 'product_list_filters', 'inc_invoice', 'product_list_pagination']),
    invoice_data: {
      get () {
        return this.inc_invoice
      },
      set (val) {
        this.setIncInvoice(val)
      }
    },
    product_filters: {
      get () {
        return this.product_list_filters
      },
      set (val) {
        this.setProductListFilters(val)
      }
    },
    sortBy: {
      get () {
        return this.product_filters.order_by
      },
      set (val) {
        this.setProductListOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.product_filters.order_direction
      },
      set (val) {
        this.setProductListOrderDirection(val)
      }
    },
    page: {
      get () {
        return this.product_filters.page
      },
      set (value) {
        this.setProductListPage(value)
        this.getProductList()
      }
    },
    disabled_field () {
      return false
      // return this.order_data.ttn !== null || this.order_data.delivery_status > 1 || this.order_data.status > 1
    }
  },
  watch: {
    sortBy () {
      this.getProductList()
    },
    sortDesc () {
      this.getProductList()
    }
  },
  methods: {
    ...mapActions('catalog', ['getChildCategories']),
    ...mapActions('season', ['getSeasons']),
    ...mapActions('incoming', ['getProductList', 'updateIncomingInvoice']),
    ...mapMutations('incoming', ['setProductListFilters', 'clearProductListFilters', 'setProductInInvoice', 'setProductListOrderBy', 'setProductListOrderDirection', 'setIncInvoice', 'setProductListPage']),
    clearOrderItemFilter () {
      this.clearProductListFilters()
      this.$nextTick(() => {
        this.getProductList()
      })
    },
    getColorRemains (val) {
      return helper.getColorRemains(val)
    },
    addInvoiceProduct (product) {
      if (this.disabled_field) {
        return false
      }
      product.remains = 1
      product.price = product.purchase_price
      this.invoice_data.products.push(product)
      this.$nextTick(() => {
        this.setProductInInvoice()
      })
      if (this.invoice_data.id) {
        this.$nextTick(() => {
          this.$emit('change-product')
        })
      }
    },
    delInvoiceProduct (product) {
      if (this.disabled_field) {
        return false
      }

      this.invoice_data.products = _.filter(this.inc_invoice.products, p => { return p.product_color_size_id !== product.product_color_size_id })
      if (this.inc_invoice.id) {
        this.$nextTick(() => {
          this.$emit('change-product')
        })
      }
      this.setProductInInvoice()
    },
    updateFilterInput (e) {
      if (e.target.value !== '') {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.getProductList()
        }, 1000)
      }
    }
  },
  created () {
    this.clearProductListFilters()
    this.getChildCategories()
    this.getSeasons()
    this.getProductList()
  }
}
</script>

<style scoped lang="scss">
.add-product-wrap {
  position: relative;
  .add-product {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    .disabled {
      background-color: grey;
    }
  }
  &:hover {
    .add-product {
      z-index: 1;
      opacity: 1;
    }
  }

}
</style>
