import axios from 'axios'
import { eventBus } from '../../main'
import router from '../../router'
// import { eventBus } from '@/main'

const defaultContent = {
  name: null,
  title: null,
  content: '',
  position: null,
  sort: 0
}

const baseUrl = 'api/contents/'

export default {
  namespaced: true,
  state: {
    position_list: {
      1: 'ИНФОРМАЦИЯ',
      2: 'СЛУЖБА ПОДДЕРЖКИ'
    },
    contents: [],
    create_content: defaultContent,
    edit_content: defaultContent,
    filters: {
      search: null,
      page: 1,
      per_page: localStorage.getItem('content-per-page') || 10
    },
    pagination: {
      last_page: 1
    },
    show_delete: false
  },
  mutations: {
    setContents (state, payload) {
      state.contents = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPage (state, v) {
      state.filters.page = v
    },
    setSearch (state, v) {
      state.filters.search = v
    },
    setPerPage (state, v) {
      state.filters.per_page = v
      localStorage.setItem('content-per-page', v)
    },
    setEditContent (state, payload) {
      state.edit_content = payload
    },
    clearEditDate (state) {
      state.edit_content = defaultContent
    },
    setShowDelete (state, v) {
      state.show_delete = v
    }
  },
  actions: {
    getContents ({ commit, state }) {
      axios.get(baseUrl,
        {
          params: state.filters
        }
      )
        .then(res => {
          commit('setContents', res.data.data)
          commit('setPagination', res.data.meta)
        })
    },
    getContent ({ commit }, id) {
      console.log('test ddd')
      axios.get(baseUrl + id).then(res => {
        commit('setEditContent', res.data.data)
      })
    },
    createContent ({ state }, data) {
      axios.post(baseUrl, data).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Сохранено'
        )
        router.go(-1)
      })
    },
    updateContent ({ state }) {
      axios.put(baseUrl + state.edit_content.id, state.edit_content).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Обновлено'
        )
        router.go(-1)
      })
    },
    updatePublish ({ state }, data) {
      axios.put(baseUrl + data.id, {
        is_published: data.value
      }).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Обновлено'
        )
      })
    },
    updateSort ({ state }, data) {
      axios.put(baseUrl + data.id, {
        sort: data.value
      }).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Обновлено'
        )
      })
    },
    deleteContent ({ dispatch, state }) {
      axios.delete(baseUrl + state.edit_content.id).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Удалено'
        )
        dispatch('getContents')
      })
    }
  }
}
