<template>
  <div class="discount_to" data-id="to_product" :class="{ active: show_add_discount_product_modal }">
    <div class="top flx">
      <span class="page_subtitle">Скидка на товар</span>
      <span class="close" @click="setShowAddDiscountProductModal(false)"> </span>
    </div>
    <div class="row flx">
      <div class="inp_wrap">
        <p>Название:</p>
        <div class="select" >
          <brew-select
              name="dropshipper"
              class="vue-select select-search"
              v-model="discount_data.model_id"
              :options="products"
              :clearable="false"
              label="name"
              @search="fetchProducts"
              :reduce="item => item.id"
              :class="{required: hasError('model_id')}"
          >
            <template #selected-option="{ name }">
              <truncate-text :text="name.toString()" :clamp="32"></truncate-text>
            </template>
          </brew-select>
        </div>
      </div>
      <div class="inp_wrap">
        <p>Скидка:</p>
        <div class="wrap_disc flx">
          <input type="number" id="discount" placeholder="0" v-model.number="discount_data.value">
          <div class="select select-search discount-select-type" id="discount_value">
            <brew-select
                class="select-discount-type vue-select "
                :class="{required:hasError('type')}"
                :clearable="false"
                :options="discount_types"
                v-model="discount_data.type"
            ></brew-select>
          </div>
          <div class="btn"  @click="store">Добавить</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TruncateText from '../../helpers/components/TruncateText'
const modelType = 'product'
export default {
  name: 'AddDiscountProductModal',
  props: {
    id_user: Number,
    type_model: String
  },
  components: {
    TruncateText
  },
  data () {
    return {
      discount_types: [
        '%', 'грн'
      ]
    }
  },
  computed: {
    ...mapState('product', ['products']),
    ...mapState('discount', ['show_add_discount_product_modal', 'discount']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    discount_data: {
      get () {
        return this.discount
      },
      set (val) {
        this.setDiscount(val)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    }
  },
  methods: {
    ...mapActions('discount', ['createUserDiscount']),
    ...mapMutations('discount', ['setShowAddDiscountProductModal', 'setDiscount', 'setModelType', 'setDiscountUserId', 'setDiscountType', 'setDiscountValue']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapMutations('product', ['setSearch', 'setProducts']),
    ...mapActions('product', ['getProducts']),
    fetchProducts (search) {
      if (search.length >= 3) {
        this.setSearch(search)
        this.$nextTick(() => {
          this.getProducts()
        })
      }
    },
    store () {
      this.createUserDiscount(this.discount)
    }
  },
  watch: {
    show_add_discount_product_modal () {
      this.discount_data.model_id = null
      this.setSearch('')
      this.setProducts([])
      this.setModelType(modelType)
      this.setDiscountUserId(this.id_user)
      this.setDiscountType('%')
      this.setDiscountValue(0)
    }
  },
  mounted () {
    this.setModelType(modelType)
    this.setDiscountUserId(this.id_user)
    this.setDiscountType('%')
    this.setDiscountValue(0)
  }
}
</script>

<style scoped>

</style>
