<template>
  <div class="delivery_form flx box_shadow column">
    <div class="top flx">
      <span class="page_subtitle">Доставка</span>
      <div class="inp_wrap">
      <p>Статус заказа:  </p>
      <div class="select">
        <super-select
          key="order-status"
          item-key="order_status"
          field-name="name"
          :items="order_statuses"
          v-model="orderStatusId"
          searchable="false"
        />
      </div>
      </div>
    </div>
    <div class="bot" v-if="order_data.delivery_data">
      <div class="row flx">
        <div class="inp_wrap">
          <p>Тип доставки:</p>
          <div class="select">
            <i class="ti-angle-down"></i>
            <super-select
              key="select-delivery"
              item-key="select-delivery"
              field-name="name"
              :items="deliveries"
              v-model="order_data.delivery_data.delivery_id"
              searchable="false"
            />
          </div>
        </div>
        <div class="inp_wrap" v-if="order.delivery_data && order.delivery_data.delivery_id === 1">
          <p>Кто оплачивает:</p>
          <div class="select" id="storage" v-if="config.order">
            <super-select
              :class="{required: hasError('delivery_data.delivery_pay_person')}"
              key="select-delivery-pay-person"
              item-key="select-delivery"
              field-name="name"
              :items="config.order.pay_person"
              v-model="order_data.delivery_data.delivery_pay_person"
              searchable="false"
            />
          </div>
        </div>
      </div>
      <div class="row flx" >
        <div class="inp_wrap" v-if="order.delivery_data && order.delivery_data.delivery_id === 1">
          <p>Оплата:</p>
          <div class="select" id="payment_delivery" v-if="config.order">
            <super-select
              :class="{required: hasError('pay_type')}"
              key="select-pay-types"
              item-key="select-pay-types"
              field-name="name"
              :items="config.order.pay_types"
              v-model="order_data.pay_type"
              searchable="false"
            />
          </div>
        </div>
        <div class="inp_wrap" v-if="order.delivery_data && order.delivery_data.delivery_id === 2">
          <p>Оплата:</p>
          <div class="select" id="payment_delivery2" v-if="config.order">
            <super-select
              :class="{required: hasError('pay_type')}"
              key="select-pay-types-2"
              item-key="select-pay-types-2"
              field-name="name"
              :items="config.order.pay_types_method_2"
              v-model="order_data.pay_type"
              searchable="false"
            />
          </div>
        </div>
        <div class="inp_wrap" v-if="order.delivery_data && order.delivery_data.delivery_id === 1">
          <p>Кто оплачивает:</p>
          <div class="select" v-if="config.order">
            <super-select
              :class="{required: hasError('pay_person')}"
              key="select-pay-person"
              item-key="select-pay-person"
              field-name="name"
              :items="filtered_pay_person"
              v-model="order_data.pay_person"
              searchable="false"
            />
          </div>
        </div>
      </div>
      <div class="row registr_post flx" :class="{active:!order_data.ttn}" v-if="config.order && order.delivery_data && order.delivery_data.delivery_id === 1">
        <span>Дата регистрации декларации в Новой Почте:</span>
        <div class="select" id="type_delivery">
          <brew-select
              class="select-delivered-at vue-select"
              :class="{required:hasError('period_id'), disabled: disabled_field }"
              :clearable="false"
              :options="config.order.periods"
              label="name"
              :reduce="item => item.id"
              v-model="period_id"
              :disabled="disabled_field"
          ></brew-select>
        </div>
        <div class="btn" @click="regDeclaration" :class="{disabled: !order.id}" :disabled="!order.id">Зарегистрировать</div>
      </div>
      <div class="row declaration_post flx" :class="{active:order_data.ttn}">
        <div class="decl_number_wrap flx">
              <span class="decl_number">ТТН №  <span v-html="asTTNwithHTML(order_data.ttn)"></span>  <span class="close open_pop"
                                                                               data-id="delete_ttn" @click="delDeclaration"></span></span>
          <span class="note blue">Создан!</span>
        </div>
        <div class="btn empty" @click="printDeclaration" v-if="order_data.prints">Распечатать декларацию</div>
        <div class="btn" @click="printSticker" v-if="order_data.prints">Распечатать наклейку</div>
      </div>
<!--      <div><input id="printed" v-model="isPrinted" class="check" type="checkbox"><label for="printed">Распечатано</label></div>-->
    </div>
    <div class="sender_form">
      <p>Отправитель:</p>
      <div class="select">
        <super-select
          key="select-sender"
          item-key="sender-item"
          field-name="full_name"
          :class="{required:hasError('sender_id'), disabled: disabled_field }"
          :items="senders"
          :init-selected="order_data.sender"
          v-model="order_data.sender_id"
          clearable="true"
        />
<!--        <brew-select-->
<!--            name="sender"-->
<!--            class="vue-select"-->
<!--            :class="{required:hasError('sender_id'), disabled: disabled_field }"-->
<!--            v-model="order_data.sender_id"-->
<!--            :options="senders"-->
<!--            :reduce="item => item.id"-->
<!--            label="last_name"-->
<!--            :disabled="disabled_field"-->
<!--        >-->
<!--          <template #selected-option="{ last_name, first_name, middle_name, remains }">-->
<!--            {{ last_name }} {{ first_name }} {{ middle_name }} ({{remains}})-->
<!--          </template>-->
<!--          <template v-slot:option="option">-->
<!--            {{ option.last_name }} {{ option.first_name }} {{ option.middle_name }} ({{option.remains}})-->
<!--          </template>-->
<!--        </brew-select>-->
      </div>
    </div>
    <div class="delivery_bot">
      <div class="row package flx">
        <div class="inp_wrap">
          <p>Упаковка:</p>
          <div class="select" id="wrap_post">
            <brew-select
                class="select-delivered-at vue-select"
                :clearable="false"
                :options="packages"
                label="name"
                :reduce="item => item.id"
                v-model="m_package_id"
                :disabled="disabled_field"
            ></brew-select>
          </div>
        </div>
        <div class="inp_wrap" v-if="m_package_id === 1">
          <p>Объем, м3</p>
          <input type="number" id="volume_package" min="0.001" step="0.001" :value="order_volume" @input="payloadOrderDeliveryVolume" :disabled="disabled_field">
        </div>
        <div class="inp_wrap">
          <p>Мест:</p>
          <input type="number" id="num_seats" min="1" :value="order_seats" @input="payloadOrderDeliverySeats" :disabled="disabled_field">
        </div>
      </div>
      <div v-if="m_package_id===2">
        <div class="row flx" v-for="(order_dimension, index) in order_dimensions" :key="'order-dimension-'+index">
          <div class="inp_wrap">
            <p>Длина, см:</p>
            <input type="number" :value="order_dimension.length" @input="payloadOrderDeliveryDimensionLength($event, index)" :disabled="disabled_field">
          </div>
          <div class="inp_wrap">
            <p>Ширина, см:</p>
            <input type="number" :value="order_dimension.width" @input="payloadOrderDeliveryDimensionWidth($event, index)" :disabled="disabled_field">
          </div>
          <div class="inp_wrap">
            <p>Высота, см:</p>
            <input type="number" :value="order_dimension.height" @input="payloadOrderDeliveryDimensionHeight($event, index)" :disabled="disabled_field">
          </div>
        </div>
      </div>
      <div class="row weight_package flx">
        <div class="inp_wrap weight">
          <p>Вес, кг:</p>
          <input type="number" name="weight" class="disabled" disabled :value="get_total_weight()" v-if="change_total_weight">
          <input type="number" name="weight"  :value="order_weight" @input="payloadOrderDeliveryWeight" v-else :disabled="disabled_field">
        </div>
        <label class="custom-checkbox">
          <input type="checkbox" name="check" :checked="change_total_weight" @change="payloadOrderDeliveryCustomWeight" :disabled="disabled_field">
          <span></span>
        </label>
        <helper-info>
          <template v-slot:description>
            <p>Вес посылки расчитывается автоматически на основании веса, указанного в каждой номенклатуре.</p>
            <p>Если Вы хотите указать произвольный вес - снимите галочку и впишите в поле нужное значение.</p>
          </template>
        </helper-info>
        <div class="wrap flx">
          <div class="sum_weight">
            <p>Объемный вес:</p>
            <span>{{ get_volume_weight() }} кг.</span>
          </div>
        </div>
        <div class="wrap flx">
        <div class="sum_weight">
          <p>Доставка / Возврат:</p>
          {{ order_data.delivery_data.delivery_cost | asPriceNoCurrency}} / {{ order_data.return_cost | asPriceNoCurrency}}
        </div>
        </div>
      </div>
    </div>
    <change-order-status-modal></change-order-status-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import helper from '../../../../helpers/helper'
import { dates } from '../../../../helpers/dates'
import { numbers } from '../../../../helpers/numbers'
import HelperInfo from '../../../Helpers/HelperInfo'
import _ from 'lodash'
import { prices } from '../../../../helpers/prices'
import ChangeOrderStatusModal from '../../../Modals/ChangeOrderStatusModal'
import SuperSelect from '../../../Helpers/SuperSelect/SuperSelect'
let timer
export default {
  name: 'OrderDelivery',
  mixins: [
    dates,
    numbers,
    prices
  ],
  components: {
    HelperInfo,
    ChangeOrderStatusModal,
    SuperSelect
  },
  data () {
    return {
      period_id: 1,
      packages: [
        {
          id: 1,
          name: 'Объем'
        },
        {
          id: 2,
          name: 'Габариты'
        }
      ]
    }
  },
  computed: {
    ...mapState('orders', ['order']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapState('delivery', ['deliveries']),
    ...mapState('senders', ['senders']),
    ...mapState('config', ['config']),
    ...mapGetters('orders', ['get_total_weight', 'get_volume_weight']),
    orderStatusId: {
      get () { return this.order.status },
      set (v) {
        this.order.status = v
        this.changeStatus({
          order_id: this.order.id,
          status_id: v
        })

        clearTimeout(timer)
        timer = setTimeout(() => {
          this.getOrder(this.$route.params.id)
        }, 1000)
      }
    },
    order_statuses () {
      if (!this.config.order) {
        return []
      }

      return this.config.order.statuses
      // return _.filter(this.config.order.statuses, s => { return s.id !== 1 })
    },
    order_data: {
      get () {
        return this.order
      },
      set (val) {
        this.setOrder(val)
      }
    },
    isPrinted: {
      get () { return this.order.is_printed },
      set (v) {
        if (v) {
          this.markPrint(this.order)
        } else {
          this.unmarkPrint(this.order)
        }
      }
    },
    m_package_id: {
      get () {
        return this.order?.delivery_data?.package_id || 1
      },
      set (id) {
        this.$set(this.order_data.delivery_data, 'package_id', id)
      }
    },
    order_volume: {
      get () {
        return this.order?.delivery_data?.volume || 0.001
      }
    },
    order_seats: {
      get () {
        return this.order?.delivery_data?.seats || 1
      }
    },
    order_weight: {
      get () {
        return this.order?.delivery_data?.weight || 0
      }
    },
    order_dimensions: {
      get () {
        return this.order?.delivery_data?.dimensions || [{
          length: 0,
          width: 0,
          height: 0
        }]
      },
      set (data) {
        this.order.delivery_data.dimensions = data
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    },
    filtered_pay_person: {
      get () {
        return _.forEach(this.config?.order?.pay_person, (item) => {
          item.disabled = this.order_data.pay_type === 1 && item.id !== 1
          return item
        }) || []
      }
    },
    change_total_weight: {
      get () {
        return !this.order?.delivery_data?.is_custom_weight
      }
    },
    disabled_field () {
      return false
      // return this.order_data.ttn !== null || this.order_data.delivery_status > 1 || this.order_data.status > 1
    }
  },
  watch: {
    'order_data.delivery_data.delivery_id' (value) {
      this.order_data.client_data.delivery_id = value
      if (this.order_data.delivery_data.delivery_id === 2 && this.order_data.pay_type === 1) {
        this.order_data.pay_type = 2
      }
    },
    'order_data.pay_type' (value) {
      if (value === 1) {
        this.order_data.pay_person = 1
      }
    },
    'change_total_weight' (value) {
      if (value) {
        this.$set(this.order_data.delivery_data, 'weight', this.get_total_weight())
      }
    },
    'm_package_id' (value) {
      if (value === 2) {
        this.$set(this.order_data.delivery_data, 'volume', 0.001)
        if (!this.order_data.delivery_data.dimensions) {
          this.$set(this.order_data.delivery_data, 'dimensions', [{ width: 0, length: 0, height: 0 }])
        }
      }
    },
    'order_seats' (value) {
      this.delDimensionsAfter(value)
      for (let i = 0; i < value; i++) {
        if (!this.order_data.delivery_data?.dimensions[i]) {
          this.addDimension({
            length: 0,
            width: 0,
            height: 0
          })
        }
      }
    }
  },
  methods: {
    ...mapMutations('orders', ['setOrder', 'addOrderDeliveryEmptyDimension', 'setOrderDimensionWidth', 'setPackageId', 'setShowDeleteTTNModal', 'addDimension', 'delDimensionsAfter']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapMutations('senders', ['setSenderPerPageV1']),
    ...mapActions('senders', ['getSenders']),
    ...mapActions('orders', ['getOrder', 'createEn', 'markPrint', 'unmarkPrint', 'updateOrder', 'changeStatus']),
    getOrderStatusColor (order) {
      return helper.getOrderStatusColor(order.status)
    },
    payloadOrderDeliveryVolume (e) {
      this.$set(this.order_data.delivery_data, 'volume', e.target.valueAsNumber)
    },
    payloadOrderDeliverySeats (e) {
      this.$set(this.order_data.delivery_data, 'seats', e.target.valueAsNumber)
    },
    payloadOrderDeliveryWeight (e) {
      this.$set(this.order_data.delivery_data, 'weight', e.target.valueAsNumber)
    },
    payloadOrderDeliveryDimensionLength (e, index) {
      this.$set(this.order_data?.delivery_data.dimensions[index], 'length', e.target.valueAsNumber)
    },
    payloadOrderDeliveryDimensionWidth (e, index) {
      this.$set(this.order_data.delivery_data.dimensions[index], 'width', e.target.valueAsNumber)
    },
    payloadOrderDeliveryDimensionHeight (e, index) {
      this.$set(this.order_data.delivery_data.dimensions[index], 'height', e.target.valueAsNumber)
    },
    payloadOrderDeliveryCustomWeight (e) {
      this.$set(this.order_data.delivery_data, 'is_custom_weight', !e.target.checked)
    },
    async regDeclaration () {
      if (!this.order.id) {
        return
      }
      await this.updateOrder()
      await this.createEn([this.order.id, this.period_id])
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
    },
    delDeclaration () {
      this.setShowDeleteTTNModal(true)
    },
    printDeclaration () {
      this.markPrint(this.order)
      window.open(this.order.prints?.document)
    },
    printSticker () {
      this.markPrint(this.order)
      window.open(this.order.prints?.marker_100)
    }
  },
  created () {
    this.setSenderPerPageV1(50) // TODO FIX
    this.getSenders()
  }
}
</script>

<style scoped lang="scss">

</style>
