
export default {
  getColorRemains (value) {
    if (value === 0) {
      return 'clock'
    }

    if (value > 0 && value < 10) {
      return 'red'
    }

    if (value >= 10 && value < 20) {
      return 'orange'
    }

    if (value >= 20 && value < 50) {
      return 'blue'
    }

    if (value >= 50) {
      return 'green'
    }
    // infinity
  },
  getColorBalance (value) {
    if (value > 0) {
      return 'green'
    }
    if (value < 0) {
      return 'red'
    }
    return 'grey'
  },
  formatDigit (value) {
    const formattedValue = parseFloat(value)

    value = isNaN(formattedValue) ? null : formattedValue
    return value
  },
  getOrderClassByStatus (value) {
    let orderClass = ''
    switch (value) {
      case 1: {
        break
      }
      case 2:
      case 4: {
        orderClass = 'attention'
        break
      }
      case 3: {
        orderClass = 'success'
        break
      }
    }
    return orderClass
  },
  getOrderStatusColor (value) {
    let orderClass = ''
    switch (value) {
      case 1: {
        orderClass = 'yellow'
        break
      }
      case 2: {
        orderClass = 'blue'
        break
      }
      case 3: {
        orderClass = 'green'
        break
      }
      case 4: {
        orderClass = 'red'
        break
      }
    }
    return orderClass
  },
  getOrderClassByDeliveryStatus (value) {
    let orderClass = ''
    switch (value) {
      case 1: {
        break
      }
      case 2: {
        break
      }
      case 3: {
        break
      }
      case 4: {
        orderClass = 'return'
        break
      }
      case 5: {
        orderClass = 'closed_return'
        break
      }
      case 6: {
        break
      }
    }
    return orderClass
  },
  getOrderDeliveryColor (value) {
    let orderClass = ''
    switch (value) {
      case 1: {
        orderClass = 'grey'
        break
      }
      case 2: {
        orderClass = 'blue'
        break
      }
      case 3: {
        orderClass = 'orange'
        break
      }
      case 4: {
        orderClass = 'red'
        break
      }
      case 5: {
        orderClass = 'orange'
        break
      }
      case 6: {
        orderClass = 'green'
        break
      }
    }
    return orderClass
  },
  // getAbbreviatedName (name) {
  //   return name.replace(/(?<=\S+) (\S)\S* (\S)\S*/, ' $1. $2.')
  // },
  getDiscountText (type) {
    if (!type) return ''
    switch (type) {
      case 'product': {
        return 'Скидка на товар'
      }
      case 'category': {
        return 'Скидка на категорию'
      }
      case 'color': {
        return 'Скидка на цвет'
      }
      default: return ''
    }
  },
  getRuleName (key) {
    if (!key) return ''
    switch (key) {
      case 'product': {
        return 'Товары'
      }
      case 'order': {
        return 'Заказы'
      }
      default: return ''
    }
  },
  getOperationByBillingInfo (sum) {
    if (!sum || sum === 0) {
      return ''
    }
    return +sum > 0 ? 'Забрать' : 'Отдать'
  },
  getBillingInfoColor (value) {
    if (value > 0) {
      return 'green'
    }
    if (value < 0) {
      return 'red'
    }
    return ''
  },
  getPriceNoCurrencyString (value) {
    let price = Number.prototype.toFixed.call(parseFloat(value) || 0, 2)
    price = price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    return price
  },
  getIncInvoiceClass (value) {
    if (value > 0) {
      return 'payment'
    }
    if (value <= 0) {
      return ''
    }
    return 'block'
  }
}
