<template>
  <section class="table_box single_order">
    <loader v-if="is_loading" object="#5C6579" color1="#ccc" color2="#FFA500" size="5" speed="2" bg="#fff" objectbg="#999793" opacity="80" name="circular"></loader>
    <div class="container reverse large flx">
      <div class="table_head flx">
        <span class="page_title long">Информация о заказе ID: {{ order.id }}</span>
        <div class="btn empty open_pop" data-id="history_change" @click="toggleHistoryModal">История изменений</div>
        <span class="del_wrap" @click="cancelOrder"><span class="close"> </span><span class="red">Отменить заказ</span></span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn empty" @click="sendCopyOrder">Копировать заказ</div>
        <div class="actions_cont export">
          <div class="btn actions_btn empty" data-id="actions_box" :class="{ active: show_order_actions }" @click="toggleOrderActions">
            <span>Действия</span>
            <svg width="9" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L4 4L7 1" stroke="#F5A32A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <order-actions></order-actions>
        </div>
        <router-link class="btn" v-if="order.id" :to="{ name: 'EditOrder', params: { id: order.id }}">Редактировать</router-link>
      </div>
    </div>
    <div class="container tbl flx">
      <div class="box_shadow info_single_order">
        <div class="top flx">
          <span class="page_subtitle">Заказ #{{ order.id }}</span>
        </div>
        <div class="row order">
          <div class="item flx">
            <span class="name">ТТН:</span>
            <div class="declaration_info">
              <order-ttn v-if="order.ttn" :order="order"></order-ttn>
            </div>
          </div>
          <div class="item flx">
            <span class="name">Статус заказа:</span>
            <span class="status mt-0" :class="getOrderStatusColor(order)">{{ order.status_name }}</span>
          </div>
          <div class="item flx">
            <span class="name">Статус доставки:</span>
            <span class="status mt-0" :class="getOrderDeliveryColor(order)">{{ order.delivery_status_name}}</span>
          </div>
        </div>
        <div class="row">
          <span class="page_subtitle">Стоимость</span>
          <div class="item flx">
            <span class="name">Сумма дроп:</span>
            <span>{{ order.total_drop | asPrice }}</span>
          </div>
          <div class="item flx">
            <span class="name">Скидка:</span>
            <span v-if="order.discount">{{ order.discount.value}}{{ order.discount.type}}</span>
            <span v-else>0%</span>
          </div>
          <div class="item flx">
            <span class="name">Выплата продавцу:</span>
            <span :class="getColorBalance(order.drop_profit)"><b>{{ order.drop_profit | asPrice }}</b></span>
          </div>
          <div class="item flx">
            <span class="name">Финальная цена продажи:</span>
            <span><b>{{ order.total_final | asPrice }}</b></span>
          </div>
        </div>
        <div class="row">
          <span class="page_subtitle">Товары・Комментарий</span>
          <p>
            <span>
              {{order.comment}}
            </span>
          </p>
          <span v-if="order.comment && order.comment.length > 100" class="status red">Подписать!</span>
        </div>
        <div class="row" style="background: antiquewhite;" v-if="order.seller_comment">
          <span class="page_subtitle">Комментарий продавца</span>
          <p>
            <span>
              {{order.seller_comment}}
            </span>
          </p>
        </div>
      </div>
      <div class="box_shadow products_in_order" v-if="order.products">
        <div class="top">
          <span class="page_subtitle">Товары <span><b>({{ order.products.length }})</b></span></span>
        </div>
        <div class="row flx" v-for="product in order.products" :key="'show-order-product-'+product.product_color_size_id">
          <img style="object-fit: cover;" v-if="product.image_url" :src="product.image_url" :alt="product.name">
          <img v-else src="/images/no-img.svg">
          <div class="item">
            <span><b>{{ product.name }}</b> ({{product.qty}} шт)</span>
            <span class="grey">{{ product.category_name }}</span>
            <span>{{ product.vendor_code}} · {{ product.size }} · {{ product.color }}</span>
            <span><b>{{ product.discount_price | asPrice }} / {{ product.total_final | asPrice }}</b></span>
          </div>
        </div>
      </div>
      <div class="box_shadow delivery_info_order">
        <div class="row">
          <span class="page_subtitle">Продавец</span>
          <div class="item flx">
            <span class="name">Продал:</span>
            <span v-if="order.dropshipper">
              [<router-link :to="{ name: 'EditDropshipper', params: { id: order.dropshipper.id }}">{{ order.dropshipper_id }}</router-link>]
              <b>{{ order.dropshipper.name }}
                <span class="grey">({{order.dropshipper.balance }})
                </span>
              </b>
            </span>
          </div>
        </div>
        <div class="row">
          <span class="page_subtitle">Доставка</span>
          <div class="item flx" v-if="order.client_data">
            <span class="name">Город:</span>
            <span v-if="order.delivery_method_id === 1 && order.client_data.city&&order.client_data.branch">
                  <b v-if="order.client_data.city.name">{{ order.client_data.is_ua ? order.client_data.city.name_ua : order.client_data.city.name }} </b>
                  <span v-if="order.client_data.branch.number" class="grey"> (склад {{ order.client_data.branch.number }})</span>
                  <span v-else-if="order.client_data.branch.name" class="grey"> ({{ order.client_data.is_ua ?  order.client_data.branch.name_ua : order.client_data.branch.name }})</span>
            </span>
            <span v-if="order.delivery_method_id === 2 && order.client_data.city">
                  <b v-if="order.client_data.city.name">{{ order.client_data.is_ua ? order.client_data.city.name_ua : order.client_data.city.name }} </b><br>
                  <span class="grey" v-if="order.client_data.address">{{ order.client_data.address.street ? order.client_data.address.street.name : '' }} {{ order.client_data.address.house ?  order.client_data.address.house : 'н-д'}} {{ order.client_data.address.appt}}</span>
            </span>
          </div>
          <div class="item flx">
            <span class="name">Тип доставки:</span>
            <span v-if="order.delivery_data">{{ order.delivery_data.delivery_name }}</span>
          </div>
          <div class="item flx">
            <span class="name">Кто оплачивает:</span>
            <span class="grey" v-if="order.delivery_data">{{ order.delivery_data.delivery_pay_person_name }}</span>
          </div>
          <div class="item flx">
            <span class="name">Отправитель:</span>
            <span class="grey" v-if="order.sender">{{ order.sender.last_name }} {{ order.sender.first_name | firstLetter }} {{ order.sender.middle_name | firstLetter }}</span>
          </div>
        </div>
        <div class="row">
          <span class="page_subtitle">Получатель</span>
          <div class="item flx" v-if="order.client_data">
            <span class="name">ФИО:</span>
            <span><b>{{ order.client_data.last_name }} </b>{{ order.client_data.first_name }} {{ order.client_data.middle_name }}</span>
          </div>
          <div class="item flx" v-if="order.client_data">
            <span class="name">Телефон:</span>
            <span v-if="order.client_data.phone">{{ order.client_data.phone | removeCodeCountry }}</span>
          </div>
        </div>
        <div class="row" v-if="order.delivery_id === 1">
          <span class="page_subtitle">Оплата</span>
          <div class="item flx">
            <span class="name">Тип оплаты:</span>
            <span>{{ order.pay_type_name }}</span>
          </div>
          <div class="item flx">
            <span class="name">Кто оплачивает:</span>
            <span class="grey">{{ order.pay_person_name }}</span>
          </div>
          <div class="item flx" v-if="order.file">
            <span class="name">Файл:</span>
            <span class="red">
              <a :href="order.file" target="_blank">Посмотреть файл</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <order-history-modal :data="order.history"></order-history-modal>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import OrderTtn from './OrderTtn'
import OrderActions from './OrderActions'
import helper from '../../../helpers/helper'
import { prices } from '../../../helpers/prices'
import { strings } from '../../../helpers/strings'
import { phones } from '../../../helpers/phones'
import OrderHistoryModal from '@/components/Modals/OrderHistoryModal'

export default {
  name: 'Order',
  components: {
    OrderTtn,
    OrderActions,
    OrderHistoryModal
  },
  mixins: [
    prices,
    strings,
    phones
  ],
  computed: {
    ...mapState('orders', ['order', 'show_order_actions', 'is_loading']),
    ...mapState('history', ['show_history_modal'])
  },
  methods: {
    ...mapActions('orders', ['getSingleOrder', 'toggleOrderActions', 'createOrder', 'copyOrder']),
    ...mapMutations('orders', ['clearOrder']),
    ...mapActions('history', ['toggleHistoryModal']),
    loadOrder () {
      if (Object.keys(this.order).length === 0 || (Object.keys(this.order).length > 0 && this.order.id !== this.$route.params.id)) {
        this.getSingleOrder(this.$route.params.id)
      }
    },
    cancelOrder () {
    //  TODO cancel order
    },
    sendCopyOrder () {
      this.copyOrder(this.order)
    },
    getOrderStatusColor (order) {
      return helper.getOrderStatusColor(order.status)
    },
    getOrderDeliveryColor (order) {
      return helper.getOrderDeliveryColor(order.delivery_status)
    },
    getColorBalance (balance) {
      return helper.getColorBalance(balance)
    }
  },
  watch: {
    '$route.params.id': function (id) {
      if (this.$route.name === 'Order') {
        this.loadOrder()
      }
    }
  },
  created () {
    this.loadOrder()
  },
  beforeRouteLeave (from, to, next) {
    this.clearOrder()
    next()
  }
}
</script>

<style scoped>

</style>
