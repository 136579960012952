<template>
  <div class="popup_box" data-id="add_discount" :class="{ active: show_add_discount_dropshipper_modal }">
    <div class="popup add_discount">
      <span class="page_title">
        <slot name="title">Добавить скидку</slot>
      </span>
      <span class="close_popup" @click="setShowAddDiscountDropshipperModal(false)"></span>
      <p>Продавец:</p>
      <div class="select">
        <multi-select
          :clear-values="show_add_discount_dropshipper_modal"
          @users-list-changes="setUserIds"
        ></multi-select>
      </div>
      <div class="wrap flx">
        <div class="inp_wrap">
          <p>Скидка:</p>
          <input type="number" id="discount" placeholder="0" v-model.number="discount_data.value">
        </div>
        <div class="select select-search" id="discount_value">
          <brew-select
            class="select-discount-type vue-select"
            :class="{required:hasError('type')}"
            :clearable="false"
            :options="discount_types"
            v-model="discount_data.type"
          ></brew-select>
        </div>
      </div>
      <button class="btn" @click="store">Добавить скидку</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MultiSelect from '../Helpers/MultiSelect'

export default {
  name: 'AddDiscountDropshipperModal',
  components: {
    MultiSelect
  },
  props: {
    type_model: String,
    id_model: Number
  },
  data () {
    return {
      test_users: [
        { id: 1, name: 'test' }
      ],

      discount_types: [
        '%', 'грн'
      ]
    }
  },
  computed: {
    ...mapState('product', ['product']),
    ...mapState('catalog', ['category']),
    ...mapState('discount', ['show_add_discount_dropshipper_modal', 'discount']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapState('users', ['dropshippers']),
    discount_data: {
      get () {
        return this.discount
      },
      set (val) {
        this.setDiscount(val)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    }
  },
  methods: {
    ...mapActions('discount', ['createDiscount']),
    ...mapMutations('discount', ['setShowAddDiscountDropshipperModal', 'setDiscount', 'setModelType', 'setModelId']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapActions('users', ['searchDropshippers', 'getDropshippers']),
    fetchDropshippers (search) {
      if (search.length >= 3) {
        this.searchDropshippers(search)
      }
    },
    store () {
      this.createDiscount(this.discount)
    },
    setUserIds (ids) {
      this.discount_data.user_ids = ids
    }
  },
  watch: {
    type_model (type) {
      this.setModelType(type)
    },
    id_model (id) {
      this.setModelId(id)
    }
  },
  created () {
    this.clearErrors()
    this.getDropshippers()
  },
  mounted () {
    this.setModelType(this.type_model)
    this.setModelId(this.id_model)
  },
  beforeDestroy () {
    console.log('des')
  }
}
</script>

<style scoped>

</style>
