<template>
  <div class="image_wrap flx">
    <div
      class="item"
      v-for="(image, index) in images"
      :key="index"
      @click="selectedImage = index"
    >
      <div class="delete-image" v-if="image.image_source || image.url" @click="removeImage(index)">x</div>
      <img :src="image.image_source || image.url" alt="">
    </div>
    <div
      @dragover.prevent
      @drop.prevent
      @drop="dragFile"
      class="item"
    >
      <label class="custom-file-upload">
        <input class="upload-file-input" multiple type="file" ref="file" @change="uploadFile()">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABoElEQVRoge2YPU7DQBCFP/HT01DFEQ1Q01NxAw7BMSgoKZE4RWSlInABEnMELkCJJWJKpJjCtmSCDbvr2fXfPmnkaLUzb15mNLs2eHh4jBpLIK2wVWlPZGG9bEudhHdq1s9r1lPLv1Vy0ELxr7QFbf66ivQOgxGyV7P+AmxcJtIxfg9riPg563vL78dvW/BTy8MxVKdGAIRAQvUNVtISYA6c6AhRmRoBEDsQsG0xMJEUEuZ7HnQCN8AEWOScM1WniP9fbIp2ciGiwDTn/JAM2tahWck7mAOxTkjbdy0xqLTN6Fvr1iWZytQyrUgK3Bj4NeUVD1j4mVamc0JMxWjxqkwtCSEmbabFa3NqVd2fdCrTaSE6YjonZAO8ApfAGXAgmNuvzdu2qtijixR4Aq6AL+DIwF+Z95lqIeWzxVTIPbAL7ANvwJ2mf2fGbxnXwCfqbSXFKx7wELhogdduwCa8g38f6R2aCEnyZyCRiCKm+XMtGXRO1qsL3IgJgMecM5QMfAq8Y+fb1V8WA8eSQiD7FDQjK7VtAWuySoiL8PAYA74BrJ0x93i8VhIAAAAASUVORK5CYII="/>
      </label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImagesBar',
  props: ['product_images'],
  data () {
    return {
      key: 0,
      files: null,
      selectedImage: 0,
      images: {

      }
    }
  },
  methods: {
    addImage (image) {
      this.$set(this.images, this.key, { image_source: image })
      this.key++
      // const lastIndex = Object.keys(this.images)[Object.keys(this.images).length - 1]
      // console.log(lastIndex)
      // this.images.push({ image_source: image })
      // console.log('add', image)
      // const lastIndex = Object.keys(this.images)[Object.keys(this.images).length - 1]
      // if (lastIndex === this.selectedImage) {
      //   this.$set(this.images, this.key, { image_source: image })
      //   this.key++
      //   //this.$set(this.images, this.key, { image_source: null })
      // } else {
      //   this.$set(this.images, this.selectedImage, { image_source: image })
      // }
      this.$emit('change_images', this.images)
    },
    removeImage (key) {
      this.$delete(this.images, key)
      this.$emit('change_images', this.images)
    },
    dragFile (e) {
      this.files = e.dataTransfer.files
      for (let i = 0; i < this.files.length; i++) {
        getBase64(this.files[i]).then(data => {
          this.addImage(data)
        })
      }
    },
    uploadFile () {
      this.files = this.$refs.file.files
      for (let i = 0; i < this.files.length; i++) {
        getBase64(this.files[i]).then(data => {
          this.addImage(data)
        })
      }
    }
  },
  watch: {
    product_images: function (newValue) {
      if (newValue !== undefined && newValue.length > 0) {
        this.key = 0
        this.images = { 0: { image_source: null } }

        newValue.forEach(item => {
          this.$set(this.images, this.key, { url: item.link, hash_name: item.hash_name })
          this.key++
          // this.$set(this.images, this.key, { image_source: null })
        })
      }
    }
  },
  created () {
    if (this.product_images.length > 0) {
      this.product_images.forEach(item => {
        this.$set(this.images, this.key, { url: item.link, hash_name: item.hash_name })
        this.key++
        // this.$set(this.images, this.key, { image_source: null })
      })
    }
  }
}

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
</script>

<style scoped>
.delete-image {
  position: absolute;
  left: 48px;
  top: -3px;
  background: #f5a32a;
  width: 15px;
  height: 15px;
  z-index: 12;
  border-radius: 100%;
  padding-left: 4px;
  line-height: initial;
  color: white;
  font-weight: bold;
}
.upload-file-input {
  display: none;
}

.custom-file-upload {
  height: 60px;
  width: 60px;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  display: inline-block;
  cursor: pointer;
}

.item {
  border: 1px solid #dbdbdb;
}

.custom-file-upload img {
  height: 40px !important;
  width: 40px !important;
  margin: 10px
;
}

</style>
