export const numbers = {
  filters: {
    asTTN (value) {
      if (!value) {
        return ''
      }
      const str = value.split('').reverse().join('')
      return [...str].map((d, i) => (i) % 4 === 0 ? ' ' + d : d).join('').trim().split('').reverse().join('')
    }
  },
  methods: {
    asTTNwithHTML (value) {
      if (!value) {
        return ''
      }
      let str = this.$options.filters.asTTN(value)
      str = str.replace(/\d{4}$/g, '<b>$&</b>')
      return str
    }
  }
}
