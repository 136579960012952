<template>
    <transition name="fade">
        <div v-if="show" class="popup_box" style="display: block">
            <div class="popup delete_category">
                <span class="page_title">Удалить продавца: {{dropshipper.name}}?</span>
                <span class="close_popup" @click="close()"></span>
                <button class="btn" @click="close()">Отмена</button>
                <button class="btn empty" @click="confirm()">Удалить</button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'DropshipperDeleteItemModal',
  computed: {
    ...mapState('users', ['show_dropshipper_delete_modal', 'dropshipper_on_delete']),
    show: {
      get () {
        return this.show_dropshipper_delete_modal
      },
      set (value) {
        this.setShowDropshipperDeleteModal(value)
      }
    },
    dropshipper: {
      get () {
        return this.dropshipper_on_delete
      },
      set (value) {
        this.setDropshipperOnDelete(value)
      }
    }
  },
  methods: {
    ...mapActions('users', {
      remove: 'deleteDropshipper'
    }),
    ...mapMutations('users', ['setShowDropshipperDeleteModal', 'setDropshipperOnDelete']),
    confirm () {
      this.remove(this.dropshipper.id)
      this.close()
    },
    close () {
      this.show = false
      this.dropshipper = null
    }
  }
}
</script>

<style scoped>

</style>
