import axios from 'axios'
import { eventBus } from '@/main'
const baseUrl = 'api/suppliers/'
const redirectTo = 'Suppliers'

export default {
  namespaced: true,
  state: {
    suppliers: [],
    supplier: {
      id: null,
      name: null,
      deposit: null
    },
    pagination: {
      last_page: 1
    },
    supplier_filters: {
      per_page: localStorage.getItem('suppliers-per-page') || 10,
      order_by: localStorage.getItem('suppliers-order-by') || 'id',
      order_direction: localStorage.getItem('suppliers-order-direction') || 'desc',
      search: null,
      page: 1
    },
    supplier_on_delete: null,
    show_delete_supplier_modal: false,
    suppliers_counters: {
      debit: 0,
      pay: 0
    },
    selected_suppliers: [],
    show_add_supplier_payment_modal: false,
    supplier_for_payment: null,
    inc_suppliers: []
  },
  mutations: {
    setSuppliers (state, suppliers) {
      state.suppliers = suppliers
    },
    setSupplier (state, supplier) {
      state.supplier = supplier
    },
    clearSupplier (state) {
      state.supplier = {
        id: null,
        name: null,
        deposit: null
      }
    },
    setPagination (state, pagination) {
      state.pagination = pagination
    },
    setFilters (state, filters) {
      state.supplier_filters = filters
    },
    clearFilters (state) {
      state.supplier_filters = {
        per_page: localStorage.getItem('suppliers-per-page') || 10,
        order_by: localStorage.getItem('suppliers-order-by') || 'id',
        order_direction: localStorage.getItem('suppliers-order-direction') || 'desc',
        search: null,
        page: 1
      }
    },
    setSearch (state, search) {
      state.supplier_filters.search = search
    },
    setPage (state, page) {
      state.supplier_filters.page = page
    },
    setPerPage (state, perPage) {
      localStorage.setItem('suppliers-per-page', perPage)
      state.supplier_filters.per_page = perPage
    },
    setOrderBy (state, orderBy) {
      localStorage.setItem('suppliers-order-by', orderBy)
      state.supplier_filters.order_by = orderBy
    },
    setOrderDirection (state, orderDirection) {
      localStorage.setItem('suppliers-order-direction', orderDirection)
      state.supplier_filters.order_direction = orderDirection
    },
    setSupplierOnDelete (state, supplier) {
      state.supplier_on_delete = supplier
    },
    setShowDeleteSupplierModal (state, payload) {
      state.show_delete_supplier_modal = payload
    },
    setSuppliersCounters (state, payload) {
      state.suppliers_counters = payload
    },
    setSelectedSuppliers (state, payload) {
      state.selected_suppliers = payload
    },
    clearSelectedSuppliers (state) {
      state.selected_suppliers = []
    },
    setShowAddSupplierPaymentModal (state, payload) {
      state.show_add_supplier_payment_modal = payload
    },
    setSupplierForPayment (state, payload) {
      state.supplier_for_payment = payload
    },
    clearSupplierForPayment (state) {
      state.supplier_for_payment = null
    },
    setIncSuppliers (state, payload) {
      state.inc_suppliers = payload
    }
  },
  actions: {
    getSuppliersCounters ({ state, commit }) {
      axios.get(baseUrl + 'counters')
        .then(response => {
          commit('setSuppliersCounters', response.data)
        })
    },
    getSuppliers ({ state, commit }) {
      axios.get(baseUrl, {
        params: state.supplier_filters
      })
        .then(response => {
          commit('setSuppliers', response.data.data)
          commit('setPagination', response.data.meta)
        })
    },
    getSupplier ({ state, commit }, id) {
      axios.get(baseUrl + id)
        .then(response => {
          commit('setSupplier', response.data.data)
        })
    },
    createSupplier ({ state, commit }) {
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.post(baseUrl, state.supplier).then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Поставщик создан',
            'Создан: ' + response.data.data.name
          )
          commit('setSupplier', response.data.data)
          resolve(redirectTo)
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Поставщик не создан!',
            'Проверьте данные'
          )
          reject(err)
        })
      })
    },
    updateSupplier ({ commit, state }) {
      commit('errors/clearErrors', null, { root: true })
      axios.put(baseUrl + state.supplier.id, state.supplier)
        .then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные поставщика обновлены'
          )
          commit('setSupplier', response.data.data)
          commit('errors/clearErrors', null, { root: true })
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Данные поставщика не обновлены!',
            'Проверьте данные'
          )
        })
    },
    deleteSupplier (action, id) {
      axios.delete(baseUrl + id).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Поставщик успешно удален'
        )
        action.dispatch('getSuppliers')
      }).catch(err => {
        console.log(err)
      })
    },
    getIncSuppliers ({ commit }) {
      axios.get(baseUrl, {
        params: {
          perPage: 99999,
          page: 1
        }
      })
        .then(response => {
          commit('setIncSuppliers', response.data.data)
        })
    }
  }
}
