import axios from 'axios'

const baseUrl = 'api/admin/statistic'

export default {
  namespaced: true,
  state: {
    period: 'week',
    seriesDinamic: [{
      name: 'Заказов',
      data: [0]
    }],
    chartOptionsDinamic: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: '',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: [0]
      }
    },
    popular_goods: [],
    popular_categories: []
  },
  mutations: {
    setCircleStatistics (state, payload) {
      state.seriesCircle[0] = payload.data.perc.completed_perc // Завершенныые
      state.seriesCircle[1] = payload.data.perc.send_perc // Отправленные
      state.seriesCircle[2] = payload.data.perc.return_perc // возврат
      state.ordersConf.counter.completed_count = payload.data.completed_count
      state.ordersConf.counter.send_count = payload.data.send_count
      state.ordersConf.counter.return_count = payload.data.return_count
      state.ordersConf.counter.total_count = payload.data.total_count
      state.chartOptionsCircle.plotOptions.radialBar.dataLabels.total.formatter(() => {
        return 11
      })
    },
    setStatistic (state, payload) {
      state.seriesDinamic = [{
        name: 'Заказов',
        data: payload.count
      }]
      state.chartOptionsDinamic = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: '',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: payload.date
        }
      }
    },
    setPopularGoods (state, payload) {
      state.popular_goods = payload
    },
    setPopularCategories (state, payload) {
      state.popular_categories = payload
    }
  },
  actions: {
    getOrderGraph ({ state, commit }, data) {
      axios.get(baseUrl + '/graph', {
        params: {
          period: data.period,
          user_id: data.user_id
        }
      }).then(res => {
        commit('setStatistic', res.data)
      })
    },
    getOrderCustomGraph ({ state, commit }, data) {
      axios.get(baseUrl + '/graph', {
        params: {
          period: 'custom',
          from: data.date[0],
          to: data.date[1],
          user_id: data.user_id
        }
      }).then(res => {
        commit('setStatistic', res.data)
      })
    },
    getPopularGoods ({ state, commit }, userId) {
      axios.get(baseUrl + '/popular-goods', {
        params: {
          user_id: userId
        }
      }).then(res => {
        commit('setPopularGoods', res.data)
      })
    },
    getPopularCategories ({ commit }, userId) {
      axios.get(baseUrl + '/popular-categories', {
        params: {
          user_id: userId
        }
      }).then(res => {
        commit('setPopularCategories', res.data)
      })
    }
  }
}
