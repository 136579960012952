<template>
    <transition name="fade">
        <div v-if="show" class="popup_box" style="display: block">
            <div class="popup delete_category">
                <span class="page_title">Удалить: {{product.name}}?</span>
                <span class="close_popup" @click="close()"></span>
                <button class="btn" @click="close()">Отмена</button>
                <button class="btn empty" @click="confirm()">Удалить</button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProductDeleteItemModal',
  props: {
    show: Boolean,
    product: Object
  },
  methods: {
    ...mapActions('product', {
      remove: 'deleteProduct'
    }),
    confirm () {
      this.remove(this.product.id)
        .then(path => {
          this.close()
          this.$router.push({ name: path })
        })
    },
    close () {
      this.$root.$emit('show_product_delete', false)
    }
  }
}
</script>

<style scoped>

</style>
