import axios from 'axios'
const baseUrl = '/api/clients/'
export default {
  namespaced: true,
  state: {
    import_data: {},
    import_progress: 0,
    validate_progress: 0,
    is_load: false,
    is_import: false,
    is_import_finish: false,
    history: {}
  },
  mutations: {
    setIsLoad (state, value) {
      state.is_load = value
    },
    setIsImport (state, value) {
      state.is_import = value
    },
    setIsImportFinish (state, value) {
      state.is_import_finish = value
    },
    setImportData (state, data) {
      state.import_data = data
    },
    setHistory (state, data) {
      state.history = data
    },
    setProgress (state, data) {
      state.is_import = true
      state.import_progress = data
      if (data === 100) {
        state.is_import = false
      }
    },
    setProgressValidate (state, data) {
      state.validate_progress = data
      if (data === 100) {
        state.is_load = false
      }
    }
  },
  actions: {
    getData (action) {
      axios.get(`${baseUrl}import-data`).then(response => {
        action.commit('setImportData', response.data)
      })
    },
    getHistory (action) {
      axios.get(`${baseUrl}import-history`).then(response => {
        action.commit('setHistory', response.data)
      })
    },
    uploadFile (action, file) {
      if (!file) {
        return
      }
      action.commit('setProgress', 0)
      action.commit('setProgressValidate', 0)
      action.commit('setIsLoad', true)
      action.commit('setIsImport', false)
      action.commit('setIsImportFinish', false)
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${baseUrl}import-upload`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {})
    },
    confirmImport (action, data) {
      action.commit('setIsImport', true)
      axios.post(`${baseUrl}import-confirm`, data).then(response => {

      })
    }
  }
}
