<template>
  <transition name="fade">
    <div class="popup_box" :class="{ active: show_order_products_modal}" @click.self="setShowOrderProductsModal([false, null])"
         data-id="list_product">
      <div class="popup list_prod" v-if="order_for_modal">
        <span class="page_title">Товары в заказе: {{ order_for_modal.id }}</span>
        <span class="close_popup" @click="show = !show"></span>
        <div class="table_body">
          <div class="headline_table flx">
            <div class="code_prod">
              <p>Артикул</p>
            </div>
            <div class="name_prod">
              <p>Название</p>
            </div>
            <div class="color_prod">
              <p>Цвет</p>
            </div>
            <div class="size_prod">
              <p>Размер</p>
            </div>
            <div class="quantity_prod">
              <p>Кол-во</p>
            </div>
            <div class="price_drop_prod">
              <p>Цена дроп</p>
            </div>
          </div>
          <div class="row_list">
            <div class="row flx" v-for="product in order_for_modal.products" :key="'product-in-order'+product.product_color_size_id">
              <div class="code_prod">
                <router-link :to="{ name: 'ShowProduct', params: { id: product.id }}">{{ product.vendor_code }}</router-link>
              </div>
              <div class="name_prod">
                <p>{{ product.name}}</p>
              </div>
              <div class="color_prod">
                <p>{{ product.color }}</p>
              </div>
              <div class="size_prod">
                <p>{{ product.size }}</p>
              </div>
              <div class="quantity_prod">
                <p>{{ product.qty }}</p>
              </div>
              <div class="price_drop_prod">
                <p>{{ product.total_final }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'OrderProductsModal',
  computed: {
    ...mapState('orders', ['show_order_products_modal', 'order_for_modal']),
    show: {
      get () {
        return this.show_order_products_modal
      },
      set (val) {
        if (val) {
          this.setShowOrderProductsModal([val, this.order_for_modal])
        } else {
          this.setShowOrderProductsModal([val, null])
        }
      }
    }
  },
  methods: {
    ...mapActions('orders', ['toggleOrderProductsModal']),
    ...mapMutations('orders', ['setShowOrderProductsModal'])
  }
}
</script>

<style scoped>

</style>
