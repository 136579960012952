<template>
  <div class="table_body sellers_with_disc_table" style="margin-bottom: 20px">
    <div class="top_table flx">
      <span class="page_subtitle" v-if="this.model_type === 'category'">Акции категории</span>
      <span class="page_subtitle" v-if="this.model_type === 'product'">Акции товара</span>
    </div>
    <div class="wrap_tbl">
      <div class="row_list">
        <div class="row flx">
          <div class="discount_seller">
            <span>Скидка</span>
            <input v-model="discount" type="number" placeholder="0">
          </div>
          <div class="discount_seller">
            <brew-select
              class="select-discount-type vue-select "
              :clearable="false"
              :options="discount_types"
              v-model="discount_type"
            ></brew-select>
          </div>
          <div class="discount_seller" style="width: 60%">
            <span>Дата</span>
            <date-picker v-model="date" valueType="format" format="D.M.Y" lang="ru" range></date-picker>
          </div>
        </div>
        <div class="row" style="padding-top: unset">
          <span>Описание: </span>
          <textarea v-model="text"></textarea>
        </div>
        <div class="row">
          <label class="custom-checkbox">
            <input type="checkbox" v-model="view_in_home">
            <span></span> Отображать на главной странице
          </label>
        </div>
        <div class="row">
          <div class="btn open_pop" data-id="add_product_to_category" :class="{'disabled':disabled_btn}"
               @click="createStock">Сохранить
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue2-datepicker/locale/ru'
import DatePicker from 'vue2-datepicker'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'Stock',
  components: { DatePicker },
  props: {
    stock_id: Number,
    model_id: Number,
    model_type: String
  },
  data () {
    return {
      local: null,
      discount_types: ['грн', '%']
    }
  },
  computed: {
    ...mapState('stock', ['stock_data']),
    disabled_btn: {
      get () {
        return this.stock_data.discount < 0
      }
    },
    discount: {
      get () {
        return this.stock_data.discount
      },
      set (data) {
        this.setDiscount(data)
      }
    },
    discount_type: {
      get () {
        return this.stock_data.discount_type
      },
      set (data) {
        this.setDiscountType(data)
      }
    },
    text: {
      get () {
        return this.stock_data.text
      },
      set (data) {
        this.setText(data)
      }
    },
    date: {
      get () {
        return [this.stock_data.expiration_from_at, this.stock_data.expiration_to_at]
      },
      set (data) {
        this.setExpiration(data)
      }
    },
    view_in_home: {
      get () {
        return this.stock_data.view_in_home
      },
      set (data) {
        this.setViewInHome(data)
      }
    }
  },
  methods: {
    ...mapMutations('stock', ['setDiscount', 'setDiscountType', 'setText', 'setModelId', 'setModelType', 'setExpiration', 'clearStockData', 'setViewInHome']),
    ...mapActions('stock', ['createStock', 'getStock'])
  },
  watch: {
    model_id (value) {
      if (this.stock_id) {
        this.getStock(this.stock_id)
      } else {
        this.clearStockData()
      }
      this.setModelId(value)
      this.setModelType(this.model_type)
    }
  },
  created () {
    if (this.stock_id) {
      this.getStock(this.stock_id)
    }
  }
}
</script>

<style scoped>
.disabled {
  background-color: grey;
  pointer-events: none;
}

.top_table {
  border-bottom: 1px solid #e4ebf6;
}

.table_body .row:hover {
  background: none;
}

.table_body .row {
  border-bottom: none;
}

.mx-datepicker-range {
  width: 210px;
}
</style>
