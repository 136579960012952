export const format = {
  update (el, binding) {
    if (binding.modifiers) {
      if (binding.modifiers.number) {
        // positive and negative float
        const event = new Event('input', { bubbles: true })
        const formattedValue = parseFloat(el.value)
        el.value = isNaN(formattedValue) ? null : formattedValue
        binding.value = el.value
        el.dispatchEvent(event)
      }
      if (binding.modifiers.integer) {
        // positive integer
        const event = new Event('input', { bubbles: true })
        const formattedValue = parseInt(el.value)
        el.value = isNaN(formattedValue) ? null : Math.abs(formattedValue)
        binding.value = el.value
        el.dispatchEvent(event)
      }
      if (binding.modifiers.price) {
        // price format
        const event = new Event('input', { bubbles: true })
        let formattedValue = Number.prototype.toFixed.call(parseFloat(el.value) || 0, 2)
        formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
        el.value = isNaN(formattedValue) ? null : formattedValue
        binding.value = el.value
        el.dispatchEvent(event)
      }
    }
  }
}
