export default {
  namespaced: true,
  state: {
    history: [],
    show_history_modal: false
  },
  mutations: {
    setHistory (state, history) {
      state.history = history
    },
    clearHistory (state) {
      state.history = []
    },
    setShowHistoryModal (state, isTrue) {
      state.show_history_modal = isTrue
    }
  },
  actions: {
    toggleHistoryModal ({ state, commit }) {
      commit('setShowHistoryModal', !state.show_history_modal)
    }
  }
}
