<template>
  <div class="table_body">
    <div class="top_table flx">
      <div class="search_box flx">
        <p>Продавец:</p>
        <div class="select" id="sellers">
          <user-select
            class="mini-select"
            style="min-width: 225px;margin-right: 10px"
            :default-id="edit_user.id"
            :default-name="edit_user.name"
            :items="dropshippers"
            @search="fetchDropshippers"
            @select="setSelectUserId"
            @active-input="fetchDropshippers"
          ></user-select>
        </div>
      </div>
      <div class="pagination flx">
        <div class="flx">
          <p class="desctop_v">Строк:</p>
          <div class="select">
            <brew-select
                class="select-pagination select-dropshipper-per_page"
                style="margin-left:10px;width: 100px"
                v-model="perPage"
                :clearable="false"
                :options="page_sizes"
            >
            </brew-select>
          </div>
        </div>
        <paginate
            style="margin-left: 20px"
            class="flx"
            v-model="page"
            :page-count="closed_pagination.last_page"
            :page-range="3"
            :margin-pages="2"
            :prev-text="'<'"
            :next-text="'>'"
        >
        </paginate>
      </div>
    </div>
    <div class="bot_table">
      <headline :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
      <div class="row_list">
        <div class="row flx"
             :rel="invoice.id"
             v-for="invoice in closed_invoices"
             :key="'invoice-'+invoice.id">
          <div
            v-if="invoice.order"
            class="id_ttn"
               @click="$router.push({ name: 'Order', params: { id: invoice.order.id } })">
            <a>{{ invoice.order.id }}</a>
            <span v-if="invoice.order.ttn"><b>{{ invoice.order.ttn | asTTN }}</b></span>
          </div>
          <div v-else class="id_ttn">Заказ удален</div>
          <div class="client_info" v-if="invoice.order">
            <span>[<router-link class="edit_ico" :to="{name: 'ShowClient', params: {id: invoice.order.client_data.id}}">{{ invoice.order.client_data.id }}</router-link>]</span>
            <span>{{ invoice.order.client_data.last_name }} {{ invoice.order.client_data.first_name | firstLetter }} {{ invoice.order.client_data.middle_name | firstLetter }}</span>
          </div>
          <div class="client_info" v-else> - </div>
          <div class="date_change_info">
            <span>{{ invoice.created_at }}</span>
            <span class="grey">{{ invoice.updated_at }}</span>
          </div>
          <div class="status_info" v-if="invoice.order">
            <span class="status" :class="getOrderStatusColor(invoice.order)">{{ invoice.order.status_name }}</span>
          </div>
          <div class="status_info" v-else> - </div>
          <div class="drop_price_info" v-if="invoice.order">
            <span>{{ invoice.order.total_discount | asPrice }}</span>
            <span class="grey">{{ invoice.order.total_final | asPrice }}</span>
          </div>
          <div class="drop_price_info" v-else> - </div>
          <div class="delivery_status_info" v-if="invoice.order">
            <span class="status" :class="getOrderDeliveryColor(invoice.order)">{{ invoice.order.delivery_status_name }}</span>
          </div>
          <div class="delivery_status_info" v-else> - </div>
          <div class="calculations_info">
            <div class="item flx">
              <span v-if="invoice.amount !== 0">{{  getOperationByBillingInfo(invoice.amount) }}:</span>
              <span class="line" v-if="invoice.amount !== 0"> </span>
              <p :class="getBillingInfoColor(invoice.amount)"><b>{{ invoice.amount | asPrice }}</b></p>
            </div>
          </div>
          <div class="documents_info">
            <span>[<b>{{ invoice.id }}</b>] {{ invoice.comment }}</span>
          </div>
          <!--          TODO invoices comment -->
          <div class="icon_wrap payment_comment">
               <span class="av_wrap" v-if="invoice.payment_comment">
              <svg class="open_drop" data-id="list_payment_info2" width="14" height="12" viewBox="0 0 14 12" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.24443 3.93334C3.86397 3.93334 3.55554 4.24176 3.55554 4.62222C3.55554 5.00269 3.86397 5.31111 4.24443 5.31111H9.75554C10.136 5.31111 10.4444 5.00269 10.4444 4.62222C10.4444 4.24176 10.136 3.93334 9.75554 3.93334H4.24443Z"
                  fill="#ADB8C6"/>
                <path
                  d="M4.24443 6.68889C3.86397 6.68889 3.55554 6.99732 3.55554 7.37778C3.55554 7.75824 3.86397 8.06667 4.24443 8.06667H7.68888C8.06934 8.06667 8.37777 7.75824 8.37777 7.37778C8.37777 6.99732 8.06934 6.68889 7.68888 6.68889H4.24443Z"
                  fill="#ADB8C6"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.799988 1.86667C0.799988 1.10682 1.41792 0.488892 2.17777 0.488892H11.8222C12.5821 0.488892 13.2 1.10682 13.2 1.86667V10.1333C13.2 10.8932 12.5821 11.5111 11.8222 11.5111H2.17777C1.41792 11.5111 0.799988 10.8932 0.799988 10.1333V1.86667ZM10.8669 10.1333H3.13306H2.17777V9.17804V2.82196V1.86667H3.13306H10.8669H11.8222V2.82196V9.17805V10.1333H10.8669Z"
                      fill="#ADB8C6"/>
            </svg>
              <div class="menu_drop">
                  <div class="dropdown">
                    <span class="title">Комментарий менеджера: </span>
                    <p>{{ invoice.payment_comment }}</p>
                  </div>
                </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { strings } from '../../../../helpers/strings'
import { prices } from '../../../../helpers/prices'
import { numbers } from '../../../../helpers/numbers'
import helper from '../../../../helpers/helper'
import UserSelect from '../../../Helpers/UserSelect'

import Headline from '../../../Helpers/Headline'
export default {
  name: 'CalculatedInvoices',
  components: {
    Headline,
    UserSelect
  },
  data () {
    return {
      page_sizes: [10, 50, 100, 1000],
      headers: [
        {
          title: 'ID・ТТН',
          title_class: 'title',
          sortable: true,
          class: 'id_ttn',
          value: 'id'
        },
        {
          title: 'Клиент',
          class: 'client_info'
        },
        {
          title: 'ДАТА・Изм',
          class: 'date_change_info'
        },
        {
          title: 'СТАТУС',
          class: 'status_info'
        },
        {
          title: 'Дроп・сумма',
          class: 'drop_price_info'
        },
        {
          title: 'Состояние доставки',
          class: 'delivery_status_info'
        },
        {
          title: 'Расчёт',
          class: 'calculations_info'
        },
        {
          title: 'Документы',
          class: 'documents_info flx'
        },
        {
          title: '',
          class: 'icon_wrap'
        }
      ]
    }
  },
  mixins: [
    strings,
    prices,
    numbers
  ],
  computed: {
    ...mapState('invoices', ['closed_invoices', 'closed_filters', 'closed_pagination', 'invoice_user', 'invoice_users']),
    ...mapState('users', ['dropshippers', 'edit_user']),
    ...mapGetters('users', ['getDefaultDropshipperFilters']),
    filters: {
      get () {
        return this.closed_filters
      }
    },
    page: {
      get: function () {
        return this.closed_filters.page
      },
      set: function (value) {
        this.setClosedPage(value)
        this.getClosedInvoices()
      }
    },
    perPage: {
      'get' () {
        return this.closed_filters.per_page
      },
      'set' (perPage) {
        this.setClosedPerPage(perPage)
        this.setClosedPage(1)
        this.getClosedInvoices()
      }
    },
    sortBy: {
      get () {
        return this.filters.order_by
      },
      set (val) {
        this.setClosedInvoiceOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.filters.order_direction
      },
      set (val) {
        this.setClosedInvoiceOrderDirection(val)
      }
    }
  },
  methods: {
    ...mapActions('invoices', ['getClosedInvoices', 'getInvoiceUser']),
    ...mapMutations('invoices', ['setClosedPage', 'setClosedPerPage', 'setClosedSearch', 'setClosedInvoiceOrderBy', 'setClosedInvoiceOrderDirection']),
    ...mapActions('users', ['getDropshippersForSelect', 'getUser']),
    ...mapMutations('users', ['setDropshipperFilters', 'setSearch', 'setDropshippers']),
    setUserId (userId) {
      if (userId !== undefined && userId !== 'all') {
        this.$set(this.closed_filters, 'user_id', +userId)
        this.getInvoiceUser(+userId)
      } else {
        this.$set(this.closed_filters, 'user_id', null)
      }

      this.getClosedInvoices()
    },
    setSelectUserId (value) {
      if (value.id && value.id !== 0) {
        this.$set(this.closed_filters, 'user_id', +value.id)
        this.$router.push({ name: 'DropshipperOrders', params: { id: value.id } })
      }
      this.setDropshippers([])
    },
    getOrderStatusColor (order) {
      return helper.getOrderStatusColor(order.status)
    },
    getOrderDeliveryColor (order) {
      return helper.getOrderDeliveryColor(order.delivery_status)
    },
    getOrderClassByStatus (order) {
      return helper.getOrderClassByStatus(order.status)
    },
    getOperationByBillingInfo (value) {
      return helper.getOperationByBillingInfo(value)
    },
    getBillingInfoColor (value) {
      return helper.getBillingInfoColor(value)
    },
    fetchDropshippers (search) {
      this.setSearch(search)
      this.getDropshippersForSelect()
    }
  },
  watch: {
    $route (to, from) {
      if (to.params.id !== from.params.id) {
        this.setUserId(to.params.id)
        this.getUser(to.params.id)
      }
    },
    sortBy () {
      this.getClosedInvoices()
    },
    sortDesc () {
      this.getClosedInvoices()
    }
  },
  created () {
    this.setUserId(this.$route.params.id)
    const dropshipperFilters = this.getDefaultDropshipperFilters
    dropshipperFilters.per_page = 99999
    this.setDropshipperFilters(dropshipperFilters)
    this.$nextTick(() => {
      this.getDropshippersForSelect()
    })
  }
}
</script>

<style scoped>

</style>
