<template>
  <section class="table_box single_product">
    <loader v-if="is_loading" object="#5C6579" color1="#ccc" color2="#FFA500" size="5" speed="2" bg="#fff" objectbg="#999793" opacity="80" name="circular"></loader>
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Информация о товаре</span>
<!--        <a href="new_order.html" class="btn">Создать заказ с этим товаром</a>-->
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" href="#" class="back flx">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <span class="point"> </span>
        <div class="btn empty open_pop" data-id="history_change" @click="toggleHistoryModal();getHistory()">История
          изменений
        </div>
        <router-link :to='"/dashboard/dropshipping/products/edit/"+product.id' class="btn">Редактировать товар
        </router-link>
      </div>
    </div>
    <div class="container tbl flx">
      <div class="about_product flx">
        <div class="images_product" v-if="images.length > 0">
          <div class="slider_wrap flx">
            <vue-slick-carousel
              style="overflow-x: hidden"
              class="slider-nav"
              ref="c2"
              :asNavFor="$refs.c1"
              :focusOnSelect="true"
              @beforeChange="syncSliders"
              v-bind="settings"
            >
              <div :key="index" v-for="(image, index) in images">
                <div class="small_img_wrapper">
                  <img :src="image.links[60]">
                </div>
              </div>
            </vue-slick-carousel>
            <div class="wrap-slider-for">
              <vue-slick-carousel
                class="slider-for"
                ref="c1"
                :asNavFor="$refs.c2"
                :focusOnSelect="true"
                @beforeChange="syncSliders"
                v-bind="image_view_settings"
              >
                <div :key="index" v-for="(image, index) in images">
                  <div class="img_wrapper cursor-pointer" @click="img_index = index">
                    <img :src="image.links[225]">
                  </div>
                </div>
              </vue-slick-carousel>
            </div>
          </div>
          <div class="view_wrap">
            <span class="photo_ico"></span>
            <a @click="downloadImages(product.id)">Скачать все изображения товара</a>
          </div>
          <div class="view_wrap">
            <hr>
            <h3 style="padding: 15px 5px">Размерные сетки</h3>
            <input @change="selectSizeImgs" type="file" multiple accept="image/png, image/jpeg"/>
            <button class="btn" @click="sendUploadSizeImages">Загрузить</button>
          </div>
          <div class="flx">
            <div v-for="(imgUrl, i) in product.size_images" style="width: 48%;position: relative" :key="'xx'+i">
              <img @click="sendDeleteSizeImage(imgUrl)" src="/images/close_red_ico.svg" style="position: absolute;top:0;right: 0;background: white;border-radius: 30px;padding: 5px;cursor: pointer"/>
              <img style="object-fit: cover;max-width: 100%; max-height: 100px" :src="baseUrl+imgUrl" alt="">
            </div>
          </div>
        </div>
        <div class="images_product" v-else>
          <div class="slider_wrap flx">
            <div class="wrap-slider-for">
              <div class="img_wrapper cursor-pointer">
                <img src="/images/no-img.svg">
              </div>
            </div>
          </div>
        </div>
        <div class="info_product">
          <div class="top">
            <span class="page_subtitle">{{ product.name }}</span>
          </div>
          <div class="row">
            <div class="data_item flx">
              <span class="name">Категория:</span>
              <p v-for="(category,index) in product.categories" :key="category.id">
                {{ category.name }}
                <span v-if="index < product.categories.length -1">&nbsp;·&nbsp;</span>
              </p>
            </div>
            <div class="data_item flx">
              <span class="name">Сезон:</span>
              <p v-for="(season, index) in product.seasons" :key="season.id">
                {{ season.name }}
                <span v-if="index < product.seasons.length -1">&nbsp;·&nbsp;</span>
              </p>
            </div>
            <div class="data_item flx">
              <span class="name">Артикул:</span>
              <p>{{ product.vendor_code }}</p>
            </div>
          </div>
          <div class="row">
            <div class="data_item flx">
              <span class="name">Цвет:</span>
              <div class="tabs_box flx" data-type="color-card">
                <div class="tab"
                     :key="color.id"
                     v-for="(color,index) in product.remains ? product.remains : []"
                     :class="{active : activeColor === index}"
                     @click="activeColor = index"
                >
                  <span :style="{'background':color.color ? color.color.primary_color : null }"
                        :class="{ 'black-border': color.color && color.color.name === 'Белый' }"></span>
                  <p>{{ color.color ? color.color.name : null }} {{ color.pictures }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="data_item flx">
              <div class="wrap_name">
                <span class="name">Размеры:</span>
                <span class="name">Наличие: <span class="info"></span></span>
              </div>
              <div class="size_wrap">
                <div class="tab_container active flx">
                  <div class="size flx"
                       v-tooltip="(!size.remains && getWaitDate()) ? 'Поставка ожидается: ' + getWaitDate() :null"
                       :class="{pending: !size.remains}"
                       v-for="size in getColorData()"
                       :key="size.id"
                       @click="showEditRemains({
                       product_color_size_id:size.product_color_size_id,
                       remains:size.remains
                       })"
                  >
                    <span>{{ size.size ? size.size.name : null }}</span>
                    <span :class="getColorRemains(size.remains)"
                          v-text="size.remains > 0 ? size.remains+'шт': ''">
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="data_item flx">
              <span class="name">Вес:</span>
              <p>{{ product.weight }}</p>
            </div>
            <div class="data_item flx">
              <span class="name">Шт. в упаковке:</span>
              <p>{{ product.package }}</p>
            </div>
            <div class="data_item flx">
              <span class="name">Мин. остаток:</span>
              <p>0</p>
            </div>
          </div>
          <span class="page_subtitle">Стоимость</span>
          <div class="price_box flx">
            <div class="item flx">
              <span class="name">Закупка:</span>
              <span>{{ getPurchasePriceByColor() }} грн</span>
            </div>
            <div class="item flx">
              <span class="name">Цена (дроп):</span>
              <span><b>{{ getRetailPriceByColor() }} грн</b></span>
            </div>
            <div class="item flx">
              <span class="name">Скидка:</span>
              <span>{{ getDiscountByColor() }}</span>
            </div>
          </div>
          <span class="page_subtitle">Описание</span>
          <div class="tab_content">
            <div class="tabs_box" data-type="description">
              <div class="tab" @click="activeTab=0" :class="{active:activeTab===0}">
                <p>Основное</p>
              </div>
              <div class="tab" @click="activeTab=1" :class="{active:activeTab===1}">
                <p>На Укр.</p>
              </div>
              <div class="tab" @click="activeTab=2" :class="{active:activeTab===2}">
                <p>в Instagram</p>
              </div>
              <div class="tab" @click="activeTab=3" :class="{active:activeTab===3}">
                <p>в Instagram на Укр.</p>
              </div><!-- tab -->
            </div><!-- tabs_box -->
            <div class="tab_container"
                 :class="{active:activeTab===0}"
                 v-html="product.descriptions ? product.descriptions.description : null"
            ></div>
            <div class="tab_container"
                 :class="{active:activeTab===1}"
                 v-html="product.descriptions ? product.descriptions.description_ua : null"
            ></div>
            <div class="tab_container"
                 :class="{active:activeTab===2}"
                 v-html="product.descriptions ? product.descriptions.instagram : null"
            ></div>
            <div class="tab_container"
                 :class="{active:activeTab===3}"
                 v-html="product.descriptions ? product.descriptions.instagram_ua : null"
            ></div>
          </div>
        </div>
      </div>
      <div>
        <stock :model_id="product.id" model_type="product" :stock_id="product.stock_id"></stock>
        <div class="table_body sellers_with_disc_table">
          <div class="top_table flx">
            <span class="page_subtitle">Продавцы со скидкой на товар</span>
            <span class="plus open_pop" data-id="add_discount" :class="{ active: show_add_discount_dropshipper_modal }"
                  @click="setShowAddDiscountDropshipperModal(true)"> </span>
          </div>
          <div class="wrap_tbl">
            <div class="headline_table flx">
              <div class="id_seller">
                <p>ID</p>
              </div>
              <div class="full_name_seller">
                <p>ФИО</p>
              </div>
              <div class="discount_seller">
                <p>Скидка грн</p>
              </div>
              <div class="discount_seller">
                <p>Скидка %</p>
              </div>
              <div class="cross"></div>
            </div>
            <div class="row_list">
              <div class="row flx" v-for="discount in discount_list" :key="'discount-'+discount.id">
                <div class="id_seller">
                  <router-link :to="{ name: 'EditDropshipper', params: { id: discount.user.id } }">{{
                      discount.user.id
                    }}
                  </router-link>
                </div>
                <div class="full_name_seller">
                  <p>{{ discount.user.name }}</p>
                </div>
                <div class="discount_seller">
                  <span class="green" v-if="discount.type==='грн'">{{ discount.value }} {{ discount.type }}</span>
                </div>
                <div class="discount_seller">
                  <span class="green" v-if="discount.type==='%'">{{ discount.value }} {{ discount.type }}</span>
                </div>
                <div class="cross close" @click="deleteDiscount(discount)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <discount-delete-item-modal></discount-delete-item-modal>
    <add-discount-dropshipper-modal v-if="product" type_model="product" :id_model="product.id">
      <template v-slot:title>Добавить скидку на этот товар для продавца</template>
    </add-discount-dropshipper-modal>
    <CoolLightBox
      :items="product.properties.images"
      :index="img_index"
      @close="img_index = null">
    </CoolLightBox>
    <product-history-modal></product-history-modal>
    <change-remains :show="show_edit_remains"></change-remains>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import helper from '@/helpers/helper'
import VueSlickCarousel from 'vue-slick-carousel'
import DiscountDeleteItemModal from '../../Modals/DiscountDeleteItemModal'
import AddDiscountDropshipperModal from '../../Modals/AddDiscountDropshipperModal'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ProductHistoryModal from '@/components/Modals/HistoryModal'
import Stock from '@/components/Droppshipping/Stock/Stock'
import ChangeRemains from './ChangeRemains'
import { eventBus } from '../../../main'

export default {
  name: 'ShowProduct',
  components: {
    ChangeRemains,
    ProductHistoryModal,
    VueSlickCarousel,
    DiscountDeleteItemModal,
    AddDiscountDropshipperModal,
    CoolLightBox,
    Stock
  },
  data () {
    return {
      baseUrl: null,
      selected_size_images: [],
      images: [],
      show_edit_remains: false,
      img_index: null,
      activeColor: 0,
      activeTab: 0,
      settings: {
        centerMode: true,
        centerPadding: '0px',
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true
      },
      image_view_settings: {
        dots: false,
        fade: true,
        infinite: true,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  },
  computed: {
    ...mapState('product', ['product', 'is_loading']),
    ...mapState('discount', ['discount_list', 'show_discount_delete_modal', 'show_add_discount_dropshipper_modal']),
    ...mapState('productHistory', ['show_history_modal', 'history'])
  },
  watch: {
    activeColor: {
      handler (val) {
        this.images = this.getImagesByColor()
        this.syncSliders(0, 0)
        this.settings.centerMode = !this.settings.centerMode
        // this.settings.centerMode = !this.settings.centerMode
        // this.c1 = this.$refs.c1
        // this.c2 = this.$refs.c2
      }
    }
  },
  methods: {
    ...mapActions('productHistory', ['toggleHistoryModal']),
    ...mapActions('product', ['getProduct', 'getHistory', 'downloadImages', 'uploadSizeImages', 'deleteSizeImage']),
    ...mapMutations('product', ['setEditableRemains']),
    ...mapMutations('discount', ['setDiscountOnDelete', 'setShowDiscountDeleteModal', 'setShowAddDiscountDropshipperModal']),
    deleteDiscount (discount) {
      this.setDiscountOnDelete(discount)
      this.setShowDiscountDeleteModal(true)
    },
    getColorRemains (value) {
      return helper.getColorRemains(value)
    },
    getPurchasePriceByColor () {
      if (this.product.id && this.product.remains.length > 0 && this.product.remains.indexOf(this.activeColor)) {
        return this.product.remains[this.activeColor].purchase_price
      }
      return 0
    },
    getImagesByColor () {
      if (this.product.id && this.product.remains.length > 0 && this.product.remains.indexOf(this.activeColor)) {
        return this.product.remains[this.activeColor].images
      }

      return []
    },
    getRetailPriceByColor () {
      if (this.product.id && this.product.remains.length > 0 && this.product.remains.indexOf(this.activeColor)) {
        return this.product.remains[this.activeColor].retail_price
      }
      return 0
    },
    getDiscountByColor () {
      if (this.product.id && this.product.remains.length > 0 && this.product.remains.indexOf(this.activeColor)) {
        return this.product.remains[this.activeColor].discount ? this.product.remains[this.activeColor].discount.value + this.product.remains[this.activeColor].discount.type : 0
      }
      return 0
    },
    getColorData () {
      if (this.product.id && this.product.remains.length > 0 && this.product.remains.indexOf(this.activeColor)) {
        return this.product.remains[this.activeColor] ? this.product.remains[this.activeColor].sizes : []
      }

      return []
    },
    getWaitDate () {
      if (this.product.id && this.product.remains.length > 0 && this.product.remains.indexOf(this.activeColor)) {
        return this.product.remains[this.activeColor].wait_formatted
      }
      return 0
    },
    syncSliders (currentPosition, nextPosition) {
      if (this.$refs.c1 && this.$refs.c2) {
        this.$refs.c1.goTo(nextPosition)
        this.$refs.c2.goTo(nextPosition)
      }
    },
    showEditRemains (obj) {
      this.setEditableRemains(obj)
      this.show_edit_remains = true
    },
    selectSizeImgs (e) {
      this.selected_size_images = e.target.files
    },
    sendUploadSizeImages () {
      const formData = new FormData()
      this.selected_size_images.forEach(item => {
        formData.append('images[]', item)
      })

      const promise = this.uploadSizeImages({
        id: this.product.id,
        formData: formData
      })
      promise.then(r => {
        eventBus.$emit('send_notify', 'success',
          'Успешно загружено',
          'Добавлены новые файлы'
        )
        this.product.size_images = r.data.images
      })
    },
    sendDeleteSizeImage (url) {
      const request = {
        id: this.product.id,
        image_path: url
      }
      const promise = this.deleteSizeImage(request)
      promise.then(r => {
        eventBus.$emit('send_notify', 'success',
          'Успешно удалено',
          'файл удален'
        )
        this.product.size_images = r.data.images
      })
    }
  },
  async created () {
    this.baseUrl = process.env.VUE_APP_API_URL
    await this.getProduct(this.$route.params.id)
    this.$root.$on('show_edit_remains', (data) => {
      this.show_edit_remains = data
    })
    this.images = this.getImagesByColor()
    this.c1 = this.$refs.c1
    this.c2 = this.$refs.c2
  },
  // mounted () {
  //   this.c1 = this.$refs.c1
  //   this.c2 = this.$refs.c2
  // },
  beforeRouteUpdate (to, from, next) {
    this.getProduct(to.params.id)
    next()
  }
}

</script>

<style scoped lang="scss">
.img_wrapper {
  display: flex;
  width: 200px;
  height: 300px;
  justify-content: center;
  overflow: hidden;
  border-radius: 7px;

  img {
    width: auto;
    max-height: 300px;
  }
}

.small_img_wrapper {
  display: flex;
  width: 60px;
  height: 90px;
  justify-content: center;
  overflow: hidden;
  border-radius: 7px;
  margin-bottom: 10px;

  img {
    width: auto;
    max-height: 90px;
    margin-bottom: 0;
  }
}
</style>
