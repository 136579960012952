<template>
<!--  TODO single waybill -->
  <section class="table_box single_invoice">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title" v-if="!isPayment">Приходная накладная ID: {{ inc_invoice.id }} от {{ inc_invoice.received_at }}</span>
        <span class="page_title" v-else>Платеж ID: {{ inc_invoice.id }} от {{ inc_invoice.received_at }}</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)"  class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <router-link :to="{ name: 'EditWaybill', params: { id: inc_invoice.supplier_id, waybill_id: inc_invoice.id }}" class="btn create" v-if="!isPayment">Редактировать</router-link>
      </div>
    </div>
    <div class="container flx inv_wrap">
      <div class="column">
        <div class="info_invoice box_shadow">
          <div class="row flx">
            <span class="name">Поставщик:</span>
            <span class="descr" v-if="inc_invoice.supplier">[<router-link :to="{ name: 'Supplier', params: { id: inc_invoice.supplier_id }}">{{ inc_invoice.supplier_id }}</router-link>] - {{ inc_invoice.supplier.name }}</span>
          </div>
          <div class="row flx">
            <span class="name" v-if="!isPayment">Дата получения:</span>
            <span class="name" v-else>Дата платежа:</span>
            <span class="descr">{{ inc_invoice.received_at }}</span>
          </div>
          <div class="row flx">
            <span class="name">Прикрепленные файлы:</span>
            <a :href="inc_invoice.url" download class="clip_link descr" v-if="inc_invoice.url">
              <span class="clip_ico"></span>
              <span>{{ inc_invoice.file_name | truncate(15) }}</span>
            </a>
          </div>
          <div class="row">
            <div class="item flx" v-if="!isPayment">
              <span class="name">Общее кол-во:</span>
              <span class="grey descr">{{ inc_invoice.product_count }}</span>
            </div>
            <div class="item flx">
              <span class="name">Общая сумма:</span>
              <span class="descr"><b>{{ inc_invoice.total | asPrice }}</b></span>
            </div>
          </div>
          <div class="row flx">
            <span class="name">Менеджер:</span>
            <span class="descr" v-if="inc_invoice.created_by">[<router-link :to="{ name: 'User', params: { id: inc_invoice.created_id }}">{{ inc_invoice.created_id }}</router-link>] - {{ inc_invoice.created_by.name | abbreviated }}</span>
          </div>
        </div>
        <span class="page_subtitle">Комментарии на склад</span>
        <p>{{ inc_invoice.comment }}</p>
        <div class="btn" @click="printInvoice(inc_invoice)">Печать</div>
      </div>
      <div class="column wrap_tbl">
        <div class="table_body info_invoice_table" v-if="!isPayment">
          <headline :headers="headers"></headline>
          <div class="row_list">
            <div class="row flx" v-for="product in inc_invoice.products" :key="'product-' + product.product_color_size_id">
              <div class="id_product">
                <router-link :to="{ name: 'ShowProduct', params: { id: product.product_id}}">{{ product.product_color_size_id }}</router-link>
              </div>
              <div class="code_product">
                <span><b>{{ product.vendor_code }}</b></span>
              </div>
              <div class="name_product">
                <span>{{ product.name}}</span>
              </div>
              <div class="color_product">
                <span>{{ product.color }}</span>
              </div>
              <div class="size_product">
                <span>{{ product.size }}</span>
              </div>
              <div class="quantity_product">
                <span>{{ product.remains }}</span>
              </div>
              <div class="price_product">
                <span>{{ product.price | asPrice }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { prices } from '../../../helpers/prices'
import { strings } from '../../../helpers/strings'
import Headline from '../../Helpers/Headline'

export default {
  name: 'Waybill',
  mixins: [strings, prices],
  components: {
    Headline
  },
  data () {
    return {
      headers: [
        {
          title: 'ID',
          class: 'id_product'
        },
        {
          title: 'Артикул',
          class: 'code_product'
        },
        {
          title: 'Название',
          class: 'name_product'
        },
        {
          title: 'Цвет',
          class: 'color_product'
        },
        {
          title: 'Размер',
          class: 'size_product'
        },
        {
          title: 'Кол-во',
          class: 'quantity_product'
        },
        {
          title: 'Цена Закупка',
          class: 'price_product'
        }
      ]
    }
  },
  computed: {
    ...mapState('incoming', ['inc_invoice']),
    isPayment () {
      return this.inc_invoice?.total ? this.inc_invoice.total >= 0 : false
    }
  },
  methods: {
    ...mapActions('incoming', ['getIncInvoice']),
    ...mapMutations('incoming', ['clearIncInvoice']),
    loadInvoice () {
      if (!this.inc_invoice.id || (this.inc_invoice.id !== this.$route.params.waybill_id)) {
        this.getIncInvoice(this.$route.params.waybill_id)
      }
    },
    printInvoice (invoice) {
      //  TODO print invoice
    }
  },
  watch: {
    '$route.params.waybill_id': function (id) {
      if (this.$route.name === 'EditWaybill') {
        this.loadInvoice()
      }
    }
  },
  created () {
    this.loadInvoice()
  },
  beforeDestroy () {
    this.clearIncInvoice()
  }
}
</script>

<style scoped>

</style>
