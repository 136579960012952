
export const dates = {
  filters: {
    onlyDate: function (value) {
      if (!value) {
        return ''
      }
      const d = new Date(value)
      const days = d.getDate() <= 9 ? '0' + d.getDate() : d.getDate()
      const month = (d.getMonth() + 1) <= 9 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)

      return days + '.' + month + '.' + d.getFullYear()
    },
    asYMD (date) {
      const d = date.getDate()
      const m = date.getMonth() + 1 // Month from 0 to 11
      const y = date.getFullYear()
      return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
    },
    onlyTime: function (value) {
      if (!value) {
        return ''
      }
      const d = new Date(value)
      const hours = d.getHours() <= 9 ? '0' + d.getHours() : d.getHours()
      const minutes = d.getMinutes() <= 9 ? '0' + d.getMinutes() : d.getMinutes()
      return hours + '.' + minutes
    }
  }

}
