<template>
  <transition name="fade">
    <div v-if="show" class="popup_box" style="display: block">
      <div class="popup delete_category">
        <span class="page_title">Удалить [{{edit_banner.id}}] {{edit_banner.name}}  ?</span>
        <span class="close_popup" @click="close()"></span>
        <button class="btn" @click="close()">Отмена</button>
        <button class="btn empty" @click="confirm()">Удалить</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'DeleteBannerModal',
  computed: {
    ...mapState('banners', ['edit_banner', 'show_delete']),
    show: {
      get () {
        return this.show_delete
      },
      set (value) {
        this.setShowDelete(value)
      }
    }
  },
  methods: {
    ...mapActions('banners', ['deleteBanner']),
    ...mapMutations('banners', ['setShowDelete', 'setEditBanner']),
    close () {
      this.setEditBanner(null)
      this.show = false
    },
    confirm () {
      this.deleteBanner()
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
