<template>
    <div class="size_card flx">
        <label class="custom-checkbox">
            <input type="checkbox" v-model="data.size_enable" @change="changeDataCheckbox($event)">
            <span></span>
            <span>{{ size.name }}</span>
        </label>
        <div class="inp_wrap" style="margin-left: 10px;width: 70px;padding: 0">
            <input type="number" v-model.number="data.remains" @change="changeData" v-format.integer>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { format } from '../../../helpers/format'
export default {
  name: 'SizeSelect',
  directives: {
    format
  },
  props: [
    'product_size',
    'selectData',
    'index',
    'size'
  ],
  data () {
    return {
      data: {
        size_id: this.size.id,
        size_enable: !_.isEmpty(this.product_size),
        // size: this.size.size,
        remains: _.isEmpty(this.product_size) ? 0 : parseInt(this.product_size.remains)
      }
    }
  },
  methods: {
    changeData () {
      this.$emit('change',
        {
          id: this.size.id,
          data: this.data
        }
      )
    },
    changeDataCheckbox (e) {
      // console.log(e.target.checked)
      if (e.target.checked) {
        this.$emit('check', this.size.id)
      } else {
        this.$emit('remove', this.size.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .size_card {
    .inp_wrap {
      position: relative;
      .remove-size {
        position: absolute;
        right: -3px;
        top: -3px;
        background: #f5a32a;
        width: 15px;
        height: 15px;
        z-index: 100000;
        border-radius: 100%;
        padding-left: 4px;
        line-height: initial;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
</style>
