<template>
    <transition name="fade">
        <div v-if="show" class="popup_box" style="display: block">
            <div class="popup delete_category">
                <span class="page_title">Удалить заказ ID:{{order.id}}?</span>
                <span class="close_popup" @click="close()"></span>
                <button class="btn" @click="close()">Отмена</button>
                <button class="btn empty" @click="confirm()">Удалить</button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'OrderDeleteModal',
  computed: {
    ...mapState('orders', ['show_order_delete_modal', 'order_on_delete']),
    show: {
      get () {
        return this.show_order_delete_modal
      },
      set (value) {
        this.setShowOrderDeleteModal(value)
      }
    },
    order: {
      get () {
        return this.order_on_delete
      },
      set (value) {
        this.setOrderOnDelete(value)
      }
    }
  },
  methods: {
    ...mapActions('orders', {
      remove: 'deleteOrder'
    }),
    ...mapMutations('orders', ['setShowOrderDeleteModal', 'setOrderOnDelete']),
    confirm () {
      this.remove(this.order)
      this.close()
    },
    close () {
      this.show = false
      this.order = null
    }
  }
}
</script>

<style scoped>

</style>
