<template>
  <div class="actions_box open_box flx" :class="{ active: show_order_actions }" data-id="actions_box">
    <p>Действия с заказом:</p>
    <div class="btn empty" @click="downloadCheck">
      <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.95898 0.999959L5.95898 10.9166" stroke="#F5A32A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.9167 13H1" stroke="#F5A32A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.00081 5.95829L5.95915 10.9166L10.9175 5.95829" stroke="#F5A32A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span>Скачать чек</span>
    </div>
    <div class="btn empty" @click="downloadPostBlank">Скачать почтовый бланк</div>
    <div class="btn empty" @click="goToAnotherBlank">Перейти к бланку №2</div>
    <div class="btn empty" @click="printCheck">Распечатать чек</div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'OrderActions',
  computed: {
    ...mapState('orders', ['show_order_actions'])
  },
  methods: {
    ...mapActions('orders', ['toggleOrderActions']),
    ...mapMutations('orders', ['setShowOrderActions']),
    downloadCheck () {
      // TODO download order check
    },
    downloadPostBlank () {
      // TODO download order post blank
    },
    goToAnotherBlank () {
      // TODO go to blank number 2
    },
    printCheck () {
      // TODO print check
    }
  }
}
</script>

<style scoped>

</style>
