<template>
  <div class="row_list">
    <span class="page_subtitle">Настройка прав доступа для пользователя</span>
    <div class="row" v-for="(rule, index) in role_rules" :key="'role-rule-'+index">
      <span>{{ getRuleName(index) }}</span>
      <div class="role flx">
        <label class="custom-checkbox">
          <input type="checkbox" :class="{disabled: !is_edit}" :disabled="!is_edit"
                 :checked="edit_user.rules[index].create &&
                    edit_user.rules[index].update &&
                    edit_user.rules[index].view &&
                    edit_user.rules[index].delete" @change="toggleAllRules($event, index)">
          <span></span>
          <span>Все</span>
        </label>
      </div>
      <div class="role flx">
        <label class="custom-checkbox">
          <input type="checkbox" :class="{disabled: !is_edit}" :disabled="!is_edit" :checked="edit_user.rules[index].create" @change="toggleRule($event, index, 'create')" >
          <span></span>
          <span>Создание</span>
        </label>
        <label class="custom-checkbox">
          <input type="checkbox" :class="{disabled: !is_edit}" :disabled="!is_edit" :checked="edit_user.rules[index].view" @change="toggleRule($event, index, 'view')">
          <span></span>
          <span>Просмотр</span>
        </label>
        <label class="custom-checkbox">
          <input type="checkbox" :class="{disabled: !is_edit}" :disabled="!is_edit" :checked="edit_user.rules[index].update" @change="toggleRule($event, index, 'update')" >
          <span></span>
          <span>Редактирование</span>
        </label>
        <label class="custom-checkbox">
          <input type="checkbox" :class="{disabled: !is_edit}" :disabled="!is_edit" :checked="edit_user.rules[index].delete" @change="toggleRule($event, index, 'delete')" >
          <span></span>
          <span>Удаление</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import _ from 'lodash'
import helper from '../../../helpers/helper'
export default {
  name: 'UserRoleRules',
  props: {
    is_edit: Boolean
  },
  computed: {
    ...mapState('users', ['edit_user']),
    ...mapState('roles', ['roles', 'default_rules']),
    role_rules: {
      get () {
        return !this.is_edit ? _.find(this.roles, r => { return r.id === this.edit_user.role.id })?.rules : this.edit_user.rules
      }
    }
  },
  methods: {
    ...mapMutations('users', ['setUserRoleModelRules', 'toggleUserSelectAllRules']),
    getRuleName (key) {
      return helper.getRuleName(key)
    },
    toggleAllRules (e, model) {
      this.toggleUserSelectAllRules([model, e.target.checked])
    },
    toggleRule (e, model, type) {
      this.setUserRoleModelRules([model, type, e.target.checked])
    }
  }
}
</script>

<style scoped>

</style>
