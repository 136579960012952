<template>
  <section class="table_box all_clients inner">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Импорт клиентов</span>
      </div>
    </div>
    <div class="container">
      <div class="table_body">
        <div class="top_table">
          <input style="height: auto" type="file" @change="getFile" :disabled="is_load">
          <div v-if="is_load">Проверка файла: {{ validate_progress }}%
            <progress-bar bar-color="#F5A32A" :val="validate_progress" :text="validate_progress+'%'"/>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="table_body">
        <div class="top_table" v-if="is_import_finish">
          <h2>Импорт успешно завершен</h2>
        </div>
        <div class="top_table" v-if="Object.keys(import_data).length > 0">
          <h2>Данные импорта</h2>
          <div>
            <p>Будет созданно новых клиентов: {{ import_data.new }} </p>
            <p>Будет обновлено клиентов: {{ import_data.update }} </p>
            <div v-if="import_data['errors']">
              <br>
              <h2>Найдено ошибок: {{ import_data.errors.length }}</h2>
              <ul>
                <li
                  class="red"
                  v-for="(data,index) in import_data['errors']"
                  :key="index"
                >ID:{{ data.id }} - {{ data.error }}
                </li>
              </ul>
              <br>
              <div>
                <div v-if="!is_import" class="btn empty" @click="confirmImport">Импортировать</div>
                <div v-if="is_import" style="margin-top:20px;font-size: 16px">Импортированно: {{ import_progress }}%
                  <progress-bar bar-color="#F5A32A" :val="import_progress" :text="import_progress+'%'"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="Object.keys(import_data).length > 0">
      <div class="table_body">
        <div class="top_table flx">
          <h1>Пример клиентов из файла</h1>
        </div>
        <div class="bot_table">
          <div class="headline_table flx">
            <div class="full_name_client">
              <p>ФИО</p>
            </div>
            <div class="contacts_client">
              <p>Контакты </p>
            </div>
            <div class="delivery_client">
              <p>Доставка </p>
            </div>
            <div class="city_client">
              <p>Город</p>
            </div>
            <div class="branch_client">
              <p>Отделение</p>
            </div>
          </div>
          <div class="row_list">
            <div
                class="row flx"
                rel="205402"
                v-for="client in import_data.clients"
                :key="'client '+client.id"
            >
              <div class="full_name_client">
                <span><b>{{ client.last_name }}</b></span>
                <span>{{ client.first_name }} {{ client.middle_name }}</span>
              </div>
              <div class="contacts_client">
                <a :href="'tel:'+client.phone">{{ client.phone }}</a>
              </div>
              <div class="delivery_client">
                <span v-if="client.delivery">{{ client.delivery.name }}</span>
              </div>
              <div class="city_client">
                <span v-if="client.city">{{ client.city.name }}</span>
              </div>
              <div class="branch_client">
                <span v-if="client.branch">{{ client.branch.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProgressBar from 'vue-simple-progress'

export default {
  name: 'ImportClients',
  components: { ProgressBar },
  data () {
    return {
      file: null
    }
  },
  computed: {
    ...mapState('importClients', [
      'import_data',
      'import_progress',
      'validate_progress',
      'is_load',
      'is_import',
      'is_import_finish'
    ])
  },
  methods: {
    ...mapActions('importClients', ['uploadFile', 'getData', 'confirmImport']),
    getFile (event) {
      if (event.target.files[0]) {
        this.file = event.target.files[0]
        this.uploadFile(this.file)
      }
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped>

</style>
