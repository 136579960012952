<template>
  <section class="table_box single_role">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title">Информация о роли ID: {{edit_role.id}}・{{ edit_role.name }}</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <router-link :to="{ name: 'EditRole', params: { id: edit_role.id }}" href="new_user.html" class="btn">Редактировать</router-link>
      </div>
    </div>
    <div class="container tbl_box flx">
      <div class="info_role_form box_shadow">
        <div class="top">
          <span class="page_subtitle">Общее</span>
        </div>
        <div class="row">
          <div class="item flx">
            <span class="name">Название:</span>
            <span>{{ edit_role.name }}</span>
          </div>
          <div class="item flx">
            <span class="name">Дата создания:</span>
            <span>{{ edit_role.date }}</span>
          </div>
          <div class="item flx">
            <span class="name">ID:</span>
            <span>{{ edit_role.id }}</span>
          </div>
        </div>
      </div>
      <role-rules></role-rules>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { dates } from '../../../helpers/dates'
import RoleRules from './RoleRules'
export default {
  name: 'Role',
  mixins: [
    dates
  ],
  components: {
    RoleRules
  },
  computed: {
    ...mapState('roles', ['edit_role'])
  },
  methods: {
    ...mapActions('roles', ['getRole']),
    ...mapMutations('roles', ['clearRole'])
  },
  created () {
    this.getRole(this.$route.params.id)
  },
  beforeRouteUpdate (to, from, next) {
    this.getRole(this.$route.params.id)
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.clearRole()
    next()
  }
}
</script>

<style scoped>

</style>
