<template>
<!--TODO supplier-->
  <section class="table_box">
    <div class="container reverse short flx" v-if="supplier.id">
      <div class="table_head flx">
        <span class="page_title">Информация о поставщике ID: {{ supplier.id }}・{{ supplier.name }}</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <router-link :to="{ name: 'EditSupplier', params: {id: supplier.id }}" class="btn">Редактировать</router-link>
      </div>
    </div>
    <div class="container tbl flx" v-if="supplier.id">
      <div class="box_shadow info_single_supplier">
        <div class="row">
          <span class="page_subtitle">Общее</span>
          <div class="item flx">
            <span class="name">Название:</span>
            <span>{{ supplier.name }}</span>
          </div>
        </div>
      </div>
      <div class="table_body table_actions_supplier">
        <div class="top_table flx">
          <span class="page_subtitle">Последние накладные</span>
          <div class="pagination flx">
            <div class="flx">
              <p>Строк:</p>
              <div class="select" id="sellers">
                <i class="ti-angle-down"></i>
                <select name="select">
                  <option value="" selected>Все</option>
                  <option value="1">100</option>
                  <option value="2">500</option>
                  <option value="3">1500</option>
                </select>
              </div>
            </div>
<!--            TODO pagination-->
<!--            <ul class="flx">-->
<!--              <li><a href="#"><<</a></li>-->
<!--              <li><a href="#"><</a></li>-->
<!--              <li><a href="#">1</a></li>-->
<!--              <li><a href="#">...</a></li>-->
<!--              <li><a href="#">3</a></li>-->
<!--              <li class="active"><a href="#">4</a></li>-->
<!--              <li><a href="#">5</a></li>-->
<!--              <li><a href="#">6</a></li>-->
<!--              <li><a href="#">...</a></li>-->
<!--              <li><a href="#">80</a></li>-->
<!--              <li><a href="#">></a></li>-->
<!--              <li><a href="#">>></a></li>-->
<!--            </ul>-->
          </div>
        </div>
        <div class="bot_table">
          <div class="headline_table flx">
            <div class="id_invoice">
              <p class="title">ID </p>
            </div>
            <div class="date_invoice">
              <p class="title">Дата</p>
            </div>
            <div class="qty_invoice">
              <p class="title">кол-во товаров </p>
            </div>
            <div class="sum_invoice">
              <p class="title">Итоговая Сумма</p>
            </div>
            <div class="comment_invoice">
              <p class="title">Комментарий</p>
            </div>
          </div>
          <div class="row_list">
            <div class="row flx invoice">
              <div class="id_invoice">
                <a href="single_invoice.html">487</a>
              </div>
              <div class="date_invoice">
                <span><b>08.04.2021</b></span>
              </div>
              <div class="qty_invoice">
                <span>21</span>
              </div>
              <div class="sum_invoice">
                <span><b>-12 500.00 грн</b></span>
              </div>
              <div class="comment_invoice">
                <span>Партия Шорт + Бомберы (летняя коллекция)</span>
              </div>
            </div>
            <div class="row flx payment">
              <div class="id_invoice">
                <a href="single_invoice.html">5</a>
              </div>
              <div class="date_invoice">
                <span><b>08.04.2021</b></span>
              </div>
              <div class="qty_invoice"></div>
              <div class="sum_invoice">
                <span><b>-12 500.00 грн</b></span>
              </div>
              <div class="comment_invoice">
                <span>Партия Шорт + Бомберы (летняя коллекция)</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'Supplier',
  computed: {
    ...mapState('suppliers', ['supplier'])
  },
  methods: {
    ...mapActions('suppliers', ['getSupplier']),
    ...mapMutations('suppliers', ['clearSupplier'])
  },
  created () {
    this.getSupplier(this.$route.params.id)
  },
  beforeRouteLeave (to, from, next) {
    this.clearSupplier()
    next()
  }
}
</script>

<style scoped>

</style>
