<template>
    <div id="app">
        <notifications class="notify" group="notify"/>
        <router-view/>
    </div>
</template>

<script>

import { eventBus } from '@/main'

export default {
  name: 'App',

  mounted () {
    eventBus.$on('send_notify', (type, title, message) => {
      this.$notify({
        type: type,
        group: 'notify',
        title: title,
        text: message,
        duration: 5000
      })
    })
  }
}
</script>
<style lang="scss">
@import "assets/scss/tooltips";
.notify {
  > span {
    width: 100%;
    .vue-notification {
      margin: 0 0 5px;
    }
  }
}
</style>
