<template>
  <div>
    <section class="sub_header">
      <div class="container">
        <nav class="sub_menu flx" >
          <router-link :to="{ name: 'Users'}" class="item">
            <p>Пользователи CRM</p>
          </router-link>
          <router-link :to="{ name: 'Roles' }" class="item">
            <p>Роли пользователей</p>
          </router-link>
          <router-link :to="{ name: 'Senders'}" class="item">
            <p>Отправители</p>
          </router-link>
          <router-link :to="{ name: 'Suppliers' }" class="item">
            <p>Поставщики</p>
          </router-link>
          <router-link :to="{ name: 'Contents' }" class="item">
            <p>Контент</p>
          </router-link>
          <router-link :to="{ name: 'Banners' }" class="item">
            <p>Баннеры</p>
          </router-link>
          <router-link :to="{ name: 'Checks' }" class="item">
            <p>Чеки</p>
          </router-link>
        </nav>
      </div>
    </section>
    <router-view :key="$router.path" />
    <delete-user-modal></delete-user-modal>
    <delete-sender-modal></delete-sender-modal>
    <delete-role-modal></delete-role-modal>
    <delete-supplier-modal></delete-supplier-modal>
  </div>
</template>

<script>
import DeleteUserModal from './Users/DeleteUserModal'
import DeleteSenderModal from './Senders/DeleteSenderModal'
import DeleteRoleModal from './Roles/DeleteRoleModal'
import DeleteSupplierModal from './Suppliers/DeleteSupplierModal'
export default {
  name: 'Administration',
  components: {
    DeleteUserModal,
    DeleteSenderModal,
    DeleteRoleModal,
    DeleteSupplierModal
  }
}
</script>

<style scoped>

</style>
