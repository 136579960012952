export const strings = {
  filters: {
    firstLetter (str) {
      if (!str) {
        return ''
      }
      return str.charAt(0).toUpperCase() + '.'
    },
    truncate (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    },
    abbreviated (str) {
      return (str === null || str === undefined || str === '' || typeof (str) !== 'string') ? '' : str.replace(/(\S+) (\S)\S* (\S)\S*/, '$1 $2. $3.')
    }
  },
  methods: {
    searchByName: (option, label, search) => {
      const temp = search.toLowerCase()
      return option.name.toLowerCase().indexOf(temp) > -1 ||
        option.name_ua.toLowerCase().indexOf(temp) > -1
    }
  }
}
