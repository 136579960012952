
export const phones = {
  filters: {
    removeCodeCountry: function (value) {
      if (!value) {
        return ''
      }
      let phoneNumber = value
      if (phoneNumber && phoneNumber.indexOf('+38') === 0) {
        phoneNumber = phoneNumber.substring(3)
      }
      return phoneNumber.replace(/[{()}]/g, '').replace(/-/g, ' ').trim()
    }
  }

}
