<script>
import vSelect from 'vue-select'
export default {
  name: 'BrewSelect',
  extends: vSelect,
  methods: {
    clearSelection: function () {
      vSelect.methods.clearSelection.call(this)
      this.$emit('option:cleared')
    }
  }
}
</script>
