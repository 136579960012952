<template>
    <transition name="fade">
        <div v-if="show" class="popup_box" style="display: block">
            <div class="popup delete_category">
                <span class="page_title">Удалить созданый ТТН?</span>
                <span class="close_popup" @click="close()"></span>
                <button class="btn" @click="close()">Отмена</button>
                <button class="btn empty" @click="confirm()">Удалить</button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'DeliveryDeclarationDeleteModal',
  computed: {
    ...mapState('orders', ['show_delete_ttn_modal', 'order']),
    show: {
      get () {
        return this.show_delete_ttn_modal
      },
      set (value) {
        this.setShowDeleteTTNModal(value)
      }
    }
  },
  methods: {
    ...mapActions('orders', {
      remove: 'deleteEN'
    }),
    ...mapMutations('orders', ['setShowDeleteTTNModal']),
    confirm () {
      this.remove(this.order.id)
      this.close()
    },
    close () {
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>
