<template>
  <div>
    <transition name="fade">
      <div v-if="show" class="popup_box" style="display: block">
        <div class="popup add_category" style="max-height: 550px">
          <span class="page_title">Изменить Категорию</span>
          <span class="close_popup" @click="close()"></span>
          <div class="inp_wrap">
            <p>Название категории:</p>
            <input type="text" v-model="category_data.name">
          </div>
          <div class="inp_wrap">
            <p>Родительская категория:</p>
            <div>
              <brew-select
                class="vue-select"
                label="name"
                style="background: white; border-radius: 7px;margin-bottom: 15px"
                v-model="category_data.parent_id"
                :reduce="item => item.id"
                :options="filteredRootCategories"
              >
              </brew-select>
            </div>
          </div>
          <div class="inp_wrap">
            <p>Порядок сортировки:</p>
            <input type="number" v-model="category_data.sort">
          </div>
          <div class="inp_wrap" style="text-overflow:unset;margin-bottom: 10px;margin-top: 5px">
            <p style="overflow:unset">Показывать в главном меню:
              <label class="custom-checkbox">
                <input v-model="category_data.show_in_menu" type="checkbox" name="remembered">
                <span></span>
              </label>
            </p>
          </div>
          <div class="inp_wrap" style="text-overflow:unset;margin-bottom: 10px;margin-top: 5px">
            <p style="overflow:unset">Показывать в слайдере:
              <label class="custom-checkbox">
                <input v-model="category_data.show_in_slider" type="checkbox" name="remembered">
                <span></span>
              </label>
            </p>
          </div>
          <div class="inp_wrap" style="margin-bottom: 20px; ">
            <p>Изображение:</p>
            <picture-input
              style="width: 100px;margin: 0"
              :prefill="category_data.image_url"
              class=""
              width="100"
              height="100"
              :plain="true"
              margin="0"
              radius="10"
              accept="image/jpeg,image/png"
              :hideChangeButton="true"
              size="5"
              @change="addImage"
              :z-index="10"
            >
            </picture-input>
          </div>
          <button class="btn" @click="save()">Изменить</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'
import { eventBus } from '@/main'
import PictureInput from 'vue-picture-input'

export default {
  name: 'CategoryEditModal',
  props: {
    show: Boolean,
    category: Object
  },
  components: { PictureInput },
  data () {
    return {
      category_data: {
        name: null,
        parent_id: null,
        sort: null,
        image_source: null,
        show_in_menu: null,
        show_in_slider: null,
        image_url: null
      }
    }
  },
  computed: {
    ...mapState('catalog', ['rootCategories']),
    filteredRootCategories: {
      get: function () {
        if (this.category !== undefined) {
          return _.reject(this.rootCategories, { id: this.category.id })
        }
        return this.rootCategories
      }
    }
  },
  watch: {
    show: function (newValue) {
      if (newValue) {
        this.getList()
        this.category_data = Object.assign({}, this.category)
      }
    }
  },
  methods: {
    ...mapActions('catalog',
      {
        getList: 'getRootCategories',
        update: 'updateCategory'
      }
    ),
    addImage (image) {
      this.category_data.image_source = image
    },
    save () {
      this.update(this.category_data)
    },
    close () {
      this.clearCategoryData()
      this.$root.$emit('show_category_edit', false)
    },
    clearCategoryData () {
      this.category_data = {
        name: null,
        parent_id: null
      }
    }
  },
  created () {
    eventBus.$on('system.category.updated', (value) => {
      if (value) {
        this.close()
      }
    })
  },
  beforeDestroy () {
    eventBus.$off('system.category.updated')
  }
}
</script>

<style scoped>
.select-pagination {
  background: #F9FAFC;
  border-radius: 6px;
  border: 1px solid #E4EBF6;
}

.select-pagination >>> .vs__dropdown-toggle {
  border-radius: 0;
  border: none;
}

.select-pagination >>> .vs__selected-options {
  height: 18px;
}

.select-pagination >>> .vs__selected {
  margin: 2px 2px 0;
}

.select-pagination >>> .vs__open-indicator {
  transform: scale(0.8);
}

.vs--open >>> .vs__open-indicator {
  transform: scale(0.8) rotate(180deg);
}

</style>
