<template>
  <div class="dou-super-select">
    <input
      v-if="searchable !== 'false'"
      v-model="selected_name"
      :class="{'dou-super-select-active':show_results_list, 'dou-disabled': disabled}"
      type="text"
      @focus="activeInput"
      @input="search"
      :disabled="disabled"
    >
    <span
      @click.stop="activeInput"
      v-else
      class="dou-no-input"
      :class="{'dou-super-select-active':show_results_list, 'dou-disabled': disabled}"
    > {{selected_name}}</span>

    <div class="dou-action-clear"
         v-if="clearable && selected_name && selected_name.length > 0"
    >
      <svg
        @click.stop="clearInput"
        width="8" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 11L6 6M6 6L1 1M6 6L11 1M6 6L11 11" stroke="#6E84A3" stroke-width="1.33" stroke-linecap="round"
              stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="dou-action-block"
         :class="{'dou-select-opened':show_results_list}"
         @click.stop="toggleResultsView()"
    >
      <svg width="10" height="12" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0H0L3 4L6 0Z" fill="#6E84A3"/>
      </svg>
    </div>
    <div class="dou-super-select-results" :class="{'dou-super-select-results-show':show_results_list}"
    >
      <div class="dou-super-select-result-item"
           v-for="(item, index) in items"
           :key="itemKey+'-'+index"
           @click="!item.disabled ? selectItem(item) : null"
           :class="{'dou-disabled': item.disabled}"
      >
        {{ langToggle ? item[fieldNameAlt] : item[fieldName] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuperSelect',
  props: [
    'itemKey',
    'initSelected',
    'items',
    'fieldName',
    'fieldNameAlt',
    'langToggle',
    'clearable',
    'searchable',
    'disabled'
  ],
  data () {
    return {
      show_results_list: false,
      selected_name: null,
      selected: null,
      timerInput: null,
      bool_disabled: false
    }
  },
  watch: {
    '$attrs.value': {
      immediate: true,
      handler (value) {
        if (value !== undefined && this.items && this.items.length > 0) {
          const findItem = this.items.find(item => item.id === value)
          this.selected = findItem
          this.selected_name = findItem.name
        }
      }
    },
    items: {
      immediate: true,
      handler (value) {
        if (value && value.length > 0) {
          if (this.$attrs.value) {
            const findItem = value.find(item => item.id === this.$attrs.value)
            this.selected = findItem
            this.selected_name = findItem.name
          }
        }
      }
    },
    initSelected: {
      immediate: true,
      handler (value) {
        if (value && typeof value === 'object' && Object.keys(value).length !== 0) {
          this.selected = value
        } else if (this.$attrs.value === null || this.$attrs.value === undefined) {
          this.selected = null
        }
      }
    },
    selected: {
      immediate: true,
      handler (value) {
        if (value && typeof value === 'object') {
          this.selected_name = this.langToggle ? value[this.fieldNameAlt] : value[this.fieldName]
        } else {
          // this.selected_name = null
        }
      }
    }
  },
  methods: {
    activeInput () {
      if (this.disabled) {
        return
      }
      window.addEventListener('click', this.mouseClick)
      this.show_results_list = true
      if (this.selected_name === null) {
        this.search()
      }
    },
    close () {
      window.removeEventListener('click', this.mouseClick)
      this.show_results_list = false
      if (this.selected === null) {
        this.clearInput()
      }
    },
    clearInput () {
      this.selected = null
      this.selected_name = null
      this.$emit('input', null)
      this.$emit('selected', null)
    },
    selectItem (item) {
      this.selected = item
      this.$emit('selected', item)
      this.$emit('input', item.id)
      this.close()
    },
    search () {
      this.selected = null
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('search', this.selected_name)
      }, 500)
    },
    toggleResultsView () {
      if (this.disabled) {
        this.show_results_list = false
        return
      }

      this.show_results_list = !this.show_results_list
      if (this.show_results_list) {
        this.activeInput()
      }
    },
    mouseClick (e) {
      if (this.selected === null) {
        this.selected_name = null
      }
      if (!this.$el.contains(e.target)) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss">
.required {
  .dou-no-input {
    border-color: #f5a1b1 !important;
    color: #ff2c51 !important;
  }
  input {
    border-color: #f5a1b1 !important;
    color: #ff2c51 !important;
  }
}
.dou-super-select {
  position: relative;
  .dou-disabled {
    background-color: #fafafa!important;
    color: #ccc!important;
    cursor: not-allowed!important;
  }
  .dou-no-input {
    cursor: pointer;
    padding: 10px 72px 10px 12px;
    width: 100%;
    height: 36px;
    background-color: #FFFFFF;
    border: 1px solid #E4EBF6;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.2;
    color: #3C434A;
    font-family: 'Gilroy', sans-serif;
    white-space: nowrap;
  }

  .dou-action-clear {
    padding: 12px 10px;
    position: absolute;
    top: 0px;
    right: 25px;
    transform: rotate(0deg);
    transition: .5s all;

    svg {
      display: block;
    }
  }

  .dou-action-block {
    padding: 12px 10px 12px 10px;
    position: absolute;
    top: 0px;
    right: 0px;
    transform: rotate(0deg);
    transition: .2s all;

    svg {
      display: block;
    }
  }

  .dou-select-opened {
    transform: rotate(180deg);
  }

  input {
    padding: 10px 72px 10px 12px;
    margin-bottom: 0!important;
  }

  .dou-super-select-active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
  }

  .dou-super-select-results {
    opacity: 0;
    position: absolute;
    z-index: 100;
    background: white;
    width: 100%;
    height: 0px;
    overflow-y: auto;
    border: 1px solid #e4ebf7;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    transition: .2s all;

    .dou-super-select-result-item {
      display: none;
      padding: 5px;
      cursor: pointer;
      transition: .5s all;

      &:hover {
        background: #feead2;
      }
    }
  }

  .dou-super-select-results-show {
    max-height: 200px;
    height: fit-content;
    opacity: 1;

    .dou-super-select-result-item {
      display: block;
    }
  }
}
</style>
