<template>
  <div class="popup_box" data-id="add_product_to_category" :class="{ active: show_add_category_products_modal }">
    <div class="popup add_product_to_category" v-if="category_for_products">
      <span class="page_title">Добавить товары в категорию: {{ category_for_products.name | truncate(20)}}</span>
      <span class="close_popup" @click="setShowAddCategoryProductsModal(false)"></span>
      <div class="add_item_wrap flx">
        <div class="filters_box">
          <div class="inp_wrap">
            <p>Артикул:</p>
            <input type="text" id="category_product" v-model="product_filters.vendor_code"
                   @keypress.enter="getProducts">
          </div>
          <div class="inp_wrap">
            <p>Название:</p>
            <input type="text" id="name_product" v-model="product_filters.name"
                   @keypress.enter="getProducts">
          </div>
          <div class="inp_wrap">
            <p>Цена:</p>
            <input type="text" id="price_product" v-model="product_filters.price_from"
                   @keypress.enter="getProducts">
          </div>
          <div class="inp_wrap">
            <div class="flx product-list-filter-actions">
              <div class="reset mb-1" @click="clearProductFilters">Сбросить фильтры</div>
              <button class="btn empty" @click="getProducts">Применить</button>
            </div>
          </div>
        </div>
        <div class="add_product_table_wrap">
          <div class="add_product_table">
            <div class="table_body">
              <div class="headline_table flx">
                <div class="code_prod">
                  <p>Артикул</p>
                </div>
                <div class="name_prod">
                  <p>Название</p>
                </div>
                <div class="price_prod">
                  <p>цена дроп</p>
                </div>
                <div class="btn_wrap"></div>
              </div>
              <div class="row_list">
                <div class="row flx" :class="{ added: product.categories.length > 0 }" v-for="product in products" :key="'add-product-'+product.id">
                  <div class="code_prod">
                    <router-link :to="{name: 'ShowProduct', params: { id: product.id }}">{{ product.vendor_code }}</router-link>
                  </div>
                  <div class="name_prod">
                    <span>{{ product.name }}</span>
                  </div>
                  <div class="price_prod">
                    <span>{{ product.prices.retail_price }} грн</span>
                  </div>
                  <div class="btn_wrap">
                    <div class="btn add_product" v-if="product.categories.length === 0" @click="addProductToCategory(product)">
                      <span class="plus_light"></span>
                      <span>Добавить</span>
                    </div>
                    <span class="status green" v-if="product.categories.length > 0">Добавлен</span>
                    <div class="delete" v-if="product.categories.length > 0" @click="deleteProductCategories(product)">
                      <span class="close"> </span>
                      <span>Удалить категории</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { prices } from '../../helpers/prices'
import { strings } from '../../helpers/strings'
export default {
  name: 'AddProductsToCategoryModal',
  mixins: [
    prices,
    strings
  ],
  computed: {
    ...mapState('catalog', ['show_add_category_products_modal', 'category_for_products']),
    ...mapState('product', { productsFilter: 'filters', products: 'products' }),
    product_filters: {
      get () {
        return this.productsFilter
      },
      set (val) {
        this.setFilters(val)
      }
    }
  },
  methods: {
    ...mapMutations('catalog', ['setShowAddCategoryProductsModal', 'setCategoryForProducts']),
    ...mapActions('product', ['getProducts', 'updateProduct']),
    ...mapMutations('product', ['setPage', 'setPerPage', 'clearFilters', 'setFilters']),
    addProductToCategory (product) {
      product.category_ids = [this.category_for_products.id]
      this.updateProduct(product)
        .then(res => {
          this.getProducts()
        })
    },
    deleteProductCategories (product) {
      product.category_ids = []
      this.updateProduct(product)
        .then(res => {
          this.getProducts()
        })
    },
    clearProductFilters () {
      this.clearFilters()
      this.$nextTick(() => {
        this.getProducts()
      })
    }
  },
  watch: {
    show_add_category_products_modal () {
      this.clearFilters()
      this.setPage(1)
      this.setPerPage(100)
      this.getProducts()
    }
  },
  create () {
    this.clearFilters()
    this.setPage(1)
    this.setPerPage(100)
    this.getProducts()
  }
}
</script>

<style scoped>

</style>
