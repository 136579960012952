<template>
  <div class="headline_table flx">
    <div v-for="(item, index) in headers" :key="'header-' + index" :class="item.class">
      <label class="custom-checkbox" v-if="item.check_all && item.wrapper">
        <input type="checkbox" name="remembered" :checked="selectedAll" @change="selectAll">
        <span></span>
      </label>
      <p :class="item.title_class || ''" v-if="item.title && item.check_all && item.wrapper">{{item.title}}</p>
      <label v-else-if="item.check_all && !item.wrapper">
        <input type="checkbox" name="remembered" :checked="selectedAll" @change="selectAll">
        <span></span>
      </label>
      <div  class="sortable" v-else-if="item.sortable" @click="changeSorting(item.value)">
        <p :class="item.title_class || ''" v-if="item.title">{{item.title}}</p>
        <div class="sorting" v-if="item.sortable">
            <svg width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg" class="sorting--up">
              <path d="M0.441406 4L6.44141 4L3.44141 0L0.441406 4Z" :fill="item.value && item.value === sortBy && sortDesc === 'desc' ?'#6E84A3':'#D2DDEC'"/>
            </svg>
            <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg" class="sorting--down">
              <path d="M6 0H0L3 4L6 0Z" :fill="item.value && item.value === sortBy && sortDesc === 'asc' ?'#6E84A3':'#D2DDEC'"/>
            </svg>
        </div>
      </div>
      <p :class="item.title_class || ''" v-else-if="item.title && item.title !== ''">{{item.title}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Headline',
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: 'id'
    },
    sortDesc: {
      type: String,
      default: 'decs'
    },
    selectedAll: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectAll (e) {
      this.$emit('update:selectedAll', e.target.checked)
    },
    changeSorting (sortBy) {
      if (sortBy) {
        if (sortBy === this.sortBy) {
          this.$emit('update:sortDesc', this.sortDesc === 'asc' ? 'desc' : 'asc')
        } else {
          this.$emit('update:sortBy', sortBy)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.headline_table {
  .sortable {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    .sorting {
      display: flex;
      flex-direction: column;
      height: 10px;
      justify-content: space-between;
      margin-left: 4px;
      &--up {
       display: block;
       height: 4px;
      }
      &--down {
       display: block;
       height: 4px;
      }
    }
  }
}
</style>
