import Vue from 'vue'
import Vuex from 'vuex'
import catalog from './modules/catalog'
import product from './modules/product'
import season from './modules/season'
import size from './modules/size'
import color from './modules/color'
import config from './modules/config'
import importProduct from './modules/importProduct'
import importClients from './modules/importClients'
import users from './modules/users'
import errors from './modules/errors'
import history from './modules/history'
import clients from './modules/clients'
import roles from './modules/roles'
import senders from './modules/senders'
import orders from './modules/orders'
import delivery from './modules/delivery'
import discount from './modules/discount'
import invoices from './modules/invoices'
import payments from './modules/payments'
import suppliers from './modules/suppliers'
import incoming from './modules/incoming'
import deduction from '@/store/modules/deduction'
import productHistory from '@/store/modules/productHistory'
import stock from '@/store/modules/stock'
import statistics from './modules/statistics'
import contents from './modules/contents'
import banners from './modules/banners'
import returnItems from './modules/returnItems'
import checkboxes from './modules/checkboxes'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    catalog,
    product,
    season,
    size,
    color,
    config,
    importProduct,
    importClients,
    users,
    errors,
    history,
    clients,
    roles,
    senders,
    orders,
    delivery,
    discount,
    invoices,
    payments,
    suppliers,
    incoming,
    deduction,
    productHistory,
    stock,
    statistics,
    contents,
    banners,
    returnItems,
    checkboxes
  }
})
