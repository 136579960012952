<template>
  <div>
    <div class="container statistic-page">
      <div class="table_body table_all_orders" style="margin-top: 20px">
        <div class="top_table flx">
          <div class="search_box flx">
            <div class="choose_seller flx">
              <p>Продавец:</p>
              <user-select
                style="min-width: 300px"
                :default-id="null"
                default-name="Все"
                :is-filter="true"
                :items="dropshippers"
                @search="fetchDropshippers"
                @active-input="fetchDropshippers"
                @select="selectUser"
              ></user-select>
            </div>
          </div>
        </div>
        <div class="brew-block" v-if="user && user.role && user.role.id === 4">
          <h1>Статистика прибыли</h1>
          <div class="main_info">
            <div class="statistic_outer flx" style="justify-content: flex-start">
              <div class="e-item">
                <label for="new-orders" class="orange"> Новые </label>
                <input :disabled="is_load_profit" id="new-orders" class="checkbox" type="checkbox" value="1"
                       v-model="profit_statuses">
              </div>
              <div class="e-item">
                <label for="new-orders1" class="blue"> Отправленные </label>
                <input :disabled="is_load_profit" id="new-orders1" class="checkbox" type="checkbox" value="2"
                       v-model="profit_statuses">
              </div>
              <div class="e-item">
                <label for="new-orders2" class="green"> Выполненые </label>
                <input :disabled="is_load_profit" id="new-orders2" class="checkbox" type="checkbox" value="3"
                       v-model="profit_statuses">
              </div>
              <div class="e-item">
                <label for="new-orders3" class="red"> Возвраты </label>
                <input :disabled="is_load_profit" id="new-orders3" class="checkbox" type="checkbox" value="4"
                       v-model="profit_statuses">
              </div>
            </div>
            <div class="statistic_outer">
              <date-picker
                :disabled="is_load_profit"
                style="margin-top: 10px;margin-left: 10px;"
                v-model="profit_time_range"
                format="D.M.Y"
                range>
              </date-picker>
            </div>
            <div class="statistic_outer" v-if="!is_load_profit">
              <div class="profit-out green">
                <small style="color: #2A2A2B">Прибыль:</small>
                {{ profit_response.profit }} грн.
                <small style="color: #2A2A2B">({{ profit_response.count }} заказов)</small>
              </div>
            </div>
            <div class="statistic_outer" v-else>
              <div class="profit-out">
                <small style="color: #2A2A2B">Обновление данных....</small>
              </div>
            </div>
          </div>
        </div>
        <!--        RADIAL -->
        <div class="brew-block">
          <h1>Статистика заказов</h1>
          <div style="width: 400px; padding-top: 20px">
            <p>Товар:</p>
            <super-select
              v-model="product_id"
              key="select-product"
              item-key="product-item"
              :items="products"
              field-name="full_name"
              clearable="true"
              @search="searchProduct"
            />
          </div>
          <div class="main_info flx">
            <div class="statistic_outer flx" id="chart">
              <div>
                <p @click="selectPeriodCircle('day')" class="btn-p" :class="{'active': ordersConf.period === 'day'}">
                  День</p>
                <p @click="selectPeriodCircle('week')" class="btn-p" :class="{'active': ordersConf.period === 'week'}">
                  Неделя</p>
                <p @click="selectPeriodCircle('month')" class="btn-p"
                   :class="{'active': ordersConf.period === 'month'}">Месяц</p>
                <p @click="selectPeriodCircle('year')" class="btn-p" :class="{'active': ordersConf.period === 'year'}">
                  Год</p>
                <p @click="selectPeriodCircle('all_time')" class="btn-p"
                   :class="{'active': ordersConf.period === 'all_time'}">Все время</p>
                <p @click="setPeriodCircle('period')" class="btn-p"
                   :class="{'active': ordersConf.period === 'period'}">
                  Период
                </p>
                <date-picker
                  v-if="ordersConf.period === 'period'"
                  style="width: 160px"
                  v-model="circle_time_range" format="D.M.Y"
                  range/>
              </div>
              <apexchart type="radialBar" height="350" :options="chartOptions" :series="series"></apexchart>
              <div>
                <p>
                  <span class="statistic-circle orange"></span>
                  <span class="count">{{ ordersConf.counter.new }}</span> Новых
                </p>
                <p>
                  <span class="statistic-circle green"></span>
                  <span class="count">{{ ordersConf.counter.completed_count }}</span> Завершенных
                </p>
                <p><span class="statistic-circle blue"></span><span
                  class="count">{{ ordersConf.counter.send_count }}</span> Отправленных</p>
                <p><span class="statistic-circle red"></span><span
                  class="count">{{ ordersConf.counter.return_count }}</span> Возвратов</p>
              </div>
            </div>
          </div>
        </div>
        <div class="brew-block">
          <h1>Динамика заказов</h1>
          <div class="container">
            <div class="top flx">
              <h2></h2>
              <div class="right_box flx">
                <div class="dinamics_period_fix">
                  <span @click="setPeriod('week')" class="item"
                        :class="{'active': periodGraph === 'week'}">Неделя</span>
                  <span @click="setPeriod('month')" class="item"
                        :class="{'active': periodGraph === 'month'}">Месяц</span>
                  <span @click="setPeriod('year')" class="item" :class="{'active': periodGraph === 'year'}">Год</span>
                  <span @click="setPeriod('all_time')" class="item"
                        :class="{'active': periodGraph === 'all_time'}">Всё время</span>
                </div>
                <div class="dinamics_period_from_to flx">
                  <span class="grey">Период </span>
                  <date-picker style="margin-top: 15px;margin-left: 20px;" v-model="time_range" format="D.M.Y"
                               range></date-picker>
                </div>
              </div>
            </div>
            <div style="margin-top: 20px">
              <apexchart type="line" height="350" :options="chartOptionsDinamic" :series="seriesDinamic"></apexchart>
            </div>
          </div>
        </div>
        <div class="brew-block">
          <h1>Топ-10 товаров</h1>
          <section class="top_products_outer" style="margin-top: 20px">
            <div class="container">
              <div class="top_products_inner flx">
                <div class="item_top"
                     v-for="(popular, index) in popular_goods" :key="index"
                     :style="{ background: popular.color, width: popular.perc + '%' }"
                >
                  <span>{{ popular.count }} шт</span>
                  <div class="menu_drop popular_product">
                    <div class="dropdown flx">
                      <img v-if="popular.product.image_url" :src="popular.product.image_url" alt="product">
                      <span class="name">{{ popular.product.name }}</span>
                      <div class="popular_prod_info">
                        <div class="item">
                          <span>Артикул:</span>
                          <span class="line"> </span>
                          <p>{{ popular.product.vendor_code }}</p>
                        </div>
                        <div class="item">
                          <span>Цвет:</span>
                          <span class="line"> </span>
                          <p>{{ popular.product.color }}</p>
                        </div>
                        <div class="item">
                          <span>Цена дроп:</span>
                          <span class="line"> </span>
                          <p>{{ popular.product.retail_price }} грн/шт</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="brew-block">
          <h1>Популярные категории</h1>
          <section class="category_card_outer profile_category">
            <div class="container">
              <div class="category_card_inner flx">
                <a :href="'/dashboard/dropshipping/categories/'+item.id" target="_blank" class="card flx"
                   v-for="item in popular_categories" :key="item.id">
                  <div v-if="!item.image" class="img_wrap"
                       style="background-image: url(/images/tolstovki-i-svitshoty.png);">
                    <span class="qty_orders">{{ item.count }}<span>заказов</span></span>
                  </div>
                  <div v-else class="img_wrap" :style="{'background-image': 'url(' + item.image + ')'}">
                    <span class="qty_orders">{{ item.count }}<span>заказов</span></span>
                  </div>
                  <span>{{ item.name }}</span>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import UserSelect from './Helpers/UserSelect'
import VueApexCharts from 'vue-apexcharts'
import DatePicker from 'vue2-datepicker'
import axios from 'axios'
import SuperSelect from './Helpers/SuperSelect/SuperSelect'

export default {
  name: 'Statistics',
  data () {
    return {
      timer: null,
      is_load_profit: false,
      profit_response: {
        count: 0,
        profit: 0
      },
      profit_statuses: [1, 2, 3, 4],
      user_id: null,
      product_id: null,
      ordersConf: {
        period: 'day',
        counter: {
          new: 0,
          total_count: 0,
          completed_count: 0,
          send_count: 0,
          return_count: 0
        }
      },
      series: [0, 0, 0, 0],
      chartOptions: {
        chart: {
          height: 350,
          type: 'radialBar'
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px'
              },
              value: {
                fontSize: '16px'
              },
              total: {
                show: true,
                label: 'Заказов',
                value: 1,
                formatter: () => {
                  return this.getTotal()
                }
              }
            }
          }
        },
        colors: ['#ffb52d', '#0fa75e', '#005aba', '#fd5e75'],
        labels: ['Новых', 'Завершенных', 'Отправленных', 'Возвратов']
      },
      period: 'week',
      periodGraph: 'week',
      time_range: [],
      circle_time_range: [],
      profit_time_range: []
    }
  },
  components: {
    SuperSelect,
    UserSelect,
    DatePicker,
    apexchart: VueApexCharts
  },
  computed: {
    ...mapState('product', ['products']),
    ...mapState('users', ['dropshippers']),
    ...mapState('catalog', ['user']),
    ...mapState('statistics', ['seriesDinamic', 'chartOptionsDinamic', 'popular_goods', 'popular_categories'])
    // ...mapState('statistics', ['series', 'ordersConf', 'seriesCircle', 'chartOptionsCircle', 'chartOptions', 'popular_goods', 'popular_categories'])
  },
  watch: {
    circle_time_range (value) {
      this.getStatistics()
    },
    time_range (value) {
      const data = {
        date: value,
        user_id: this.user_id
      }
      this.periodGraph = 'custom'
      this.getOrderCustomGraph(data)
    },
    'profit_statuses' (v) {
      this.getProfit()
    },
    profit_time_range (v) {
      if (v[0] !== null && v[1] !== null) {
        this.getProfit()
      }
    },
    user_id () {
      this.getProfit()
    },
    product_id () {
      this.getStatistics()
    },
    'user.role' () {
      this.getProfit()
    }
  },
  methods: {
    ...mapActions('users', ['getDropshippersForSelect']),
    ...mapActions('statistics', ['getOrderGraph', 'getOrderCustomGraph', 'getPopularGoods', 'getPopularCategories']),
    ...mapMutations('users', { setDropshipperSearch: 'setSearch' }),
    ...mapActions('product', ['getProducts']),
    ...mapMutations('product', ['setPage', 'setSearch']),
    getTotal () {
      return this.ordersConf.counter.total_count
    },
    selectUser (data) {
      this.user_id = data.id
      this.getStatistics()
      this.getOrderGraph({
        period: this.periodGraph,
        user_id: this.user_id
      })
      this.getPopularGoods(this.user_id)
      this.getPopularCategories(this.user_id)
    },
    getStatistics () {
      axios.get('api/admin/statistic', {
        params: {
          period: this.ordersConf.period,
          user_id: this.user_id,
          range: this.circle_time_range,
          product_id: this.product_id
        }
      }).then(res => {
        console.log(res.data)
        this.$set(this.series, 0, res.data.perc.new_perc) // новые
        this.$set(this.series, 1, res.data.perc.completed_perc) // Завершенныые
        this.$set(this.series, 2, res.data.perc.send_perc) // Отправленные
        this.$set(this.series, 3, res.data.perc.return_perc) // возврат

        this.ordersConf.counter.completed_count = res.data.completed_count
        this.ordersConf.counter.new = res.data.new
        this.ordersConf.counter.send_count = res.data.send_count
        this.ordersConf.counter.return_count = res.data.return_count
        this.ordersConf.counter.total_count = res.data.total_count
      })
    },
    getProfit () {
      if (this.user && this.user.role && this.user.role.id === 4) {
        const params = {}
        params.user_id = this.user_id
        params.period = 'custom'
        params.from = this.profit_time_range[0]
        params.to = this.profit_time_range[1]
        params.statuses = this.profit_statuses
        this.is_load_profit = true
        axios.get('api/admin/statistic/profit', {
          params: params
        }).then(res => {
          this.profit_response = res.data
          this.is_load_profit = false
        }).catch(e => {
          this.is_load_profit = false
        })
      }
    },
    fetchDropshippers (search) {
      this.setDropshipperSearch(search)
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getDropshippersForSelect()
      }, 500)
    },
    setPeriodCircle (period) {
      this.ordersConf.period = period
    },
    selectPeriodCircle (period) {
      this.ordersConf.period = period
      if (period !== 'period') {
        this.circle_time_range = []
      }
      this.getStatistics()
    },
    setPeriod (p) {
      this.periodGraph = p
      this.getOrderGraph({
        period: this.periodGraph,
        user_id: this.user_id
      })
    },
    searchProduct (search) {
      this.setSearch(search)
      this.setPage(1)
      this.getProducts()
    }
  },
  created () {
    this.getStatistics()
    this.getOrderGraph({
      period: this.periodGraph,
      user_id: this.user_id
    })
    this.getPopularGoods(this.user_id)
    this.getPopularCategories(this.user_id)

    const end = new Date()
    const start = new Date()
    start.setDate(end.getDate() - 30)
    this.profit_time_range = [start, end]
  }
}
</script>

<style lang="scss">
.statistic-page {
  .btn-p {
    cursor: pointer;
    padding: 5px;
    border-radius: 6px;
  }

  .btn-p:hover {
    background: #f1f1f1;
  }

  .active {
    color: orangered;
    background: #f1f1f1;
  }

  .brew-block {
    padding: 20px;
  }

  .brew-block h1 {
    font-size: 24px;
    border-bottom: 1px solid #f1f1f1;
  }

  .dinamics_period_fix .item {
    padding: 5px;
  }

  .statistic-circle {
    width: 20px;
    height: 20px;
    padding: 5px;
    vertical-align: sub;
    border-radius: 100px;
    margin-right: 10px;
  }

  .statistic-circle.orange {
    border: 5px solid orange;
  }

  .statistic-circle.green {
    border: 5px solid rgba(15, 167, 94, 0.85);
  }

  .statistic-circle.blue {
    border: 5px solid rgba(0, 90, 186, 0.85);
  }

  .statistic-circle.red {
    border: 5px solid rgba(253, 94, 117, 0.85);;
  }

  .count {
    font-size: 24px;
  }

  .checkbox {
    width: 12px;
    height: 12px;
  }

  .e-item {
    padding: 10px;
  }

  .profit-out {
    padding: 10px;
    font-size: 18px;
  }

  .statistic_outer {
    input {
      margin: 0 !important;
    }

    .mx-icon-calendar {
      margin-top: 6px;
    }

    .mx-icon-clear {
      margin-top: 6px;
    }
  }

  .mx-input {
    &:hover {
      border-color: #E4EBF6 !important;
    }
  }
}

</style>
