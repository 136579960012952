<template>
    <div>
        <section class="table_box all_category inner">
            <div class="container flx">
                <div class="table_head flx">
                    <span class="page_title">Категории товаров
                    <p v-if="category_pagination.from">Результат: {{category_pagination.from}} - {{category_pagination.to}} из {{category_pagination.total}}</p>
                    </span>
                </div>
                <div class="wrap_btn flx">
                    <div class="wrap_action active flx">
                        <span class="quantity">Отмечено: {{ selected_categories.length }}</span>
                        <div class="btn delete" @click="showCategoryDelete()">
                            <span class="close"></span>
                            <span class="red">Удалить категории</span>
                        </div>
                    </div>
                    <div
                        class="btn create open_pop"
                        @click="show_category_editor = true"
                    >
                        Создать новую категорию
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="table_body">
                    <div class="top_table flx">
                        <div class="wrap_search flx">
                            <button type="submit"></button>
                            <input v-model="search" type="text" placeholder="Поиск...">
                        </div>
                        <div class="pagination flx">
                            <div class="flx">
                                <p>Строк: </p>
                                <div><brew-select
                                        class="select-pagination"
                                        style="margin-left:10px;width: 100px"
                                        :clearable="false"
                                        v-model="perPage"
                                        :options="page_sizes"
                                    >
                                    </brew-select>
                                </div>
                            </div>
                            <paginate
                                v-model="page"
                                style="margin-left: 20px"
                                class="flx"
                                :page-count="category_pagination.last_page"
                                :page-range="3"
                                :margin-pages="2"
                                :prev-text="'<'"
                                :next-text="'>'"
                            >
                            </paginate>
                        </div>
                    </div>
                    <div class="bot_table">
                      <headline :headers="headers" :selected-all.sync="isSelectAll" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
                        <div class="row_list">
                            <div class="row flx" :class="{ checked: selected_categories.indexOf(category.id) !== -1}"
                                 :key="category.id"
                                 v-for="category in categories"
                            >
                                <label class="custom-checkbox">
                                    <input v-model="selected_categories" type="checkbox" name="remembered"
                                           :value="category.id">
                                    <span></span>
                                </label>
                                <router-link :to="'/dashboard/dropshipping/categories/'+category.id"
                                             class="category_product" tag="div">
                                    <span v-if="category.parent !== null">
                                        {{ category.parent == null ? '' : category.parent.name }}
                                    </span>
                                    <span> {{ category.name }}</span>
                                </router-link>
                                <div class="sorting_order">
                                    <span>{{ category.sort }}</span>
                                </div>
                                <div class="quantity_product">
                                    <span>{{ category.products_count }}</span>
                                </div>
                                <div class="sellers_with_discount">
                                    <span>{{ category.discounts_count }}</span>
                                </div>
                              <div class="btn_wrap">
                                <div class="btn add_product open_pop" data-id="add_product_to_category" @click="showCategoryAddProduct(category)">
                                  <span class="plus_light"></span>
                                  <span>Добавить товары</span>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <category-edit-modal
            :show="show_category_editor"
        ></category-edit-modal>
        <category-delete-modal
            :show="show_category_delete"
            :categories="selected_categories"
        ></category-delete-modal>
        <add-products-to-category-modal></add-products-to-category-modal>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { eventBus } from '@/main'
import CategoryEditModal from '@/components/Droppshipping/Categories/CategoryCreateModal'
import CategoryDeleteModal from '@/components/Droppshipping/Categories/CategoryDeleteModal'
import _ from 'lodash'
import AddProductsToCategoryModal from '../../Modals/AddProductsToCategoryModal'
import Headline from '../../Helpers/Headline'
let timer

export default {
  name: 'Categories',
  components: { CategoryEditModal, CategoryDeleteModal, AddProductsToCategoryModal, Headline },
  data () {
    return {
      is_select_all: false,
      page_sizes: [10, 50, 100, 1000],
      show_category_editor: false,
      show_category_delete: false,
      selected_categories: [],
      headers: [
        {
          title: '',
          check_all: true,
          class: 'custom-checkbox'
        },
        {
          title: 'Категория',
          title_class: '',
          sortable: true,
          class: 'category_product',
          value: 'id'
        },
        {
          title: 'Порядок сортировки',
          class: 'sorting_order',
          sortable: true,
          value: 'sort'
        },
        {
          title: 'Кол-во товаров',
          class: 'quantity_product',
          sortable: true,
          value: 'products_count'
        },
        {
          title: 'Продавцы со скидкой',
          class: 'sellers_with_discount',
          sortable: true,
          value: 'discounts_count'
        },
        {
          title: '',
          class: 'btn_wrap'
        }
      ]
    }
  },
  computed: {
    ...mapState('catalog',
      [
        'categories',
        'category',
        'category_page_filters',
        'category_pagination',
        'show_add_category_products_modal'
      ]
    ),
    sortBy: {
      get () {
        return this.category_page_filters.order_by
      },
      set (val) {
        this.setOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.category_page_filters.order_direction
      },
      set (val) {
        this.setOrderDirection(val)
      }
    },
    isSelectAll: {
      get: function () {
        if (this.categories === null) {
          return false
        }
        return this.selected_categories.length === this.categories.length && this.selected_categories.length > 0
      },
      set: function (value) {
        if (value) {
          this.selected_categories = _.map(this.categories, 'id')
        } else {
          this.selected_categories = []
        }
      }
    },
    page: {
      get: function () {
        return this.category_page_filters.page
      },
      set: function (value) {
        this.setCategoryPage(value)
        this.getCategories()
      }
    },
    perPage: {
      get: function () {
        return this.category_page_filters.per_page
      },
      set: function (value) {
        this.setPerPage(value)
        this.setCategoryPage(1)
        this.getCategories()
        this.selected_categories = []
      }
    },
    search: {
      get: function () {
        return this.category_page_filters.name
      },
      set: function (value) {
        clearTimeout(timer)
        timer = setTimeout(() => {
          this.setCategorySearch(value)
          this.getCategories()
        }, 1000)
      }
    }
  },
  watch: {
    sortBy () {
      this.getCategories()
    },
    sortDesc () {
      this.getCategories()
    }
  },
  methods: {
    ...mapMutations('catalog',
      [
        'setPerPage',
        'setCategorySearch',
        'setCategoryPage',
        'setShowAddCategoryProductsModal',
        'setCategoryForProducts',
        'setOrderBy',
        'setOrderDirection'
      ]
    ),
    ...mapActions('catalog',
      [
        'getCategories',
        'showCategory'
      ]
    ),
    showCategoryDelete () {
      if (this.selected_categories.length === 0) {
        eventBus.$emit('send_notify', 'warn',
          'Выберите категории',
          'Категории для удаления не выбраны'
        )
        return
      }

      this.show_category_delete = true
    },
    showCategoryAddProduct (category) {
      this.setCategoryForProducts(category)
      this.setShowAddCategoryProductsModal(true)
    }
  },
  created () {
    console.log('created')
    this.setCategoryPage(1)
    this.getCategories()
    this.$root.$on('show_category_editor', (data) => {
      this.show_category_editor = data
      this.selected_categories = []
    })
    this.$root.$on('show_category_delete', (data) => {
      this.show_category_delete = data
      this.selected_categories = []
    })
  }
}
</script>
