<template>
  <section class="table_box">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title">Информация о пользователе ID: {{ edit_user.id }}・{{ edit_user.name }}</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <router-link :to="{ name: 'EditUser', params: { id: edit_user.id }}" href="new_user.html" class="btn">Редактировать</router-link>
      </div>
    </div>
    <div class="container tbl flx">
      <div class="box_shadow info_single_user">
        <div class="row">
          <span class="page_subtitle">Общее</span>
          <div class="item flx">
            <span class="name">Логин:</span>
            <span><b>{{ edit_user.login }}</b></span>
          </div>
          <div class="item flx">
            <span class="name">Роль:</span>
            <router-link :to="{ name: 'Role', params: { id: edit_user.role.id }}" v-if="edit_user.role">{{ edit_user.role.name }}</router-link>
          </div>
          <div class="item flx">
            <span class="name">ФИО:</span>
            <span>{{ edit_user.name }}</span>
          </div>
          <div class="item flx">
            <span class="name">Телефон:</span>
            <span v-if="edit_user.phone">{{edit_user.phone | removeCodeCountry}}</span>
          </div>
          <div class="item flx">
            <span class="name">Email:</span>
            <span v-if="edit_user.email">{{edit_user.email}}</span>
          </div>
          <div class="item flx">
            <span class="name">ID:</span>
            <span>{{ edit_user.id }}</span>
          </div>
        </div>
      </div>
      <div class="table_body table_actions_user">
        <div class="top_table flx">
          <span class="page_subtitle">Последние действия</span>
          <div class="pagination flx">
            <div class="flx">
              <p class="desctop_v">Строк:</p>
              <div class="select" id="sellers">
                <i class="ti-angle-down"></i>
                <select name="select">
                  <option value="" selected>Все</option>
                  <option value="1">100</option>
                  <option value="2">500</option>
                  <option value="3">1500</option>
                </select>
              </div>
            </div>
<!--            pagination -->
          </div>
        </div>
        <div class="bot_table">
          <div class="headline_table flx">
            <div class="date_action_info">
              <p class="title">Дата </p>
            </div>
            <div class="action_user_info">
              <p class="title">Действие</p>
            </div>
          </div>
          <div class="row_list">
            <div class="row flx">
              <div class="date_action_info">
                <span><b>05.04.2021 </b></span>
                <span class="time">12.35</span>
              </div>
              <div class="action_user_info">
                <span>Создание заказа ID: <a href="single_order.html">225122</a></span>
              </div>
            </div>
            <div class="row flx">
              <div class="date_action_info">
                <span><b>05.04.2021 </b></span>
                <span class="time">12.35</span>
              </div>
              <div class="action_user_info">
                <span>Добавление продавца ID: <a href="single_seller.html">1234</a> · <b>AlexDropTime</b> · Чернов Алексей </span>
              </div>
            </div>
            <div class="row flx">
              <div class="date_action_info">
                <span><b>05.04.2021 </b></span>
                <span class="time">12.35</span>
              </div>
              <div class="action_user_info">
                <span>Создание заказа ID: <a href="single_order.html">225122</a></span>
              </div>
            </div>
            <div class="row flx">
              <div class="date_action_info">
                <span><b>05.04.2021 </b></span>
                <span class="time">12.35</span>
              </div>
              <div class="action_user_info">
                <span>Добавление продавца ID: <a href="single_seller.html">1234</a> · <b>AlexDropTime</b> · Чернов Алексей </span>
              </div>
            </div>
            <div class="row flx">
              <div class="date_action_info">
                <span><b>05.04.2021 </b></span>
                <span class="time">12.35</span>
              </div>
              <div class="action_user_info">
                <span>Создание заказа ID: <a href="single_order.html">225122</a></span>
              </div>
            </div>
            <div class="row flx">
              <div class="date_action_info">
                <span><b>05.04.2021 </b></span>
                <span class="time">12.35</span>
              </div>
              <div class="action_user_info">
                <span>Добавление продавца ID: <a href="single_seller.html">1234</a> · <b>AlexDropTime</b> · Чернов Алексей </span>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { dates } from '../../../helpers/dates'
import { phones } from '../../../helpers/phones'

export default {
  name: 'User',
  mixins: [
    dates,
    phones
  ],
  computed: {
    ...mapState('users', ['edit_user'])
  },
  methods: {
    ...mapActions('users', ['getUser']),
    ...mapMutations('users', ['clearEditUser']),
    ...mapMutations('errors', ['setErrors', 'clearErrors'])
  },
  created () {
    this.getUser(this.$route.params.id)
  },
  beforeRouteUpdate (from, to, next) {
    this.getUser(this.$route.params.id)
    next()
  },
  beforeRouteLeave (from, to, next) {
    this.clearEditUser()
    this.clearErrors()
    next()
  }
}
</script>

<style scoped>

</style>
