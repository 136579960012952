<template>
  <section class="table_box all_products inner">
    <loader v-if="is_loading" object="#5C6579" color1="#ccc" color2="#FFA500" size="5" speed="2" bg="#fff" objectbg="#999793" opacity="80" name="circular"></loader>
    <div class="container flx">
      <div class="table_head">
        <span class="page_title">Все товары</span>
        <p v-if="pagination.from">Результат: {{pagination.from}} - {{pagination.to}} из {{pagination.total}}</p>
      </div>
      <div class="wrap_btn flx">
        <div class="wrap_action active flx">
          <span class="quantity">Отмечено: {{ selected_products.length }}</span>
          <product-actions></product-actions>
        </div>
        <span class="del_wrap" @click="showProductsDelete()">
                    <span class="close"></span> <span class="red"> Удалить</span>
                </span>
        <router-link to="/dashboard/dropshipping/import" class="btn empty">Импорт CSV</router-link>
        <span class="point"></span>
        <div class="btn actions_btn empty" @click="show_product_filters=!show_product_filters" :class="{active: show_product_filters}">
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3333 1H1L6.33333 7.30667V11.6667L9 13V7.30667L14.3333 1Z" stroke="#F5A32A"
                  stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Фильтры</span>
        </div>
        <router-link class="btn create" to="/dashboard/dropshipping/products/create">Новый товар</router-link>
      </div>
      <product-filter
        :show="show_product_filters"
      />
    </div>
    <div class="container">
      <div class="table_body">
        <div class="top_table flx">
          <div class="wrap_search flx">
            <button type="submit"></button>
            <input v-model="search" type="text" placeholder="Поиск..." id="search">
            <ul class="search_list_box">
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
            </ul>
          </div>
          <div class="pagination flx">
            <div class="flx">
              <p>Строк:</p>
              <div>
                <brew-select
                  class="select-pagination"
                  style=""
                  :clearable="false"
                  v-model="perPage"
                  :options="page_sizes"
                >
                </brew-select>
              </div>
            </div>
            <paginate
              v-model="page"
              style="margin-left: 20px"
              class="flx"
              :page-count="pagination.last_page"
              :page-range="3"
              :margin-pages="2"
              :prev-text="'<'"
              :next-text="'>'"
            >
            </paginate>
          </div>
        </div>
        <div class="bot_table">
          <headline :headers="headers" :selected-all.sync="isSelectAll" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
          <div class="row_list">
            <div
              class="row flx"
              :class="{ checked: selected_products.indexOf(product.id) !== -1}"
              :key="product.id"
              v-for="product in products"
              @click="routeTo(product.id)"
            >
              <label class="custom-checkbox" @click.stop>
                <input v-model="selected_products" type="checkbox" :value="product.id">
                <span></span>
              </label>
              <div class="img_prod">
                <img v-if="product.cache.image" :src="product.cache.image[60]" alt="product">
                <img v-else src="/images/no-img.svg" alt="product">
              </div>
              <div class="name_prod flx" @click="$router.push('/dashboard/dropshipping/products/'+product.id)">
                <span><b>{{ product.name }}</b></span>
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.3333 5.34627C11.1565 5.34627 10.987 5.41651 10.8619 5.54154C10.7369 5.66656 10.6667 5.83613 10.6667 6.01294V10.0129C10.6667 10.1898 10.5964 10.3593 10.4714 10.4843C10.3464 10.6094 10.1768 10.6796 10 10.6796H2C1.82319 10.6796 1.65362 10.6094 1.5286 10.4843C1.40357 10.3593 1.33333 10.1898 1.33333 10.0129V2.01294C1.33333 1.83613 1.40357 1.66656 1.5286 1.54154C1.65362 1.41651 1.82319 1.34627 2 1.34627H6C6.17681 1.34627 6.34638 1.27603 6.47141 1.15101C6.59643 1.02599 6.66667 0.856417 6.66667 0.679606C6.66667 0.502795 6.59643 0.333226 6.47141 0.208202C6.34638 0.0831773 6.17681 0.0129395 6 0.0129395H2C1.46957 0.0129395 0.960859 0.223653 0.585787 0.598726C0.210714 0.973799 0 1.48251 0 2.01294V10.0129C0 10.5434 0.210714 11.0521 0.585787 11.4272C0.960859 11.8022 1.46957 12.0129 2 12.0129H10C10.5304 12.0129 11.0391 11.8022 11.4142 11.4272C11.7893 11.0521 12 10.5434 12 10.0129V6.01294C12 5.83613 11.9298 5.66656 11.8047 5.54154C11.6797 5.41651 11.5101 5.34627 11.3333 5.34627Z"
                    fill="#ADB8C6"/>
                  <path
                    d="M8.66667 1.34627H9.72L5.52667 5.53294C5.46418 5.59491 5.41459 5.66865 5.38074 5.74989C5.34689 5.83113 5.32947 5.91826 5.32947 6.00627C5.32947 6.09428 5.34689 6.18142 5.38074 6.26266C5.41459 6.3439 5.46418 6.41763 5.52667 6.47961C5.58864 6.54209 5.66238 6.59169 5.74362 6.62553C5.82486 6.65938 5.91199 6.67681 6 6.67681C6.08801 6.67681 6.17515 6.65938 6.25639 6.62553C6.33763 6.59169 6.41136 6.54209 6.47333 6.47961L10.6667 2.29294V3.34627C10.6667 3.52308 10.7369 3.69265 10.8619 3.81768C10.987 3.9427 11.1565 4.01294 11.3333 4.01294C11.5101 4.01294 11.6797 3.9427 11.8047 3.81768C11.9298 3.69265 12 3.52308 12 3.34627V0.679606C12 0.502795 11.9298 0.333226 11.8047 0.208202C11.6797 0.0831773 11.5101 0.0129395 11.3333 0.0129395H8.66667C8.48986 0.0129395 8.32029 0.0831773 8.19526 0.208202C8.07024 0.333226 8 0.502795 8 0.679606C8 0.856417 8.07024 1.02599 8.19526 1.15101C8.32029 1.27603 8.48986 1.34627 8.66667 1.34627Z"
                    fill="#ADB8C6"/>
                </svg>
              </div>
              <div class="code_prod">
                <router-link :to="'/dashboard/dropshipping/products/'+product.id">{{ product.vendor_code }}</router-link>
              </div>
              <div class="size_prod">
                <span>{{ product.cache.size_string }}</span>
              </div>
              <div class="color_prod">
                <span v-for="color in product.cache.colors" :key="color">{{ color }}</span>
              </div>
              <div class="category_prod">
                <span v-for="category in product.cache.categories" :key="category">
                  {{ category }}
                </span>
              </div>
              <div class="season_prod">
                <span v-for="season in product.cache.seasons" :key="season">{{ season  }}</span>
              </div>
              <div class="price_prod">
                <div class="item flx">
                  <span>Закупка/шт:</span>
                  <span class="line"> </span>
                  <p>{{ product.cache.purchase_price }}</p>
                </div>
                <div class="item flx">
                  <span>Цена/шт (дроп):</span>
                  <span class="line"> </span>
                  <p>{{ product.cache.retail_price }}</p>
                </div>
                <div class="item flx">
                  <span>Скидка:</span>
                  <span class="line"> </span>
                  <p>0.00 (todo)</p>
                </div>
                <div class="item flx">
                  <span>Сумма:</span>
                  <span class="line"> </span>
                  <p>{{ product.cache.retail_price }}</p>
                </div>
              </div>
              <div class="discount_prod">
                <span>{{ product.discounts_count }}</span>
              </div>
              <div class="available_prod flx">
                <span :class="getColorRemains(product.total_remains)">{{ product.total_remains > 0 ? product.total_remains : '' }}</span>
                <span class="av_wrap">
                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M1.33075 0.0129395C0.59684 0.0129395 0 0.60978 0 1.34369V9.32818C0 10.0621 0.59684 10.6589 1.33075 10.6589H3.71678L5.56749 12.5096C5.90231 12.8002 6.09208 12.8092 6.40925 12.5096L8.25995 10.6589H10.646C11.3799 10.6589 11.9767 10.0621 11.9767 9.32818V1.34369C11.9767 0.60978 11.3799 0.0129395 10.646 0.0129395H1.33075ZM10.646 9.32818H7.70902L5.98837 11.0488L4.26771 9.32818H1.33075V1.34369H10.646V9.32818Z"
                                          fill="#ADB8C6"/>
                                        <path
                                          d="M2.6615 3.33981H9.31524V4.67056H2.6615V3.33981ZM2.6615 6.00131H7.31911V7.33205H2.6615V6.00131Z"
                                          fill="#ADB8C6"/>
                                    </svg>
                                    <div class="menu_drop">
                                        <div class="dropdown">
                                            <p>Наличие на складе</p>
                                            <div class="item flx" v-for="remains in product.cache.remains"
                                                 :key="remains.size">
                                                <span class="grey">{{ remains.size }}</span>
                                                <span class="line"> </span>
                                                <span :class="getColorRemains(remains.remains)">
                                                    {{ remains.remains > 0 ? remains.remains : '' }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </span>
              </div>
              <div class="ico_wrap" @click.stop>
                <router-link :to='"/dashboard/dropshipping/products/edit/"+product.id' class="edit_ico">
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M0 3.07755C0 2.05297 0.830588 1.22238 1.85517 1.22238H5.88881C6.2769 1.22238 6.59151 1.53699 6.59151 1.92508C6.59151 2.31317 6.2769 2.62778 5.88881 2.62778H1.85517C1.60677 2.62778 1.4054 2.82915 1.4054 3.07755V11.1448C1.4054 11.3932 1.60677 11.5946 1.85517 11.5946H9.92245C10.1709 11.5946 10.3722 11.3932 10.3722 11.1448V7.11119C10.3722 6.7231 10.6868 6.40849 11.0749 6.40849C11.463 6.40849 11.7776 6.7231 11.7776 7.11119V11.1448C11.7776 12.1694 10.947 13 9.92245 13H1.85517C0.830588 13 0 12.1694 0 11.1448V3.07755Z"
                          fill="#ADB8C6"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M11.4424 1.55761C11.2394 1.35467 10.9104 1.35467 10.7074 1.55761L5.37082 6.89421L5.12585 7.87412L6.10576 7.62914L11.4424 2.29255C11.6453 2.0896 11.6453 1.76056 11.4424 1.55761ZM9.71365 0.563843C10.4654 -0.187948 11.6843 -0.187948 12.4361 0.563843C13.1879 1.31563 13.1879 2.53453 12.4361 3.28632L6.9619 8.76054C6.87184 8.8506 6.759 8.91449 6.63544 8.94538L4.33051 9.52161C4.09104 9.58148 3.83773 9.51131 3.66319 9.33678C3.48865 9.16224 3.41849 8.90893 3.47835 8.66946L4.05459 6.36452C4.08548 6.24097 4.14937 6.12813 4.23942 6.03807L9.71365 0.563843Z"
                          fill="#ADB8C6"/>
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <product-delete-modal
      :show="show_product_delete"
      :products="selected_products"
    ></product-delete-modal>
  </section>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex'
import helper from '@/helpers/helper'
import _ from 'lodash'
import { eventBus } from '@/main'
import ProductDeleteModal from '@/components/Droppshipping/Products/ProductDeleteModal'
import ProductFilter from '@/components/Droppshipping/Products/ProductFilter'
import Headline from '../../Helpers/Headline'
import ProductActions from './ProductActions'
import router from '../../../router'
let timer

export default {
  name: 'Products',
  components: { ProductDeleteModal, ProductFilter, Headline, ProductActions },
  data () {
    return {
      page_sizes: [10, 50, 100, 1000, 10000],
      selected_products: [],
      show_product_delete: false,
      show_product_filters: false,
      headers: [
        {
          title: '',
          check_all: true,
          class: 'custom-checkbox'
        },
        {
          title: 'Фото',
          sortable: true,
          class: 'img_prod',
          value: 'id'
        },
        {
          title: 'Название',
          class: 'name_prod',
          sortable: true,
          value: 'name'
        },
        {
          title: 'Артикул',
          class: 'code_prod',
          sortable: true,
          value: 'vendor_code'
        },
        {
          title: 'Размеры',
          class: 'size_prod'
        //  TODO sort by sizes
        },
        {
          title: 'Цвет',
          class: 'color_prod'
          //  TODO sort by color
        },
        {
          title: 'Категория',
          class: 'category_prod'
          //  TODO sort by category name
        },
        {
          title: 'Сезон',
          class: 'season_prod'
          //  TODO sort by season name
        },
        {
          title: 'Цена',
          class: 'price_prod'
          //  TODO sort by price
        },
        {
          title: 'Скидки продавцам',
          class: 'discount_prod'
          //  TODO sort by discount
        },
        {
          title: 'Наличие',
          class: 'available_prod'
          //  TODO sort by remains
        },
        {
          title: '',
          class: 'ico_wrap'
        }
      ]
    }
  },
  computed: {
    ...mapState('product', [
      'products',
      'pagination',
      'filters',
      'is_loading'
    ]),
    isSelectAll: {
      get: function () {
        if (this.products === null) {
          return false
        }
        return this.selected_products.length === this.products.length && this.selected_products.length > 0
      },
      set: function (value) {
        if (value) {
          this.selected_products = _.map(this.products, 'id')
        } else {
          this.selected_products = []
        }
      }
    },
    page: {
      get: function () {
        return this.filters.page
      },
      set: function (value) {
        this.setPage(value)
        this.getProducts()
      }
    },
    perPage: {
      get: function () {
        return this.filters.per_page
      },
      set: function (value) {
        this.setPerPage(value)
        this.setPage(1)
        this.getProducts()
        this.selected_products = []
      }
    },
    search: {
      get: function () {
        return this.filters.search
      },
      set: function (value) {
        clearTimeout(timer)
        this.setSearch(value)
        timer = setTimeout(() => {
          this.setPage(1)
          this.getProducts()
        }, 500)
      }
    },
    sortBy: {
      get () {
        return this.filters.order_by
      },
      set (val) {
        this.setOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.filters.order_direction
      },
      set (val) {
        this.setOrderDirection(val)
      }
    }
  },
  watch: {
    sortBy () {
      this.getProducts()
    },
    sortDesc () {
      this.getProducts()
    }
  },
  methods: {
    ...mapActions('product', ['getProducts']),
    ...mapMutations('product', [
      'setPage',
      'setPerPage',
      'setSearch',
      'setCategoryId',
      'setOrderBy',
      'setOrderDirection'
    ]),
    getColorRemains (value) {
      return helper.getColorRemains(value)
    },
    showProductsDelete () {
      if (this.selected_products.length === 0) {
        eventBus.$emit('send_notify', 'warn',
          'Выберите товары',
          'Товары для удаления не выбраны'
        )
        return
      }

      this.show_product_delete = true
    },
    routeTo (id) {
      router.push({ name: 'ShowProduct', params: { id: id } })
    }
  },
  created () {
    this.setCategoryId(null)
    this.setPage(1)
    this.getProducts()
    this.$root.$on('show_product_delete', (data) => {
      this.show_product_delete = data
      this.selected_products = []
    })
    this.$root.$on('show_product_filters', (data) => {
      this.show_product_filters = data
    })
  }
}
</script>
