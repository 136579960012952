import axios from 'axios'
import { eventBus } from '@/main'

const baseUrl = 'api/products/'
const redirectTo = 'Products'

export default {
  namespaced: true,
  state: {
    editableRemains: {
      product_color_size_id: null,
      remains: null
    },
    is_loading: false,
    products: [],
    product: {
      category_ids: [],
      season_ids: [],
      name: null,
      short_name: null,
      vendor_code: null,
      weight: 0,
      package: 0,
      categories: [],
      seasons: [],
      properties: {
        image: null,
        images: [],
        sizes: null,
        colors: []
      },
      remains: [],
      images: [],
      total_remains: 35,
      descriptions: {
        description: '',
        description_ua: '',
        instagram: '',
        instagram_ua: ''
      }
    },
    pagination: {
      last_page: 1
    },
    filters: {
      category_id: null,
      search: null,
      page: 1,
      per_page: localStorage.getItem('product-per-page') || 10,
      order_by: localStorage.getItem('products-order-by') || 'id',
      order_direction: localStorage.getItem('products-order-direction') || 'desc'
    },
    export_in_process: false
  },
  mutations: {
    setEditableRemains (state, payload) {
      state.editableRemains = payload
    },
    setIsLoading (state, payload) {
      state.is_loading = payload
    },
    setProducts (state, data) {
      state.products = data
    },
    setProduct (state, data) {
      state.product = data
    },
    setPagination (state, data) {
      state.pagination = data
    },
    setPage (state, num) {
      state.filters.page = num
    },
    setPerPage (state, num) {
      state.filters.per_page = num
      localStorage.setItem('product-per-page', num)
    },
    setOrderBy (state, payload) {
      state.filters.order_by = payload
      localStorage.setItem('products-order-by', payload)
    },
    setOrderDirection (state, payload) {
      state.filters.order_direction = payload
      localStorage.setItem('products-order-direction', payload)
    },
    setSearch (state, searchString) {
      state.filters.search = searchString
    },
    setCategoryId (state, id) {
      state.filters.category_id = id
    },
    setFilters (state, filters) {
      // state.filters = {}
      state.filters.page = 1
      state.filters.per_page = localStorage.getItem('product-per-page') || 10
      state.filters.name = filters.name
      state.filters.search = filters.search
      state.filters.vendor_code = filters.vendor_code
      state.filters.category_id = filters.category_id
      state.filters.season_id = filters.season_id
      state.filters.price_from = filters.price_from
      state.filters.price_to = filters.price_to
      state.filters.remains_code = filters.remains_code
      state.filters.order_by = filters.order_by
      state.filters.order_direction = filters.order_direction
    },
    clearFilters (state) {
      // state.filters = {}
      state.filters.page = 1
      state.filters.per_page = localStorage.getItem('product-per-page') || 10
      state.filters.name = null
      state.filters.vendor_code = null
      state.filters.category_id = null
      state.filters.season_id = null
      state.filters.price_from = null
      state.filters.price_to = null
      state.filters.remains_code = null
      state.filters.order_by = localStorage.getItem('products-order-by') || 'id'
      state.filters.order_direction = localStorage.getItem('products-order-direction') || 'asc'
    },
    setExportInProcess (state, payload) {
      state.export_in_process = payload
    }
  },
  actions: {
    getProducts ({ state, commit }) {
      // state.is_loading = true
      axios.get(baseUrl, {
        params: state.filters
      }).then(response => {
        commit('setProducts', response.data.data)
        commit('setPagination', response.data.meta)
        state.is_loading = false
      }).catch(err => {
        console.log(err)
        state.is_loading = false
      })
    },
    async getProduct ({ state, commit, dispatch }, id) {
      await axios.get(baseUrl + id).then(response => {
        commit('setProduct', response.data.data)
        commit('discount/setModelType', 'product', { root: true })
        dispatch('discount/getDiscountList', response.data.data.id, { root: true })
        state.is_loading = false
      }).catch(err => {
        console.log(err)
        state.is_loading = false
      })
    },
    createProduct ({ state, commit }, data) {
      if (state.is_loading) {
        return
      }

      commit('setIsLoading', true)
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.post(baseUrl, data).then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Товар создан',
            'Создан: ' + response.data.data.name
          )
          commit('setIsLoading', false)
          resolve(redirectTo)
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Товар не создан!',
            'Проверьте данные'
          )
          commit('setIsLoading', false)
          reject(err)
        })
      })
    },
    move ({ state, dispatch }, data) {
      axios.put(baseUrl + 'move', {
        products: data.products,
        category_id: data.category_id
      }).then(response => {
        dispatch('getProducts')
      })
    },
    updateProduct ({ state, commit }, data) {
      if (state.is_loading) {
        return
      }

      commit('setIsLoading', true)
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.put(baseUrl + data.id, data).then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Товар успешно обновлен',
            'Обновлено ' + response.data.data.name
          )
          commit('setIsLoading', false)
          resolve({ success: true })
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Товар не обновлен!',
            'Проверьте данные'
          )
          commit('setIsLoading', false)
          reject(err)
        })
      })
    },
    deleteProduct (action, id) {
      return new Promise((resolve, reject) => {
        action.commit('errors/clearErrors', null, { root: true })
        axios.delete(baseUrl + id).then(response => {
          console.log(response.data)
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Товар успешно удален'
          )
          resolve(redirectTo)
        }).catch(err => {
          reject(err)
        })
      })
    },
    deleteProducts (action, products) {
      action.commit('errors/clearErrors', null, { root: true })
      axios.delete(baseUrl, {
        data: { ids: products }
      }).then(response => {
        console.log(response.data)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Выбранные товары удалены'
        )
        action.dispatch('getProducts')
      }).catch(err => {
        console.log(err)
      })
    },
    updateField ({ state, dispatch }, data) {
      axios.put(baseUrl + 'update-field', data)
        .then(resource => {
          eventBus.$root.$emit(
            'order_item:updated'
          )
        })
    },
    updateProductInOrder ({ state, dispatch }) {
      axios.put(baseUrl + state.editableRemains.product_color_size_id + '/remains', state.editableRemains)
        .then(resource => {
          console.log(resource)
          dispatch('getProduct', state.product.id)
        })
    },
    exportProducts ({ commit }) {
      commit('setExportInProcess', true)
      axios.get(baseUrl + 'export')
        .then(response => {
          const link = document.createElement('a')
          console.log(response.data)
          link.href = response.data
          // link.setAttribute('href', '"data:text/csv;charset=utf-16,' + encodeURI(response.data))
          // link.setAttribute('download', 'Products-' + getFormattedTime() + '.csv')
          document.body.appendChild(link)
          link.click()
        })
        .finally(() => {
          commit('setExportInProcess', false)
        })
    },
    downloadImages ({ commit }, id) {
      axios.post(baseUrl + 'images/' + id, {}, {
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'images-' + id + '.zip')
          document.body.appendChild(link)
          link.click()
        })
        .finally(() => {
        })
    },
    getHistory ({ state, commit }) {
      axios.get('api/product-histories', {
        params: { product_id: state.product.id }
      })
        .then(response => {
          commit('productHistory/setHistory', response.data.data, { root: true })
        })
    },
    uploadSizeImages ({ commit }, data) {
      return axios.post(baseUrl + 'upload-size-images/' + data.id, data.formData)
    },
    deleteSizeImage ({ commit }, data) {
      return axios.delete(baseUrl + 'delete-size-images/' + data.id, { data: data })
    }
  }
}
// // TODO
// function getFormattedTime () {
//   const today = new Date()
//   const y = today.getFullYear()
//   // JavaScript months are 0-based.
//   const m = today.getMonth() + 1
//   const d = today.getDate()
//   const h = today.getHours()
//   const mi = today.getMinutes()
//   const s = today.getSeconds()
//   return y + '-' + m + '-' + d + '-' + h + '-' + mi + '-' + s
// }
