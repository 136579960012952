<template>
  <div class="table_body adds_product_table">
    <div class="top_table flx">
      <span class="page_subtitle">Добавленые товары</span>
      <div class="info_sum flx">
        <p>Выплата продавцу: <span class="summary" :class="{green: get_drop_pay() > 0, red: get_drop_pay() <= 0}">{{
            get_drop_pay() | asPrice
          }}</span></p>
        <p>Сумма заказа (дроп): <span class="summary grey">{{ get_total_drop() | asPrice }}</span></p>
      </div>
    </div>
    <div class="wrap_tbl">
      <div class="headline_table flx">
        <div class="img_prod">
          <p>Фото</p>
        </div>
        <div class="name_prod">
          <p>Название</p>
        </div>
        <div class="category_prod">
          <p>Категория</p>
        </div>
        <div class="characteristic_prod">
          <p>Характеристики</p>
        </div>
        <div class="price_prod">
          <p>Цена</p>
        </div>
        <div class="discount_prod">
          <p>Скидка</p>
        </div>
        <div class="quantity_prod">
          <p>Кол-во</p>
        </div>
        <div class="sum_drop_prod">
          <p>Сумма (дроп) со скидкой</p>
        </div>
        <div class="available_prod">
          <p>Наличие</p>
        </div>
        <div class="cross"></div>
      </div>
      <div class="row_list">
        <div class="row flx" v-for="item in order_products" :key="'order_product-'+item.product_color_size_id">
          <div class="img_prod">
            <img v-if="item.image_url" :src="item.image_url" :alt="item.name" :title="item.name">
            <img v-else src="/images/no-img.svg">
          </div>
          <div class="name_prod">
            <p><b>{{ item.name }}</b></p>
          </div>
          <div class="category_prod">
            <p class="grey"><b>{{ item.category_name }}</b></p>
          </div>
          <div class="characteristic_prod">
            <div class="item flx">
              <span>Артикул:</span>
              <span class="line"> </span>
              <p>{{ item.vendor_code }}</p>
            </div>
            <div class="item flx">
              <span>Размер:</span>
              <span class="line"> </span>
              <p>{{ item.size }}</p>
              <!--              <field-editor></field-editor>-->
            </div>
            <div class="item flx">
              <span>Цвет:</span>
              <span class="line"> </span>
              <p>{{ item.color }}</p>
              <!--              <field-editor></field-editor>-->
            </div>
            <div class="item flx">
              <span>Вес:</span>
              <span class="line"> </span>
              <p class="clickable-icon-item">{{ item.weight }}
                <field-editor
                    class="clickable-icon"
                    :id="item.id"
                    field="weight"
                    :value="item.weight"
                ></field-editor>
              </p>
            </div>
          </div>
          <div class="price_prod">
            <div class="item flx">
              <span>Закупка/шт:</span>
              <span class="line"> </span>
              <p class="clickable-icon-item">{{ item.purchase_price | asPrice }}
                <field-editor
                    class="clickable-icon"
                    :id="item.id"
                    field="purchase_price"
                    :value="item.purchase_price"
                ></field-editor>
              </p>
            </div>
            <div class="item flx">
              <span>Цена/шт (дроп):</span>
              <span class="line"> </span>
              <p class="clickable-icon-item">{{ item.retail_price | asPrice }}
                <field-editor
                    v-if="!hasReturn(item.id)"
                    class="clickable-icon"
                    :id="item.id"
                    field="retail_price"
                    :value="item.retail_price"
                ></field-editor>
              </p>
            </div>
            <div class="item flx">
              <span>Сумма (дроп):</span>
              <span class="line"> </span>
              <p>{{ item.retail_price * item.qty | asPrice }}
                <span class="clickable-icon-mr"></span>
              </p>
            </div>
            <div class="item flx">
              <div v-if="!hasReturn(item.id) && order.status === 3" class="return-btn" @click.self="openReturnModal(item)">Создать возврат
              </div>
            </div>
          </div>
          <div class="discount_prod clickable-icon-item">
            <span v-if="item.discount">{{ item.discount.value }} {{ item.discount.type }}</span>
            <span v-else>0%</span>
            <field-discount-editor
                v-if="!hasReturn(item.id)"
                class="clickable-icon"
                :id="item.id"
                field="discount"
                :value="item.discount ? item.discount.value : 0"
                :discount_type="item.discount ? item.discount.type : '%'"
            ></field-discount-editor>
          </div>
          <div class="quantity_prod">
            <input type="number"
                   :value="item.qty"
                   min="1"
                   :max="item.remains"
                   @input="setOrderProductQty($event, item)"
                   :disabled="disabled_field || hasReturn(item.id)"
                   :class="{ disabled: disabled_field || hasReturn(item.id) }">
          </div>
          <div class="sum_drop_prod">
            <p v-if="item.discount">{{ item.retail_price * item.qty - item.discount.total * item.qty | asPrice }}</p>
            <p v-else>{{ item.retail_price * item.qty | asPrice }}</p>
          </div>
          <div class="available_prod">
            <span :class="getColorRemains(item.remains)">{{ item.remains ? item.remains : '' }}</span>
          </div>
          <div class="cross close" @click="deleteOrderProduct(item)" v-if="!disabled_field && !hasReturn(item.id)"></div>
        </div>
      </div>
    </div>
    <return-modal :order-item="selectedProduct"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { prices } from '../../../../helpers/prices'
import { format } from '../../../../helpers/format'
import helper from '../../../../helpers/helper'
import FieldEditor from '../Products/FieldEditor'
import FieldDiscountEditor from '../Products/FieldDiscountEditor'
import ReturnModal from './ReturnModal'
import _ from 'lodash'

export default {
  name: 'OrderProducts',
  directives: {
    format
  },
  data () {
    return {
      selectedProduct: null
    }
  },
  components: {
    FieldEditor,
    FieldDiscountEditor,
    ReturnModal
  },
  mixins: [
    prices
  ],
  computed: {
    ...mapState('orders', ['order']),
    ...mapGetters('returnItems', ['hasReturn']),
    ...mapGetters('orders', ['get_total_drop', 'get_drop_pay']),
    order_products: {
      get () {
        return this.order.products
      },
      set (val) {
        this.setOrderProductsItems(val)
      }
    },
    disabled_field () {
      return false
      // return this.order.ttn !== null || this.order.delivery_status > 1 || this.order.status > 1
    }
  },
  methods: {
    ...mapActions('orders', ['deleteOrderItem', 'setOrderProductsItems']),
    ...mapMutations('orders', ['setOrderProducts', 'setProductInOrder', 'setReturnModal']),
    deleteOrderProduct (product) {
      if (!this.order.id) {
        this.order_products = _.filter(this.order_products, p => {
          return p.product_color_size_id !== product.product_color_size_id
        })
      } else {
        this.deleteOrderItem(product.id)
        this.setProductInOrder()
      }
    },
    setOrderProductQty (e, product) {
      product.qty = e.target.valueAsNumber
      const index = this.order_products.findIndex(p => p.product_color_size_id === product.product_color_size_id)
      this.$set(this.order_products, index, product)
    },
    getColorRemains (val) {
      return helper.getColorRemains(val)
    },
    openReturnModal (item) {
      this.selectedProduct = item
      this.setReturnModal(true)
    }
  }
}
</script>

<style lang="scss">
.return-btn {
  padding-top: 5px;
  font-size: 12px;
  color: #ff8787;

  &:hover {
    color: orangered;
  }
}
</style>
