<template>
  <section class="table_box payments_general to_privatbank">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Заказы</span>
      </div>
      <div class="wrap_btn flx">
        <div class="btn actions_btn empty"  data-id="filters_box" :class="{active: show_order_filters}" @click="toggleOrderFilters">
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3333 1H1L6.33333 7.30667V11.6667L9 13V7.30667L14.3333 1Z" stroke="#F5A32A" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Фильтры</span>
          <span class="counter_filters" v-if="count_pb_order_filters > 0">{{ count_pb_order_filters }}</span>
        </div>
      </div>
      <calc-order-filters></calc-order-filters>
    </div>
    <div class="container has_table">
      <div class="table_body">
        <div class="top_table flx">
          <div class="search_box flx">
            <p>Продавец:</p>
            <brew-select
                name="dropshipper"
                class="vue-select select-search select-search-sm"
                v-model.number="filters.dropshipper_id"
                :options="dropshippers"
                :reduce="item => item.id"
                @option:selected="getOrders"
                @option:cleared="getOrders"
                label="name"
                placeholder="Все"
            >
              <template #selected-option="{ name, id }">
                <div>
                  [<a @click="$router.push({ name: 'EditDropshipper', params: { id: id } })">{{id}}</a>] - {{name | abbreviated}}
                </div>
              </template>
            </brew-select>
          </div>
          <div class="pagination flx">
            <div class="flx">
              <p class="desctop_v">Строк:</p>
              <div class="select" id="sellers">
                <brew-select
                    class="select-pagination select-orders-per_page select-full"
                    style="margin-left:10px;width: 100px"
                    v-model="perPage"
                    :clearable="false"
                    :options="page_sizes"
                >
                </brew-select>
              </div>
            </div>
            <paginate
                style="margin-left: 20px"
                class="flx"
                v-model="page"
                :page-count="pagination.last_page"
                :page-range="3"
                :margin-pages="2"
                :prev-text="'<'"
                :next-text="'>'"
            >
            </paginate>
          </div>
        </div>
        <div class="bot_table">
          <headline :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
          <div class="row_list">
            <div class="row flx" :class="getOrderClassByStatus(order)" :rel="order.id" v-for="order in orders" :key="'order-'+order.id">
              <div class="id_ttn" @click="$router.push({ name: 'Order', params: { id: order.id } })">
                <a>{{ order.id }}</a>
                <span v-if="order.ttn"><b>{{ order.ttn | asTTN }}</b></span>
              </div>
              <div class="client_info">
                <span>[<router-link class="edit_ico" :to="{name: 'ShowClient', params: {id: order.client.id}}">{{ order.client.id }}</router-link>]</span>
                <span>{{ order.client.last_name }} {{ order.client.first_name | firstLetter }} {{ order.client.middle_name | firstLetter }}</span>
              </div>
              <div class="date_change_info">
<!--                TODO updated history dates -->
                <span>08.05.2021</span>
                <span class="grey">09.05.2021</span>
              </div>
              <div class="status_info">
                <span class="status" :class="getOrderStatusColor(order)">{{ order.status_name }}</span>
              </div>
              <div class="drop_price_info">
                <span>{{ order.total_drop | asPrice }}</span>
              </div>
              <div class="delivery_status_info">
                <span class="status" :class="getOrderDeliveryColor(order)">{{ order.delivery_status_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { dates } from '../../../helpers/dates'
import { phones } from '../../../helpers/phones'
import { strings } from '../../../helpers/strings'
import { prices } from '../../../helpers/prices'
import { numbers } from '../../../helpers/numbers'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import helper from '../../../helpers/helper'
import CalcOrderFilters from './CalcOrderFilters'
import Headline from '../../Helpers/Headline'

export default {
  name: 'CalcOrders',
  mixins: [
    dates,
    phones,
    strings,
    prices,
    numbers
  ],
  components: {
    CalcOrderFilters, Headline
  },
  data () {
    return {
      page_sizes: [1, 3, 5, 100],
      timer: null,
      show_orders_actions: false,
      headers: [
        {
          title: 'ID',
          title_class: 'title',
          sortable: true,
          class: 'id_ttn',
          value: 'id'
        },
        {
          title: 'Клиент',
          class: 'client_info'
        },
        {
          title: 'ДАТА・Изм',
          class: 'date_change_info'
        },
        {
          title: 'СТАТУС',
          class: 'status_info'
        },
        {
          title: 'Дроп',
          class: 'drop_price_info'
        },
        {
          title: 'Состояние доставки',
          class: 'delivery_status_info'
        }
      ]
    }
  },
  computed: {
    ...mapState('orders', ['orders', 'pagination', 'show_order_filters', 'filters']),
    ...mapState('users', ['dropshippers']),
    ...mapGetters('orders', ['count_pb_order_filters']),
    ...mapGetters('users', ['getDefaultDropshipperFilters']),
    order_filters: {
      get () {
        return this.filters
      },
      set (val) {
        this.setFilters(val)
      }
    },
    page: {
      get () {
        return this.filters.page
      },
      set (value) {
        this.setPage(value)
        this.getOrders()
      }
    },
    perPage: {
      get () {
        return this.filters.per_page
      },
      set (perPage) {
        this.setPerPage(perPage)
        this.setPage(1)
        this.getOrders()
      }
    },
    search: {
      get () {
        return this.filters.search
      },
      set (value) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.setPage(1)
          this.setSearch(value)
          this.getOrders()
        }, 1000)
      }
    },
    sortBy: {
      get () {
        return this.order_filters.order_by
      },
      set (val) {
        this.setOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.order_filters.order_direction
      },
      set (val) {
        this.setOrderDirection(val)
      }
    }
  },
  watch: {
    sortBy () {
      this.getOrders()
    },
    sortDesc () {
      this.getOrders()
    }
  },
  methods: {
    ...mapActions('orders', ['getOrders', 'toggleOrderFilters']),
    ...mapMutations('orders', ['setPage', 'setPerPage', 'setPagination', 'setSearch', 'setFilters', 'clearFilters', 'setOrderBy', 'setOrderDirection']),
    ...mapActions('users', ['getDropshippers']),
    ...mapMutations('users', ['setDropshipperFilters']),
    orderClass (order) {
      return helper.getOrderClassByStatus(order.status) + ' ' + helper.getOrderClassByDeliveryStatus(order.delivery_status)
    },
    getOrderStatusColor (order) {
      return helper.getOrderStatusColor(order.status)
    },
    getOrderDeliveryColor (order) {
      return helper.getOrderDeliveryColor(order.delivery_status)
    },
    getOrderClassByStatus (order) {
      return helper.getOrderClassByStatus(order.status)
    }
  },
  created () {
    this.$set(this.order_filters, 'pay_type', 2)
    const dropshipperFilters = this.getDefaultDropshipperFilters
    dropshipperFilters.per_page = 99999
    this.setDropshipperFilters(dropshipperFilters)
    this.$nextTick(() => {
      this.getOrders()
      this.getDropshippers()
    })
  }
}
</script>

<style scoped>

</style>
