<template>
  <div class="role_actions_form box_shadow">
    <div class="top">
      <span class="page_subtitle">Права доступа для роли</span>
    </div>
    <div class="row_list">
      <div class="row" v-for="(rule, index) in role_rules" :key="'role-rule-'+index">
        <span>{{ getRuleName(index) }}</span>
        <div class="role flx">
          <label class="custom-checkbox">
            <input type="checkbox"
                   :disabled="!canEdit"
                   :checked="edit_role.rules[index].create &&
                    edit_role.rules[index].update &&
                    edit_role.rules[index].view &&
                    edit_role.rules[index].delete" @change="toggleAllRules($event, index)">
            <span></span>
            <span>Все</span>
          </label>
        </div>
        <div class="role flx">
          <label class="custom-checkbox">
            <input type="checkbox" :checked="edit_role.rules[index].create" @change="toggleRule($event, index, 'create')" :disabled="!canEdit">
            <span></span>
            <span>Создание</span>
          </label>
          <label class="custom-checkbox">
            <input type="checkbox" :checked="edit_role.rules[index].view" @change="toggleRule($event, index, 'view')" :disabled="!canEdit">
            <span></span>
            <span>Просмотр</span>
          </label>
          <label class="custom-checkbox">
            <input type="checkbox" :checked="edit_role.rules[index].update" @change="toggleRule($event, index, 'update')" :disabled="!canEdit">
            <span></span>
            <span>Редактирование</span>
          </label>
          <label class="custom-checkbox">
            <input type="checkbox" :checked="edit_role.rules[index].delete" @change="toggleRule($event, index, 'delete')" :disabled="!canEdit">
            <span></span>
            <span>Удаление</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import helper from '../../../helpers/helper'

export default {
  name: 'RoleRules',
  props: {
    canEdit: Boolean
  },
  computed: {
    ...mapState('roles', ['default_rules', 'edit_role']),
    role_rules: {
      get () {
        return this.default_rules
      }
    }
  },
  methods: {
    ...mapMutations('roles', ['toggleSelectAllRules', 'setRoleModelRules']),
    getRuleName (key) {
      return helper.getRuleName(key)
    },
    toggleAllRules (e, model) {
      this.toggleSelectAllRules([model, e.target.checked])
    },
    toggleRule (e, model, type) {
      this.setRoleModelRules([model, type, e.target.checked])
    }
  }
}
</script>

<style scoped>

</style>
