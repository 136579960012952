import axios from 'axios'
import { eventBus } from '@/main'
import router from '../../router'

const baseUrl = 'api/orders/'
const itemsUrl = 'api/order-items/'
const productListUrl = 'api/products/list/'
// const redirectTo = 'Orders'

export default {
  namespaced: true,
  state: {
    is_loading: false,
    /**
     * @type {import('../../types/order_types').Order[]}
     */
    orders: [],
    /**
     * @type {import('../../types/order_types').Order}
     */
    order: {
      products: [],
      delivery_data: {
        dimensions: [{
          length: 0,
          width: 0,
          height: 0
        }]
      }
    },
    /** @type {import('../../types/pagination_type').Pagination} */
    pagination: {
      last_page: 1
    },
    /** @type {import('../../types/order_types').OrderFilters} */
    filters: {
      search: null,
      page: 1,
      per_page: +(localStorage.getItem('orders-per-page')) || 10,
      order_by: localStorage.getItem('orders-order-by') || 'id',
      order_direction: localStorage.getItem('orders-order-direction') || 'desc',
      order_id: null,
      pay_type: null,
      pay_status: null,
      dropshipper_id: null,
      sender_id: null,
      client_id: null,
      client_last_name: null,
      client_phone: null,
      dropshipper_phone: null,
      status: null,
      delivery_status: null,
      pay_person: null,
      total_drop: null,
      total_final: null,
      drop_profit: null,
      category_id: null,
      change_from: null,
      change_to: null,
      change_id: 1,
      updated_at: null,
      city_id: null,
      dropshipper_visited_at_from: null,
      dropshipper_visited_at_to: null,
      drop_profit_from: null,
      drop_profit_to: null,
      has_invoices: null
    },
    /** @type {boolean} */
    show_order_filters: false,
    /** @type {boolean} */
    show_order_delete_modal: false,
    /** @type {Array<number> | null} */
    selected_orders: [],
    /** @type {boolean} */
    show_order_products_modal: false,
    /** @type {boolean} */
    show_order_actions: false,
    show_return_modal: false,
    product_list: [],
    product_list_pagination: {
      last_page: 1
    },
    product_list_filters: {
      search: null,
      user_id: null,
      category_id: null,
      vendor_code: null,
      name: null,
      season_id: null,
      price: null,
      price_from: null,
      price_to: null,
      per_page: 50,
      page: 1,
      order_by: 'id',
      order_direction: 'asc'
    },
    /** @type {import('../../types/order_types').Order | null} */
    order_for_modal: null,
    /** @type {boolean} */
    show_delete_ttn_modal: false,
    period_id: 1,
    show_change_orders_status: false,
    order_on_delete: null,
    order_counters: {
      returned: 0,
      completed: 0
    },
    total_sum: 0,
    completed_sum: 0
  },
  getters: {
    get_comment_text: state => () => {
      let text = ''
      if (state.order.products && state.order.products.length > 0) {
        text = state.order.products.reduce((text, product) => {
          if (text !== '') {
            text += ' + '
          }

          const size = product.size !== 'One size' ? '·' + product.size : null
          const qty = product.qty > 1 ? ' ' + product.qty + 'шт' : null

          text += product.short_name
          if (size) {
            text += size
          }
          if (qty) {
            text += qty
          }

          return text
        }, '')
      }
      return text
    },
    count_order_filters: state => Object.values(state.filters).filter(f => f).length - 5,
    count_pb_order_filters: state => Object.values(state.filters).filter(f => f).length - 6,
    get_total_drop: (state) => () => {
      let dropTotal = 0
      if (state.order.is_custom_drop_price) {
        return state.order.custom_drop_price
      }

      if (state.order.products && state.order.products.length > 0) {
        dropTotal = state.order.products.reduce((total, product) => {
          if (product.discount) {
            return total + parseFloat(product.retail_price) * parseInt(product.qty) - product.discount.total * parseInt(product.qty)
          } else {
            return total + parseFloat(product.retail_price) * parseInt(product.qty)
          }
        }, 0)
      }
      if (state.order.discount) {
        if (state.order.discount.type === 'грн') {
          dropTotal = dropTotal - parseFloat(state.order.discount.value)
        } else {
          dropTotal = dropTotal - parseFloat(state.order.discount.value) / 100 * dropTotal
        }
      }
      return dropTotal
    },
    get_drop_pay: (state, getters) => () => {
      if (state.order.total_final === 0) {
        // return 0
      }
      if (state.order.products && state.order.products.length > 0) {
        const totalDrop = getters.get_total_drop()
        return state.order.total_final - totalDrop
      }
      return 0
    },
    get_total_weight: (state) => () => {
      return state.order.products.reduce((total, product) => {
        return total + parseFloat(product.weight) * parseInt(product.qty)
      }, 0)
    },
    get_volume_weight: (state) => () => {
      let volumeWeight = 0
      if (state.order?.delivery_data?.package_id === 2) {
        if (state.order?.delivery_data?.dimensions !== undefined) {
          for (let i = 0; i < state.order.delivery_data.dimensions.length; i++) {
            volumeWeight += (state.order.delivery_data.dimensions[i].length * state.order.delivery_data.dimensions[i].height * state.order.delivery_data.dimensions[i].width) / 4000
          }
        }
      } else if (state.order?.delivery_data?.package_id === 1) {
        volumeWeight = state.order.delivery_data.volume * 250
      }
      return volumeWeight > 0 ? volumeWeight : 0.5
    }
  },
  mutations: {
    setReturnModal (state, value) {
      state.show_return_modal = value
    },
    /**
     * mutation set orders data
     * @param [state]
     * @param  {Array<import('../../types/order_types').Order>} data
     */
    setOrders (state, data) {
      state.orders = data
    },
    /**
     * mutation set order pagination data
     * @param [state]
     * @param {import('../../types/pagination_type').Pagination} data
     */
    setPagination (state, data) {
      state.pagination = data
    },
    /**
     * set order page
     * @param [state]
     * @param {number | null} num
     */
    setPage (state, num) {
      state.filters.page = num
    },
    /**
     * set orders per page
     * @param [state]
     * @param {number} num
     */
    setPerPage (state, num) {
      state.filters.per_page = num
      localStorage.setItem('orders-per-page', num)
    },
    setOrderBy (state, payload) {
      state.filters.order_by = payload
      localStorage.setItem('orders-order-by', payload)
    },
    setOrderDirection (state, payload) {
      state.filters.order_direction = payload
      localStorage.setItem('orders-order-direction', payload)
    },
    /**
     * set order search string
     * @param [state]
     * @param {string | null} searchString
     */
    setSearch (state, searchString) {
      state.filters.search = searchString
    },
    /**
     * set order data
     * @param [state]
     * @param {import('../../types/order_types').Order} order
     */
    setOrder (state, order) {
      state.order = order

      if (state.order.client_data.address === undefined) {
        state.order.client_data.address = {
          street: null,
          house: null,
          appt: null
        }
      }

      if (order?.delivery_data?.dimensions && order?.delivery_data?.dimensions[0]?.length > 0) {
        state.package_id = 2
      } else {
        state.package_id = 1
      }
    },
    /**
     * clear order data
     * @param [state]
     */
    clearOrder (state) {
      state.order = {
        id: null,
        dropshipper_id: null,
        sender_id: null,
        client_data: {
          id: null,
          phone: null,
          city: {
            id: null,
            name: null
          },
          city_id: null,
          branch_id: null,
          branch: {
            id: null,
            name: null
          },
          last_name: null,
          first_name: null,
          delivery_id: 1
        },
        delivery_data: {
          delivery_id: 1,
          delivery_pay_person: 1,
          weight: 0,
          volume: 0.001,
          seats: 1,
          package_id: 1,
          delivery_cost: 0,
          is_custom_weight: false,
          dimensions: [{
            length: 0,
            width: 0,
            height: 0
          }]
        },
        discount: {
          type: '%',
          value: 0
        },
        status: 1,
        status_name: 'Новый',
        delivery_status: null,
        pay_type: 1,
        pay_person: 1,
        total_drop: 0.00,
        total_final: 0.00,
        drop_profit: 0.00,
        ttn: null,
        comment: '',
        products: [],
        pay_from_balance: true
      }
    },
    /**
     * set order filters
     * @param [state]
     * @param {import('../../types/order_types').OrderFilters} filters
     */
    setFilters (state, filters) {
      state.filters = filters
    },
    /**
     * clear order filters
     * @param [state]
     */
    clearFilters (state) {
      state.filters = {
        search: null,
        page: 1,
        per_page: +(localStorage.getItem('orders-per-page')) || 10,
        order_by: localStorage.getItem('orders-order-by') || 'id',
        order_direction: localStorage.getItem('orders-order-direction') || 'desc',
        order_id: null,
        pay_type: null,
        pay_status: null,
        dropshipper_id: null,
        sender_id: null,
        client_id: null,
        client_last_name: null,
        client_phone: null,
        dropshipper_phone: null,
        status: null,
        delivery_status: null,
        pay_person: null,
        total_drop: null,
        total_final: null,
        drop_profit: null,
        category_id: null,
        change_from: null,
        change_to: null,
        change_id: 1,
        updated_at: null,
        city_id: null,
        dropshipper_visited_at_from: null,
        dropshipper_visited_at_to: null,
        drop_profit_from: null,
        drop_profit_to: null,
        calculate_type_id: null,
        has_invoices: null
      }
    },
    /**
     * show order filters
     * @param [state]
     * @param {boolean} isTrue
     */
    setShowOrderFilters (state, isTrue) {
      state.show_order_filters = isTrue
    },
    /**
     * show order delete modal
     * @param [state]
     * @param {boolean} isTrue
     */
    setShowOrderDeleteModal (state, isTrue) {
      state.show_order_delete_modal = isTrue
    },
    /**
     * set selected orders ids
     * @param [state]
     * @param {Array<number> | null} ids
     */
    setSelectedOrders (state, ids) {
      state.selected_orders = ids
    },
    /**
     * show order products in order list
     * @param [state]
     * @param {boolean} isTrue
     * @param {import('../../types/order_types').Order} order
     */
    setShowOrderProductsModal (state, [isTrue, order]) {
      state.order_for_modal = order
      state.show_order_products_modal = isTrue
    },
    /**
     * show order actions
     * @param [state]
     * @param {boolean} isTrue
     */
    setShowOrderActions (state, isTrue) {
      state.show_order_actions = isTrue
    },
    setProductList (state, products) {
      state.product_list = products.map(obj => ({ ...obj, in_order: state.order.products && state.order.products.length > 0 && state.order.products.some(e => e.product_color_size_id === obj.product_color_size_id) }))
    },
    setProductListPagination (state, data) {
      state.product_list_pagination = data
    },
    setProductListFilters (state, filters) {
      state.product_list_filters = filters
    },
    clearProductListFilters (state) {
      state.product_list_filters = {
        search: null,
        category_id: null,
        vendor_code: null,
        name: null,
        season_id: null,
        price: null,
        price_from: null,
        price_to: null,
        per_page: 50,
        page: 1,
        order_by: 'id',
        order_direction: 'asc'
      }
    },
    setProductListPage (state, num) {
      state.product_list_filters.page = num
    },
    setProductListOrderby (state, payload) {
      state.product_list_filters.order_by = payload
    },
    setProductListOrderDirection (state, payload) {
      state.product_list_filters.order_direction = payload
    },
    setOrderProducts (state, products) {
      state.order.products = []
      state.order = { ...state.order, products: products }
    },
    setProductInOrder (state) {
      state.product_list = state.product_list.map(obj => ({ ...obj, in_order: state.order.products && state.order.products.length > 0 && state.order.products.some(e => e.product_color_size_id === obj.product_color_size_id) }))
    },
    /**
     * show order delete ttn modal
     * @param [state]
     * @param {boolean} isTrue
     */
    setShowDeleteTTNModal (state, isTrue) {
      state.show_delete_ttn_modal = isTrue
    },
    /**
     * add order delivery data dimension
     * @param [state]
     * @param {import('../../types/order_types').Dimension} dimension
     */
    addDimension (state, dimension) {
      if (!state.order.delivery_data?.dimensions) {
        state.order.delivery_data.dimensions = [dimension]
      } else {
        state.order.delivery_data.dimensions.push(dimension)
      }
    },
    /**
     * delete all dimensions after index
     * @param state
     * @param {number} index
     */
    delDimensionsAfter (state, index) {
      if (state.order?.delivery_data?.dimensions) {
        state.order.delivery_data.dimensions.splice(index)
      }
    },
    setShowChangeOrdersStatus (state, payload) {
      state.show_change_orders_status = payload
    },
    setOrderOnDelete (state, order) {
      state.order_on_delete = order
    },
    setOrderCounters (state, counters) {
      state.order_counters = counters
    },
    setTotalSum (state, sum) {
      state.total_sum = sum
    },
    setCompletedSum (state, sum) {
      state.completed_sum = sum
    }
  },
  actions: {
    getOrdersListV1 ({ state, commit, dispatch }) {
      state.is_loading = false
      axios.get('/api/v1/orders', {
        params: state.filters
      })
        .then(res => {
          commit('setOrders', res.data.data)
          commit('setPagination', res.data.meta)
          commit('setShowOrderDeleteModal', false)
          commit('setSelectedOrders', [])
          commit('setTotalSum', res.data.total_sum)
          commit('setCompletedSum', res.data.completed_sum)
          state.is_loading = false
        })
        .catch(err => {
          console.log(err)
          state.is_loading = false
        })
        .finally()
    },
    getOrders ({ state, commit, dispatch }) {
      state.is_loading = false
      axios.get(baseUrl, {
        params: state.filters
      })
        .then(res => {
          commit('setOrders', res.data.data)
          commit('setPagination', res.data.meta)
          commit('setShowOrderDeleteModal', false)
          commit('setSelectedOrders', [])
          state.is_loading = false
        })
        .catch(err => {
          console.log(err)
          state.is_loading = false
        })
        .finally()
    },
    getOrder ({ state, commit, dispatch }, id) {
      state.is_loading = false
      axios.get(baseUrl + id).then(response => {
        if (response.data.data.client.city_id) {
          dispatch('delivery/getCity', response.data.data.client.city_id, { root: true })
            .then(city => {
              commit('delivery/setCities', [city], { root: true })
            })
        }
        if (response.data.data.client.branch_id) {
          dispatch('delivery/getBranch', response.data.data.client.branch_id, { root: true })
            .then(branch => {
              commit('delivery/setBranches', [branch], { root: true })
            })
        }
        commit('setOrder', response.data.data)
        commit('setProductInOrder')
        state.is_loading = false
      }).catch(err => {
        console.log(err)
        commit('clearOrder')
        state.is_loading = false
      })
    },
    getSingleOrder ({ state, commit, dispatch }, id) {
      state.is_loading = false
      axios.get(baseUrl + id).then(response => {
        commit('setOrder', response.data.data)
        state.is_loading = false
      }).catch(err => {
        console.log(err)
        state.is_loading = false
      })
    },
    async updateOrder ({ commit, state, dispatch }) {
      commit('errors/clearErrors', null, { root: true })

      return new Promise((resolve, reject) => {
        axios.put(baseUrl + state.order.id, state.order)
          .then(response => {
            eventBus.$root.$emit(
              'send_notify',
              'success',
              'Данные заказа обновлены'
            )
            commit('setOrder', response.data.data)
            resolve(response.data)
          }).catch(err => {
            console.log(err)
            eventBus.$root.$emit(
              'send_notify',
              'error',
              'Данные заказа не обновлены!',
              'Проверьте данные'
            )
            reject(err)
          })
      })
    },
    updateOrderTTN ({ dispatch }, [orderId, ttn]) {
      return new Promise((resolve, reject) => {
        axios.put(baseUrl + orderId + '/edit-ttn', {
          ttn: ttn.replace(/\s+/g, '')
        })
          .then(res => {
            eventBus.$root.$emit(
              'send_notify',
              'success',
              'ТТН успешно изменена'
            )
            resolve(res.data)
            dispatch('getOrders')
          })
          .catch(err => {
            eventBus.$root.$emit(
              'send_notify',
              'error',
              'Не удалось обновить ТТН!'
            )
            reject(err)
          })
      })
    },
    createOrder ({ state, commit }) {
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.post(baseUrl, state.order).then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Заказ создан'
          )
          commit('clearOrder')
          //   resolve(redirectTo)
          commit('setOrder', response.data.data)
          router.push({ name: 'EditOrder', params: { id: state.order.id } })
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Заказ не создан!',
            'Проверьте данные'
          )
          reject(err)
        })
      })
    },
    deleteOrder (action, order) {
      axios.delete(baseUrl + order.id).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Заказ успешно удален'
        )
        action.commit('setShowOrderDeleteModal', false)
        action.commit('setOrderOnDelete', null)
        action.dispatch('getOrders')
      }).catch(err => {
        console.log(err)
      })
    },
    deleteOrders ({ state, commit, dispatch }) {
      axios.delete(baseUrl, {
        data: { ids: state.selected_orders }
      })
        .then(res => {
          dispatch('getOrders')
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Выбранные заказы удалены'
          )
        })
        .catch(err => {
          console.log(err)
          commit('setShowOrderDeleteModal', false)
          commit('setSelectedOrders', [])
        })
    },
    exportOrders ({ state, commit }) {
    },
    toggleOrderFilters ({ state, commit }) {
      commit('setShowOrderFilters', !state.show_order_filters)
    },
    toggleOrderProductsModal ({ state, commit }) {
      commit('setShowOrderProductsModal', !state.show_order_products_modal)
    },
    toggleOrderActions ({ state, commit }) {
      commit('setShowOrderActions', !state.show_order_actions)
    },
    deleteOrderItem ({ state, dispatch }, id) {
      axios.delete(itemsUrl + id).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Товар успешно удален'
        )
        dispatch('getOrder', state.order.id)
      }).catch(err => {
        console.log(err)
      })
    },
    getProductList ({ state, commit }) {
      state.product_list_filters.user_id = state.order.dropshipper_id
      axios.get(productListUrl, {
        params: state.product_list_filters
      })
        .then(res => {
          commit('setProductList', res.data.data)
          commit('setProductListPagination', res.data.meta)
        })
        .catch(err => {
          console.log(err)
        })
    },
    setOrderProductsItems ({ commit }, products) {
      commit('setOrderProducts', products)
    },
    createEn ({ commit, dispatch }, [orderId, periodId]) {
      return new Promise((resolve, reject) => {
        axios.post(baseUrl + 'create-en', {
          order_id: orderId,
          period_id: periodId
        })
          .then(res => {
            if (!res.data.success) {
              const errText = res.data.errors.join(' ')
              eventBus.$root.$emit(
                'send_notify',
                'error',
                'Не удалось создать ТТН!',
                errText
              )
            } else {
              eventBus.$root.$emit(
                'send_notify',
                'success',
                'Декларация успешно зарегистрирована',
                'Номер декларации: ' + res.data.data[0].IntDocNumber
              )
            }
            dispatch('getOrder', orderId)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    deleteEN ({ commit, dispatch }, orderId) {
      return new Promise((resolve, reject) => {
        axios.delete(baseUrl + 'delete-en/' + orderId)
          .then(res => {
            if (!res.data.success) {
              const errText = res.data.errors.join(' ')
              eventBus.$root.$emit(
                'send_notify',
                'error',
                'Не удалось удалить ТТН!',
                errText
              )
            } else {
              eventBus.$root.$emit(
                'send_notify',
                'success',
                'ТТН успешно удалена'
              )
            }
            dispatch('getOrder', orderId)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    changeStatus ({ state, commit, dispatch }, data) {
      axios.put(baseUrl + 'change-status', {
        orders_id: [data.order_id],
        status: data.status_id
      }).then(res => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Статус изменен'
        )
      })
    },
    changeOrdersStatus ({ state, commit, dispatch }, statusId) {
      axios.put(baseUrl + 'change-status', {
        orders_id: state.selected_orders,
        status: statusId
      })
        .then(res => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Смена статуса',
            'Статус выбранных заказов успешно изменен'
          )
          commit('setShowChangeOrdersStatus', false)
          dispatch('getOrders', null, { root: false })
        })
        .catch(err => {
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Смена статуса',
            'Не удалось сменить статус выбранных заказов'
          )
          commit('setShowChangeOrdersStatus', false)
          console.log(err)
        })
    },
    getOrderCounters ({ state, commit }) {
      axios.get(baseUrl + 'counters', {
        params: state.filters
      })
        .then(res => {
          commit('setOrderCounters', res.data)
        })
    },
    markPrint ({ commit }, order) {
      axios.put(baseUrl + order.id + '/mark-printed')
        .then(res => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные обновлены'
          )
        })
        .catch(err => {
          console.log(err)
        })
    },
    unmarkPrint ({ commit }, order) {
      axios.put(baseUrl + order.id + '/unmark-printed')
        .then(res => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные обновлены'
          )
        })
        .catch(err => {
          console.log(err)
        })
    },
    copyOrder ({ commit }, order) {
      axios.post(baseUrl + order.id + '/copy')
        .then(res => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Создана копия заказа'
          )
          router.push('/dashboard/orders/orders/')
        })
        .catch(err => {
          console.log(err)
        })
    },
    setDropshipperInOrder ({ commit, state }) {
      axios.put(baseUrl + state.order.id + '/set-dropshipper', {
        new_id: state.order.dropshipper_id
      })
        .then(res => {
          commit('setOrder', res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
