import axios from 'axios'
import { eventBus } from '@/main'

const baseUrl = 'api/return-items/'

export default {
  namespaced: true,
  state: {
    return_items: []
  },
  getters: {
    get_total_drop: (state) => () => {
      let dropTotal = 0

      if (state.return_items && state.return_items.length > 0) {
        dropTotal = state.return_items.reduce((total, product) => {
          if (product.discount) {
            return total + parseFloat(product.retail_price) * parseInt(product.qty) - product.discount.total * parseInt(product.qty)
          } else {
            return total + parseFloat(product.retail_price) * parseInt(product.qty)
          }
        }, 0)
      }

      return dropTotal
    },
    hasReturn: (state) => (orderItemId) => {
      if (state.return_items && state.return_items.length > 0) {
        return state.return_items.findIndex(p => p.order_item_id === orderItemId) > -1
      }
      return false
    }
  },
  mutations: {
    setReturnItems (state, data) {
      state.return_items = data
    }
  },
  actions: {
    getReturnItems ({ commit, state }, id) {
      axios.get(baseUrl,
        {
          params: {
            order_id: id
          }
        }
      )
        .then(res => {
          commit('setReturnItems', res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async createReturnItem ({ state, commit }, data) {
      await axios.post(baseUrl, {
        order_item_id: data.order_item_id,
        qty: data.qty
      }).then(response => {
        state.return_items.push(response.data.data)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Возврат создан'
        )
      }).catch(err => {
        console.log(err)
      })
    },
    updateReturnItem ({ state, commit }, data) {
      axios.put(baseUrl + data.id, {
        qty: data.qty
      }).then(response => {
      }).catch(err => {
        console.log(err)
      })
    },
    async deleteReturnItem ({ state, commit }, id) {
      await axios.delete(baseUrl + id).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Роль создана'
        )
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
