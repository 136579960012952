<template>
  <div class="table_body adds_product_invoice">
    <div class="top_table flx">
      <span class="page_subtitle">Приход</span>
      <div class="top_info_wrap flx">
        <p>Общее кол-во: <span class="summary"> {{ getTotalCount() }} шт</span></p>
        <div class="change_sum_wrap flx">
          <p>на сумму: <span class="summary"> {{ Math.abs(getTotalInvoice()) | asPrice }} </span></p>
        </div>
      </div>
    </div>
    <div class="wrap_tbl">
      <headline :headers="headers"></headline>
      <div class="row_list">
        <div class="row flx" v-for="product in products" :key="product.product_color_size_id">
          <div class="id_product">
            <router-link :to="{ name: 'ShowProduct', params: { id: product.product_id}}">{{ product.product_color_size_id }}</router-link>
          </div>
          <div class="code_product">
            <span><b>{{ product.vendor_code }}</b></span>
          </div>
          <div class="name_product">
            <span>{{ product.name}}</span>
          </div>
          <div class="color_product">
            <span>{{ product.color }}</span>
          </div>
          <div class="size_product">
            <span>{{ product.size }}</span>
          </div>
          <div class="quantity_product">
            <input style="width: 100px" type="number" :value="product.remains" min="1" @input="setIncProductQty($event, product)">
          </div>
          <div class="price_product">
            <product-price :price.sync="product.price" :key="'product_price_' + product.product_color_size_id" :id="product.product_color_size_id" @update-price="updatePrice"></product-price>
          </div>
          <div class="cross close" @click="deleteIncProduct(product)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { prices } from '../../../../helpers/prices'
import Headline from '../../../Helpers/Headline'
import ProductPrice from './ProductPrice'

export default {
  name: 'InvoiceProducts',
  mixins: [prices],
  components: {
    Headline,
    ProductPrice
  },
  data () {
    return {
      headers: [
        {
          title: 'ID',
          class: 'id_product'
        },
        {
          title: 'Артикул',
          class: 'code_product'
        },
        {
          title: 'Название',
          class: 'name_product'
        },
        {
          title: 'Цвет',
          class: 'color_product'
        },
        {
          title: 'Размер',
          class: 'size_product'
        },
        {
          title: 'Кол-во',
          class: 'quantity_product'
        },
        {
          title: 'Закупка',
          class: 'price_product'
        },
        {
          title: '',
          class: 'cross'
        }
      ]
    }
  },
  computed: {
    ...mapState('incoming', ['inc_invoice']),
    products: {
      get () {
        return this.inc_invoice.products
      },
      set (val) {
        this.setIncInvoiceProducts(val)
      }
    }
  },
  methods: {
    ...mapMutations('incoming', ['setIncInvoiceProducts']),
    ...mapGetters('incoming', ['getTotalInvoice', 'getTotalCount']),
    setIncProductQty (e, product) {
      product.remains = e.target.valueAsNumber
      const index = this.products.findIndex(p => p.product_color_size_id === product.product_color_size_id)
      this.$set(this.products, index, product)
      if (this.inc_invoice.id) {
        this.$nextTick(() => {
          this.$emit('change-product')
        })
      }
    },
    deleteIncProduct (product) {
      this.products = _.filter(this.products, p => { return p.product_color_size_id !== product.product_color_size_id })
      if (this.inc_invoice.id) {
        this.$nextTick(() => {
          this.$emit('change-product')
        })
      }
    },
    updatePrice (productColorSizeId) {
      const product = this.products.find(p => p.product_color_size_id === productColorSizeId)
      this.products = this.products.map(obj => ({ ...obj, price: product.product_color_size_id === obj.product_color_size_id ? product.price : obj.price }))
      if (this.inc_invoice.id) {
        this.$nextTick(() => {
          this.$emit('change-product')
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
