import axios from 'axios'

const baseUrl = 'api/colors'

export default {
  namespaced: true,
  state: {
    colors: []
  },
  mutations: {
    setColors (state, data) {
      state.colors = data
    }
  },
  actions: {
    getColors (action) {
      axios.get(baseUrl).then(response => {
        action.commit('setColors', response.data.data)
      })
    }
  }
}
