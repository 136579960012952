<template>
  <div class="add_item_wrap flx">
    <div class="filters_box">
      <span class="page_subtitle">Добавить товар</span>
      <div class="inp_inner">
        <div class="inp_wrap">
          <p>Поиск:</p>
          <input type="text" v-model="product_filters.search" @input="updateFilterInput">
        </div>
        <div class="inp_wrap">
          <p>Категория:</p>
          <div class="select select-search" id="category">
            <brew-select
                class="vue-select"
                label="path_name"
                v-model="product_filters.category_id"
                :options="childCategories"
                :reduce="item => item.id"
                @option:selected="getProductList"
                @option:cleared="getProductList"
            />
          </div>
        </div>
        <div class="inp_wrap">
          <p>Артикул:</p>
          <input type="text" id="category_product" v-model="product_filters.vendor_code"
                 @keypress.enter="getProductList" @input="updateFilterInput">
        </div>
        <div class="inp_wrap">
          <p>Название:</p>
          <input type="text" id="name_add_product" name="product_filter_name" v-model="product_filters.name" @keypress.enter="getProductList" @input="updateFilterInput">
        </div>
        <div class="inp_wrap">
          <p>Сезон:</p>
          <div class="select select-search">
            <brew-select
                class="vue-select"
                label="name"
                v-model="product_filters.season_id"
                :options="seasons"
                :reduce="item => item.id"
                @option:selected="getProductList"
                @option:cleared="getProductList"
            />
          </div>
        </div>
        <div class="inp_wrap">
          <p>Цена:</p>
          <div class="flx" style="justify-content: space-between">
            <div style="width: 47%">
              <p>от:</p>
              <input type="number" v-model="product_filters.price_from" @keypress.enter="getProductList" @input="updateFilterInput">
            </div>
            <div style="width: 47%">
              <p>до:</p>
              <input type="number" v-model="product_filters.price_to" @keypress.enter="getProductList" @input="updateFilterInput">
            </div>
          </div>
        </div>
      </div>
      <div class="flx product-list-filter-actions">
        <div class="reset" @click="clearOrderItemFilter">Сбросить фильтры</div>
      </div>
    </div>
    <div class="add_item_table">
      <div class="top_table flx">
        <div class="pagination flx w-100" style="justify-content: center;">
          <paginate
              style="margin-left: 20px"
              class="flx"
              v-model="page"
              :page-count="product_list_pagination.last_page"
              :page-range="3"
              :margin-pages="2"
              :prev-text="'<'"
              :next-text="'>'"
          >
          </paginate>
        </div>
      </div>
      <div class="table_body">
        <headline :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
        <div class="row_list fix-scroll">
          <div class="row flx add-product-wrap"
               v-for="(item, index) in product_list"
               :key="index + '-product-color-size-'+item.product_color_size_id"
              :class="{'in-order':item.in_order}"
          >
            <div class="add-product" v-if="!item.in_order"><button class="btn" :disabled="item.remains === 0 || disabled_field" :class="{ disabled: item.remains === 0 || disabled_field }"  @click="addOrderProduct(item)">Добавить в заказ</button></div>
            <div class="add-product" v-else><button class="btn" :disabled="disabled_field" :class="{ disabled: disabled_field}">Уже в заказе</button></div>
            <div class="img_prod">
              <img v-if="item.images" :src="item.images[60]" :alt="item.name" :title="item.name">
              <img v-else src="/images/no-img.svg">
            </div>
            <div class="name_prod">
              <span><b>{{ item.name }}</b></span>
            </div>
            <div class="code_prod">
              <span class="orange"><b>{{ item.vendor_code }}</b></span>
            </div>
            <div class="size_prod">
              <span v-if="item.size">{{ item.size }}</span>
            </div>
            <div class="color_prod">
              <span v-if="item.color">{{ item.color }}</span>
            </div>
            <div class="category_prod">
              <span class="grey" v-if="item.category_name"><b>{{ item.category_name }}</b></span>
            </div>
            <div class="season_prod">
              <span class="grey" v-if="item.season"><b>{{ item.season }}</b></span>
            </div>
            <div class="price_prod">
              <div class="item flx">
                <span>Закупка/шт:</span>
                <span class="line"> </span>
                <p>{{ item.purchase_price }}</p>
              </div>
              <div class="item flx">
                <span>Цена/шт (дроп):</span>
                <span class="line"> </span>
                <p>{{ item.retail_price }}</p>
              </div>
              <div class="item flx">
                <span>Скидка:</span>
                <span class="line"> </span>
                <p v-if="item.discount&&item.discount.value">{{ item.discount.value }}{{ item.discount.type }}
                  <helper-info class="mr-0">
                    <template v-slot:description>
                      <span>{{getDiscountText(item.discount.discount_type)}}</span>
                    </template>
                  </helper-info>
                </p>
                <p v-else>0%</p>
              </div>
              <div class="item flx">
                <span>Сумма:</span>
                <span class="line"> </span>
                <p v-if="item.discount">{{ (item.retail_price - item.discount.total).toFixed(2) }}</p>
                <p v-else>{{ item.retail_price }}</p>
              </div>
            </div>
            <div class="available_prod">
              <span :class="getColorRemains(item.remains)">{{ item.remains ? item.remains : '' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import helper from '../../../../helpers/helper'
import HelperInfo from '../../../Helpers/HelperInfo'
import _ from 'lodash'
import Headline from '../../../Helpers/Headline'
export default {
  name: 'AddOrderItem',
  components: {
    HelperInfo,
    Headline
  },
  data () {
    return {
      timer: null,
      headers: [
        {
          title: 'Фото',
          sortable: true,
          class: 'img_prod',
          value: 'id'
        },
        {
          title: 'Название',
          class: 'name_prod',
          sortable: true,
          value: 'name'
        },
        {
          title: 'Артикул',
          class: 'code_prod',
          sortable: true,
          value: 'vendor_code'
        },
        {
          title: 'Размеры',
          class: 'size_prod'
          //  TODO sort by sizes
        },
        {
          title: 'Цвет',
          class: 'color_prod'
          //  TODO sort by color
        },
        {
          title: 'Категория',
          class: 'category_prod'
          //  TODO sort by category name
        },
        {
          title: 'Сезон',
          class: 'season_prod'
          //  TODO sort by season name
        },
        {
          title: 'Цена',
          class: 'price_prod'
          //  TODO sort by price
        },
        {
          title: 'Наличие',
          class: 'available_prod',
          sortable: true,
          value: 'total_remains'
        }
      ]
    }
  },
  computed: {
    ...mapState('catalog', ['childCategories']),
    ...mapState('season', ['seasons']),
    ...mapState('orders', ['product_list', 'product_list_filters', 'order', 'product_list_pagination']),
    order_data: {
      get () {
        return this.order
      },
      set (val) {
        this.setOrder(val)
      }
    },
    product_filters: {
      get () {
        return this.product_list_filters
      },
      set (val) {
        console.log('set_filters', val)
        this.setProductListFilters(val)
      }
    },
    sortBy: {
      get () {
        return this.product_filters.order_by
      },
      set (val) {
        this.setProductListOrderby(val)
      }
    },
    sortDesc: {
      get () {
        return this.product_filters.order_direction
      },
      set (val) {
        this.setProductListOrderDirection(val)
      }
    },
    page: {
      get () {
        return this.product_filters.page
      },
      set (value) {
        this.setProductListPage(value)
        this.getProductList()
      }
    },
    disabled_field () {
      return false
      // return this.order_data.ttn !== null || this.order_data.delivery_status > 1 || this.order_data.status > 1
    }
  },
  watch: {
    sortBy () {
      this.getProductList()
    },
    sortDesc () {
      this.getProductList()
    }
  },
  methods: {
    ...mapActions('catalog', ['getChildCategories']),
    ...mapActions('season', ['getSeasons']),
    ...mapActions('orders', ['getProductList', 'updateOrder', 'deleteOrderItem']),
    ...mapMutations('orders', ['setProductListFilters', 'clearProductListFilters', 'setOrder', 'setProductInOrder', 'setProductListOrderby', 'setProductListOrderDirection', 'setProductListPage']),
    clearOrderItemFilter () {
      this.clearProductListFilters()
      this.$nextTick(() => {
        this.getProductList()
      })
    },
    getColorRemains (val) {
      return helper.getColorRemains(val)
    },
    addOrderProduct (product) {
      if (this.disabled_field) {
        return false
      }
      product.qty = 1
      this.order_data.products.push(product)
      this.$nextTick(() => {
        this.setProductInOrder()
      })
      if (this.order_data.id) {
        this.updateOrder()
      }
    },
    delOrderProduct (product) {
      if (this.disabled_field) {
        return false
      }
      if (this.order_data.id) {
        const item = _.find(this.order_data.products, p => { return p.product_color_size_id === product.product_color_size_id })
        if (item) {
          this.deleteOrderItem(item.id)
          this.setProductInOrder()
        }
      } else {
        this.order_data.products = _.filter(this.order_data.products, p => { return p.product_color_size_id !== product.product_color_size_id })
        this.$nextTick(() => {
          this.setProductInOrder()
        })
      }
    },
    getDiscountText (type) {
      return helper.getDiscountText(type)
    },
    updateFilterInput (e) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.setProductListPage(1)
        this.getProductList()
      }, 300)
    }
  },
  created () {
    this.clearProductListFilters()
    this.getChildCategories()
    this.getSeasons()
    this.getProductList()
  }
}
</script>

<style scoped lang="scss">
  .in-order{
    background: antiquewhite;
  }
  .add-product-wrap {
    position: relative;
    .add-product {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      .disabled {
        background-color: grey;
      }
    }
    &:hover {
      .add-product {
        z-index: 1;
        opacity: 1;
      }
    }

  }
</style>
