import { render, staticRenderFns } from "./EditDropshipper.vue?vue&type=template&id=251c7537&scoped=true&"
import script from "./EditDropshipper.vue?vue&type=script&lang=js&"
export * from "./EditDropshipper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "251c7537",
  null
  
)

export default component.exports