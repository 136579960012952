<template>
  <section class="table_box new_edit_sender">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title">Редактировать отправителя ID: {{ sender.id }}</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="store">Сохранить</div>
      </div>
    </div>
    <div class="container tbl_box flx">
      <div class="info_sender_form box_shadow">
        <div class="top">
          <span class="page_subtitle">Информация отправителя</span>
          <input style="display: none" type="email" name="email" value="" />
          <input style="display: none" type="text" name="login" />
          <input style="display: none" type="password" name="password"/>
        </div>
        <div class="row_list">
          <div class="row flx">
            <div class="inp_wrap">
              <p>Фамилия:<span>*</span></p>
              <input type="text" id="surname_sender" :class="{required:hasError('last_name')}" required
                     v-model="edit_sender.last_name">
            </div>
            <div class="inp_wrap">
              <p>Имя:<span>*</span></p>
              <input type="text" id="name_sender" :class="{required:hasError('first_name')}" required
                     v-model="edit_sender.first_name">
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap">
              <p>Отчество:</p>
              <input type="text" :class="{required:hasError('middle_name')}" required v-model="edit_sender.middle_name">
            </div>
            <div class="inp_wrap">
              <p>Телефон:<span>*</span></p>
              <input type="tel" id="phone" required :class="{required:hasError('phone')}" v-model="edit_sender.phone"
                     v-mask="phone_mask">
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap">
              <p>Доставка:</p>
              <brew-select
                  class="select-delivery vue-select select-search"
                  :class="{required:hasError('delivery_id')}"
                  :clearable="false"
                  :options="deliveries"
                  label="name"
                  :reduce="item => item.id"
                  v-model="edit_sender.delivery_id"
              ></brew-select>
            </div>
            <div class="inp_wrap">
              <p>Город<span>*</span></p>
              <auto-select
                  :class="{required:hasError('city_id')}"
                  :items="cities"
                  :default-id="edit_sender.city_id"
                  :default-name="edit_sender.city ? edit_sender.city.name : null "
                  @search="fetchCities"
                  @select="selectCity"
                  @active-input="fetchCities"
              ></auto-select>
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap warehouse w-100">
              <p>Склад<span>*</span></p>
              <auto-select
                  :class="{required:hasError('branch_id')}"
                  :items="branches"
                  :default-id="edit_sender.branch_id"
                  :default-name="edit_sender.branch ? edit_sender.branch.name : null "
                  @search="fetchBranches"
                  @select="selectBranch"
                  @active-input="fetchBranches"
              ></auto-select>
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap api_key w-100">
              <p>Api ключ:</p>
              <input type="text" id="api_key" :class="{required:hasError('api_key')}" v-model="edit_sender.api_key">
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap api_key w-100">
              <p>Ref контакта:</p>
              <input type="text" id="ref" :class="{required:hasError('ref')}" v-model="edit_sender.ref">
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap email">
              <p>Email:</p>
              <input type="email" id="email" :class="{required:hasError('email')}" v-model="edit_sender.email">
            </div>
            <p class="necessary"><span>*</span>- поля обязательные для заполнения</p>
          </div>
        </div>
      </div>
      <div></div>
      <div class="sales_volumes_form box_shadow">
        <div class="top flx">
          <span class="page_subtitle">Объём продаж</span>
        </div>
        <div class="row_list">
          <div class="row flx mb-1">
            <label class="custom-checkbox">
              <input type="checkbox" :checked="edit_sender.is_fop" @change="toggleFop">
              <span></span>
              <span>ФОП</span>
            </label>
          </div>
          <div class="row flx mb-1">
            <label class="custom-checkbox">
              <input type="checkbox" :checked="edit_sender.is_enable_pay_control" @change="togglePayControl">
              <span></span>
              <span>Контроль оплат (Новая Почта)</span>
            </label>
          </div>
          <div class="row flx">
            <div class="inp_wrap w-100">
              <p>Обьем продаж:<span>*</span></p>
              <input type="text" :class="{required:hasError('volume')}" required v-model.number="edit_sender.volume">
              <p>Цвет</p>
              <input type="color" v-model="edit_sender.color" style="padding: 0;width: 100px">
            </div>
          </div>
        </div>
        <hr>
        <div class="top flx">
          <span class="page_subtitle">Интеграция CHECKBOX</span>
        </div>
        <div class="row_list">
          <div class="row flx">
            <div class="inp_wrap w-100">
              <div class="row flx mb-1">
                <label class="custom-checkbox">
                  <input type="checkbox" v-model="edit_sender.checkbox_is_active">
                  <span></span>
                  <span>Касса Активна</span>
                </label>
              </div>
              <p>PIN-Code Кассира:</p>
              <input type="password" name="pin-code" :class="{required:hasError('checkbox_pin')}" autocomplete="new-name-auto" required v-model.number="edit_sender.checkbox_pin">
              <p>Лицензионный ключ кассы:</p>
              <input type="password" :class="{required:hasError('checkbox_license_key')}" required v-model="edit_sender.checkbox_license_key" autocomplete="_new-pin-code">
              <p>Время работы кассы:</p>
              <span>Открытие в: </span>
              <input style="max-width: 100px;margin-left: 20px"
                     v-model="edit_sender.open_checkbox_at"
                     type="time"
                     min="00:00" max="23:59"
                     required
                     :class="{required:hasError('open_checkbox_at')}"
              />
              <span style="padding-left: 30px">Закрытие в: </span>
              <input style="max-width: 100px; margin-left: 20px"
                     v-model="edit_sender.close_checkbox_at"
                     type="time"
                     min="00:00" max="23:50"
                     required
                     :class="{required:hasError('close_checkbox_at')}"
              />
              <br>
              <p>Дата начала:</p>
              <input style="max-width: 200px; margin-bottom: 0"
                     v-model="edit_sender.checkbox_after_at"
                     type="datetime-local"
              />
              <br>
              <small>Чеки будут создаваться для заказов созданных позднее этой даты</small>
              <br>
              <br>
              <p>Генерировать чеки:</p>
              <div>
                <input v-model="edit_sender.checkbox_order_type" style="height: 15px;width: 15px; vertical-align: sub"
                       type="radio"
                       id="huey1"
                       name="drone"
                       value="0"
                       checked
                />
                <label for="huey1">Для всех заказов</label>
              </div>
              <div>
                <input style="height: 15px;width: 15px; vertical-align: sub"
                       type="radio"
                       id="huey2"
                       name="drone"
                       value="1"
                       checked
                       v-model="edit_sender.checkbox_order_type"
                />
                <label for="huey2">Для заказов с оплатой на карту (и с баланса)</label>
              </div>
              <div>
                <input style="height: 15px;width: 15px; vertical-align: sub"
                       type="radio"
                       id="huey3"
                       name="drone"
                       value="2"
                       checked
                       v-model="edit_sender.checkbox_order_type"
                />
                <label for="huey3">Для заказов с Наложенным платежом</label>
              </div>
              <p>Header чека (текст заголовка чека):</p>
              <input type="text" name="pin-code" autocomplete="new-name-auto" required v-model.number="edit_sender.checkbox_header">
              <p>Footer чека (текст внизу чека):</p>
              <input type="text" name="pin-code" autocomplete="new-name-auto" required v-model.number="edit_sender.checkbox_footer">

              <button class="btn" @click="testCheckbox">Проверить подключение</button>
              <div style="margin-top: 20px; color: green" v-if="checkboxTestResult === true">Подключение успешно!</div>
              <div style="margin-top: 20px; color: red" v-if="checkboxTestResult === false">Не удалось подключиться!
                Проверьте данные Pin-Code и Лицензионный ключ кассы
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import AutoSelect from '../../Helpers/AutoSelect'

export default {
  name: 'EditSender',
  directives: {
    mask
  },
  components: {
    AutoSelect
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##',
      checkboxTestResult: null
    }
  },
  computed: {
    ...mapState('senders', ['sender']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapState('delivery', ['cities', 'branches', 'deliveries']),
    edit_sender: {
      get () {
        return this.sender
      },
      set (val) {
        this.setSender(val)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    }
  },
  methods: {
    ...mapActions('senders', ['updateSender', 'getSender', 'clearSender', 'testCheckboxConnect']),
    ...mapMutations('senders', ['setSender']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapActions('delivery', ['getCities', 'getBranches', 'getDeliveries']),
    ...mapMutations('delivery', ['setCities', 'setBranches', 'setSearchBranch']),
    store () {
      this.updateSender()
    },
    fetchCities (search) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getCities(search)
      }, 200)
    },
    fetchBranches (search) {
      this.$set(this.edit_sender, 'branch_id', null)
      this.$set(this.edit_sender, 'branch.name', null)

      if (this.edit_sender.city_id) {
        this.setSearchBranch(search)
        this.getBranches({
          city_id: this.edit_sender.city_id,
          is_ua: this.is_ua
        })
      }
    },
    toggleFop (e) {
      if (e.target.checked) {
        this.edit_sender.volume = 5000000
        this.edit_sender.is_fop = true
      } else {
        this.edit_sender.volume = 500000
        this.edit_sender.is_fop = false
      }
    },
    togglePayControl (e) {
      if (e.target.checked) {
        this.edit_sender.is_enable_pay_control = true
      } else {
        this.edit_sender.is_enable_pay_control = false
      }
    },
    selectCity (data) {
      this.edit_sender.city_id = data.id
      this.edit_sender.city.name = data.name
      this.is_ua = data.is_ua
      this.fetchBranches(data)
    },
    selectBranch (data) {
      this.edit_sender.branch_id = data.id
      this.edit_sender.branch.name = data.name
    },
    testCheckbox () {
      const promise = this.testCheckboxConnect()
      promise.then(r => {
        if (r.data.access_token) {
          this.checkboxTestResult = true
        } else {
          this.checkboxTestResult = false
        }
      }).catch(e => {
        this.checkboxTestResult = false
      })
    }
  },
  created () {
    this.clearErrors()
    this.getSender(this.$route.params.id)
    this.$nextTick(() => {
      this.getDeliveries()
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.getSender(this.$route.params.id)
    this.clearErrors()
    this.$nextTick(() => {
      this.getDeliveries()
    })
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.clearSender()
    this.clearErrors()
    next()
  }
}
</script>

<style scoped>

</style>
