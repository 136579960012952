import axios from 'axios'
import { eventBus } from '@/main'
const baseUrl = 'api/clients/'
const ordersUrl = 'api/orders/'
const redirectTo = 'Clients'

export default {
  namespaced: true,
  state: {
    clients: [],
    pagination: {
      last_page: 1
    },
    default_client_filters: {
      per_page: localStorage.getItem('clients-per-page') || 10,
      order_by: localStorage.getItem('clients-order-by') || 'id',
      order_direction: localStorage.getItem('clients-order-direction') || 'desc',
      search: null,
      page: 1,
      client_id: null,
      last_name: null,
      first_name: null,
      phone: null,
      city: null,
      last_order_from: null,
      last_order_to: null,
      created_id: null
    },
    client_filters: {
      per_page: localStorage.getItem('clients-per-page') || 10,
      order_by: localStorage.getItem('clients-order-by') || 'id',
      order_direction: localStorage.getItem('clients-order-direction') || 'desc',
      search: null,
      page: 1,
      client_id: null,
      last_name: null,
      first_name: null,
      phone: null,
      city: null,
      last_order_from: null,
      last_order_to: null,
      created_id: null
    },
    show_client_filters: false,
    edit_client: {
      id: null,
      first_name: null,
      last_name: null,
      middle_name: null,
      phone: null,
      delivery_id: 1,
      city_id: null,
      branch_id: null
    },
    show_client_actions: false,
    show_client_delete_modal: false,
    selected_clients: [],
    client: {},
    client_orders: []
  },
  getters: {
    count_client_filters: state => Object.values(state.client_filters).filter(f => f).length - 4,
    get_city_id: state => state.edit_client.city_id
  },
  mutations: {
    setClients (state, clients) {
      state.clients = clients
    },
    setPagination (state, pagination) {
      state.pagination = pagination
    },
    setClientFilters (state, clientFilters) {
      state.client_filters = clientFilters
    },
    setPage (state, page) {
      state.client_filters.page = page
    },
    setSearch (state, searchString) {
      state.client_filters.search = searchString
    },
    setClientPerPage (state, perPage) {
      state.client_filters.per_page = perPage
      localStorage.setItem('clients-per-page', perPage)
    },
    setOrderBy (state, payload) {
      state.client_filters.order_by = payload
      localStorage.setItem('clients-order-by', payload)
    },
    setOrderDirection (state, payload) {
      state.client_filters.order_direction = payload
      localStorage.setItem('clients-order-direction', payload)
    },
    setShowClientFilters (state, isTrue) {
      state.show_client_filters = isTrue
    },
    setEditClient (state, client) {
      state.edit_client = client
    },
    clearEditClient (state) {
      state.edit_client = {
        id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        phone: null,
        delivery_id: 1,
        city_id: null,
        branch_id: null
      }
    },
    setShowClientActions (state, isTrue) {
      state.show_client_actions = isTrue
    },
    setShowClientDeleteModal (state, isTrue) {
      state.show_client_delete_modal = isTrue
    },
    setSelectedClients (state, clients) {
      state.selected_clients = clients
    },
    setClient (state, client) {
      state.client = client
    },
    setClientOrders (state, orders) {
      state.client_orders = orders
    }
  },
  actions: {
    getClients ({ state, commit }) {
      axios.get(baseUrl, {
        params: {
          per_page: state.client_filters.per_page || 10,
          search: state.client_filters.search || null,
          page: state.client_filters.page || 1,
          client_id: state.client_filters.client_id || null,
          last_name: state.client_filters.last_name || null,
          first_name: state.client_filters.first_name || null,
          phone: state.client_filters.phone || null,
          city: state.client_filters.city || null,
          last_order_from: state.client_filters.last_order_from || null,
          last_order_to: state.client_filters.last_order_to || null,
          created_id: state.client_filters.created_id || null,
          order_by: state.client_filters.order_by || null,
          order_direction: state.client_filters.order_direction || null
        }
      })
        .then(res => {
          commit('setClients', res.data.data)
          commit('setPagination', res.data.meta)
          commit('setShowClientDeleteModal', false)
          commit('setSelectedClients', [])
        })
        .catch(err => {
          console.log(err)
        })
        .finally()
    },
    searchClients ({ state, commit, dispatch }, search) {
      axios.get(baseUrl, {
        params: {
          per_page: 20,
          search: search,
          page: 1
        }
      })
        .then(res => {
          commit('setClients', res.data.data)
          commit('setPagination', res.data.meta)
          commit('setShowClientDeleteModal', false)
          commit('setSelectedClients', [])
        })
        .catch(err => {
          console.log(err)
        })
        .finally()
    },
    getClient ({ state, commit, dispatch }, id) {
      axios.get(baseUrl + id).then(response => {
        commit('setEditClient', response.data.data)
        if (response.data.data.city) {
          commit('delivery/setCities', [response.data.data.city], { root: true })
          dispatch('delivery/getBranches', 'clients', { root: true })
        }
      }).catch(err => {
        console.log(err)
        commit('clearEditClient')
        commit('delivery/setCities', [], { root: true })
        commit('delivery/setBranches', [], { root: true })
      })
    },
    getSingleClient ({ state, commit, dispatch }, id) {
      axios.get(baseUrl + id).then(response => {
        commit('setClient', response.data.data)
        dispatch('getClientOrders', id)
      }).catch(err => {
        console.log(err)
      })
    },
    updateClient ({ commit, state, dispatch }) {
      commit('errors/clearErrors', null, { root: true })
      axios.put(baseUrl + state.edit_client.id, {
        first_name: state.edit_client.first_name || null,
        medium_name: state.edit_client.medium_name || null,
        last_name: state.edit_client.last_name || null,
        phone: state.edit_client.phone || null,
        delivery_id: state.edit_client.delivery_id,
        city_id: state.edit_client.city_id || null,
        branch_id: state.edit_client.branch_id || null
      })
        .then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные клиента обновлены'
          )
          commit('setEditClient', response.data.data)
          if (response.data.data.city) {
            commit('delivery/setCities', [response.data.data.city], { root: true })
            dispatch('delivery/getBranches', 'clients', { root: true })
          }
          commit('errors/clearErrors', null, { root: true })
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Данные клиента не обновлены!',
            'Проверьте данные'
          )
        })
    },
    createClient ({ state, commit }) {
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.post(baseUrl, state.edit_client).then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Клиент создан',
            'Создан: ' + response.data.data.first_name + ' ' + response.data.data.last_name
          )
          commit('setEditClient', response.data.data)
          resolve(redirectTo)
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Клиент не создан!',
            'Проверьте данные'
          )
          reject(err)
        })
      })
    },
    deleteClient (action, id) {
      axios.delete(baseUrl + id).then(response => {
        // action.commit('setShowDropshipperDeleteModal', false)
        // action.commit('setDropshipperOnDelete', null)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Клиент успешно удален'
        )

        action.dispatch('getClients')
      }).catch(err => {
        console.log(err)
        // action.commit('setShowDropshipperDeleteModal', false)
        // action.commit('setDropshipperOnDelete', null)
      })
    },
    deleteClients ({ state, commit, dispatch }) {
      axios.delete(baseUrl, {
        data: { ids: state.selected_clients }
      })
        .then(res => {
          dispatch('getClients')
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Выбранные клиенты удалены'
          )
        })
        .catch(err => {
          console.log(err)
          commit('setShowClientDeleteModal', false)
          commit('setSelectedClients', [])
        })
    },
    getClientOrders ({ state, commit }, clientId) {
      axios.get(ordersUrl, {
        params: {
          per_page: 99999,
          page: 1,
          client_id: clientId
        }
      })
        .then(res => {
          commit('setClientOrders', res.data.data)
        })
        .catch(err => {
          console.log(err)
          commit('setClientOrders', [])
        })
    },
    exportClients ({ state, commit }) {},
    importClients ({ state, commit }) {},
    toggleClientFilters ({ state, commit }) {
      commit('setShowClientFilters', !state.show_client_filters)
    },
    toggleClientActions ({ state, commit }) {
      commit('setShowClientActions', !state.show_client_actions)
    },
    clearClientFilters ({ state, commit }) {
      commit('setClientFilters', state.default_client_filters)
    }
  }
}
