<template>
  <div>
    <span class="clip_ico"  @click="pickFile()"></span>
    <input
        type="file"
        style="display: none"
        :ref="'pic_file_'+invoice_id"
        accept=".pdf,.xls,.xlsx,.docx,.txt,.csv"
        @change="onFilePicked($event)"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AttachDocFile',
  props: {
    invoice_id: Number
  },
  data () {
    return {
      image_file: null,
      image_name: null
    }
  },
  methods: {
    ...mapActions('incoming', ['uploadFile', 'getIncomingInvoices']),
    pickFile () {
      this.$refs['pic_file_' + this.invoice_id].click()
    },
    onFilePicked (e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        this.image_name = files[0].name
        if (this.image_name.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.image_file = files[0] // this is an image file that can be sent to server...
          this.$nextTick(() => {
            const formData = new FormData()
            if (this.image_file !== '') {
              formData.append('file', this.image_file)
              this.uploadFile([this.invoice_id, formData])
                .then(res => {
                  this.getIncomingInvoices()
                })
            }
          })
        })
      } else {
        this.image_file = null
        this.image_name = null
      }
    }
  }
}
</script>

<style scoped>

</style>
