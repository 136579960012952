import { render, staticRenderFns } from "./ClientFilter.vue?vue&type=template&id=63003162&scoped=true&"
import script from "./ClientFilter.vue?vue&type=script&lang=js&"
export * from "./ClientFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63003162",
  null
  
)

export default component.exports