<template>
  <div class="table_body adds_product_table" v-if="return_items.length > 0">
    <div class="top_table flx">
      <span class="page_subtitle">Товары возврата</span>
      <div class="info_sum flx">
        <p>Сумма возврата (drop): <span class="summary">{{get_total_drop() | asPrice }}</span></p>
      </div>
    </div>
    <div class="wrap_tbl">
      <div class="headline_table flx">
        <div class="img_prod">
          <p>Фото</p>
        </div>
        <div class="name_prod">
          <p>Название</p>
        </div>
        <div class="category_prod">
          <p>Категория</p>
        </div>
        <div class="characteristic_prod">
          <p>Характеристики</p>
        </div>
        <div class="price_prod">
          <p>Цена</p>
        </div>
        <div class="discount_prod">
          <p>Скидка</p>
        </div>
        <div class="quantity_prod">
          <p>Кол-во</p>
        </div>
        <div class="sum_drop_prod">
          <p>Сумма (дроп) со скидкой</p>
        </div>
        <div class="cross"></div>
      </div>
      <div class="row_list return-accent">
        <div class="row flx" v-for="item in return_items" :key="'product_return-'+item.id">
          <div class="img_prod">
            <img v-if="item.image_url" :src="item.image_url" :alt="item.name" :title="item.name" >
            <img v-else src="/images/no-img.svg">
          </div>
          <div class="name_prod">
            <p><b>{{ item.name }}</b></p>
          </div>
          <div class="category_prod">
            <p class="grey"><b>{{ item.category_name }}</b></p>
          </div>
          <div class="characteristic_prod">
            <div class="item flx">
              <span>Артикул:</span>
              <span class="line"> </span>
              <p>{{ item.vendor_code }}</p>
            </div>
            <div class="item flx">
              <span>Размер:</span>
              <span class="line"> </span>
              <p>{{ item.size }}</p>
            </div>
            <div class="item flx">
              <span>Цвет:</span>
              <span class="line"> </span>
              <p>{{ item.color }}</p>
            </div>
          </div>
          <div class="price_prod">
            <div class="item flx">
              <span>Цена/шт (дроп):</span>
              <span class="line"> </span>
              <p class="clickable-icon-item">{{ item.retail_price | asPrice }}</p>
            </div>
            <div class="item flx">
              <span>Сумма (дроп):</span>
              <span class="line"> </span>
              <p>{{ item.retail_price * item.qty | asPrice }}
                <span class="clickable-icon-mr"></span>
              </p>
            </div>
          </div>
          <div class="discount_prod clickable-icon-item">
            <span v-if="item.discount">{{ item.discount.value }} {{ item.discount.type }}</span>
            <span v-else>0%</span>
          </div>
          <div class="quantity_prod">
            <input type="number" :value="item.qty" min="1" @input="setOrderProductQty($event, item)">
          </div>
          <div class="sum_drop_prod">
            <p v-if="item.discount">{{ item.retail_price * item.qty - item.discount.total * item.qty | asPrice }}</p>
            <p v-else>{{ item.retail_price * item.qty | asPrice }}</p>
          </div>
          <div class="cross close" @click="deleteItem(item)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { prices } from '../../../../helpers/prices'
import { format } from '../../../../helpers/format'

let timer

export default {
  name: 'OrderProducts',
  directives: {
    format
  },
  mixins: [
    prices
  ],
  computed: {
    ...mapState('returnItems', ['return_items']),
    ...mapState('orders', ['order']),
    ...mapGetters('returnItems', ['get_total_drop'])
  },
  methods: {
    ...mapActions('returnItems', ['getReturnItems', 'updateReturnItem', 'deleteReturnItem']),
    deleteItem (item) {
      this.deleteReturnItem(item.id).then(r =>
        this.getReturnItems(this.$route.params.id)
      )
    },
    setOrderProductQty (e, product) {
      product.qty = e.target.valueAsNumber
      const indexProduct = this.order.products.findIndex(p => p.product_color_size_id === product.product_color_size_id)
      const maxQty = this.order.products[indexProduct].qty

      if (product.qty > maxQty) {
        product.qty = maxQty
      }

      const index = this.return_items.findIndex(p => p.id === product.id)
      this.$set(this.return_items, index, product)

      clearTimeout(timer)
      timer = setTimeout(() => {
        this.updateReturnItem({
          id: product.id,
          qty: product.qty
        })
      }, 2000)
    }
  },
  created () {
    this.getReturnItems(this.$route.params.id)
  }
}
</script>

<style lang="scss">
.return-accent {
  background: #fffaf1
}
</style>
