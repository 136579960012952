import axios from 'axios'
import { eventBus } from '@/main'
const baseUrl = '/api/incoming-invoices/'
const productListUrl = 'api/products/list/'

export default {
  namespaced: true,
  state: {
    inc_invoices: [],
    pagination: {
      last_page: 1
    },
    inc_invoice_filters: {
      supplier_id: null,
      id: null,
      count_product_from: null,
      count_product_to: null,
      total_from: null,
      total_to: null,
      created_id: null,
      per_page: localStorage.getItem('inc-invoices-per-page') || 10,
      order_by: localStorage.getItem('inc-invoices-order-by') || 'id',
      order_direction: localStorage.getItem('inc-invoices-order-direction') || 'desc',
      search: null,
      page: 1
    },
    show_inc_filters: false,
    inc_invoice: {
      id: null,
      supplier_id: null,
      comment: null,
      received_at: null,
      products: []
    },
    show_change_waybill_comment_modal: false,
    invoice_to_change_comment: null,
    product_list: [],
    product_list_pagination: {
      last_page: 1
    },
    product_list_filters: {
      category_id: null,
      vendor_code: null,
      name: null,
      season_id: null,
      price: null,
      per_page: 50,
      page: 1,
      order_by: 'id',
      order_direction: 'asc'
    }
  },
  getters: {
    count_inc_filters: state => Object.values(state.inc_invoice_filters).filter(f => f).length - 5,
    getTotalInvoice: state => {
      let total = 0
      state.inc_invoice.products.map(item => {
        total += item.price * item.remains
        return 0
      })
      state.inc_invoice.total = total

      return total
    },
    getTotalCount: state => {
      let total = 0
      state.inc_invoice.products.map(item => {
        total += item.remains
        return 0
      })

      return total
    }
  },
  mutations: {
    setIncInvoices (state, payload) {
      state.inc_invoices = payload
    },
    setIncInvoice (state, payload) {
      state.inc_invoice = payload
    },
    clearIncInvoice (state) {
      state.inc_invoice = {
        id: null,
        supplier_id: null,
        comment: null,
        received_at: null,
        products: []
      }
    },
    setInvoiceSupplierId (state, payload) {
      state.inc_invoice.supplier_id = payload
    },
    setSupplierId (state, payload) {
      state.inc_invoice_filters.supplier_id = payload
    },
    setIncInvoiceFilters (state, payload) {
      state.inc_invoice_filters = payload
    },
    clearIncInvoiceFilters (state) {
      state.inc_invoice_filters = {
        supplier_id: null,
        id: null,
        count_product_from: null,
        count_product_to: null,
        total_from: null,
        total_to: null,
        created_id: null,
        per_page: localStorage.getItem('inc-invoices-per-page') || 10,
        order_by: localStorage.getItem('inc-invoices-order-by') || 'id',
        order_direction: localStorage.getItem('inc-invoices-order-direction') || 'desc',
        search: null,
        page: 1
      }
    },
    setShowIncFilters (state, payload) {
      state.show_inc_filters = payload
    },
    setPagination (state, pagination) {
      state.pagination = pagination
    },
    setSearch (state, search) {
      state.inc_invoice_filters.search = search
    },
    setPage (state, page) {
      state.inc_invoice_filters.page = page
    },
    setPerPage (state, perPage) {
      localStorage.setItem('inc-invoices-per-page', perPage)
      state.inc_invoice_filters.per_page = perPage
    },
    setOrderBy (state, orderBy) {
      localStorage.setItem('inc-invoices-order-by', orderBy)
      state.inc_invoice_filters.order_by = orderBy
    },
    setOrderDirection (state, orderDirection) {
      localStorage.setItem('inc-invoices-order-direction', orderDirection)
      state.inc_invoice_filters.order_direction = orderDirection
    },
    setShowChangeWaybillCommentModal (state, payload) {
      state.show_change_waybill_comment_modal = payload
    },
    setInvoiceToChangeComment (state, payload) {
      state.invoice_to_change_comment = payload
    },
    clearInvoiceToChangeComment (state) {
      state.invoice_to_change_comment = null
    },
    setIncInvoiceProducts (state, payload) {
      state.inc_invoice.products = []
      state.inc_invoice = { ...state.inc_invoice, products: payload }
    },
    setProductList (state, products) {
      state.product_list = products.map(obj => ({ ...obj, in_invoice: state.inc_invoice.products && state.inc_invoice.products.length > 0 && state.inc_invoice.products.some(e => e.product_color_size_id === obj.product_color_size_id) }))
    },
    setProductListFilters (state, filters) {
      state.product_list_filters = filters
    },
    setProductListPagination (state, data) {
      state.product_list_pagination = data
    },
    setProductListPage (state, num) {
      state.product_list_filters.page = num
    },
    clearProductListFilters (state) {
      state.product_list_filters = {
        category_id: null,
        vendor_code: null,
        name: null,
        season_id: null,
        price: null,
        per_page: 50,
        page: 1,
        order_by: 'id',
        order_direction: 'asc'
      }
    },
    setProductListOrderBy (state, payload) {
      state.product_list_filters.order_by = payload
    },
    setProductListOrderDirection (state, payload) {
      state.product_list_filters.order_direction = payload
    },
    setProductInInvoice (state) {
      state.product_list = state.product_list.map(obj => ({ ...obj, in_invoice: state.inc_invoice.products && state.inc_invoice.products.length > 0 && state.inc_invoice.products.some(e => e.product_color_size_id === obj.product_color_size_id) }))
    }
  },
  actions: {
    getIncomingInvoices ({ state, commit }) {
      axios.get(baseUrl, {
        params: state.inc_invoice_filters
      })
        .then(res => {
          commit('setIncInvoices', res.data.data)
          commit('setPagination', res.data.meta)
        })
        .catch(() => {
          commit('setIncInvoices', [])
          commit('setPagination', { page: 1 })
        })
    },
    createIncomingInvoice ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.post(baseUrl, data)
          .then(res => {
            eventBus.$root.$emit(
              'send_notify',
              'success',
              'Платеж создан'
              // 'Создан: ' + res.data.data.name
            )
            resolve(res.data.data.id)
          })
          .catch(err => {
            eventBus.$root.$emit(
              'send_notify',
              'error',
              'Платеж не создан!',
              'Проверьте данные'
            )
            reject(err)
          })
      })
    },
    updateIncomingInvoice ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.put(baseUrl + data.id, data)
          .then(res => {
            eventBus.$root.$emit(
              'send_notify',
              'success',
              'Изменения внесены'
              // 'Создан: ' + res.data.data.name
            )
            commit('setProductInInvoice')
            resolve('ok')
          })
          .catch(err => {
            eventBus.$root.$emit(
              'send_notify',
              'error',
              'Изменения не внесены!',
              'Проверьте данные'
            )
            reject(err)
          })
      })
    },
    toggleIncFilters ({ state, commit }) {
      commit('setShowIncFilters', !state.show_inc_filters)
    },
    updateInvoiceComment ({ commit }, [id, comment]) {
      return new Promise((resolve, reject) => {
        axios.put(baseUrl + id, {
          comment: comment
        })
          .then(res => {
            eventBus.$root.$emit(
              'send_notify',
              'success',
              'Комментарий изменен'
              // 'Создан: ' + res.data.data.name
            )
            resolve('ok')
          })
          .catch(err => {
            eventBus.$root.$emit(
              'send_notify',
              'error',
              'Комментарий изменен',
              'Проверьте данные'
            )
            reject(err)
          })
      })
    },
    toggleChangeComment ({ state, commit }) {
      commit('setShowChangeWaybillCommentModal', !state.show_change_waybill_comment_modal)
    },
    uploadFile ({ commit }, [id, formData]) {
      return new Promise((resolve, reject) => {
        axios.post(baseUrl + `upload/${id}`, formData)
          .then(res => {
            eventBus.$root.$emit(
              'send_notify',
              'success',
              'Файл прикреплен'
              // 'Создан: ' + res.data.data.name
            )
            resolve('ok')
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getIncInvoice ({ commit }, id) {
      axios.get(baseUrl + id)
        .then(res => {
          commit('setIncInvoice', res.data.data)
          commit('setProductInInvoice')
        })
        .catch(() => {
          commit('clearIncInvoice')
          commit('setProductInInvoice')
        })
    },
    getProductList ({ state, commit }) {
      axios.get(productListUrl, {
        params: state.product_list_filters
      })
        .then(res => {
          commit('setProductList', res.data.data)
          commit('setProductListPagination', res.data.meta)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
