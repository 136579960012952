<template>
  <section class="table_box sender_schedule">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title">Расписание отправителей</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn">Сохранить</div>
      </div>
    </div>
    <div class="container tbl_box flx">
      <div class="table_body senders_list">
        <div class="bot_table">
          <div class="headline_table flx">
            <div class="id_fullname_sender">
              <p>Активность · id · ФИО</p>
            </div>
            <div class="activity_volume_sender">
              <p>Даты активности · объём</p>
            </div>
          </div>
          <div class="row_list">
            <div class="row flx" v-for="sender in edit_senders" :key="'sender-activity-'+sender.id" :class="{not_check: !sender.in_schedule}">
              <div class="id_fullname_sender flx">
                <label class="custom-checkbox">
                  <input type="checkbox" name="check" @change="selectSender($event, sender)" :value="sender.id" :checked="sender.in_schedule">
                  <span></span>
                </label>
                <span>[<router-link :to="{ name: 'EditSender', params: { id: sender.id }}">{{ sender.id }}</router-link>] · {{ sender.last_name }} {{ sender.first_name }} {{ sender.middle_name }}</span>
              </div>
              <div class="activity_volume_sender">
                <span class="weeks" v-if="sender.in_schedule"></span>
                <span class="volume" :class="sender.volume > 0 ? 'is': ''">{{ sender.volume | asPrice }}</span>
                <span class="balance" v-if="sender.balance"><b>{{ sender.balance | asPrice }}</b></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box_shadow schedule_list" style="padding: 10px">
        <p>Отправитель:</p>
        <div class="flx">
        <brew-select
          name="dropshipper"
          v-model="sender_id"
          class="vue-select select-search"
          :options="senders"
          :reduce="item => item.id"
          :clearable="false"
        >
          <template slot="option" slot-scope="option">
            <span></span>
            {{ option.first_name }} {{option.last_name}}
          </template>
          <template slot="selected-option" slot-scope="option">
            <span></span>
            {{ option.first_name }} {{option.last_name}}
          </template>
        </brew-select>
          </div>
        <calendar
          @update:from-page="testFN"
          :attributes="schedules"
          is-expanded
        >
          <template v-slot:day-content="{ day, attributes }">
            <div @click="setDay(day.id)" style="height: 100px" class="flex flex-col h-full z-10 overflow-hidden" >
              <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
              <div class="flex-grow overflow-y-auto overflow-x-auto">
                <p v-for="attr in attributes" :key="attr.id"
                   style="border-radius: 5px; padding: 3px"
                   :style="{background: schedules[attr.key].sender.color}"
                  class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                > {{schedules[attr.key].sender.first_name}} {{schedules[attr.key].sender.last_name}}</p>
              </div>
            </div>
          </template>

        </calendar>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { prices } from '../../../helpers/prices'
import _ from 'lodash'
import Calendar from 'v-calendar/lib/components/calendar.umd'

export default {
  name: 'SendersSchedule',
  mixins: [
    prices
  ],
  components: { Calendar },
  data () {
    return {
      date: new Date(),
      sender_id: null,
      timer: null,
      attrx: [{
        id: 1,
        sender_id: 1,
        dates: '2021-10-01'
      }]
    }
  },
  computed: {
    ...mapState('senders', ['schedules', 'senders', 'sender_filters', 'sender_schedule']),
    edit_senders: {
      get () {
        return this.senders
      },
      set (val) {
        this.setSenders(val)
      }
    },
    edit_schedules: {
      get () {
        return this.schedules
      },
      set (val) {
        this.setSchedules(val)
      }
    }
  },
  methods: {
    testX (v) {
      console.log(v)
    },
    testFN (val) {
      this.getSchedules({
        month: val.month,
        year: val.year
      })
    },
    setDay (date) {
      if (!this.sender_id) {
        return
      }

      this.setSchedule({
        sender_id: this.sender_id,
        date: date
      })
    },
    ...mapActions('senders', ['getSchedules', 'setSchedule', 'getSenders', 'updateSchedule', 'getSenderSchedule']),
    ...mapMutations('senders', ['setAllSender', 'setSenderPage', 'setSenders', 'setSchedules', 'setSenderSearch', 'setSenderInSchedule']),
    onChangeSchedule (event, schedule) {
      this.$nextTick(() => {
        this.updateSchedule(schedule)
      })
    },
    selectSender (e, sender) {
      sender.in_schedule = e.target.checked
      const index = this.edit_senders.findIndex(s => s.id === sender.id)
      this.$set(this.senders, index, sender)
      if (!e.target.checked) {
        const senderSchedules = _.filter(this.schedules, s => { return s.sender_id === sender.id })
        senderSchedules.forEach((senderSchedule) => {
          senderSchedule.sender_id = null
          senderSchedule.sender = null
          this.updateSchedule(senderSchedule)
        })
      }
    }
  },
  created () {
    this.setSenderPage(1)
    this.setAllSender()
    this.$nextTick(() => {
      this.getSchedules()
      this.getSenders()
        .then(data => {
          // this.getSenderSchedule()
        })
    })
  }
}
</script>

<style scoped>

</style>
