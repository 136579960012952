<template>
  <section class="table_box all_products inner">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Импорт товара</span>
      </div>
    </div>
    <div class="container">
      <div class="table_body">
        <div class="top_table">
          <input style="height: auto" type="file" @change="getFile" :disabled="is_load">
          <div v-if="is_load">Проверка файла: {{ validate_progress }}%
            <progress-bar bar-color="#F5A32A" :val="validate_progress" :text="validate_progress+'%'"/>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="table_body">
        <div class="top_table" v-if="is_import_finish">
          <h2>Импорт успешно завершен</h2>
        </div>
        <div class="top_table" v-if="Object.keys(import_data).length > 0">
          <h2>Данные импорта</h2>
          <div>
            <p>Будет созданно новых товаров: {{ import_data.new }} </p>
            <p>Будет обновлено товаров: {{ import_data.update }} </p>
            <div v-if="import_data['errors']">
              <br>
              <h2>Найдено ошибок: {{ import_data.errors.length }}</h2>
              <ul>
                <li
                  class="red"
                  v-for="(data,index) in import_data['errors']"
                  :key="index"
                >ID:{{ data.id }} - {{ data.error }}
                </li>
              </ul>
              <br>
              <h2>Дубликаты: {{import_data.duplicates.length}}</h2>
              <ul>
                <li
                    class="red"
                    v-for="(data,index) in import_data['duplicates']"
                    :key="index"
                >Line: {{ data[0] }} : {{ data[1] }}
                </li>
              </ul>
              <br>
              <div>
                <div v-if="!is_import" class="btn empty" @click="confirmImport({remains:update_remains, img:update_images, categories: update_categories})">Импортировать</div>
                <div v-if="!is_import" style="display: flex;margin-top: 10px">
                  <input v-model="update_categories" type="checkbox" style="width: auto; height: auto">
                  <span style="margin-right: 20px">Обновить категории</span>
                </div>
                <div v-if="!is_import" style="display: flex;">
                    <input v-model="update_remains" type="checkbox" style="width: auto; height: auto">
                    <span style="margin-right: 20px">Обновить остатки товара</span>
                </div>
                <div v-if="!is_import" style="display: flex">
                  <input v-model="update_images" type="checkbox" style="width: auto; height: auto">
                  <span style="margin-right: 20px">Загрузить изображения товара</span>
                </div>
                <div v-if="is_import" style="margin-top:20px;font-size: 16px">Импортированно: {{ import_progress }}%
                  <progress-bar bar-color="#F5A32A" :val="import_progress" :text="import_progress+'%'"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="count_images > 0">
      <div class="table_body">
        <div class="top_table">
          <h2>Загрузка изображенией</h2>
          <span>Осталось: <b>{{ count_images }}</b></span>
        </div>
      </div>
    </div>
    <div class="container" v-if="Object.keys(import_data).length > 0">
      <div class="table_body">
        <div class="top_table flx">
          <h1>Пример товаров из файла</h1>
        </div>
        <div class="bot_table">
          <div class="headline_table flx">
            <div class="img_prod">
              <p>Фото</p>
            </div>
            <div class="name_prod">
              <p>Название</p>
            </div>
            <div class="code_prod">
              <p>Артикул </p>
            </div>
            <div class="size_prod">
              <p>Размеры</p>
            </div>
            <div class="color_prod">
              <p>Цвет</p>
            </div>
            <div class="category_prod">
              <p>Категория</p>
            </div>
            <div class="price_prod">
              <p>Цена</p>
            </div>
            <div class="available_prod">
              <p>Наличие</p>
            </div>
          </div>
          <div class="row_list">
            <div class="row flx"
                 :key="product.id"
                 v-for="(product) in import_data.products"
            >
              <div class="img_prod">
                <img :src="product.img" alt="product">
              </div>
              <div class="name_prod flx">
                <span><b>{{ product.title }}</b></span>
              </div>
              <div class="code_prod">{{ product.article }}</div>
              <div class="size_prod">
                <span>{{ product.size }}</span>
              </div>
              <div class="color_prod">{{ product.color }}</div>
              <div class="category_prod"> {{ product.category_id }}</div>
              <div class="price_prod">{{ product.cost }} - {{ product.price_opt }}</div>
              <div>{{ product.count }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProgressBar from 'vue-simple-progress'

export default {
  name: 'ImportProducts',
  components: { ProgressBar },
  data () {
    return {
      file: null,
      update_remains: false,
      update_images: false,
      update_categories: false
    }
  },
  computed: {
    ...mapState('importProduct', [
      'import_data',
      'import_progress',
      'validate_progress',
      'is_load',
      'is_import',
      'is_import_finish',
      'count_images'
    ])
  },
  methods: {
    ...mapActions('importProduct', ['uploadFile', 'getData', 'confirmImport', 'getImageLoader']),
    getFile (event) {
      if (event.target.files[0]) {
        this.file = event.target.files[0]
        this.uploadFile(this.file)
      }
    }
  },
  created () {
    this.getData()
    this.getImageLoader()
  }
}
</script>

<style scoped>

</style>
