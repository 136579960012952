import Vue from 'vue'
import VueRouter from 'vue-router'
import SelectSupplier from '../components/Supply/Suppliers/SelectSupplier'
import Supply from '../components/Supply/Supply'
import EditWaybill from '../components/Supply/Waybills/EditWaybill'
import Waybill from '../components/Supply/Waybills/Waybill'
import Waybills from '../components/Supply/Waybills/Waybills'
import Auth from '../views/Auth.vue'
// import store from '../store'
import DashboardLayout from '@/views/Dashboard/DashboardLayout'
import Orders from '@/components/Orders/Orders'
import AllOrders from '@/components/Orders/Orders/AllOrders'
import Clients from '@/components/Orders/Clients/Clients'
import ShowClient from '../components/Orders/Clients/ShowClient'
import Dropshipping from '@/components/Droppshipping/Dropshipping'
import Products from '@/components/Droppshipping/Products/Products'
import Categories from '@/components/Droppshipping/Categories/Categories'
import Dropshippers from '@/components/Droppshipping/Dropshippers/Dropshippers'
import ShowCategory from '@/components/Droppshipping/Categories/ShowCategory'
import CreateProduct from '@/components/Droppshipping/Products/CreateProduct'
import EditProduct from '@/components/Droppshipping/Products/EditProduct'
import ShowProduct from '@/components/Droppshipping/Products/ShowProduct'
import ImportProducts from '@/components/Droppshipping/Import/ImportProducts'
import CreateDropshipper from '../components/Droppshipping/Dropshippers/CreateDropshipper'
import EditDropshipper from '../components/Droppshipping/Dropshippers/EditDropshipper'
import ImportClients from '../components/Orders/Import/ImportClients'
import Administration from '../components/Administration/Administration'
import Users from '../components/Administration/Users/Users'
import CreateUser from '../components/Administration/Users/CreateUser'
import EditUser from '../components/Administration/Users/EditUser'
import User from '../components/Administration/Users/User'
import Senders from '../components/Administration/Senders/Senders'
import CreateSender from '../components/Administration/Senders/CreateSender'
import EditSender from '../components/Administration/Senders/EditSender'
import SendersSchedule from '../components/Administration/Senders/SendersSchedule'
import Roles from '../components/Administration/Roles/Roles'
import CreateRole from '../components/Administration/Roles/CreateRole'
import EditRole from '../components/Administration/Roles/EditRole'
import Role from '../components/Administration/Roles/Role'
import Suppliers from '../components/Administration/Suppliers/Suppliers'
import CreateSupplier from '../components/Administration/Suppliers/CreateSupplier'
import EditSupplier from '../components/Administration/Suppliers/EditSupplier'
import Supplier from '../components/Administration/Suppliers/Supplier'
import CreateOrder from '../components/Orders/Orders/CreateOrder'
import Order from '../components/Orders/Orders/Order'
import EditOrder from '../components/Orders/Orders/EditOrder'
import NotFoundPage from '../components/NotFoundPage'
import Calculations from '../components/Calculations/Calculations'
import CalcDropshippers from '../components/Calculations/Dropshippers/CalcDropshippers'
import CalcOrders from '../components/Calculations/Orders/CalcOrders'
import DropshipperOrders from '../components/Calculations/Dropshippers/DropshipperOrders'
import OrderItemList from '@/components/OrderItemList'
import Statistics from '../components/Statistics'
import Contents from '../components/Administration/Contents/Contents'
import CreateContent from '../components/Administration/Contents/CreateContent'
import UpdateContent from '../components/Administration/Contents/UpdateContent'
import Banners from '../components/Administration/Banners/Banners'
import CreateBanner from '../components/Administration/Banners/CreateBanner'
import UpdateBanner from '../components/Administration/Banners/UpdateBanner'
import PrintWaybill from '../components/PrintWaybill'
import CheckList from '../components/Administration/Checkbox/CheckList'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/login',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/order-item-list',
    name: 'OrderItemList',
    component: OrderItemList
  },
  {
    path: '/print-waybill/:id',
    name: 'PrintWaybill',
    component: PrintWaybill
  },
  {
    path: '/dashboard',
    redirect: { path: '/dashboard/orders' },
    name: 'Dashboard',
    component: DashboardLayout,
    children: [
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        redirect: { path: 'orders/orders' },
        children: [
          {
            path: 'import-clients',
            name: 'ImportClients',
            component: ImportClients
          },
          {
            path: 'orders',
            name: 'AllOrders',
            component: AllOrders,
            meta: {
              model: 'order',
              permission: 'view'
            }
          },
          {
            path: 'orders/create',
            name: 'CreateOrder',
            component: CreateOrder
          },
          {
            path: 'orders/:id',
            name: 'Order',
            component: Order
          },
          {
            path: 'orders/edit/:id',
            name: 'EditOrder',
            component: EditOrder
          },
          {
            path: 'clients',
            name: 'Clients',
            component: Clients
          },
          {
            path: 'clients/:id',
            name: 'ShowClient',
            component: ShowClient
          }
        ]
      },
      {
        path: 'dropshipping',
        redirect: { path: 'dropshipping/products' },
        name: 'Dropshipping',
        component: Dropshipping,
        children: [
          {
            path: 'import',
            name: 'ImportProducts',
            component: ImportProducts
          },
          {
            path: 'products',
            name: 'Products',
            component: Products
          },
          {
            path: 'products/create',
            name: 'CreateProduct',
            component: CreateProduct
          },
          {
            path: 'products/:id',
            name: 'ShowProduct',
            component: ShowProduct
          },
          {
            path: 'products/edit/:id',
            name: 'EditProduct',
            component: EditProduct
          },
          {
            path: 'categories',
            name: 'Categories',
            component: Categories
          },
          {
            path: 'categories/:id',
            name: 'ShowCategory',
            component: ShowCategory
          },
          {
            path: 'dropshippers',
            name: 'Dropshippers',
            component: Dropshippers
          },
          {
            path: 'dropshippers/create',
            name: 'CreateDropshipper',
            component: CreateDropshipper
          },
          {
            path: 'dropshippers/edit/:id',
            name: 'EditDropshipper',
            component: EditDropshipper
          }
        ]
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: Statistics
      },
      {
        path: 'administration',
        redirect: { path: 'administration/users' },
        name: 'Administration',
        component: Administration,
        children: [
          {
            path: 'users',
            name: 'Users',
            component: Users
          },
          {
            path: 'users/create',
            name: 'CreateUser',
            component: CreateUser
          },
          {
            path: 'users/edit/:id',
            name: 'EditUser',
            component: EditUser
          },
          {
            path: 'users/:id',
            name: 'User',
            component: User
          },
          {
            path: 'senders',
            name: 'Senders',
            component: Senders
          },
          {
            path: 'senders/create',
            name: 'CreateSender',
            component: CreateSender
          },
          {
            path: 'senders/edit/:id',
            name: 'EditSender',
            component: EditSender
          },
          {
            path: 'senders/schedule',
            name: 'SendersSchedule',
            component: SendersSchedule
          },
          {
            path: 'roles',
            name: 'Roles',
            component: Roles
          },
          {
            path: 'roles/create',
            name: 'CreateRole',
            component: CreateRole
          },
          {
            path: 'roles/edit/:id',
            name: 'EditRole',
            component: EditRole
          },
          {
            path: 'roles/:id',
            name: 'Role',
            component: Role
          },
          {
            path: 'suppliers',
            name: 'Suppliers',
            component: Suppliers
          },
          {
            path: 'suppliers/create',
            name: 'CreateSupplier',
            component: CreateSupplier
          },
          {
            path: 'suppliers/edit/:id',
            name: 'EditSupplier',
            component: EditSupplier
          },
          {
            path: 'suppliers/:id',
            name: 'Supplier',
            component: Supplier
          },
          {
            path: 'contents',
            name: 'Contents',
            component: Contents
          },
          {
            path: 'contents/create',
            name: 'CreateContent',
            component: CreateContent
          },
          {
            path: 'contents/:id',
            name: 'UpdateContent',
            component: UpdateContent
          },
          {
            path: 'banners',
            name: 'Banners',
            component: Banners
          },
          {
            path: 'banners/create',
            name: 'CreateBanner',
            component: CreateBanner
          },
          {
            path: 'banners/:id',
            name: 'UpdateBanner',
            component: UpdateBanner
          },
          {
            path: 'checkbox',
            name: 'Checks',
            component: CheckList
          }
        ]
      },
      {
        path: 'calculations',
        name: 'Calculations',
        redirect: { path: 'calculations/dropshippers' },
        component: Calculations,
        children: [
          {
            path: 'dropshippers',
            name: 'CalcDropshippers',
            component: CalcDropshippers
          },
          {
            path: 'dropshippers/:id',
            name: 'DropshipperOrders',
            component: DropshipperOrders
          },
          {
            path: 'orders',
            name: 'CalcOrders',
            component: CalcOrders
          }
        ]
      },
      {
        path: 'supply',
        name: 'Supply',
        redirect: { path: 'supply/suppliers' },
        component: Supply,
        children: [
          {
            path: 'suppliers',
            name: 'SelectSupplier',
            component: SelectSupplier
          },
          {
            path: 'suppliers/:id/waybills',
            name: 'Waybills',
            component: Waybills
          },
          {
            path: 'suppliers/:id/waybills/:waybill_id',
            name: 'Waybill',
            component: Waybill
          },
          {
            path: 'suppliers/:id/create-waybill',
            name: 'CreateWaybill',
            component: EditWaybill
          },
          {
            path: 'suppliers/:id/edit-waybill/:waybill_id',
            name: 'EditWaybill',
            component: EditWaybill
          }
        ]
      },
      {
        path: '*',
        name: 'NotFound',
        component: NotFoundPage
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active'
})
// function requireAuth (to, from, next) {
//   proceed()
//   store.watch(() => store.getters['catalog/getAuthUserPermissions'], function () {
//     if (!store.getters['catalog/getAuthUserPermissions']) {
//       proceed()
//     } else {
//       if (to.meta.model && to.meta.permission) {
//         if (!store.getters['catalog/userHasPermission'](to.meta.model, to.meta.permission)) {
//           console.log('permission denied')
//           next(`/dashboard/${to.meta.model}s/denied`)
//         }
//       } else {
//         next()
//       }
//     }
//   })
//   function proceed () {
//     if (!store.getters['catalog/getAuthUserPermissions']) {
//       store.dispatch('catalog/getAuthUser')
//     }
//   }
// }
export default router
