<template>
  <section class="table_box all_roles">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Все роли пользователей
        <p v-if="pagination.from">Результат: {{pagination.from}} - {{pagination.to}} из {{pagination.total}}</p>
        </span>
      </div>
      <div class="wrap_btn flx">
        <router-link class="btn create" :to="{name: 'CreateRole'}">Новая роль</router-link>
      </div>
    </div>
    <div class="container has_table">
      <div class="table_body table_all_roles">
        <div class="top_table flx">
          <div class="wrap_search flx">
            <button type="submit"></button>
            <input type="text" v-model="search" placeholder="Поиск..." id="search">
            <ul class="search_list_box">
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
            </ul>
          </div>
          <div class="pagination flx">
            <div class="flx">
              <p class="desctop_v">Строк:</p>
              <brew-select
                  class="select-pagination select-users-per_page"
                  style="margin-left:10px;width: 100px"
                  v-model="perPage"
                  :clearable="false"
                  :options="page_sizes"
              >
              </brew-select>
            </div>
            <paginate
                style="margin-left: 20px"
                class="flx"
                v-model="page"
                :page-count="pagination.last_page"
                :page-range="3"
                :margin-pages="2"
                :prev-text="'<'"
                :next-text="'>'"
            >
            </paginate>
          </div>
        </div>
        <div class="bot_table">
          <headline :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
          <div class="row_list">
            <div class="row flx" v-for="role in roles" :key="'role-' + role.id" >
              <div class="id_role">
                <router-link :to="{name: 'Role', params: { id: role.id }}">{{ role. id }}</router-link>
              </div>
              <div class="name_role">
                <p><b>{{ role.name }}</b></p>
              </div>
              <div class="date_of_creation_role">
                <span>{{ role.date }}</span>
              </div>
              <div class="icon_wrap_role flx">
                <router-link :to="{name: 'EditRole', params: { id: role.id }}" class="edit_ico">
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.07755C0 2.05297 0.830588 1.22238 1.85517 1.22238H5.88881C6.2769 1.22238 6.59151 1.53699 6.59151 1.92508C6.59151 2.31317 6.2769 2.62778 5.88881 2.62778H1.85517C1.60677 2.62778 1.4054 2.82915 1.4054 3.07755V11.1448C1.4054 11.3932 1.60677 11.5946 1.85517 11.5946H9.92245C10.1709 11.5946 10.3722 11.3932 10.3722 11.1448V7.11119C10.3722 6.7231 10.6868 6.40849 11.0749 6.40849C11.463 6.40849 11.7776 6.7231 11.7776 7.11119V11.1448C11.7776 12.1694 10.947 13 9.92245 13H1.85517C0.830588 13 0 12.1694 0 11.1448V3.07755Z" fill="#ADB8C6"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4424 1.55761C11.2394 1.35467 10.9104 1.35467 10.7074 1.55761L5.37082 6.89421L5.12585 7.87412L6.10576 7.62914L11.4424 2.29255C11.6453 2.0896 11.6453 1.76056 11.4424 1.55761ZM9.71365 0.563843C10.4654 -0.187948 11.6843 -0.187948 12.4361 0.563843C13.1879 1.31563 13.1879 2.53453 12.4361 3.28632L6.9619 8.76054C6.87184 8.8506 6.759 8.91449 6.63544 8.94538L4.33051 9.52161C4.09104 9.58148 3.83773 9.51131 3.66319 9.33678C3.48865 9.16224 3.41849 8.90893 3.47835 8.66946L4.05459 6.36452C4.08548 6.24097 4.14937 6.12813 4.23942 6.03807L9.71365 0.563843Z" fill="#ADB8C6"/>
                  </svg>
                </router-link>
                <span class="close" @click="deleteRole(role)" v-if="!role.is_system"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { dates } from '../../../helpers/dates'
import Headline from '../../Helpers/Headline'
export default {
  name: 'Roles',
  mixins: [
    dates
  ],
  components: {
    Headline
  },
  data () {
    return {
      page_sizes: [10, 50, 100, 1000],
      timer: null,
      headers: [
        {
          title: 'ID',
          sortable: true,
          class: 'id_role',
          value: 'id'
        },
        {
          title: 'Название',
          class: 'name_role',
          sortable: true,
          value: 'name'
        },
        {
          title: 'Дата создания',
          class: 'date_of_creation_role',
          sortable: true,
          value: 'created_at'
        },
        {
          title: '',
          class: 'icon_wrap_role'
        }
      ]
    }
  },
  computed: {
    ...mapState('roles', ['roles', 'role_filters', 'pagination']),
    filters: {
      get () {
        return this.role_filters
      },
      set (val) {
        this.setRoleFilters(val)
      }
    },
    page: {
      get: function () {
        return this.role_filters.page
      },
      set: function (value) {
        this.setRolePage(value)
        this.getRoles()
      }
    },
    perPage: {
      get () {
        return this.role_filters.per_page
      },
      set (perPage) {
        this.setRolePerPage(perPage)
        this.setRolePage(1)
        this.getRoles()
      }
    },
    search: {
      get: function () {
        return this.role_filters.search
      },
      set: function (value) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.setRolePage(1)
          this.setRoleSearch(value)
          this.getRoles()
        }, 1000)
      }
    },
    sortBy: {
      get () {
        return this.role_filters.order_by
      },
      set (val) {
        this.setOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.role_filters.order_direction
      },
      set (val) {
        this.setOrderDirection(val)
      }
    }
  },
  watch: {
    sortBy () {
      this.getRoles()
    },
    sortDesc () {
      this.getRoles()
    }
  },
  methods: {
    ...mapActions('roles', ['getRoles']),
    ...mapMutations('roles', ['setRoleFilters', 'clearRoleFilters', 'setRolePage', 'setRolePerPage', 'setRolePagination', 'setRoleSearch', 'setRoleOnDelete', 'setShowRoleDeleteModal', 'setOrderBy', 'setOrderDirection']),
    deleteRole (role) {
      this.setRoleOnDelete(role)
      this.setShowRoleDeleteModal(true)
    }
  },
  created () {
    this.clearRoleFilters()
    this.getRoles()
  }
}
</script>

<style scoped>

</style>
