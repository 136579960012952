<template>
  <div class="seller_form flx box_shadow">
    <span class="page_subtitle">Продавец</span>
    <p>Баланс: <span class="balance" :class="getColorBalance(order_data.dropshipper.balance)"
                     v-if="order_data.dropshipper">{{ order_data.dropshipper.balance | asPrice }}</span></p>
    <div style="width: 100%;">
      <user-select
        :class="{required: hasError('dropshipper_id')}"
        :default-id="order_data.dropshipper_id"
        :default-name="order_data.dropshipper ? order_data.dropshipper.name : ''"
        :items="dropshippers"
        @search="fetchDropshippers"
        @select="selectDropshipper"
        @active-input="fetchDropshippers"
      ></user-select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import helper from '../../../../helpers/helper'
import { prices } from '../../../../helpers/prices'
import UserSelect from '../../../Helpers/UserSelect'

let timer

export default {
  name: 'OrderDropshipper',
  components: { UserSelect },
  mixins: [
    prices
  ],
  computed: {
    ...mapState('orders', ['order']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapState('users', ['dropshippers']),
    order_data: {
      get () {
        return this.order
      },
      set (val) {
        this.setOrder(val)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    },
    disabled_field () {
      // return false
      return this.order_data.ttn !== null || this.order_data.delivery_status > 1 || this.order_data.status > 1 || this.order_data.id
    }
  },
  watch: {
    // 'order_data.dropshipper' () {
    //   this.order_data.dropshipper_id = this.order_data.dropshipper ? this.order_data.dropshipper.id : null
    //   this.getProductList()
    // },
    'order_data.dropshipper_id' () {
      this.getProductList()
    }
  },
  methods: {
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapMutations('users', ['setDropshipperPerPage', 'setSearch']),
    ...mapActions('users', ['getDropshippers']),
    ...mapActions('orders', ['getProductList', 'setDropshipperInOrder']),
    getColorBalance (balance) {
      return helper.getColorBalance(balance)
    },
    selectDropshipper (data) {
      this.order_data.dropshipper_id = data.id
      this.setDropshipperInOrder()
    },
    fetchDropshippers (search) {
      this.setSearch(search)
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.getDropshippers()
      }, 500)
    }
  },
  created () {
    this.setDropshipperPerPage(20)
    this.$nextTick(() => {
      this.getDropshippers()
      this.clearErrors()
    })
  }
}
</script>

<style scoped>

</style>
