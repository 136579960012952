import axios from 'axios'
import { eventBus } from '@/main'
const baseUrl = 'api/stocks'

export default {
  namespaced: true,
  state: {
    stock_data: {
      id: null,
      model_id: null,
      model_type: null,
      discount: null,
      discount_type: '%',
      text: null,
      expiration_from_at: null,
      expiration_to_at: null,
      view_in_home: null
    }
  },
  mutations: {
    clearStockData (state) {
      state.stock_data = {
        id: null,
        model_id: null,
        model_type: null,
        discount: null,
        discount_type: '%',
        text: null,
        expiration_from_at: null,
        expiration_to_at: null,
        view_in_home: null
      }
    },
    setStockData (state, data) {
      console.log('set data', data)
      state.stock_data = data
    },
    setDiscount (state, data) {
      state.stock_data.discount = data
    },
    setDiscountType (state, data) {
      state.stock_data.discount_type = data
    },
    setText (state, data) {
      state.stock_data.text = data
    },
    setModelId (state, modelId) {
      state.stock_data.model_id = modelId
    },
    setModelType (state, modelType) {
      state.stock_data.model_type = modelType
    },
    setExpiration (state, date) {
      state.stock_data.expiration_from_at = date[0]
      state.stock_data.expiration_to_at = date[1]
    },
    setViewInHome (state, value) {
      state.stock_data.view_in_home = value
    }
  },
  actions: {
    createStock ({ state, commit }) {
      axios.post(baseUrl, state.stock_data).then(response => {
        commit('setStockData', response.data.data)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Сохранено'
        )
      })
    },
    getStock ({ state, commit }, id) {
      axios.get(baseUrl + '/' + id, {
      }).then(response => {
        commit('setStockData', response.data.data)
      })
    }
  }
}
