<template>
  <div class="auto-select" v-click-outside-element="close">
    <div
      class="auto-select-view"
      style="display: flex;justify-content: space-between"
      v-show="!magic_flag"
      @click="editMode()"
    >
      <div class="item-select-name">
        <span v-if="id">[<a @click.stop="routeTo()">{{ id }}</a>] - </span>
        {{ name }}
      </div>
      <span class="auto-select-clear" @click="clear">x</span>
    </div>
    <input
      ref="input_auto_select"
      v-show="magic_flag"
      v-model="name"
      type="text"
      @focus="activeInput"
      @input="search"
    >
    <span v-show="magic_flag" class="auto-select-close" @click="clear">x</span>

    <div v-if="magic_flag" class="auto-select-results">
      <div class="auto-select-item"
           @click="selectItem({id:item[idName], name:item[valueName]})"
           v-for="(item,index) in localItems"
           :key="index">
        [{{ item[idName] }}] - {{ item[valueName] }}
      </div>
    </div>
  </div>
</template>

<script>

import router from '../../router'
import _ from 'lodash'

export default {
  name: 'AutoSelect',
  props: {
    defaultId: Number,
    defaultName: String,
    items: Array,
    idName: {
      type: String,
      default: 'id'
    },
    valueName: {
      type: String,
      default: 'name'
    },
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show_input: false,
      magic_flag: false,
      id: null,
      name: null,
      localItems: []
    }
  },
  watch: {
    defaultId: {
      immediate: true,
      handler (val, oldVal) {
        this.id = val
      }
    },
    defaultName: {
      immediate: true,
      handler (val, oldVal) {
        this.name = val
      }
    },
    items: {
      immediate: true,
      handler (val, oldVal) {
        this.localItems = _.filter(val, s => { return s.id !== this.id })
      }
    }
  },
  methods: {
    editMode () {
      this.magic_flag = true
      this.$nextTick(() => {
        this.$refs.input_auto_select.focus()
      })
    },
    search () {
      this.id = null
      this.magic_flag = true
      this.show_input = true
      this.$emit('search', this.name)
    },
    selectItem (data) {
      this.id = data.id
      this.name = data.name
      this.magic_flag = false
      this.show_input = false
      this.$emit('select', {
        id: data.id,
        name: data.name
      })
    },
    clear () {
      this.magic_flag = false
      this.show_input = false
      this.id = null
      this.name = null
      if (this.isFilter) {
        this.name = 'Все'
      }

      this.$emit('select', {
        id: null,
        name: null
      })
    },
    activeInput () {
      this.magic_flag = true
      this.show_input = true

      if (this.id == null) {
        this.name = null
      }

      this.$emit('active-input', this.name)
    },
    close () {
      if (this.id === null) {
        // this.clear()
      }
      this.magic_flag = false
    },
    routeTo () {
      router.push({ name: 'EditDropshipper', params: { id: this.id } })
    }
  }
}
</script>

<style scoped>
.auto-select {
  position: relative;
}

.auto-select-results {
  position: absolute;
  display: block;
  background: white;
  width: 100%;
  max-height: 160px;
  overflow: auto;
  border: 1px solid #E4EBF6;
  margin-top: -2px;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 101;
}

.auto-select-item {
  padding: 5px 10px;
}

.auto-select-item:hover {
  background: #F5A32A;
  color: white;
}

.auto-select-close {
  position: absolute;
  margin-left: -31px;
  top: 0;
  padding: 10px;
  display: inline;
}

input {
  margin-bottom: 0 !important;
}

.auto-select-view {
  display: block;
  border: 1px solid #e4ebf6;
  border-radius: 5px;
  padding: 8px 10px;
  background: white;
}

.required > .auto-select-view {
  border: 1px solid #ff8787;
}

.mini-select {
  width: 280px;
}

.mini-select > .auto-select-view {
  padding: 2px 10px;
}

.mini-select > input {
  height: 24px !important;
}

.mini-select > .auto-select-close {
  padding: 3px 13px;
}

.item-select-name {
  text-overflow: ellipsis;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
}
.item-select-name span {
  display: inline!important;
}
.auto-select-clear {
  display: inline;
}

</style>
