<template>
  <div class="auto-select" v-click-outside-element="close">

    <div class="multi-bag" v-for="user in usersItems" :key="user.id">
      <span class="item-select-name">[{{user.id}}] - {{user.name}}</span>
      <span @click="removeItem(user)">x</span>
    </div>

    <input
      style="margin-top: 5px"
      ref="input_auto_select"
      v-model="name"
      type="text"
      @focus="activeInput"
      @input="search"
    >

    <div v-if="magic_flag" class="auto-select-results">
      <div class="auto-select-item"
           @click="selectItem({id:item['id'], name:item['name']})"
           v-for="(item,index) in dropshippers"
           :key="index">
        [{{ item.id }}] - {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import _ from 'lodash'
export default {
  name: 'MultiSelect',
  props: {
    clearValues: Boolean,
    values: {
      type: Array
    },
    items: Array
  },
  computed: {
    ...mapState('users', ['dropshippers'])
  },
  data () {
    return {
      show_input: false,
      magic_flag: false,
      usersItems: [],
      name: ''
    }
  },
  watch: {
    clearValues: {
      immediate: true,
      handler (val, oldVal) {
        this.usersItems.splice(0)
      }
    }
  },
  methods: {
    ...mapActions('users', ['getDropshippersForSelect']),
    ...mapMutations('users', ['setSearch']),
    search () {
      this.magic_flag = true
      this.show_input = true
      this.setSearch(this.name)
      this.getDropshippersForSelect()
    },
    selectItem (data) {
      this.usersItems.push(data)
      this.usersItems = _.uniqBy(this.usersItems, function (e) {
        return e.id
      })

      const ids = _.map(this.usersItems, 'id')

      this.$emit('users-list-changes', ids)
    },
    clear () {
    },
    activeInput () {
      this.magic_flag = true
      this.show_input = true
    },
    close () {
      this.magic_flag = false
    },
    removeItem (user) {
      this.usersItems = _.filter(this.usersItems, s => { return s.id !== user.id })
      const ids = _.map(this.usersItems, 'id')
      this.$emit('users-list-changes', ids)
    }
  }
}
</script>

<style scoped>
.auto-select {
  position: relative;
}

.auto-select-results {
  position: absolute;
  display: block;
  background: white;
  width: 100%;
  max-height: 260px;
  overflow: auto;
  border: 1px solid #E4EBF6;
  margin-top: -2px;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 101;
}

.auto-select-item {
  padding: 5px 10px;
}

.auto-select-item:hover {
  background: #F5A32A;
  color: white;
}

.auto-select-close {
  position: absolute;
  margin-left: -31px;
  top: 0;
  padding: 10px;
}

input {
  margin-bottom: 0 !important;
}

.auto-select-view {
  display: block;
  border: 1px solid #e4ebf6;
  border-radius: 5px;
  padding: 8px 10px;
  background: white;
}

.required > .auto-select-view {
  border: 1px solid #ff8787;
}

.mini-select {
  width: 260px;
}

.mini-select > .auto-select-view {
  padding: 2px 10px;
}

.mini-select > input {
  height: 24px !important;
}

.mini-select > .auto-select-close {
  padding: 3px 13px;
}

.item-select-name {
  text-overflow: ellipsis;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
}

.multi-bag {
  background: #7e7e7e;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
}

.multi-bag > span {
  color: white;
}

</style>
