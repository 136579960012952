<template>
  <div class="popup_box" :class="{ active: show_history_modal }" data-id="history_change" @click.self="toggleHistoryModal">
    <div class="popup history_change" style="width: 860px" >
      <span class="page_title">
        <slot name="title">История изменений</slot>
      </span>
      <span class="close_popup" @click="toggleHistoryModal"></span>
      <div class="row_list">
        <div v-for="(item, index) in data" class="row flx" :key="index">
          <div class="who_change" style="width: 200px">
            <p>Изменил:</p>
            <span><b>{{ item.causer }}</b> [{{ item.causer_id }}]</span>
          </div>
          <div class="when_change">
            <span>{{ item.date }}</span>
            <span class="time">{{ item.time }}</span>
          </div>
          <div class="result_change">
            <span :class="{changed: item.change}"> {{item.action}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'HistoryModal',
  props: ['data'],
  computed: {
    ...mapState('history', ['show_history_modal', 'history'])
  },
  methods: {
    ...mapActions('history', ['toggleHistoryModal'])
  }
}
</script>

<style scoped>
 .changed {
   color: orangered!important;
 }
</style>
