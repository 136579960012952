<template>
  <section class="table_box invoices_table">
    <div class="container large flx">
      <div class="table_head flx">
        <span class="page_title">Накладные поставщика ID: {{ supplier.id }}
        <p v-if="pagination.from">Результат: {{pagination.from}} - {{pagination.to}} из {{pagination.total}}</p>
        </span>
        <span class="item" v-if="supplier.balance < 0">Задолженость: <span class="red"><b>{{ supplier.balance | asPrice }}</b></span></span>
      </div>
      <div class="wrap_btn flx">
        <div class="btn open_pop" data-id="add_payment" @click="addPayment">Добавить платёж</div>
        <span class="point"></span>
        <div class="btn actions_btn empty" data-id="filters_box" :class="{ active: show_inc_filters}" @click="toggleIncFilters">
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3333 1H1L6.33333 7.30667V11.6667L9 13V7.30667L14.3333 1Z" stroke="#F5A32A" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Фильтры</span>
          <span class="counter_filters" v-if="count_inc_filters > 0">{{ count_inc_filters }}</span>
        </div>
        <router-link class="btn create" :to="{ name: 'CreateWaybill', params: { id: supplier_id }}">Новая накладная</router-link>
      </div>
      <waybill-filters :supplier_id="supplier_id"></waybill-filters>
    </div>
    <div class="container has_table">
      <div class="table_body">
        <div class="top_table flx">
          <div class="wrap_search flx">
            <button type="submit"></button>
            <input type="text" placeholder="Поиск..." id="search" v-model="search">
            <ul class="search_list_box">
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
            </ul>
          </div>
          <div class="pagination flx">
            <div class="flx">
              <p class="desctop_v">Строк:</p>
              <div class="select" id="sellers">
                <brew-select
                    class="select-pagination select-orders-per_page select-full"
                    style="margin-left:10px;width: 100px"
                    v-model="perPage"
                    :clearable="false"
                    :options="page_sizes"
                >
                </brew-select>
              </div>
            </div>
            <paginate
                style="margin-left: 20px"
                class="flx"
                v-model="page"
                :page-count="pagination.last_page"
                :page-range="3"
                :margin-pages="2"
                :prev-text="'<'"
                :next-text="'>'"
            >
            </paginate>
          </div>
        </div>
        <div class="bot_table">
          <headline :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
          <div class="row_list">
            <div
              class="row flx invoice"
              :class="getIncInvoiceClass(invoice.total)"
              v-for="invoice in inc_invoices"
              :key="'inv'+invoice.id"
              @click="routeTo(supplier_id,invoice.id)"
            >
              <div class="number_doc">
                <router-link :to="{ name: 'Waybill', params: { id: supplier_id, waybill_id: invoice.id}}">{{ invoice.id }}</router-link>
              </div>
              <div class="date_doc">
                <span><b>{{ invoice.received_at }}</b></span>
              </div>
              <div class="quantity_item_doc">
                <span v-if="invoice.product_count">{{ invoice.product_count }}</span>
              </div>
              <div class="manager_doc">
                <span>[<router-link :to="{ name: 'User', params: { id: invoice.created_id }}">{{ invoice.created_id }}</router-link>]</span>
                <span v-if="invoice.created_by">{{ invoice.created_by.name | abbreviated }}</span>
              </div>
              <div class="sum_doc">
                <span :class="getColorBalance(invoice.total)"><b>{{ invoice.total | asPrice }}</b></span>
              </div>
              <div class="comment_doc flx"  @click.stop>
                <p v-if="invoice.comment">{{ invoice.comment }}</p>
                <p v-else>Без комментария</p>
                <div @click="showEditComment(invoice)">
                  <svg class="edit_ico" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.07755C0 2.05297 0.830588 1.22238 1.85517 1.22238H5.88881C6.2769 1.22238 6.59151 1.53699 6.59151 1.92508C6.59151 2.31317 6.2769 2.62778 5.88881 2.62778H1.85517C1.60677 2.62778 1.4054 2.82915 1.4054 3.07755V11.1448C1.4054 11.3932 1.60677 11.5946 1.85517 11.5946H9.92245C10.1709 11.5946 10.3722 11.3932 10.3722 11.1448V7.11119C10.3722 6.7231 10.6868 6.40849 11.0749 6.40849C11.463 6.40849 11.7776 6.7231 11.7776 7.11119V11.1448C11.7776 12.1694 10.947 13 9.92245 13H1.85517C0.830588 13 0 12.1694 0 11.1448V3.07755Z" fill="#ADB8C6"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4424 1.55761C11.2394 1.35467 10.9104 1.35467 10.7074 1.55761L5.37082 6.89421L5.12585 7.87412L6.10576 7.62914L11.4424 2.29255C11.6453 2.0896 11.6453 1.76056 11.4424 1.55761ZM9.71365 0.563843C10.4654 -0.187948 11.6843 -0.187948 12.4361 0.563843C13.1879 1.31563 13.1879 2.53453 12.4361 3.28632L6.9619 8.76054C6.87184 8.8506 6.759 8.91449 6.63544 8.94538L4.33051 9.52161C4.09104 9.58148 3.83773 9.51131 3.66319 9.33678C3.48865 9.16224 3.41849 8.90893 3.47835 8.66946L4.05459 6.36452C4.08548 6.24097 4.14937 6.12813 4.23942 6.03807L9.71365 0.563843Z" fill="#ADB8C6"></path>
                  </svg>
                </div>
              </div>
              <div class="print_doc" @click.stop>
                <div class="btn empty" @click="printInvoice(invoice)">Печать</div>
              </div>
              <div class="attached_file" @click.stop>
                <a :href="invoice.url" download v-if="invoice.url">
                  <span class="clip_ico"></span>
                  <span>{{ invoice.file_name | truncate(15) }}</span>
                </a>
                <attach-doc-file :invoice_id="invoice.id" v-else></attach-doc-file>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-supplier-payment-modal @payment-created="getIncomingInvoices"></add-supplier-payment-modal>
    <ChangeWaybillCommentModal @invoice-changed="getIncomingInvoices"></ChangeWaybillCommentModal>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import helper from '../../../helpers/helper'
import { prices } from '../../../helpers/prices'
import { strings } from '../../../helpers/strings'
import WaybillFilters from './WaybillFilters'
import AddSupplierPaymentModal from '../Suppliers/AddSupplierPaymentModal'
import Headline from '../../Helpers/Headline'
import ChangeWaybillCommentModal from './ChangeWaybillCommentModal'
import AttachDocFile from './AttachDocFile'
import router from '../../../router'
export default {
  name: 'Waybills',
  components: {
    WaybillFilters,
    AddSupplierPaymentModal,
    Headline,
    ChangeWaybillCommentModal,
    AttachDocFile
  },
  mixins: [prices, strings],
  data () {
    return {
      timer: null,
      page_sizes: [10, 50, 100, 1000],
      headers: [
        {
          title: 'ID',
          sortable: true,
          class: 'number_doc',
          value: 'id'
        },
        {
          title: 'Дата',
          sortable: true,
          class: 'date_doc',
          value: 'received_at'
        },
        {
          title: 'кол-во товаров',
          class: 'quantity_item_doc',
          sortable: true,
          value: 'product_count'
        },
        {
          title: 'менеджер',
          sortable: true,
          class: 'manager_doc',
          value: 'created_id'
        },
        {
          title: 'Итоговая Сумма',
          sortable: true,
          class: 'sum_doc',
          value: 'total'
        },
        {
          title: 'Комментарий',
          class: 'comment_doc'
        },
        {
          title: '',
          class: 'print_doc'
        },
        {
          title: 'Прикрепленный файл',
          class: 'attached_file'
        }
      ]
    }
  },
  computed: {
    ...mapState('incoming', ['inc_invoices', 'show_inc_filters', 'inc_invoice_filters', 'pagination']),
    ...mapState('suppliers', ['supplier', 'show_add_supplier_payment_modal']),
    ...mapGetters('incoming', ['count_inc_filters']),
    supplier_id () {
      return parseInt(this.$route.params.id) || null
    },
    page: {
      get () {
        return this.inc_invoice_filters.page
      },
      set (value) {
        this.setPage(value)
        this.getIncomingInvoices()
      }
    },
    perPage: {
      get () {
        return this.inc_invoice_filters.per_page
      },
      set (perPage) {
        this.setPerPage(perPage)
        this.setPage(1)
        this.getIncomingInvoices()
      }
    },
    search: {
      get () {
        return this.inc_invoice_filters.search
      },
      set (value) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.setPage(1)
          this.setSearch(value)
          this.getIncomingInvoices()
        }, 1000)
      }
    },
    sortBy: {
      get () {
        return this.inc_invoice_filters.order_by
      },
      set (val) {
        this.setOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.inc_invoice_filters.order_direction
      },
      set (val) {
        this.setOrderDirection(val)
      }
    }
  },
  watch: {
    sortBy () {
      this.getIncomingInvoices()
    },
    sortDesc () {
      this.getIncomingInvoices()
    }
  },
  methods: {
    ...mapActions('incoming', ['getIncomingInvoices', 'toggleIncFilters', 'toggleChangeComment']),
    ...mapActions('suppliers', ['getSupplier']),
    ...mapMutations('incoming', ['setOrderBy', 'setOrderDirection', 'setPage', 'setPerPage', 'setSearch', 'setIncInvoiceFilters', 'clearIncInvoiceFilters', 'setSupplierId', 'setInvoiceToChangeComment']),
    ...mapMutations('suppliers', ['clearSupplier', 'setShowAddSupplierPaymentModal', 'setSupplierForPayment']),
    prepare () {
      this.clearIncInvoiceFilters()
      this.setSupplierId(this.supplier_id)
      this.getSupplier(this.supplier_id)
      this.getIncomingInvoices()
    },
    addPayment () {
      this.setSupplierForPayment(this.supplier)
      this.setShowAddSupplierPaymentModal(true)
    },
    getIncInvoiceClass (value) {
      return helper.getIncInvoiceClass(value)
    },
    getColorBalance (value) {
      return helper.getColorBalance(value)
    },
    showEditComment (invoice) {
      this.setInvoiceToChangeComment(invoice)
      this.toggleChangeComment()
    },
    printInvoice (invoice) {
      // router.push('/print-waybill/' + invoice.id)
      const routeData = this.$router.resolve('/print-waybill/' + invoice.id)
      window.open(routeData.href, '_blank')
    //  TODO print invoice
    },
    routeTo (supplierId, waybillId) {
      router.push({ name: 'Waybill', params: { id: supplierId, waybill_id: waybillId } })
    }
  },
  created () {
    this.prepare()
  },
  beforeRouteLeave (to, from, next) {
    this.clearIncInvoiceFilters()
    this.setSupplierId(null)
    this.clearSupplier()
    next()
  }
}
</script>

<style scoped>

</style>
