<template>
  <section class="table_box">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Редактор</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="updateData">Сохранить</div>
      </div>
    </div>
    <div class="container tbl_box flx">
      <div class="column">
        <div class="edit_description box_shadow">
          <p>Название:</p>
          <input v-model="editData.title" :class="{required:hasError('title')}" type="text" required>
          <p>Позиция:</p>
          <brew-select
            class="vue-select"
            label="name"
            v-model="editData.position"
            :options="options"
            :reduce="item => item.id"
          />
          <p style="margin-top: 16px">Сортировка:</p>
          <input v-model="editData.sort" :class="{required:hasError('sort')}" type="number" required>
        </div>
      </div>
      <div class="edit_characteristics">
        <div class="box_shadow general_change">
          <div class="row">
            <div class="inp_wrap category_prod x-edit">
              <vue2-tinymce-editor v-model="editData.content" :options="config" :height="450"></vue2-tinymce-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { Vue2TinymceEditor } from 'vue2-tinymce-editor'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'CreateRole',
  data () {
    return {
      options: [
        { id: 1, name: 'ИНФОРМАЦИЯ' },
        { id: 2, name: 'СЛУЖБА ПОДДЕРЖКИ' }
      ],
      config: {
        images_upload_url: 'https://back.brewdrop.in.ua/api/upload',
        image_uploadtab: true,
        plugins: 'lists autolink charmap code codesample directionality emoticons image table link',
        toolbar1: 'fontsizeselect | formatselect | bold italic underline strikethrough forecolor backcolor',
        toolbar2: 'alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link removeformat code | image | table tabledelete | tableprops tablerowprops tablecellprops',
        menubar: false
      }
    }
  },
  components: { Vue2TinymceEditor },
  computed: {
    ...mapState('contents', ['position_list', 'edit_content']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    editData: {
      get () { return this.edit_content },
      set (v) { this.setEditContent(v) }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    }
  },
  methods: {
    ...mapActions('contents', ['getContent', 'updateContent']),
    ...mapMutations('contents', ['setEditContent']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    updateData () {
      this.updateContent()
    }
  },
  created () {
    this.clearErrors()
    this.getContent(this.$route.params.id)
  }
}
</script>

<style lang="scss"> /* don't add "scoped"; note that this will also globalize the CSS for all editors in your project */
@import "src/assets/scss/stylex.scss";
</style>
