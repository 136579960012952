<template>
  <transition name="fade">
    <div v-if="show" class="popup_box" style="display: block">
      <div class="popup delete_category">
        <span class="page_title" v-if="selected_clients.length > 1">Удалить выбранных клиентов?</span>
        <span class="page_title" v-else-if="selected_client">Удалить клиента [{{selected_client.id}}] {{selected_client.first_name}} {{selected_client.last_name}} ?</span>
        <span class="close_popup" @click="close()"></span>
        <button class="btn" @click="close()">Отмена</button>
        <button class="btn empty" @click="confirm()">Удалить</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  name: 'DeleteClientModal',
  computed: {
    ...mapState('clients', ['selected_clients', 'show_client_delete_modal', 'clients']),
    ...mapGetters('clients', ['get_client']),
    show: {
      get () {
        return this.show_client_delete_modal
      },
      set (value) {
        this.setShowClientDeleteModal(value)
      }
    },
    selected_client () {
      if (this.selected_clients.length === 1) {
        return _.find(this.clients, c => c.id === this.selected_clients[0])
      }
      return null
    }
  },
  methods: {
    ...mapActions('clients', ['deleteClients']),
    ...mapMutations('clients', ['setShowClientDeleteModal']),
    close () {
      this.show = false
    },
    confirm () {
      this.deleteClients()
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
