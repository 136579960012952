import { render, staticRenderFns } from "./BrewLoader.vue?vue&type=template&id=4b1df032&scoped=true&"
import script from "./BrewLoader.vue?vue&type=script&lang=js&"
export * from "./BrewLoader.vue?vue&type=script&lang=js&"
import style0 from "./BrewLoader.vue?vue&type=style&index=0&id=4b1df032&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b1df032",
  null
  
)

export default component.exports