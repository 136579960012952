<template>
  <div class="filters_box open_box" :class="{active: show_order_filters}" data-id="filters_box">
    <div class="filters_head flx">
      <div class="wrap_title flx">
        <span class="page_title">Фильтры</span>
        <span class="result">Результат {{ pagination.from }} - {{ pagination.to }} из {{ pagination.total }}</span>
      </div>
      <div class="flx">
        <div class="reset" @click="clearOrderFilters">Сбросить фильтры</div>
        <button class="btn empty"  @click="getOrders" :disabled="count_order_filters === 0">Применить</button>
      </div>
    </div>
    <div class="filters_table flx">
      <div class="column flx">
        <p>ID <span class="info_grey"> </span></p>
        <input type="text" placeholder="ID продавца" id="seller_id" v-model.number="order_filters.dropshipper_id" @click.enter="getOrders" v-format.integer>
      </div>
      <div class="column flx">
        <p>Телефон</p>
        <input type="text" placeholder="Телефон продавца" id="seller_phone" v-model="filters.dropshipper_phone" v-mask="phone_mask" @click.enter="getOrders">
      </div>
      <div class="column flx">
        <p>Последний визит</p>
        <div class="wrap_from_to flx data datepicker_to">
          <VueDatePicker id="datepicker_starte" v-model="filters.dropshipper_visited_at_from"  placeholder="С" value-type="YYYY-MM-DD" format="С YYYY-MM-DD" lang="ru" />
          <VueDatePicker id="datepicker_start" v-model="filters.dropshipper_visited_at_to"  placeholder="По" value-type="YYYY-MM-DD" format="По YYYY-MM-DD" lang="ru" />
        </div>
      </div>
      <div class="column flx">
        <p>Сумма</p>
        <div class="wrap_from_to flx">
          <input type="text" placeholder="От" v-model="order_filters.drop_profit_from" @click.enter="getOrders" v-format.number>
          <input type="text" placeholder="До" v-model="order_filters.drop_profit_to" @click.enter="getOrders" v-format.number>
        </div>
        <div class="wrap_radiobutton flx">
          <label class="custom-radiobutton" v-for="calc_type in calculate_types" :key="'change_status-'+calc_type.id">
            <input type="radio" name="payment" v-model="filters.calculate_type_id" :value="calc_type.id">
            <span></span>
            <span>{{ calc_type.name }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from '../../../helpers/format'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'CalcOrderFilters',
  directives: {
    format,
    mask
  },
  components: {
    VueDatePicker: () => import('vue2-datepicker')
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##',
      date_mask: '##-##-20##',
      calculate_types: [
        {
          id: 1,
          name: 'Отдать'
        },
        {
          id: 2,
          name: 'Забрать'
        }
      ]
    }
  },
  computed: {
    ...mapState('orders', ['show_order_filters', 'filters', 'pagination']),
    ...mapState('config', ['config']),
    ...mapGetters('orders', ['count_order_filters']),
    order_filters: {
      get () {
        return this.filters
      },
      set (val) {
        this.setFilters(val)
      }
    }
  },
  methods: {
    ...mapActions('orders', ['toggleOrderFilters', 'getOrders']),
    ...mapActions('senders', ['getSenders']),
    ...mapActions('delivery', ['getCities']),
    ...mapMutations('orders', ['setPage', 'setShowOrderFilters', 'clearFilters', 'setFilters']),
    clearOrderFilters () {
      this.clearFilters()
      this.$set(this.order_filters, 'has_invoices', 0)
      this.$nextTick(() => {
        this.setPage(1)
        this.getOrders()
        this.toggleOrderFilters()
      })
    }
  },
  created () {
  }
}
</script>

<style scoped>

</style>
