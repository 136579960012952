<template>
    <div>
        <section class="sub_header">
            <div class="container">
                <nav class="sub_menu flx">
                    <router-link to="/dashboard/dropshipping/products" class="item">
                        <p>Товары</p>
                    </router-link>
                    <router-link to="/dashboard/dropshipping/categories" class="item">
                        <p>Категории товаров</p>
                    </router-link>
                    <router-link to="/dashboard/dropshipping/dropshippers" class="item">
                        <p>Продавцы</p>
                    </router-link>
                </nav>
            </div>
        </section>
        <router-view :key="$router.path" />
    </div>
</template>

<script>

export default {
  name: 'Dropshipping'
}
</script>

<style scoped>

</style>
