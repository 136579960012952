<template>
  <div class="discount_to" data-id="to_product" :class="{ active: show_add_discount_category_modal }">
    <div class="top flx">
      <span class="page_subtitle">Скидка на категорию</span>
      <span class="close" @click="setShowAddDiscountCategoryModal(false)"> </span>
    </div>
    <div class="row flx">
      <div class="inp_wrap">
        <p>Категория:</p>
        <div class="select" >
          <brew-select
              name="dropshipper"
              class="vue-select select-search"
              v-model="discount_data.model_id"
              :options="categories"
              label="name"
              :clearable="false"
              @search="fetchCategories"
              :reduce="item => item.id"
              :class="{required: hasError('model_id')}"
          >
            <template #selected-option="{ name }">
              <truncate-text :text="name.toString()" :clamp="32"></truncate-text>
            </template>
          </brew-select>
        </div>
      </div>
      <div class="inp_wrap">
        <p>Выберите сезоны</p>
        <div class="checkbox_wrap flx">
          <label class="custom-checkbox" v-for="season in seasons" :key="'season-'+season.id">
            <input type="checkbox" v-model="discount_data.params.season_ids"  :value="season.id">
            <span></span>
            <span>{{ season.name}}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="inp_wrap">
        <p>Скидка:</p>
        <div class="wrap_disc flx">
          <input type="number" id="discount" placeholder="0" v-model.number="discount_data.value">
          <div class="select select-search discount-select-type" id="discount_value">
            <brew-select
                class="select-discount-type vue-select "
                :class="{required:hasError('type')}"
                :clearable="false"
                :options="discount_types"
                v-model="discount_data.type"
            ></brew-select>
          </div>
          <div class="btn"  @click="store">Добавить</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import _ from 'lodash'
import TruncateText from '../../helpers/components/TruncateText'
const modelType = 'category'
export default {
  name: 'AddDiscountCategoryModal',
  props: {
    id_user: Number || null
  },
  data () {
    return {
      discount_types: [
        '%', 'грн'
      ]
    }
  },
  components: {
    TruncateText
  },
  computed: {
    ...mapState('catalog', ['categories']),
    ...mapState('season', ['seasons']),
    ...mapState('discount', ['show_add_discount_category_modal', 'discount']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    discount_data: {
      get () {
        return this.discount
      },
      set (val) {
        this.setDiscount(val)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    }
  },
  methods: {
    ...mapActions('discount', ['createUserDiscount']),
    ...mapMutations('discount', ['setShowAddDiscountCategoryModal', 'setDiscount', 'setModelType', 'setDiscountUserId', 'setDiscountParamsSeasonNames', 'setDiscountValue', 'setDiscountType', 'setDiscountParamsSeasonIds']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapMutations('catalog', ['setCategorySearch', 'setCategories']),
    ...mapActions('catalog', ['getCategories']),
    fetchCategories (search) {
      if (search.length >= 3) {
        this.setCategorySearch(search)
        this.$nextTick(() => {
          this.getCategories()
        })
      }
    },
    store () {
      this.createUserDiscount(this.discount)
    }
  },
  watch: {
    show_add_discount_category_modal () {
      this.discount_data.model_id = null
      this.setCategorySearch('')
      this.setCategories([])
      this.setModelType(modelType)
      this.setDiscountUserId(this.id_user)
      this.setDiscountType('%')
      this.setDiscountValue(0)
      this.setDiscountParamsSeasonIds([])
    },
    'discount_data.params.season_ids' () {
      const names = _.map(
        _.filter(
          this.seasons,
          (s) => {
            return _.includes(
              this.discount_data.params.season_ids,
              s.id
            )
          }
        ),
        'name'
      )
      this.setDiscountParamsSeasonNames(names)
    }
  },
  mounted () {
    this.setModelType(modelType)
    this.setDiscountUserId(this.id_user)
    this.setDiscountType('%')
    this.setDiscountValue(0)
    this.setDiscountParamsSeasonIds([])
  }
}
</script>

<style scoped>

</style>
