<template>
  <section class="table_box single_category">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Товары в категории: {{ category.name }} <p v-if="pagination.from">Результат: {{pagination.from}} - {{pagination.to}} из {{pagination.total}}</p></span>
        <div class="btn empty open_pop" style="margin-right: 10px" @click="show_category_edit = true">Изменить</div>
        <div class="btn empty open_pop" style="margin-right: 10px" data-id="edit_category" v-show="selected_products.length > 0" @click="show_product_delete = true">Удалить</div>
        <div class="btn empty open_pop" data-id="edit_category" v-show="selected_products.length > 0" @click="show_product_move = true">Переместить</div>
        <div class="del_wrap open_pop" data-id="delete_category" @click="show_category_delete = true">
          <span class="close"></span>
          <span class="red">Удалить категорию</span>
        </div>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" href="#" class="back flx">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn open_pop" data-id="add_product_to_category">Добавить товары</div>
      </div>
    </div>
    <div class="container tbl flx">
      <div class="top_table flx">
        <div class="wrap_search flx">
          <button type="submit"></button>
          <input type="text" v-model="search" placeholder="Поиск...">
        </div>
        <div class="pagination flx">
          <div class="flx">
            <p>Строк:</p>
            <div>
              <brew-select
                class="select-pagination"
                style=""
                :clearable="false"
                v-model="perPage"
                :options="page_sizes"
              >
              </brew-select>
            </div>
          </div>
          <paginate
            v-model="page"
            :page-count="pagination.last_page"
            style="margin-left: 20px"
            class="flx"
            :page-range="3"
            :margin-pages="2"
            :prev-text="'<'"
            :next-text="'>'"
          >
          </paginate>
        </div>
      </div>

      <div class="table_body product_in_category">
        <headline :headers="headers" :selected-all.sync="isSelectAll" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
        <div class="row_list">
          <div class="row flx"
               @click.self="routeTo(product.id)"
               v-for="product in products"
               :key="product.id">
            <label class="custom-checkbox" @click.stop>
              <input type="checkbox" name="check" v-model="selected_products" :value="product.id">
              <span></span>
            </label>
            <div class="image_product">
              <img
                  style="object-fit: cover"
                  v-if="product.cache.image"
                  :src="product.cache.image[60]"
                  :alt="product.name"
              >
              <img
                  style="object-fit: cover"
                  v-else
                  src="/images/no-img.svg"
                  :alt="product.name"
              >
            </div>
            <div class="name_product">
              <span><b>{{ product.name }}</b></span>
              <span v-for="color in product.cache.colors" :key="color">{{ color }}</span>
            </div>
            <div class="code_product">
              <router-link :to="'/dashboard/dropshipping/products/'+product.id">{{ product.vendor_code }}</router-link>
            </div>
            <div class="season_product">
              <span class="grey" v-for="season in product.cache.seasons" :key="season.id"><b>{{ season.name }}</b></span>
            </div>
            <div class="price_product">
              <div class="item flx">
                <span>Закупка/шт:</span>
                <span class="line"> </span>
                <p>{{ product.cache.purchase_price }}</p>
              </div>
              <div class="item flx">
                <span>Цена/шт (дроп):</span>
                <span class="line"> </span>
                <p>{{ product.cache.retail_price }}</p>
              </div>
              <div class="item flx">
                <span>Скидка:</span>
                <span class="line"> </span>
                <p>0.00</p>
              </div>
              <div class="item flx">
                <span>Сумма:</span>
                <span class="line"> </span>
                <p>{{ product.cache.retail_price }}</p>
              </div>
            </div>
            <div @click.stop="showProductDelete(product.id)" class="cross close"></div>
          </div>
        </div>
      </div>
      <div>
        <stock :model_id="category.id" model_type="category" :stock_id="category.stock_id"></stock>
        <div class="table_body sellers_with_disc_table" @click.stop>
          <div class="top_table flx">
            <span class="page_subtitle">Продавцы со скидкой на категорию</span>
            <span class="plus open_pop" data-id="add_discount" :class="{ active: show_add_discount_dropshipper_modal }"
                  @click="setShowAddDiscountDropshipperModal(true)"> </span>
          </div>
          <div class="wrap_tbl">
            <div class="headline_table flx">
              <div class="id_seller">
                <p>ID</p>
              </div>
              <div class="full_name_seller">
                <p>ФИО</p>
              </div>
              <div class="discount_seller">
                <p>Скидка грн</p>
              </div>
              <div class="discount_seller">
                <p>Скидка %</p>
              </div>
              <div class="cross"></div>
            </div>
            <div class="row_list">
              <div class="row flx" v-for="discount in discount_list" :key="'discount-'+discount.id">
                <div class="id_seller">
                  <router-link :to="{ name: 'EditDropshipper', params: { id: discount.user.id } }">{{
                      discount.user.id
                    }}
                  </router-link>
                </div>
                <div class="full_name_seller">
                  <p>{{ discount.user.name }}</p>
                </div>
                <div class="discount_seller">
                  <span class="green" v-if="discount.type==='грн'">{{ discount.value }} {{ discount.type }}</span>
                </div>
                <div class="discount_seller">
                  <span class="green" v-if="discount.type==='%'">{{ discount.value }} {{ discount.type }}</span>
                </div>
                <div class="cross close" @click="deleteDiscount(discount)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <category-edit-modal
      :show="show_category_edit"
      :category="category"
    ></category-edit-modal>
    <category-delete-item-modal
      :show="show_category_delete"
      :category="category"
    ></category-delete-item-modal>
    <discount-delete-item-modal></discount-delete-item-modal>
    <add-discount-dropshipper-modal v-if="category" type_model="category" :id_model="category.id">
      <template v-slot:title>Добавить скидку на эту категорию для продавца</template>
    </add-discount-dropshipper-modal>
    <product-delete-modal
      :show="show_product_delete"
      :products="selected_products"
    ></product-delete-modal>
    <product-move-modal
      :show="show_product_move"
      :products="selected_products"
    ></product-move-modal>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import CategoryEditModal from '@/components/Droppshipping/Categories/CategoryEditModal'
import CategoryDeleteItemModal from '@/components/Droppshipping/Categories/CategoryDeleteItemModal'
import DiscountDeleteItemModal from '../../Modals/DiscountDeleteItemModal'
import AddDiscountDropshipperModal from '../../Modals/AddDiscountDropshipperModal'
import ProductDeleteModal from '@/components/Droppshipping/Products/ProductDeleteModal'
import ProductMoveModal from '../Products/ProductMoveModal'
import Stock from '@/components/Droppshipping/Stock/Stock'
import router from '../../../router'
import Headline from '../../Helpers/Headline'
import _ from 'lodash'
let timer
export default {
  name: 'ShowCategory',
  components: {
    CategoryEditModal,
    CategoryDeleteItemModal,
    DiscountDeleteItemModal,
    AddDiscountDropshipperModal,
    Stock,
    ProductDeleteModal,
    ProductMoveModal,
    Headline
  },
  data () {
    return {
      show_category_edit: false,
      show_category_delete: false,
      selected_products: [],
      show_product_delete: false,
      show_product_move: false,
      page_sizes: [10, 50, 100, 1000, 10000],
      headers: [
        {
          title: '',
          check_all: true,
          class: 'custom-checkbox'
        },
        {
          title: 'Фото',
          sortable: true,
          class: 'image_product',
          value: 'id'
        },
        {
          title: 'Название',
          class: 'name_product',
          sortable: true,
          value: 'name'
        },
        {
          title: 'Артикул',
          class: 'code_product',
          sortable: true,
          value: 'vendor_code'
        },
        {
          title: 'Сезон',
          class: 'season_product'
          //  TODO sort by season name
        },
        {
          title: 'Цена',
          class: 'price_product'
          //  TODO sort by season name
        },
        {
          title: '',
          class: 'cross'
        }
      ]
    }
  },
  computed: {
    ...mapState('catalog', ['category']),
    ...mapState('product', ['products', 'filters', 'pagination']),
    ...mapState('discount', ['discount_list', 'show_discount_delete_modal', 'show_add_discount_dropshipper_modal']),
    isSelectAll: {
      get: function () {
        if (this.products === null) {
          return false
        }
        return this.selected_products.length === this.products.length && this.selected_products.length > 0
      },
      set: function (value) {
        if (value) {
          this.selected_products = _.map(this.products, 'id')
        } else {
          this.selected_products = []
        }
      }
    },
    page: {
      get: function () {
        return this.filters.page
      },
      set: function (value) {
        this.setPage(value)
        this.getProducts()
      }
    },
    perPage: {
      get: function () {
        return this.filters.per_page
      },
      set: function (value) {
        this.setPerPage(value)
        this.setPage(1)
        this.getProducts()
        this.selected_products = []
      }
    },
    search: {
      get: function () {
        return this.filters.search
      },
      set: function (value) {
        clearTimeout(timer)
        this.setSearch(value)
        timer = setTimeout(() => {
          this.setPage(1)
          this.getProducts()
        }, 300)
      }
    },
    sortBy: {
      get () {
        return this.filters.order_by
      },
      set (val) {
        this.setOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.filters.order_direction
      },
      set (val) {
        this.setOrderDirection(val)
      }
    }
  },
  watch: {
    sortBy () {
      this.getProducts()
    },
    sortDesc () {
      this.getProducts()
    }
  },
  methods: {
    ...mapActions('catalog', [
      'showCategory',
      'deleteCategory'
    ]),
    ...mapActions('product', ['getProducts']),
    ...mapMutations('product', ['setCategoryId', 'setOrderDirection', 'setOrderBy', 'setPage', 'setPerPage', 'setSearch']),
    ...mapMutations('discount', ['setDiscountOnDelete', 'setShowDiscountDeleteModal', 'setShowAddDiscountDropshipperModal']),
    deleteDiscount (discount) {
      this.setDiscountOnDelete(discount)
      this.setShowDiscountDeleteModal(true)
    },
    routeTo (id) {
      router.push({ name: 'ShowProduct', params: { id: id } })
    },
    showProductDelete (productId) {
      this.selected_products = [productId]
      this.show_product_delete = true
    }
  },
  created () {
    this.showCategory(this.$route.params.id)
    this.setCategoryId(this.$route.params.id)
    this.getProducts()
    this.$root.$on('show_category_edit', (data) => {
      this.show_category_edit = data
    })
    this.$root.$on('show_category_delete', (data) => {
      this.show_category_delete = data
    })
    this.$root.$on('show_product_delete', (data) => {
      this.show_product_delete = data
      this.selected_products = []
    })
    this.$root.$on('show_product_move', (data) => {
      this.show_product_move = data
      this.selected_products = []
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.showCategory(to.params.id)
    this.setCategoryId(to.params.id)
    this.getProducts()
    next()
  }
}
</script>

<style scoped>

</style>
