import axios from 'axios'
import { eventBus } from '@/main'
import router from '../../router'

const baseUrl = 'api/categories'

export default {
  namespaced: true,
  state: {
    role_name: '',
    user: null,
    loading_user: true,
    rootCategories: [],
    childCategories: [],
    categories: [],
    category_pagination: {
      last_page: 0,
      current_page: null
    },
    category: {
      children: []
    },
    category_page_filters: {
      page: 1,
      per_page: localStorage.getItem('category-per-page') || 10,
      order_by: localStorage.getItem('category-order-by') || 'id',
      order_direction: localStorage.getItem('category-order-direction') || 'desc',
      name: ''
    },
    show_add_category_products_modal: false,
    category_for_products: null
  },
  getters: {
    getAuthUserPermissions: state => { return state?.user?.rules },
    userHasPermission: (state) => (model, type) => { return false },
    getAuthUserId: state => { return state?.user?.id }
  },
  mutations: {
    setRoleName (state, data) {
      state.role_name = data
    },
    setUser (state, data) {
      state.user = data
    },
    setLoadingUser (state, payload) {
      state.loading_user = payload
    },
    setRootCategories (state, data) {
      state.rootCategories = data
    },
    setChildCategories (state, data) {
      state.childCategories = data
    },
    setCategories (state, data) {
      state.categories = data
    },
    setCategory (state, data) {
      state.category = data
    },
    setPerPage (state, value) {
      state.category_page_filters.per_page = value
      localStorage.setItem('category-per-page', value)
    },
    setCategoryPage (state, value) {
      state.category_page_filters.page = value
    },
    setCategorySearch (state, value) {
      state.category_page_filters.name = value
    },
    setOrderBy (state, payload) {
      state.category_page_filters.order_by = payload
      localStorage.setItem('category-order-by', payload)
    },
    setOrderDirection (state, payload) {
      state.category_page_filters.order_direction = payload
      localStorage.setItem('category-order-direction', payload)
    },
    setCategoryPagination (state, data) {
      state.category_pagination = data
    },
    setShowAddCategoryProductsModal (state, payload) {
      state.show_add_category_products_modal = payload
      if (!payload) {
        state.category_for_products = null
      }
    },
    setCategoryForProducts (state, category) {
      state.category_for_products = category
    }
  },
  actions: {
    getAuthUser (action) {
      return new Promise((resolve, reject) => {
        action.commit('setLoadingUser', true)
        axios.get('api/users/auth').then(response => {
          if (response.data.data.role.id === 3) {
            localStorage.setItem('token', null)
            router.push('/')
          }

          action.commit('setUser', response.data.data)
          action.commit('setRoleName', response.data.data.role.name)

          eventBus.$root.$emit('auth', true)
          action.commit('setLoadingUser', false)
          window.Echo.private('user.' + response.data.data.id)
            .listen('.import.info', (event) => {
              console.log('info', event)
              eventBus.$root.$emit(
                'send_notify',
                event.type,
                'Импорт товара',
                event.message
              )
            }).listen('.import.finish-validation', (event) => {
              console.log('finish-validation', event)
              action.dispatch('importProduct/getData', null, { root: true })
            }).listen('.import.start', (event) => {
              console.log('start', event)
              eventBus.$root.$emit(
                'send_notify',
                'success',
                'Старт импорта товара'
              )
            }).listen('.import.finish', (event) => {
              console.log('finish', event)
              action.commit('importProduct/setIsImportFinish', true, { root: true })
              eventBus.$root.$emit(
                'send_notify',
                'success',
                'Импорт завершен'
              )
            }).listen('.import.progress', (event) => {
              console.log('progress', event)
              action.commit('importProduct/setProgress', event.progress, { root: true })
              if (event.progress === 100) {
                action.dispatch('importProduct/getData', null, { root: true })
              }
            }).listen('.import.validate-progress', (event) => {
              console.log('progress-validate', event)
              action.commit('importProduct/setProgressValidate', event.progress, { root: true })
            }).listen('.import_clients.info', (event) => {
              console.log('info', event)
              eventBus.$root.$emit(
                'send_notify',
                event.type,
                'Импорт клиентов',
                event.message
              )
            }).listen('.import_clients.finish-validation', (event) => {
              console.log('finish-validation', event)
              action.dispatch('importClients/getData', null, { root: true })
            }).listen('.import_clients.start', (event) => {
              console.log('start', event)
              eventBus.$root.$emit(
                'send_notify',
                'success',
                'Старт импорта клиентов'
              )
            }).listen('.import_clients.finish', (event) => {
              console.log('finish', event)
              action.commit('importClients/setIsImportFinish', true, { root: true })
              eventBus.$root.$emit(
                'send_notify',
                'success',
                'Импорт клиентов завершен'
              )
            }).listen('.import_clients.progress', (event) => {
              console.log('progress', event)
              action.commit('importClients/setProgress', event.progress, { root: true })
              if (event.progress === 100) {
                action.dispatch('importClients/getData', null, { root: true })
              }
            }).listen('.import_clients.validate-progress', (event) => {
              console.log('progress-validate', event)
              action.commit('importClients/setProgressValidate', event.progress, { root: true })
            }).listen('.import.img_count', (event) => {
              console.log(event)
              action.commit('importProduct/setCountImages', event.count, { root: true })
            }).listen('.order.updated', (event) => {
              console.log(event)
              eventBus.$root.$emit(
                'send_notify',
                event.type,
                event.message
              )
            })

          window.Echo.channel('admin-notification')
            .listen('.billing', (event) => {
              console.log('bill ' + event)
              eventBus.$root.$emit(
                'send_notify',
                'success',
                'Создан новый запрос на вывод средств'
              )
              action.dispatch('deduction/getDeductionCount', null, { root: true })
            }
            ).listen('.deduction-processed', (e) => {
              action.dispatch('deduction/getDeductionCount', null, { root: true })
            })
          resolve(response.data.data)
        }).catch(err => {
          console.log(err)
          action.commit('setLoadingUser', false)
          action.dispatch('checkAuth', err)
          reject(err)
        })
      })
    },
    getRootCategories (action) {
      axios.get(baseUrl, {
        params: {
          only_root: 1
        }
      }).then(response => {
        action.commit('setRootCategories', response.data.data)
      }).catch(err => {
        action.dispatch('checkAuth', err)
      })
    },
    getChildCategories (action) {
      axios.get(baseUrl, {
        params: {
          only_root: 0
        }
      }).then(response => {
        action.commit('setChildCategories', response.data.data)
      }).catch(err => {
        action.dispatch('checkAuth', err)
      })
    },
    getCategories ({ state, commit }) {
      axios.get(baseUrl, {
        params: state.category_page_filters
      }).then(response => {
        commit('setCategories', response.data.data)
        commit('setCategoryPagination', response.data.meta)
      }).catch(err => {
        commit.dispatch('checkAuth', err)
      })
    },
    showCategory (action, id) {
      axios.get(baseUrl + '/' + id)
        .then(response => {
          action.commit('setCategory', response.data.data)
          action.commit('discount/setModelType', 'category', { root: true })
          action.dispatch('discount/getDiscountList', response.data.data.id, { root: true })
        }).catch(err => {
          action.dispatch('checkAuth', err)
        })
    },
    createCategory (action, data) {
      axios.post(baseUrl, data).then(response => {
        console.log(response.data)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Категория успешно создана',
          'Создана ' + response.data.data.name
        )
        eventBus.$root.$emit('system.category.created', true)
        action.dispatch('getCategories')
      }).catch(err => {
        action.dispatch('checkAuth', err)
        action.dispatch('showErrorNotification', err)
      })
    },
    updateCategory (action, data) {
      axios.put(baseUrl + '/' + data.id, data).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Категория успешно обновлена',
          'Обновлено ' + response.data.data.name
        )
        eventBus.$root.$emit('system.category.updated', true)
        action.commit('setCategory', response.data.data)
        action.dispatch('getCategories')
      }).catch(err => {
        action.dispatch('checkAuth', err)
        action.dispatch('showErrorNotification', err)
      })
    },
    deleteCategories (action, categories) {
      console.log(categories)
      axios.delete(baseUrl, {
        data: { ids: categories }
      }).then(response => {
        console.log(response.data)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Категории удалены'
        )
        action.dispatch('getCategories')
      }).catch(err => {
        action.dispatch('checkAuth', err)
        action.dispatch('showErrorNotification', err)
      })
    },
    deleteCategory (action, id) {
      axios.delete(baseUrl + '/' + id).then(response => {
        console.log(response.data)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Категории удалены'
        )
        action.dispatch('getCategories')
      }).catch(err => {
        action.dispatch('checkAuth', err)
      })
    }
  }
}
