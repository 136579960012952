<template>
<!--  TODO waybill filters-->
  <div class="filters_box open_box" action="#" data-id="filters_box" :class="{ active: show_inc_filters }">
    <div class="filters_head flx">
      <div class="wrap_title flx">
        <span class="page_title">Фильтры</span>
        <span class="result">Результат {{ pagination.from }} - {{ pagination.to }} из {{ pagination.total }}</span>
      </div>
      <div class="flx">
        <div class="reset" @click="clearFilters">Сбросить фильтры</div>
        <button class="btn empty" @click="getIncomingInvoices">Применить</button>
      </div>
    </div>
    <div class="filters_table flx">
      <div class="column flx">
        <p>ID <span class="info_grey"> </span></p>
        <input type="text" placeholder="ID накладной" id="invoice_id" v-model.number="filters.id" v-format.integer @click.enter="getIncomingInvoices">
      </div>
      <div class="column flx">
        <p>Количество товаров в накладной</p>
        <div class="wrap_from_to flx">
          <input type="text" id="_from" placeholder="От" v-model.number="filters.count_product_from" v-format.integer @click.enter="getIncomingInvoices">
          <input type="text" id="_to" placeholder="До" v-model.number="filters.count_product_to" v-format.integer @click.enter="getIncomingInvoices">
        </div>
      </div>
      <div class="column flx">
        <p>Менеджер</p>
        <div class="select select-full" >
          <i class="ti-angle-down"></i>
          <brew-select
              name="select"
              class="vue-select"
              v-model="filters.created_id"
              :options="creators"
              :reduce="item => item.id"
              label="name"
          >
          </brew-select>
        </div>
      </div>
      <div class="column flx">
        <p>Итоговая сумма</p>
        <div class="wrap_from_to flx">
          <input type="text" id="price_from" placeholder="От" v-model.number="filters.total_from" v-format.number @click.enter="getIncomingInvoices">
          <input type="text" id="price_to" placeholder="До" v-model.number="filters.total_to" v-format.number @click.enter="getIncomingInvoices">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { format } from '../../../helpers/format'
export default {
  name: 'WaybillFilters',
  props: {
    supplier_id: {
      type: Number || null,
      default: null
    }
  },
  directives: { format },
  computed: {
    ...mapState('incoming', ['show_inc_filters', 'inc_invoice_filters', 'pagination']),
    ...mapState('users', ['creators']),
    filters: {
      get () {
        return this.inc_invoice_filters
      },
      set (val) {
        this.setIncInvoiceFilters(val)
      }
    }
  },
  methods: {
    ...mapMutations('incoming', ['setIncInvoiceFilters', 'clearIncInvoiceFilters', 'setSupplierId']),
    ...mapActions('incoming', ['getIncomingInvoices', 'toggleIncFilters']),
    ...mapActions('users', ['getCreators']),
    clearFilters () {
      this.clearIncInvoiceFilters()
      this.setSupplierId(this.supplier_id)
      this.$nextTick(() => {
        this.getIncomingInvoices()
        this.toggleIncFilters()
      })
    },
    prepare () {
      this.clearIncInvoiceFilters()
      this.setSupplierId(this.supplier_id)
      this.getCreators()
    }
  },
  created () {
    this.prepare()
  }
}
</script>

<style scoped>

</style>
