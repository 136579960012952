<template>
  <div class="table_body">
    <div class="top_table flx">
      <div class="search_box flx">
        <p>Продавец:</p>
        <div class="flx">
          <user-select
            class="mini-select"
            style="min-width: 225px;margin-right: 10px"
            :default-id="edit_user.id"
            :default-name="edit_user.name"
            :items="dropshippers"
            @search="fetchDropshippers"
            @select="setSelectUserId"
            @active-input="fetchDropshippers"
          ></user-select>
        </div>
      </div>
      <div class="pagination flx">
        <div class="flx">
          <p class="desctop_v">Строк:</p>
          <div class="select" id="sellers">
            <brew-select
                class="select-pagination select-dropshipper-per_page"
                style="margin-left:10px;width: 100px"
                v-model="perPage"
                :clearable="false"
                :options="page_sizes"
            >
            </brew-select>
          </div>
        </div>
        <paginate
            style="margin-left: 20px"
            class="flx"
            v-model="page"
            :page-count="payments_pagination.last_page"
            :page-range="3"
            :margin-pages="2"
            :prev-text="'<'"
            :next-text="'>'"
        >
        </paginate>
      </div>
    </div>
    <div class="bot_table">
      <headline :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
      <div class="row_list">
        <div class="row flx"
             v-for="payment in payments"
             :key="'payment-'+payment.group"
             @click="selected_group=payment.group"
        >
          <div class="id_payment">
            <a @click="selected_group=payment.group">{{ payment.group }}</a>
          </div>
          <div class="orders_count">
            <span>{{ payment.order_count }}</span>
          </div>
          <div class="returns_count">
            <span>{{ payment.returned_count }}</span>
          </div>
          <div class="from_balance_count">
            <span>{{ payment.balance_count }}</span>
          </div>
          <div class="calc_payment">
            <span class="grey">{{ payment.debit_sum }} грн - {{payment.pay_sum}} грн</span>
          </div>
          <div class="total_payment">
            <span><b>{{ payment.total_payment | asPrice }}</b></span>
          </div>
          <div class="status_payment status_info">
            <span class="status green">Выплачен</span>
          </div>
          <div class="payment_at">
            <span>{{ payment.created_at }}</span>
          </div>
          <div class="icon_wrap payment_comment">
<!--            TODO payment comment-->
            <span class="av_wrap" v-if="payment.comment">
              <svg class="open_drop" data-id="list_payment_info2" width="14" height="12" viewBox="0 0 14 12" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.24443 3.93334C3.86397 3.93334 3.55554 4.24176 3.55554 4.62222C3.55554 5.00269 3.86397 5.31111 4.24443 5.31111H9.75554C10.136 5.31111 10.4444 5.00269 10.4444 4.62222C10.4444 4.24176 10.136 3.93334 9.75554 3.93334H4.24443Z"
                    fill="#ADB8C6"/>
                <path
                    d="M4.24443 6.68889C3.86397 6.68889 3.55554 6.99732 3.55554 7.37778C3.55554 7.75824 3.86397 8.06667 4.24443 8.06667H7.68888C8.06934 8.06667 8.37777 7.75824 8.37777 7.37778C8.37777 6.99732 8.06934 6.68889 7.68888 6.68889H4.24443Z"
                    fill="#ADB8C6"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.799988 1.86667C0.799988 1.10682 1.41792 0.488892 2.17777 0.488892H11.8222C12.5821 0.488892 13.2 1.10682 13.2 1.86667V10.1333C13.2 10.8932 12.5821 11.5111 11.8222 11.5111H2.17777C1.41792 11.5111 0.799988 10.8932 0.799988 10.1333V1.86667ZM10.8669 10.1333H3.13306H2.17777V9.17804V2.82196V1.86667H3.13306H10.8669H11.8222V2.82196V9.17805V10.1333H10.8669Z"
                      fill="#ADB8C6"/>
            </svg>
              <div class="menu_drop">
                  <div class="dropdown">
                    <span class="title">Комментарий менеджера: </span>
                    <p>{{ payment.comment }}</p>
                  </div>
                </div>
            </span>
          </div>
        </div>
      </div>
      <payment-details-model
          v-if="selected_group"
          :group="selected_group"
          @close-group="selected_group = null"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { prices } from '../../../../helpers/prices'
import { strings } from '../../../../helpers/strings'
import Headline from '../../../Helpers/Headline'
import UserSelect from '../../../Helpers/UserSelect'
import PaymentDetailsModel from './PaymentDetailsModel'

export default {
  name: 'PaymentReports',
  components: {
    Headline,
    UserSelect,
    PaymentDetailsModel
  },
  data () {
    return {
      selected_group: null,
      page_sizes: [10, 50, 100, 1000],
      headers: [
        {
          // TODO sort by group
          title: 'Отчеты',
          sortable: true,
          class: 'id_payment',
          value: 'group'
        },
        {
          title: 'Заказов',
          class: 'orders_count'
        },
        {
          title: 'Возвратов',
          class: 'returns_count'
        },
        {
          title: 'С баланса',
          class: 'from_balance_count'
        },
        {
          title: 'Расчёт',
          class: 'calc_payment'
        },
        {
          // TODO sort by total_payment
          title: 'Размер выплаты',
          sortable: true,
          class: 'total_payment',
          value: 'total_payment'
        },
        {
          title: 'Статус',
          class: 'status_payment'
        },
        {
          // TODO sort by created at
          title: 'Дата выплаты',
          sortable: true,
          class: 'payment_at',
          value: 'created_at'
        },
        {
          title: '',
          class: 'comment_payment'
        }
      ]
    }
  },
  mixins: [
    prices,
    strings
  ],
  computed: {
    ...mapState('payments', ['payments', 'payments_filter', 'payments_pagination']),
    ...mapState('users', ['dropshippers', 'edit_user']),
    ...mapGetters('users', ['getDefaultDropshipperFilters']),
    filters: {
      get () {
        return this.payments_filter
      }
    },
    page: {
      get: function () {
        return this.payments_filter.page
      },
      set: function (value) {
        this.setPaymentsPage(value)
        this.getPayments()
      }
    },
    perPage: {
      'get' () {
        return this.payments_filter.per_page
      },
      'set' (perPage) {
        this.setPaymentsPerPage(perPage)
        this.setPaymentsPage(1)
        this.getPayments()
      }
    },
    sortBy: {
      get () {
        return this.payments_filter.order_by
      },
      set (val) {
        this.setPaymentsOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.payments_filter.order_direction
      },
      set (val) {
        this.setPaymentsOrderDirection(val)
      }
    }
  },
  methods: {
    ...mapActions('users', ['getDropshippersForSelect', 'getUser']),
    ...mapMutations('users', ['setDropshipperFilters', 'setSearch', 'setDropshippers']),
    ...mapMutations('payments', ['setPaymentsOrderBy', 'setPaymentsOrderDirection', 'setPaymentsFilter', 'setPaymentsPage', 'setPaymentsPerPage']),
    ...mapActions('payments', ['getPayments']),
    setUserId (userId) {
      if (userId !== undefined && userId !== 'all') {
        this.$set(this.payments_filter, 'user_id', +userId)
      } else {
        this.$set(this.payments_filter, 'user_id', null)
      }

      this.getPayments()
    },
    setSelectUserId (value) {
      if (value.id && value.id !== 0) {
        this.$set(this.payments_filter, 'user_id', +value.id)
        this.$router.push({ name: 'DropshipperOrders', params: { id: value.id } })
      }
      this.setDropshippers([])
    },
    fetchDropshippers (search) {
      this.setSearch(search)
      this.getDropshippersForSelect()
    }
  },
  watch: {
    $route (to, from) {
      if (to.params.id !== from.params.id) {
        this.setUserId(to.params.id)
      }
    },
    sortBy () {
      this.getPayments()
    },
    sortDesc () {
      this.getPayments()
    }
  },
  created () {
    this.setUserId(this.$route.params.id)
    const dropshipperFilters = this.getDefaultDropshipperFilters
    dropshipperFilters.per_page = 99999
    this.setDropshipperFilters(dropshipperFilters)
    this.$nextTick(() => {
      this.getDropshippersForSelect()
      this.getPayments()
    })
  }
}
</script>

<style lang="scss">
.payments_general {
  .id_payment {
    margin-right: 30px;
    width: 115px;
  }
  .orders_count {
    margin-right: 30px;
    width: 46px;
  }
  .returns_count {
    margin-right: 30px;
    width: 57px;
  }
  .from_balance_count {
    margin-right: 30px;
    width: 86px;
  }
  .calc_payment {
    margin-right: 30px;
    width: 235px;
  }
  .total_payment {
    margin-right: 30px;
    width: 151px;
  }
  .status_payment {
    margin-right: 30px;
    width: 126px;
    text-align: center;
  }
  .payment_at {
    margin-right: 30px;
    width: 200px;
  }
  .payment_comment {
    .menu_drop {
      min-width: 250px;
      .dropdown {
        padding: 12px;
        p, .title {
          font-size: 12px;
          margin-bottom: 10px;
          font-family: 'Gilroy Semibold', sans-serif;
          color: #ADB8C6;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
