<template>
  <div>
    <div v-if="magic_flag" @click="close" class="auto-layout"></div>
    <div class="auto-select">
      <input
        v-model="name"
        type="text"
        @focus="activeInput"
        @input="search"
      >
      <span class="auto-select-close" @click="clear">x</span>
      <div v-if="magic_flag" class="auto-select-results">
        <div class="auto-select-item"
             @click="selectItem({id:item[idName], name:item[valueName], is_ua:item.is_ua , ref:item.ref})"
             v-for="(item,index) in items"
             :key="index">
          {{ item[valueName] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AutoSelect',
  props: {
    defaultId: Number,
    defaultName: String,
    items: Array,
    idName: {
      type: String,
      default: 'id'
    },
    valueName: {
      type: String,
      default: 'name'
    }
  },
  data () {
    return {
      magic_flag: false,
      id: null,
      name: null
    }
  },
  watch: {
    defaultId: {
      immediate: true,
      handler (val, oldVal) {
        this.id = val
        if (this.id === null) {
          this.name = null
        }
        if (val !== oldVal) {
          this.$emit('change-id', val)
        }
      }
    },
    defaultName: {
      immediate: true,
      handler (val, oldVal) {
        console.log('set name', oldVal, '->', val)
        this.name = val
      }
    }
  },
  methods: {
    search () {
      console.log('search')
      this.id = null
      this.magic_flag = true
      this.$emit('search', this.name)
    },
    selectItem (data) {
      console.log('select')
      this.$emit('select', {
        id: data.id,
        name: data.name,
        is_ua: data.is_ua,
        ref: data.ref
      })

      this.id = data.id
      this.name = data.name
      this.magic_flag = false
    },
    clear () {
      console.log('clear')
      this.magic_flag = false
      this.id = null
      this.name = null
      this.$emit('select', {
        id: null,
        name: null,
        is_ua: null
      })
    },
    activeInput () {
      console.log('active')
      this.magic_flag = true
      this.$emit('active-input', this.name)
    },
    close () {
      console.log('close')
      if (this.id === null) {
        this.clear()
      }
      this.magic_flag = false
    }
  }
}
</script>

<style scoped>
.auto-select {
  position: relative;
}
.auto-layout {
  background: #00000000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.auto-select-results {
  position: absolute;
  display: block;
  background: white;
  width: 100%;
  max-height: 160px;
  overflow: auto;
  border: 1px solid #E4EBF6;
  margin-top: -2px;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 101;
}

.auto-select-item {
  padding: 5px 10px;
}

.auto-select-item:hover {
  background: #F5A32A;
  color: white;
}

.auto-select-close {
  position: absolute;
  margin-left: -31px;
  top: 0;
  padding: 10px;
}

</style>
