import axios from 'axios'
import { eventBus } from '@/main'

const defaultRole = {
  name: null,
  rules: {
    order: {
      view: false,
      create: false,
      delete: false,
      update: false
    },
    product: {
      view: false,
      create: false,
      delete: false,
      update: false
    }
  }
}
const rules = {
  order: {
    view: false,
    create: false,
    delete: false,
    update: false
  },
  product: {
    view: false,
    create: false,
    delete: false,
    update: false
  }
}
const roleUrl = 'api/roles/'
const redirectTo = 'Roles'

export default {
  namespaced: true,
  state: {
    roles: [],
    edit_role: {
      name: null,
      rules: {
        order: {
          view: false,
          create: false,
          delete: false,
          update: false
        },
        product: {
          view: false,
          create: false,
          delete: false,
          update: false
        }
      }
    },
    default_rules: rules,
    role_filters: {
      per_page: localStorage.getItem('roles-per-page') || 10,
      order_by: localStorage.getItem('roles-order-by') || 'id',
      order_direction: localStorage.getItem('roles-order-direction') || 'asc',
      search: null,
      page: 1,
      without_roles: null
    },
    pagination: {
      last_page: 1
    },
    role_on_delete: null,
    show_role_delete_modal: false
  },
  mutations: {
    setRoles (state, roles) {
      state.roles = roles
    },
    setEditRole (state, role) {
      state.edit_role = role
    },
    clearRole (state) {
      state.edit_role = defaultRole
    },
    setRules (state, rules) {
      state.rules = rules
    },
    setOrderBy (state, payload) {
      state.role_filters.order_by = payload
      localStorage.setItem('roles-order-by', payload)
    },
    setOrderDirection (state, payload) {
      state.role_filters.order_direction = payload
      localStorage.setItem('roles-order-direction', payload)
    },
    setRoleFilters (state, filters) {
      state.role_filters = filters
    },
    clearRoleFilters (state) {
      state.role_filters = {
        per_page: localStorage.getItem('roles-per-page') || 10,
        order_by: localStorage.getItem('roles-order-by') || 'id',
        order_direction: localStorage.getItem('roles-order-direction') || 'asc',
        search: null,
        page: 1,
        without_roles: null
      }
    },
    setRolePagination (state, data) {
      state.pagination = data
    },
    setRolePage (state, page) {
      state.role_filters.page = page
    },
    setRolePerPage (state, perPage) {
      state.role_filters.per_page = perPage
      localStorage.setItem('roles-per-page', perPage)
    },
    setRoleSearch (state, Search) {
      state.role_filters.search = Search
    },
    setShowRoleDeleteModal (state, isTrue) {
      state.show_role_delete_modal = isTrue
    },
    setRoleOnDelete (state, role) {
      state.role_on_delete = role
    },
    setWithoutRolesField (state, data = null) {
      state.role_filters.without_roles = data
    },
    setRoleModelRules (state, [model, type, value]) {
      console.log(model, type, value)
      if (!state.edit_role.rules) {
        state.edit_role.rules = rules
      }
      if (!state.edit_role.rules[model]) {
        state.edit_role.rules[model] = {
          create: false,
          update: false,
          view: false,
          delete: false
        }
      }
      state.edit_role.rules[model][type] = value
    },
    toggleSelectAllRules (state, [model, value]) {
      console.log(model, value)
      if (!state.edit_role?.rules) {
        state.edit_role.rules = rules
      }
      state.edit_role.rules[model] = {
        create: value,
        update: value,
        view: value,
        delete: value
      }
    }
  },
  actions: {
    getRoles ({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get(roleUrl,
          {
            params: state.role_filters
          }
        )
          .then(res => {
            commit('setRoles', res.data.data)
            commit('setRolePagination', res.data.meta)
            resolve()
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    getRole ({ commit }, id) {
      axios.get(roleUrl + id)
        .then(res => {
          commit('setEditRole', res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    createRole ({ state, commit }) {
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.post(roleUrl, state.edit_role).then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Роль создана'
          )
          commit('clearRole')
          resolve(redirectTo)
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Роль не создана!',
            'Проверьте данные'
          )
          reject(err)
        })
      })
    },
    updateRole ({ state, commit }) {
      axios.put(roleUrl + state.edit_role.id, state.edit_role)
        .then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Роль обновлена'
          )
          commit('setEditRole', response.data.data)
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Роль не обновлена!',
            'Проверьте данные'
          )
        })
    },
    deleteRole (action, id) {
      axios.delete(roleUrl + id).then(response => {
        action.commit('setShowRoleDeleteModal', false)
        action.commit('setRoleOnDelete', null)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Роль успешно удалена'
        )
        action.dispatch('getRoles')
      }).catch(err => {
        console.log(err)
        action.commit('setShowRoleDeleteModal', false)
        action.commit('setRoleOnDelete', null)
        eventBus.$root.$emit(
          'send_notify',
          'error',
          'Роль не удалена!'
        )
      })
    },
    getPermissions () {
    }
  }
}
