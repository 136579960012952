<template>
  <div>
    <section class="sub_header">
      <div class="container">
        <nav class="sub_menu flx">
          <router-link :to="{ name: 'CalcDropshippers' }" class="item">
            <p>Основные</p>
          </router-link>
          <router-link :to="{ name: 'CalcOrders' }" class="item">
            <p>На карту ПБ</p>
          </router-link>
        </nav>
      </div>
    </section>
    <router-view :key="$router.path" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'Calculations',
  methods: {
    ...mapMutations('orders', { clearOrderFilters: 'clearFilters' }),
    ...mapMutations('users', { clearDropshipperFilters: 'clearDropshipperFilters' }),
    ...mapMutations('invoices', ['clearInvoiceUsersFilters'])
  },
  beforeRouteLeave (to, from, next) {
    this.clearDropshipperFilters()
    this.clearInvoiceUsersFilters()
    this.clearOrderFilters()
    this.$nextTick(() => {
      next()
    })
  }
}
</script>

<style scoped>

</style>
