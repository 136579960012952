<template>
  <div>
    <section class="table_box all_clients">
      <div class="container large flx">
        <div class="table_head flx">
          <span class="page_title">Все клиенты</span>
        </div>
        <div class="wrap_btn flx">
          <div class="wrap_action long active flx">
            <div class="flx">
              <span class="quantity" v-if="selected">Отмечено: {{ selected.length }}</span>
              <span class="reset_wrap cursor-pointer" v-if="selected && selected.length > 0" @click="setSelectedClients([])"><span class="close"> </span> <span class="red">Сбросить</span></span>
            </div>
<!--            <div class="flx desctop_v">-->
<!--              <router-link to="/dashboard/orders/import-clients" class="btn empty">Импорт</router-link>-->
<!--              <div class="btn empty">Экспорт</div>-->
<!--              <div class="btn delete" @click="showDeleteClients">-->
<!--                <span class="close"> </span> <span class="red">Удалить</span>-->
<!--              </div>-->
<!--            </div>-->
            <div class="actions_cont export">
              <div class="btn actions_btn empty mobile_v" data-id="actions_box" :class="{ active: show_client_actions }" @click="toggleClientActions">
                <span>Действия</span>
                <svg width="9" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L4 4L7 1" stroke="#F5A32A" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
              </div>
              <div class="actions_box open_box flx" :class="{ active: show_client_actions }" data-id="actions_box">
                <p class="mobile_v">Действия с клиентами:</p>
                <router-link to="/dashboard/orders/import-clients" class="btn empty">Импорт</router-link>
                <div class="btn empty">Экспорт</div>
<!--                <div class="btn delete" @click="showDeleteClients">-->
<!--                  <span class="close"> </span>-->
<!--                  <span class="red">Удалить</span>-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <span class="point"></span>
          <div class="flx">
            <div class="btn actions_btn empty" data-id="filters_box" :class="{active: show_client_filters}" @click="toggleClientFilters">
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3333 1H1L6.33333 7.30667V11.6667L9 13V7.30667L14.3333 1Z" stroke="#F5A32A"
                      stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span>Фильтры</span>
              <span class="counter_filters" v-if="count_client_filters > 0">{{ count_client_filters }}</span>
            </div>
<!--            <router-link class="btn create" to="/dashboard/orders/clients/create">Создать клиента</router-link>-->
          </div>
        </div>
        <client-filter></client-filter>
      </div>
      <div class="container">
        <div class="table_body table_all_orders">
          <div class="top_table flx">
            <div class="wrap_search flx">
              <button type="submit"></button>
              <input type="text" placeholder="Поиск..." id="search" v-model="search">
              <ul class="search_list_box">
                <li class="item"><a href="#">item</a></li>
                <li class="item"><a href="#">item</a></li>
                <li class="item"><a href="#">item</a></li>
                <li class="item"><a href="#">item</a></li>
                <li class="item"><a href="#">item</a></li>
              </ul>
            </div>
            <div class="pagination flx">
              <div class="flx">
                <p>Строк:</p>
                <brew-select
                    class="select-pagination select-clients-per_page"
                    style="margin-left:10px;width: 100px"
                    v-model="perPage"
                    :clearable="false"
                    :options="page_sizes"
                >
                </brew-select>
              </div>
            </div>
          </div>
          <div class="bot_table">
            <headline :headers="headers" :selected-all.sync="isSelectAll" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
            <div class="row_list">
              <div
                  class="row flx"
                  :class="{ checked: selected && selected.indexOf(client.id) !== -1}"
                  :rel="client.id"
                  v-for="client in clients"
                  :key="'client '+client.id"
              >
                <label class="custom-checkbox">
                  <input type="checkbox" name="remembered" v-model="selected"  :value="client.id">
                  <span></span>
                </label>
                <div class="id_client">
                  <router-link class="edit_ico" :to="{name: 'ShowClient', params: {id: client.id}}">{{ client.id }}</router-link>
                </div>
                <div class="full_name_client">
                  <span><b>{{ client.last_name }}</b></span>
                  <span>{{ client.first_name }} {{ client.middle_name }}</span>
                </div>
                <div class="contacts_client">
                  <a :href="'tel:'+client.phone">{{ client.phone | removeCodeCountry }}</a>
                </div>
                <div class="city_client">
                  <span v-if="client.city">{{ client.city.name }}</span>
                </div>
                <div class="change_client">
                  <span><b>{{ client.updated_at | onlyDate }}</b></span>
                  <span>{{ client.updated_at | onlyTime }}</span>
                </div>
                <div class="adds_client">
                  <span><b>{{ client.created_at | onlyDate }}</b></span>
                  <span>{{ client.created_at | onlyTime }}</span>
                </div>
                <div class="who_adds_client">
                  <span v-if="client.created_by">[<a href="">{{client.created_by.id}}</a>] {{ client.created_by.name }}</span>
                  <span v-if="client.created_by"><b>{{ client.created_by.login }}</b></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <delete-client-modal></delete-client-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import ClientFilter from './ClientFilter'
import { dates } from '../../../helpers/dates'
import { phones } from '../../../helpers/phones'
import { eventBus } from '@/main'
import DeleteClientModal from './DeleteClientModal'
import _ from 'lodash'
import Headline from '../../Helpers/Headline'

export default {
  name: 'Clients',
  components: {
    ClientFilter, DeleteClientModal, Headline
  },
  mixins: [
    dates,
    phones
  ],
  data () {
    return {
      page_sizes: [10, 50, 100, 1000],
      timer: null,
      headers: [
        {
          title: '',
          check_all: true,
          class: 'custom-checkbox'
        },
        {
          title: 'ID',
          title_class: 'title',
          sortable: true,
          class: 'id_client',
          value: 'id'
        },
        {
          title: 'ФИО',
          class: 'full_name_client'
        //   TODO sort by full name
        },
        {
          title: 'Контакты',
          class: 'contacts_client'
        },
        {
          title: 'Город',
          class: 'city_client'
        //  TODO sort by city name
        },
        {
          title: 'Дата изменения',
          class: 'change_client',
          sortable: true,
          value: 'updated_at'
        },
        {
          title: 'Дата добавления',
          class: 'adds_client',
          sortable: true,
          value: 'created_at'
        },
        {
          title: 'Добавил',
          class: 'who_adds_client'
        }
      ]
    }
  },
  computed: {
    ...mapState('clients', ['clients', 'client_filters', 'pagination', 'show_client_filters', 'selected_clients', 'show_client_actions']),
    ...mapGetters('clients', ['count_client_filters']),
    selected: {
      get () {
        return this.selected_clients
      },
      set (val) {
        this.setSelectedClients(val)
      }
    },
    page: {
      get () {
        return this.client_filters.page
      },
      set (value) {
        this.setPage(value)
        this.getClients()
      }
    },
    perPage: {
      get () {
        return this.client_filters.per_page
      },
      set (perPage) {
        this.setClientPerPage(perPage)
        this.setPage(1)
        this.getClients()
      }
    },
    sortBy: {
      get () {
        return this.client_filters.order_by
      },
      set (val) {
        this.setOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.client_filters.order_direction
      },
      set (val) {
        this.setOrderDirection(val)
      }
    },
    search: {
      get () {
        return this.client_filters.search
      },
      set (value) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.setPage(1)
          this.setSearch(value)
          this.getClients()
        }, 1000)
      }
    },
    isSelectAll: {
      get () {
        if (this.clients.length === 0) {
          return false
        }
        return this.selected && this.selected.length === this.clients.length && this.selected.length > 0
      },
      set (value) {
        if (value) {
          this.selected = _.map(this.clients, 'id')
        } else {
          this.selected = []
        }
      }
    }
  },
  watch: {
    sortBy () {
      this.getClients()
    },
    sortDesc () {
      this.getClients()
    }
  },
  methods: {
    ...mapActions('clients', [
      'getClients',
      'toggleClientFilters',
      'toggleClientActions'
    ]),
    ...mapMutations('clients', ['setSelectedClients', 'setClientPerPage', 'setSearch', 'setPage', 'setShowClientDeleteModal', 'setOrderBy', 'setOrderDirection']),
    showDeleteClients () {
      if (this.selected.length === 0) {
        eventBus.$emit('send_notify', 'warn',
          'Выберите клиентов',
          'Клиенты для удаления не выбраны'
        )
        return
      }
      this.setShowClientDeleteModal(true)
    }
  },
  created () {
    this.getClients()
  }
}
</script>

<style scoped>

</style>
