import axios from 'axios'
import { eventBus } from '@/main'
import _ from 'lodash'
const sendersUrl = 'api/senders/'
const schedulesUrl = 'api/sender-schedules/'
const redirectTo = 'Senders'
const defaultSender = () => {
  return {
    id: null,
    first_name: null,
    last_name: null,
    middle_name: null,
    phone: null,
    email: null,
    volume: 500000,
    delivery_id: null,
    city_id: null,
    branch_id: null,
    api_key: null,
    is_fop: false,
    color: '#FFFFFF',
    city: { name: null },
    branch: { name: null }
  }
}
export default {
  namespaced: true,
  state: {
    senders: [],
    sender: {
      id: null,
      first_name: null,
      last_name: null,
      middle_name: null,
      phone: null,
      email: null,
      volume: 500000,
      delivery_id: null,
      city_id: null,
      branch_id: null,
      api_key: null,
      ref: null,
      is_fop: false,
      color: '#FFFFFF',
      city: { name: null },
      branch: { name: null },
      checkbox_pin: null,
      checkbox_license_key: null
    },
    sender_filters: {
      per_page: localStorage.getItem('senders-per-page') || 10,
      order_by: localStorage.getItem('senders-order-by') || 'id',
      order_direction: localStorage.getItem('senders-order-direction') || 'asc',
      search: null,
      page: 1
    },
    pagination: {
      last_page: 1
    },
    show_sender_delete_modal: false,
    sender_on_delete: null,
    schedules: [],
    week_from: null,
    sender_schedule: [],
    checked_senders: []
  },
  getters: {
    getSelectedSenders: state => () => { return _.map(_.filter(state.senders, s => { return s.in_schedule }), 'id') },
    get_city_id: state => state.sender.city_id
  },
  mutations: {
    setSenders (state, senders) {
      // state.product_list.map(obj => ({ ...obj, in_order:
      state.senders = senders.map(s => ({ ...s, in_schedule: true }))
    },
    setSender (state, sender) {
      state.sender = sender
    },
    setSenderFilters (state, filters) {
      state.sender_filters = filters
    },
    clearSenderFilters (state) {
      state.sender_filters = {
        per_page: localStorage.getItem('senders-per-page') || 10,
        order_by: localStorage.getItem('senders-order-by') || 'id',
        order_direction: localStorage.getItem('senders-order-direction') || 'asc',
        search: null,
        page: 1
      }
    },
    setShowSenderDeleteModal (state, isTrue) {
      state.show_sender_delete_modal = isTrue
    },
    setSenderOnDelete (state, sender) {
      state.sender_on_delete = sender
    },
    setSenderPagination (state, data) {
      state.pagination = data
    },
    setSenderPage (state, page) {
      state.sender_filters.page = page
    },
    setSenderPerPage (state, perPage) {
      state.sender_filters.per_page = perPage
      localStorage.setItem('senders-per-page', perPage)
    },
    setSenderPerPageV1 (state, perPage) {
      state.sender_filters.per_page = perPage
    },
    setOrderBy (state, payload) {
      state.sender_filters.order_by = payload
      localStorage.setItem('senders-order-by', payload)
    },
    setOrderDirection (state, payload) {
      state.sender_filters.order_direction = payload
      localStorage.setItem('senders-order-direction', payload)
    },
    setAllSender (state) {
      state.sender_filters.per_page = 99999
    },
    setSenderSearch (state, Search) {
      state.sender_filters.search = Search
    },
    setSchedules (state, schedules) {
      state.schedules = schedules
    },
    setWeekFrom (state, weekFrom) {
      state.week_from = weekFrom
    },
    setSenderSchedule (state, data) {
      state.sender_schedule = data
    }
  },
  actions: {
    getSenders ({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(sendersUrl, {
          params: state.sender_filters
        })
          .then(res => {
            commit('setSenders', res.data.data)
            commit('setSenderPagination', res.data.meta)
            resolve('ok')
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getSender ({ state, commit, dispatch }, id) {
      axios.get(sendersUrl + id).then(response => {
        commit('setSender', response.data.data)
        if (response.data.data.city_id) {
          dispatch('delivery/getCity', response.data.data.city_id, { root: true })
            .then(city => {
              commit('delivery/setCities', [city], { root: true })
            })
          dispatch('delivery/getBranchesByCityId', response.data.data.city_id, { root: true })
            .then(branches => {
              commit('delivery/setBranches', branches, { root: true })
            })
        }
      }).catch(err => {
        commit('setSender', defaultSender())
        console.log(err)
      })
    },
    createSender ({ state, commit }) {
      return new Promise((resolve, reject) => {
        commit('errors/clearErrors', null, { root: true })
        axios.post(sendersUrl, state.sender).then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Отправитель создан',
            'Создан: ' + response.data.data.first_name + ' ' + response.data.data.last_name
          )
          commit('setSender', defaultSender())
          resolve(redirectTo)
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Отправитель не создан!',
            'Проверьте данные'
          )
          reject(err)
        })
      })
    },
    updateSender ({ state, commit, dispatch }) {
      axios.put(sendersUrl + state.sender.id, {
        first_name: state.sender.first_name || null,
        medium_name: state.sender.medium_name || null,
        last_name: state.sender.last_name || null,
        phone: state.sender.phone || null,
        email: state.sender.email || null,
        volume: state.sender.volume || null,
        delivery_id: state.sender.delivery_id || null,
        city_id: state.sender.city_id || null,
        branch_id: state.sender.branch_id || null,
        api_key: state.sender.api_key || null,
        color: state.sender.color || '#FFFFFF',
        ref: state.sender.ref,
        is_enable_pay_control: state.sender.is_enable_pay_control ? 1 : 0,
        is_fop: state.sender.is_fop ? 1 : 0,
        checkbox_pin: state.sender.checkbox_pin,
        checkbox_license_key: state.sender.checkbox_license_key,
        open_checkbox_at: state.sender.open_checkbox_at,
        close_checkbox_at: state.sender.close_checkbox_at,
        checkbox_header: state.sender.checkbox_header,
        checkbox_footer: state.sender.checkbox_footer,
        checkbox_after_at: state.sender.checkbox_after_at,
        checkbox_is_active: state.sender.checkbox_is_active,
        checkbox_order_type: state.sender.checkbox_order_type
      })
        .then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные отправителя обновлены'
          )
          commit('setSender', response.data.data)
          commit('errors/clearErrors', null, { root: true })
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Данные отправителя не обновлены!',
            'Проверьте данные'
          )
        })
    },
    updateSenderLocked ({ commit, dispatch }, sender) {
      axios.put(sendersUrl + sender.id, {
        locked: sender.locked
      })
        .then(response => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные отправителя обновлены'
          )
          commit('errors/clearErrors', null, { root: true })
          dispatch('getSenders')
        }).catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Данные отправителя не обновлены!',
            'Проверьте данные'
          )
        })
    },
    deleteSender (action, id) {
      axios.delete(sendersUrl + id).then(response => {
        action.commit('setShowSenderDeleteModal', false)
        action.commit('setSenderOnDelete', null)
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Отправитель успешно удален'
        )
        action.dispatch('getSenders')
      }).catch(err => {
        console.log(err)
        action.commit('setShowSenderDeleteModal', false)
        action.commit('setSenderOnDelete', null)
      })
    },
    getSchedules ({ state, commit }, data) {
      axios.get(schedulesUrl, { params: data })
        .then(res => {
          commit('setSchedules', res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    setSchedule ({ state, dispatch }, data) {
      axios.post(schedulesUrl + 'set', data)
        .then(res => {
          dispatch('getSchedules')
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные обновлены'
          )
        })
        .catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Данные не обновлены!',
            'Проверьте данные'
          )
        })
    },
    updateSchedule ({ state, commit, dispatch }, schedule) {
      commit('errors/clearErrors', null, { root: true })
      axios.put(schedulesUrl + schedule.id, schedule)
        .then(res => {
          dispatch('getSchedules')
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Данные обновлены'
          )
        })
        .catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Данные не обновлены!',
            'Проверьте данные'
          )
        })
    },
    getSenderSchedule ({ state, commit, getters }) {
      axios.get(sendersUrl, {
        params: {
          page: 1,
          per_page: 9999,
          ids: getters.getSelectedSenders()
        }
      })
        .then(res => {
          commit('setSenderSchedule', res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    testCheckboxConnect ({ state, commit, getters }) {
      return axios.post(sendersUrl + 'test-connection', state.sender)
    },
    clearSender ({ commit }) {
      console.log(defaultSender())
      commit('setSender', defaultSender())
    }
  }
}
