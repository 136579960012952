<template>
  <div class="filters_box open_box" :class="{active: show_invoice_user_filters}" data-id="filters_box">
    <div class="filters_head flx">
      <div class="wrap_title flx">
        <span class="page_title">Фильтры</span>
        <span class="result">Результат {{ invoice_users_pagination.from }} - {{ invoice_users_pagination.to }} из {{ invoice_users_pagination.total }}</span>
      </div>
      <div class="flx">
        <div class="reset" @click="clearFilters">Сбросить фильтры</div>
        <button class="btn empty" @click="getInvoiceUsers()" :disabled="count_user_filters === 0">Применить</button>
      </div>
    </div>
    <div class="filters_table flx">
      <div class="column flx">
        <p>ID <span class="info_grey"> </span></p>
        <input type="text" placeholder="ID продавца" id="seller_id" v-model.number="filters.user_id" v-format.integer @keypress.enter="getInvoiceUsers">
      </div>
      <div class="column flx">
        <p>Телефон</p>
        <input type="text" placeholder="Телефон продавца" id="seller_phone" v-model="filters.phone" v-mask="phone_mask" @keypress.enter="getInvoiceUsers">
      </div>
      <div class="column flx">
        <p>Последний визит</p>
        <div class="wrap_from_to datepicker_to flx">
          <VueDatePicker v-model="filters.last_visited_from"  placeholder="От" value-type="YYYY-MM-DD" format="От YYYY-MM-DD" lang="ru" />
          <VueDatePicker v-model="filters.last_visited_to"  placeholder="До" value-type="YYYY-MM-DD" format="До YYYY-MM-DD" lang="ru" />
        </div>
      </div>
      <div class="column flx">
        <p>Сумма</p>
        <div class="wrap_from_to flx">
          <input type="text" placeholder="От" v-model="filters.sum_from" @keypress.enter="getInvoiceUsers">
          <input type="text" placeholder="До" v-model="filters.sum_to" @keypress.enter="getInvoiceUsers">
        </div>
<!--        <div class="wrap_radiobutton flx">-->
<!--          <label class="custom-radiobutton" v-for="operation_type in operation_types" :key="'operation-'+operation_type.id">-->
<!--            <input type="radio" name="payment" v-model="filters.operation_id" :value="operation_type.id">-->
<!--            <span></span>-->
<!--            <span>{{ operation_type.name }}</span>-->
<!--          </label>-->
<!--        </div>-->
      </div>
    </div>
    <div class="filters_table flx">
      <div class="column flx">
        <p>Дата выплаты</p>
        <VueDatePicker v-model="filters.pay_date"  placeholder="дата выплаты" value-type="YYYY-MM-DD" format="YYYY-MM-DD" lang="ru" />
      </div>
    </div>
  </div>
</template>

<script>
import { format } from '../../../helpers/format'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'CalcDropshipperFilters',
  directives: {
    format,
    mask
  },
  components: {
    VueDatePicker: () => import('vue2-datepicker')
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##',
      operation_types: [
        {
          id: 1,
          name: 'Отдать'
        },
        {
          id: 2,
          name: 'Забрать'
        }
      ]
    }
  },
  computed: {
    ...mapState('invoices', ['show_invoice_user_filters', 'invoice_users_filters', 'invoice_users_pagination']),
    ...mapGetters('invoices', ['count_user_filters']),
    filters: {
      get () {
        return this.invoice_users_filters
      },
      set (val) {
        this.setDropshipperFilters(val)
      }
    }

  },
  methods: {
    ...mapMutations('invoices', ['clearInvoiceUsersFilters', 'setInvoiceUserFilters', 'setInvoiceUsersPage']),
    ...mapActions('invoices', ['getInvoiceUsers', 'toggleInvoiceUserFilters']),
    clearFilters () {
      this.clearInvoiceUsersFilters()
      this.$nextTick(() => {
        this.setInvoiceUsersPage(1)
        this.getInvoiceUsers()
        this.toggleInvoiceUserFilters()
      })
    }
  }
}
</script>

<style scoped>

</style>
