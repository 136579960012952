<template>
  <div class="filters_box open_box" :class="{active: show_order_filters}" action="#" data-id="filters_box">
    <div class="filters_head flx">
      <div class="wrap_title flx">
        <span class="page_title">Фильтры</span>
        <span class="result">Результат {{ pagination.from }} - {{ pagination.to }} из {{ pagination.total }}</span>
      </div>
      <div class="flx">
        <div class="reset" @click="clearOrderFilters">Сбросить фильтры</div>
        <button class="btn empty"  @click="goFilters">Применить</button>
      </div>
    </div>
    <div class="filters_table flx">
      <div class="column flx">
        <span class="filter_title">Заказ</span>
        <p>ID заказа</p>
        <input type="text" placeholder="ID Заказа" id="order_id" v-model="order_filters.order_id" @keyup.enter="goFilters" v-format.integer>
        <p>Отправитель</p>
        <div id="sender" class="select  select-full">
          <i class="ti-angle-down"></i>
          <brew-select
              name="sender"
              class="vue-select"
              v-model="filters.sender_id"
              :options="senders"
              :reduce="item => item.id"
              label="last_name"
          >
            <template #selected-option="{ last_name, first_name, middle_name }">
              {{ last_name }} {{ first_name }} {{ middle_name }}
            </template>
            <template v-slot:option="option">
              {{ option.last_name }} {{ option.first_name }} {{ option.middle_name }}
            </template>
          </brew-select>
        </div>
      </div>
      <div class="column flx">
        <span class="filter_title">Состояние заказа</span>
        <p v-if="config.order && config.order.pay_types">Оплата</p>
        <div class="select select-full select-search" id="payment" v-if="config.order && config.order.pay_types">
          <brew-select
              name="select"
              class="vue-select"
              v-model="filters.pay_type"
              :options="config.order.pay_types"
              :reduce="item => item.id"
              label="name"
          >
          </brew-select>
        </div>
        <p v-if="config.order && config.order.statuses">Статус</p>
        <div class="select select-full select-search" id="status" v-if="config.order && config.order.statuses">
          <brew-select
              name="select"
              class="vue-select"
              v-model="filters.status"
              :options="config.order.statuses"
              :reduce="item => item.id"
              label="name"
          >
          </brew-select>
        </div>
        <p v-if="config.order && config.order.delivery_statuses">Состояние доставки</p>
        <div class="select select-full" id="delivery_status" v-if="config.order && config.order.delivery_statuses">
          <i class="ti-angle-down"></i>
          <brew-select
              name="select"
              class="vue-select"
              v-model="filters.delivery_status"
              :options="config.order.delivery_statuses"
              :reduce="item => item.id"
              label="name"
          >
          </brew-select>
        </div>
      </div>
      <div class="column flx">
        <span class="filter_title">Клиент</span>
        <p>Номер телефона</p>
        <input type="tel" placeholder="Номер телефона" id="number_phone" v-model="filters.client_phone" v-mask="phone_mask"  @keyup.enter="goFilters">
        <p>Фамилия клиента</p>
        <input type="text" placeholder="Фамилия" id="surname" v-model="filters.client_last_name"  @keyup.enter="goFilters">
        <p>Город</p>
        <div class="select select-full">
          <i class="ti-angle-down"></i>
          <brew-select
              class="vue-select"
              @search="fetchCities"
              label="name"
              :options="cities"
              v-model="filters.city_id"
              :reduce="item => item.id"
          ></brew-select>
        </div>
      </div>
      <div class="column flx">
        <span class="filter_title">Товар</span>
        <p>Категория товара</p>
        <div class="select select-full select-search" id="category_product">
          <brew-select
              class="vue-select"
              label="path_name"
              v-model="filters.category_id"
              :reduce="item => item.id"
              :options="childCategories"
          ></brew-select>
        </div>
        <p>Дата</p>
        <div class="wrap">
          <p>Выберите период:</p>
          <div class="wrap_from_to data flx datepicker_to">
            <VueDatePicker v-model="filters.change_from"  placeholder="От" value-type="YYYY-MM-DD" format="От YYYY-MM-DD" lang="ru" />
            <VueDatePicker v-model="filters.change_to"  placeholder="До" value-type="YYYY-MM-DD" format="До YYYY-MM-DD" lang="ru" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from '../../../helpers/format'
import { mask } from 'vue-the-mask'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/uk'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default {
  name: 'OrderFilters',
  directives: {
    format,
    mask
  },
  components: {
    VueDatePicker: () => import('vue2-datepicker')
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##',
      date_mask: '##-##-20##'
    }
  },
  computed: {
    ...mapState('orders', ['show_order_filters', 'filters', 'pagination']),
    ...mapState('senders', ['senders']),
    ...mapState('config', ['config']),
    ...mapGetters('orders', ['count_order_filters']),
    ...mapState('delivery', ['cities']),
    ...mapState('catalog', ['childCategories']),
    order_filters: {
      get () {
        return this.filters
      },
      set (val) {
        this.setFilters(val)
      }
    }
  },
  methods: {
    ...mapActions('orders', ['toggleOrderFilters', 'getOrdersListV1', 'getOrderCounters']),
    ...mapActions('senders', ['getSenders']),
    ...mapActions('delivery', ['getCities']),
    ...mapMutations('orders', ['setPage', 'setShowOrderFilters', 'clearFilters', 'setFilters']),
    ...mapActions('catalog', ['getChildCategories']),
    clearOrderFilters () {
      this.clearFilters()
      this.$nextTick(() => {
        this.setPage(1)
        this.getOrdersListV1()
        this.getOrderCounters()
        this.toggleOrderFilters()
      })
    },
    goFilters () {
      this.$nextTick(() => {
        this.setPage(1)
        this.getOrdersListV1()
        this.getOrderCounters()
        this.toggleOrderFilters()
      })
    },
    fetchCities (search, loading) {
      if (search.length >= 3) { this.getCities(search) }
    }
  },
  created () {
    this.getSenders()
    this.getChildCategories()
  }
}
</script>

<style scoped>

</style>
