<template>
  <div class="popup_box" data-id="add_discount" :class="{ active: show_change_orders_status }">
    <div class="popup add_discount">
      <span class="page_title">
        <slot name="title">Сменить статус выбранных заказов</slot>
      </span>
      <span class="close_popup" @click="setShowChangeOrdersStatus(false)"></span>
      <p>Статус:</p>
      <div class="select" id="sellers" v-if="config.order">
        <brew-select
            name="status"
            class="vue-select select-search"
            v-model="status"
            :options="order_statuses"
            label="name"
            :reduce="item => item.id"
        >
        </brew-select>
      </div>
      <button class="btn" @click="store">Сменить статус</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
// import _ from 'lodash'

export default {
  name: 'ChangeOrderStatusModal',
  data () {
    return {
      status: null
    }
  },
  computed: {
    ...mapState('orders', ['show_change_orders_status', 'selected_orders']),
    ...mapState('config', ['config']),
    order_statuses () {
      return this.config.order.statuses
      // return _.filter(this.config.order.statuses, s => { return s.id !== 1 })
    }
  },
  methods: {
    ...mapActions('orders', ['changeOrdersStatus']),
    ...mapMutations('orders', ['setShowChangeOrdersStatus']),
    store () {
      this.changeOrdersStatus(this.status)
    }
  }
}
</script>

<style scoped lang="scss">
  .page_title {
    font-size: 26px;
  }
</style>
