<template>
    <span class="truncate-text">
      <slot class="truncate-text--title">{{ text | truncate(clamp) }}</slot>
      <div class="menu_drop" v-if="text.length > clamp">
            <div class="dropdown">
                <slot name="description">{{ text }}</slot>
            </div>
      </div>
  </span>
</template>

<script>
import { strings } from '../strings'
export default {
  name: 'TruncateText',
  mixins: [
    strings
  ],
  props: {
    text: String,
    clamp: {
      type: Number,
      default: 50
    }
  }
}
</script>

<style scoped>
.truncate-text:hover .menu_drop{
  display: block;
  top: 25px;
  z-index: 9999;
}
.truncate-text .menu_drop .dropdown{
  padding: 16px 19px;
  width: 291px;
  max-width: 100%;
}

.truncate-text .menu_drop .dropdown:before{
  bottom: inherit;
  top: -9px;
}

.truncate-text .menu_drop .dropdown:after{
  top: -11px;
  bottom: inherit;
}
</style>
