<template>
  <section class="table_box new_order inner">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title">Новый заказ</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="store">Создать</div>
      </div>
    </div>
    <div class="container order_form flx">
      <div class="column">
        <order-dropshipper></order-dropshipper>
        <order-client></order-client>
      </div>
      <order-delivery></order-delivery>
      <div class="column">
        <order-payment></order-payment>
      </div>
    </div>
    <div class="container has_table">
      <order-products></order-products>
    </div>
    <div class="container has_table">
      <add-order-item></add-order-item>
    </div>
  </section>
</template>

<script>
import { prices } from '../../../helpers/prices'
import { strings } from '../../../helpers/strings'
import { phones } from '../../../helpers/phones'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import OrderDropshipper from './Moodules/OrderDropshipper'
import OrderClient from './Moodules/OrderClient'
import OrderDelivery from './Moodules/OrderDelivery'
import OrderPayment from './Moodules/OrderPayment'
import OrderProducts from './Moodules/OrderProducts'
import AddOrderItem from './Moodules/AddOrderItem'
export default {
  name: 'CreateOrder',
  mixins: [
    prices,
    strings,
    phones
  ],
  components: {
    OrderDropshipper,
    OrderClient,
    OrderDelivery,
    OrderPayment,
    OrderProducts,
    AddOrderItem
  },
  computed: {
    ...mapState('orders', ['order']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapState('users', ['dropshippers']),
    order_data: {
      get () {
        return this.order
      },
      set (val) {
        this.setOrder(val)
      }
    }
  },
  methods: {
    ...mapActions('orders', ['createOrder']),
    ...mapMutations('orders', ['clearOrder', 'setOrder']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapActions('delivery', ['getDeliveries']),
    store () {
      this.createOrder()
        .then(path => {
          this.$router.push({ name: path })
        })
    }
  },
  created () {
    this.clearOrder()
    this.clearErrors()
    this.$nextTick(() => {
      this.getDeliveries()
    })
  },
  beforeRouteLeave (from, to, next) {
    this.clearOrder()
    this.clearErrors()
    next()
  }
}
</script>

<style scoped>

</style>
