<template>
    <div>
        <main-header :active_item="$route.name"></main-header>
        <router-view :key="$router.path"/>
    </div>
</template>

<script>

import MainHeader from '@/components/Header/MainHeader'
import { mapActions } from 'vuex'

export default {
  name: 'DashboardLayout',
  components: {
    MainHeader
  },
  methods: {
    ...mapActions('catalog', ['getAuthUser']),
    ...mapActions('season', ['getSeasons']),
    ...mapActions('size', ['getSizes']),
    ...mapActions('color', ['getColors']),
    ...mapActions('config', ['getConfig']),
    ...mapActions('deduction', ['getDeductionCount'])
  },
  mounted () {
    this.getAuthUser()
    this.getConfig()
    this.getSeasons()
    this.getSizes()
    this.getColors()
    this.getDeductionCount()
  }
}
</script>
