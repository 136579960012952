<template>
  <div class="popup_box" :class="{ active: show_history_modal }" data-id="history_change" @click.self="toggleHistoryModal">
    <div class="popup history_change" style="width: 860px">
      <span class="page_title">
        <slot name="title">История изменений</slot>
      </span>
      <span class="close_popup" @click="toggleHistoryModal"></span>
      <div class="row_list">
        <div class="row flx" v-for="change in history" :key="change.id">
          <div class="who_change" style="min-width: 250px">
            <p>Изменил:</p>
            <span v-if="change.user"><b>{{ change.user.name }}</b> [{{ change.user_id }}]</span>
            <span v-if="change.data.causer_type && change.data.causer_type === 'system'"><b>{{ change.data.causer_name }}</b></span>
          </div>
          <div class="when_change">
            <span>{{ change.date }}</span>
            <span class="time">{{ change.time }}</span>
          </div>
          <div class="result_change" v-if="change.data.change_type === 'remains'">
            <p>{{change.data.color}} {{change.data.size}} Было:
              <span><b> {{change.data.old_remains}} </b></span> Стало:<span><b> {{change.data.new_remains}} </b></span>
              <a v-if="change.data.order_id" target="_blank" :href="'/dashboard/orders/orders/'+change.data.order_id">
                Заказ [{{change.data.order_id}}]
              </a>
              <a v-if="change.data.incoming_id" target="_blank" :href="'/dashboard/supply/suppliers/any/waybills/'+change.data.incoming_id">
                Приходная накладная [{{change.data.incoming_id}}]
              </a>
              <span>{{change.data.comment}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ProductHistoryModal',
  computed: {
    ...mapState('productHistory', ['show_history_modal', 'history'])
  },
  methods: {
    ...mapActions('productHistory', ['toggleHistoryModal'])
  }
}
</script>

<style scoped>

</style>
