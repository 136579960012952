<template>
  <section class="table_box payments_general" :class="{open: selected_tab === 1, in_process: selected_tab === 2, сalculated: selected_tab === 3}">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Заказы</span>
        <div class="payment_wrap flx">
          <div class="item_wrap">
            <a :class="{ active: selected_tab === 1}" class="item cursor-pointer" @click="selected_tab = 1">Открытые</a>
            <a :class="{ active: selected_tab === 3}" class="item cursor-pointer" @click="selected_tab = 3">Рассчитаные</a>
            <a :class="{ active: selected_tab === 4}" class="item cursor-pointer" @click="selected_tab = 4">Отчеты</a>
          </div>
        </div>
      </div>
      <div class="wrap_btn flx" v-if="selected_tab === 1">
        <div class="wrap_action flx calculate_selected" :class="{ active: selected_invoices.length > 0}">
          <div class="flx" >
            <span class="quantity">Отмечено: {{ selected_invoices.length }}</span>
            <span class="reset_wrap cursor-pointer" v-if="selected_invoices.length > 0" @click="setSelectedInvoices([])" ><span class="close"> </span><span class="red">Сбросить</span></span>
          </div>
          <span class="grey" v-if="getSelectedInvoiceCalc() !== ''">({{ getSelectedInvoiceCalc() }})  <b class="red">{{ getInvoicesCalcTotal() | asPrice }}</b></span>
          <div class="btn open_pop" data-id="calculate_checked" @click="setShowCalculateModal(true)">Рассчитать отмеченные</div>
        </div>
        <div class="calculate_everything flx" :class="{ active: selected_invoices.length === 0}">
<!--          <div class="request_wrap flx">-->
<!--&lt;!&ndash;            TODO requests &ndash;&gt;-->
<!--            <div class="notice_wrap">-->
<!--              <span class="notice_ico"> </span>-->
<!--              <span>3 запроса</span>-->
<!--            </div>-->
<!--            <span>Последний от: <span>08.04.2021</span></span>-->
<!--          </div>-->
          <div class="btn open_pop" data-id="calculate_all" @click="setShowCalculateModal(true)">Рассчитать всё</div>
        </div>
      </div>
    </div>
    <div class="container has_table">
      <open-invoices v-if="selected_tab === 1"></open-invoices>
      <processed-orders v-if="selected_tab === 2"></processed-orders>
      <calculated-invoices v-if="selected_tab === 3"></calculated-invoices>
      <payment-reports v-if="selected_tab === 4"></payment-reports>
    </div>
    <calculate-invoices-modal></calculate-invoices-modal>
  </section>
</template>

<script>
import OpenInvoices from './Orders/OpenInvoices'
import ProcessedOrders from './Orders/ProcessedOrders'
import CalculatedInvoices from './Orders/CalculatedInvoices'
import CalculateInvoicesModal from '../../Modals/CalculateInvoicesModal'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { prices } from '../../../helpers/prices'
import PaymentReports from './Orders/PaymentReports'
export default {
  name: 'DropshipperOrders',
  components: {
    OpenInvoices,
    ProcessedOrders,
    CalculatedInvoices,
    CalculateInvoicesModal,
    PaymentReports
  },
  mixins: [
    prices
  ],
  computed: {
    ...mapState('invoices', ['invoice_user', 'selected_invoices', 'show_calculate_modal']),
    ...mapGetters('invoices', ['getSelectedInvoiceCalc', 'getInvoicesCalcTotal'])
  },
  methods: {
    ...mapMutations('invoices', ['setShowCalculateModal', 'setSelectedInvoices'])
  },
  data () {
    return {
      selected_tab: 1
    }
  }
}
</script>

<style scoped lang="scss">
.table_head {
  min-height: 46px;
  margin-bottom: 15px;
}
</style>
