<template>
    <transition name="fade">
        <div v-if="show" class="popup_box" style="display: block">
            <div class="popup delete_category">
                <span class="page_title">Вы действительно хотите удалить категорию: {{ category.name }} ?</span>
                <span class="close_popup" @click="close()"></span>
                <button class="btn" @click="close()">Отмена</button>
                <button class="btn empty" @click="confirm()">Удалить</button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CategoryDeleteModal',
  props: {
    show: Boolean,
    category: Object
  },
  methods: {
    ...mapActions('catalog',
      {
        remove: 'deleteCategory'
      }
    ),
    confirm () {
      this.remove(this.category.id)
      this.close()
      this.$router.push('/dashboard/dropshipping/categories')
    },
    close () {
      this.$root.$emit('show_category_delete', false)
    }
  }
}
</script>

<style scoped>

</style>
