import axios from 'axios'

const baseUrl = 'api/payments/'

export default {
  namespaced: true,
  state: {
    payments: [],
    payments_pagination: {
      last_page: 1
    },
    payments_filter: {
      search: null,
      page: 1,
      per_page: parseInt(localStorage.getItem('payments-per-page')) || 10,
      order_by: localStorage.getItem('payments-order-by') || 'id',
      order_direction: localStorage.getItem('payments-order-direction') || 'desc',
      user_id: null
    }
  },
  mutations: {
    setPayments (state, data) {
      state.payments = data
    },
    setPaymentsPagination (state, payload) {
      state.payments_pagination = payload
    },
    setPaymentsFilter (state, payload) {
      state.payments_filter = payload
    },
    setPaymentsSearch (state, payload) {
      state.payments_filter.search = payload
    },
    setPaymentsPage (state, num) {
      state.payments_filter.page = num
    },
    setPaymentsPerPage (state, num) {
      state.payments_filter.per_page = num
      localStorage.setItem('payments-per-page', `${num}`)
    },
    setPaymentsOrderBy (state, payload) {
      state.payments_filter.order_by = payload
      localStorage.setItem('payments-order-by', payload)
    },
    setPaymentsOrderDirection (state, payload) {
      state.payments_filter.order_direction = payload
      localStorage.setItem('payments-order-direction', payload)
    }
  },
  actions: {
    getPayments ({ state, commit }) {
      axios.get(baseUrl, {
        params: state.payments_filter
      })
        .then(response => {
          commit('setPayments', response.data.data)
          commit('setPaymentsPagination', response.data.meta)
        })
        .catch(err => {
          console.log(err)
          commit('setPayments', [])
        })
    }
  }
}
