<template>
  <!--    TODO add payment popup-->
  <div class="popup_box" data-id="add_payment" :class="{ active: show }" @click.self="close">>
    <div class="popup add_payment">
      <span class="page_title">Добавить платеж</span>
      <span class="close_popup" @click="close"></span>
      <div class="wrap flx">
        <div class="inp_wrap date_payment ">
          <p>Дата платежа:</p>
          <VueDatePicker v-model="payment.received_at" value-type="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD" lang="ru" class="w-100 payment_datepicker"/>
        </div>
        <div class="inp_wrap sum_payment">
          <p>Сумма:</p>
          <input v-model.number="payment.total" type="number" placeholder="0">
        </div>
      </div>
      <div class="inp_wrap">
        <p>Комментарий:</p>
        <textarea name="" v-model="payment.comment"></textarea>
      </div>
      <button class="btn" @click="addPayment">Добавить</button>
    </div>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/uk'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'AddSupplierPaymentModal',
  components: {
    VueDatePicker: () => import('vue2-datepicker')
  },
  data () {
    return {
      payment: {
        total: 0,
        received_at: null,
        comment: null,
        products: null
      }
    }
  },
  computed: {
    ...mapState('suppliers', ['show_add_supplier_payment_modal', 'supplier_for_payment']),
    show: {
      get () {
        return this.show_add_supplier_payment_modal
      },
      set (val) {
        this.setShowAddSupplierPaymentModal(val)
      }
    }
  },
  methods: {
    ...mapMutations('suppliers', ['setShowAddSupplierPaymentModal', 'clearSupplierForPayment']),
    ...mapActions('incoming', ['createIncomingInvoice']),
    addPayment () {
      this.$set(this.payment, 'supplier_id', this.supplier_for_payment.id)
      this.$nextTick(() => {
        this.createIncomingInvoice(this.payment)
          .then(() => {
            this.$emit('payment-created')
            this.close()
          })
      })
    },
    close () {
      this.clearSupplierForPayment()
      this.payment = {
        total: 0,
        received_at: null,
        comment: null,
        products: null
      }
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>
