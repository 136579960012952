<template>
  <transition name="fade">
    <div v-if="show" class="popup_box" style="display: block">
      <div class="popup delete_category">
        <span class="page_title">Удалить роль [{{role_on_delete.id}}] {{role_on_delete.name}}  ?</span>
        <span class="close_popup" @click="close()"></span>
        <button class="btn" @click="close()">Отмена</button>
        <button class="btn empty" @click="confirm()">Удалить</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'DeleteRoleModal',
  computed: {
    ...mapState('roles', ['role_on_delete', 'show_role_delete_modal']),
    show: {
      get () {
        return this.show_role_delete_modal
      },
      set (value) {
        this.setShowRoleDeleteModal(value)
      }
    }
  },
  methods: {
    ...mapActions('roles', ['deleteRole']),
    ...mapMutations('roles', ['setShowRoleDeleteModal', 'setRoleOnDelete']),
    close () {
      this.setRoleOnDelete(null)
      this.show = false
    },
    confirm () {
      this.deleteRole(this.role_on_delete.id)
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
