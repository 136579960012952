<template>
  <transition name="fade">
    <div v-if="show" class="popup_box" style="display: block">
      <div class="popup delete_category">
        <span class="page_title">Переместить товары в: </span>
        <div class="inp_wrap">
          <p>Родительская категория:</p>
          <div>
            <brew-select
              class="select-category-move vue-select"
              label="name"
              style="background: white; border-radius: 7px;margin-bottom: 15px"
              v-model="category_id"
              :reduce="item => item.id"
              :options="childCategories"
            >
            </brew-select>
          </div>
        </div>
        <span class="close_popup" @click="close()"></span>
        <button class="btn" @click="confirm()">Переместить</button>
        <button class="btn empty" @click="close()">Отмена</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ProductMoveModal',
  data () {
    return {
      category_id: null
    }
  },
  props: {
    show: Boolean,
    products: Array
  },
  computed: {
    ...mapState('catalog', ['childCategories'])
  },
  methods: {
    ...mapActions('product', ['move', 'getProducts']),
    ...mapActions('catalog', ['getChildCategories']),
    confirm () {
      this.move({
        products: this.products,
        category_id: this.category_id
      })
        .then(path => {
          this.close()
        })
    },
    close () {
      this.$root.$emit('show_product_move', false)
    }
  },
  created () {
    this.getChildCategories()
  }
}
</script>

<style scoped>

</style>
