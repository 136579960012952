<template>
  <div class="decl_number_wrap" v-click-outside="close">
    <span class="decl_number" v-if="order.ttn"><b>{{ order.ttn | asTTN }}</b></span>
    <span class="decl_number" v-else><b></b></span>
    <svg class="pencil open_drop" :class="{active:active_change}" data-id="decl_drop1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" @click="active_change = !active_change">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.22065 1.33967C7.67354 0.886778 8.40782 0.886778 8.86071 1.33967C9.3136 1.79255 9.3136 2.52683 8.86071 2.97972L3.66721 8.17322L1.48047 8.71991L2.02715 6.53317L7.22065 1.33967Z" stroke="#ADB8C6" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div class="menu_drop" data-id="decl_drop1" :class="{active:active_change}" :ref="'change_order_ttn' + order.id">
      <span class="close" @click="close"> </span>
      <div class="dropdown">
        <div class="change_decl_number ">
          <p v-if="order.ttn">Изменить номер декларации</p>
          <p v-else>Добавить номер декларации</p>
          <input type="text" v-model="ttn" v-mask="ttn_mask">
          <button class="btn" @click="updateTTN" v-if="order.ttn">Изменить</button>
          <button class="btn" @click="updateTTN" v-else>Добавить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numbers } from '../../../helpers/numbers'
import { mapActions } from 'vuex'
import { mask } from 'vue-the-mask'
import { clickOutside } from '../../../helpers/clickOutside'
export default {
  name: 'OrderTtn',
  props: {
    order: Object
  },
  mixins: [
    numbers
  ],
  directives: {
    mask, clickOutside
  },
  data () {
    return {
      ttn: this.order.ttn,
      active_change: false,
      ttn_mask: '## #### #### ####'
    }
  },
  methods: {
    ...mapActions('orders', ['updateOrderTTN']),
    updateTTN () {
      const ttn = this.ttn.trim()
      this.updateOrderTTN([this.order.id, ttn])
      this.close()
    },
    close () {
      this.active_change = false
    }
  }
}
</script>

<style scoped lang="scss">
  .close {
    position: absolute;
    right: 7px;
    top: 6px;
    z-index: 10;
  }
</style>
