<template>
    <section class="login_page">
        <notifications group="notify"/>
        <div class="admin_login">
            <div class="logo_wrap flx">
                <span class="logo"><img src="images/logo.svg" alt="logo"></span>
                <span class="logo_text">BrewDrop</span>
            </div>
            <p>Логин</p>
            <input type="text" v-model="login_fields.login" id="login_name" required @keyup.enter="loginRequest">
            <div class="pass_wrap">
                <p>Пароль</p>
                <input
                    :type="view_password ? 'text' : 'password'"
                    v-model="login_fields.password"
                    id="password"
                    required
                    @keyup.enter="loginRequest"
                >
                <span
                    @click="view_password = !view_password"
                    class="show_pass"
                    v-text="view_password ? 'Скрыть' : 'Показать'"
                ></span>
            </div>

            <label class="custom-checkbox">
                <input type="checkbox" name="remembered" value="remembered">
                <span></span>
                <span>Запомнить меня</span>
            </label>
            <button class="btn" @click="loginRequest()">Войти</button>
            <p><a href="#">Не помню пароль</a></p>
        </div>
    </section>
</template>
<script>

import axios from 'axios'
import router from '@/router'
import { mapActions, mapMutations, mapState } from 'vuex'
import { eventBus } from '@/main'

export default {
  name: 'Auth',
  data () {
    return {
      login_fields: {
        login: null,
        password: null
      },
      view_password: false
    }
  },
  computed: {
    ...mapState('catalog', ['user'])
  },
  methods: {
    ...mapActions('catalog', ['getAuthUser']),
    ...mapMutations('catalog', ['setUser']),
    loginRequest () {
      axios.post('/login', this.login_fields)
        .then(response => {
          if (response.data.data.role && response.data.data.role.id === 3) {
            this.notify('warn', 'Доступ закрыт', '')
          } else {
            localStorage.setItem('token', response.data.data.api_token)
            this.setUser(response.data.data)
            axios.defaults.headers.common.Authorization = `Bearer ${response.data.data.api_token}`
            router.push('/dashboard')
          }
        }).catch(err => {
          if (err.response.status === 422) {
            this.notify('warn', 'Неверные данные', 'Невервый логин или пароль')
          } else if (err.response.status === 405) {
            this.notify('error', 'Ошибка', 'Пользователь заблокирован')
          } else {
            this.notify('error', 'Произошла ошибка', 'Попробуйте позже или обратитесь в службу тех.поддержки')
          }
        })
    },
    notify (type, title, message) {
      this.$notify({
        type: type,
        group: 'notify',
        title: title,
        text: message
      })
    }
  },
  created () {
    this.getAuthUser()
  },
  mounted () {
    eventBus.$on('auth', (value) => {
      if (value) {
        console.log('User auth completed')
        router.push('/dashboard')
      }
    })
  }
}
</script>
