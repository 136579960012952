<template>
    <div>
      <loader v-if="is_loading" object="#5C6579" color1="#ccc" color2="#FFA500" size="5" speed="2" bg="#fff" objectbg="#999793" opacity="80" name="circular"></loader>
        <section class="sub_header">
            <div class="container">
                <nav class="sub_menu flx">
                  <router-link :to="{ name: 'AllOrders' }" class="item">
                        <p>Все заказы <span v-if="ordersPagination.total">({{ordersPagination.total}})</span></p>
                  </router-link>
                  <router-link :to="{ name: 'Clients' }" class="item">
                        <p>Клиенты <span v-if="clientsPagination.total">({{clientsPagination.total}})</span></p>
                  </router-link>
                </nav>
            </div>
        </section>
        <router-view :key="$router.path" />
        <order-products-modal></order-products-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import OrderProductsModal from './Orders/Products/OrderProductsModal'

export default {
  name: 'Orders',
  components: {
    OrderProductsModal
  },
  computed: {
    ...mapState('clients', { clientsPagination: 'pagination' }),
    ...mapState('orders', {
      ordersPagination: 'pagination',
      is_loading: 'is_loading'
    })
  },
  methods: {
    ...mapActions('clients', ['getClients']),
    ...mapActions('orders', ['getOrders'])
  },
  created () {
    // todo get countrs
    // this.getClients()
    // this.getOrders()
  }
}
</script>

<style scoped>

</style>
