<template>
<!--   TODO select supplier -->
  <section class="table_box seller_selection">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Выберите поставщика
        <p v-if="pagination.from">Результат: {{pagination.from}} - {{pagination.to}} из {{pagination.total}}</p>
        </span>
        <span class="item">Общая задолженость: <span class="red"><b>{{ suppliers_counters.debit }}</b></span></span>
        <span class="item">Общий задаток: <span class="green"><b>{{  suppliers_counters.pay }}</b></span></span>
      </div>
      <div class="wrap_btn flx">
<!--        TODO -->
<!--        <a class="btn empty" href="new_order.html">Все платежи</a>-->
      </div>
    </div>
    <div class="container has_table">
      <div class="table_body table_all_orders">
        <div class="top_table flx">
          <div class="wrap_search flx">
            <button type="submit"></button>
            <input type="text" placeholder="Поиск..." v-model="search" id="search">
            <ul class="search_list_box">
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
            </ul>
          </div>
          <div class="pagination flx">
            <p class="desctop_v">Строк:</p>
              <brew-select
                  class="select-pagination select"
                  style="margin-left:10px;width: 100px"
                  v-model="perPage"
                  :clearable="false"
                  :options="page_sizes"
              >
              </brew-select>
            <paginate
                style="margin-left: 20px"
                class="flx"
                v-model="page"
                :page-count="pagination.last_page"
                :page-range="3"
                :margin-pages="2"
                :prev-text="'<'"
                :next-text="'>'"
            >
            </paginate>
          </div>
        </div>
        <div class="bot_table">
          <headline :headers="headers" :selected-all.sync="isSelectAll" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
          <div class="row_list">
            <div class="row flx" v-for="supplier in suppliers" :key="supplier.id" @click="routeTo(supplier.id)">
              <label class="custom-checkbox">
                <input type="checkbox" v-model="selected"  :value="supplier.id">
                <span></span>
              </label>
              <div class="id_seller">
                <router-link :to="{ name: 'Waybills', params: { id: supplier.id }}">{{ supplier.id }}</router-link>
              </div>
              <div class="name_seller">
                <p>{{ supplier.name }}</p>
              </div>
              <div class="last_invoice_seller">
                <span v-if="supplier.last_invoice_at">{{ supplier.last_invoice_at }}</span>
              </div>
              <div class="balance_seller">
                <span :class="getColorBalance(supplier.balance)"><b>{{ supplier.balance | asPrice }}</b></span>
              </div>
              <div class="last_payment_seller">
                <span v-if="supplier.last_payment_at">{{ supplier.last_payment_at }}</span>
              </div>
              <div class="btn_wrap open_pop" data-id="add_payment" @click.stop>
                <div class="btn empty" :class="{ active: show_add_supplier_payment_modal}" @click="addPayment(supplier)">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 6L5.99966 6.00002M5.99966 6.00002L6 1M5.99966 6.00002L11 6M5.99966 6.00002V11" stroke="#F5A32A" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span>Добавить платеж</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-supplier-payment-modal @payment-created="prepare"></add-supplier-payment-modal>
  </section>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapMutations, mapState } from 'vuex'
import { dates } from '../../../helpers/dates'
import helper from '../../../helpers/helper'
import { prices } from '../../../helpers/prices'
import AddSupplierPaymentModal from './AddSupplierPaymentModal'
import Headline from '../../Helpers/Headline'
import router from '../../../router'
export default {
  name: 'SelectSupplier',
  mixins: [prices, dates],
  data () {
    return {
      timer: null,
      page_sizes: [10, 50, 100, 1000],
      headers: [
        {
          title: '',
          check_all: true,
          class: 'custom-checkbox'
        },
        {
          title: 'ID',
          sortable: true,
          class: 'id_seller',
          value: 'id'
        },
        {
          title: 'Название',
          class: 'name_seller',
          value: 'name'
        },
        {
          title: 'Последняя накладная',
          class: 'last_invoice_seller',
          value: 'last_invoice_at'
        },
        {
          title: 'Баланс',
          class: 'balance_seller',
          value: 'balance'
        },
        {
          title: 'Последний платеж',
          class: 'last_payment_seller',
          value: 'last_payment_at'
        }
      ]
    }
  },
  components: {
    AddSupplierPaymentModal, Headline
  },
  computed: {
    ...mapState('suppliers', ['pagination', 'suppliers', 'suppliers_counters', 'supplier_filters', 'selected_suppliers', 'show_add_supplier_payment_modal']),
    selected: {
      get () {
        return this.selected_suppliers
      },
      set (val) {
        this.setSelectedSuppliers(val)
      }
    },
    page: {
      get () {
        return this.supplier_filters.page
      },
      set (value) {
        this.setPage(value)
        this.getSuppliers()
      }
    },
    perPage: {
      get () {
        return this.supplier_filters.per_page
      },
      set (perPage) {
        this.setPerPage(perPage)
        this.setPage(1)
        this.getSuppliers()
      }
    },
    search: {
      get () {
        return this.supplier_filters.search
      },
      set (value) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.setPage(1)
          this.setSearch(value)
          this.getSuppliers()
        }, 1000)
      }
    },
    sortBy: {
      get () {
        return this.supplier_filters.order_by
      },
      set (val) {
        this.setOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.supplier_filters.order_direction
      },
      set (val) {
        this.setOrderDirection(val)
      }
    },
    isSelectAll: {
      get () {
        if (this.suppliers.length === 0) {
          return false
        }
        return this.selected && this.selected.length === this.suppliers.length && this.selected.length > 0
      },
      set (value) {
        if (value) {
          this.selected = _.map(this.suppliers, 'id')
        } else {
          this.selected = []
        }
      }
    }
  },
  watch: {
    sortBy () {
      this.getSuppliers()
    },
    sortDesc () {
      this.getSuppliers()
    }
  },
  methods: {
    ...mapActions('suppliers', ['getSuppliers', 'getSuppliersCounters']),
    ...mapMutations('suppliers', ['clearFilters', 'setSearch', 'setSelectedSuppliers', 'setPage', 'setPerPage', 'setSupplierOnDelete', 'setFilters', 'setOrderDirection', 'setOrderBy', 'setShowAddSupplierPaymentModal', 'setSupplierForPayment']),
    prepare () {
      this.clearFilters()
      this.getSuppliersCounters()
      this.getSuppliers()
    },
    addPayment (supplier) {
      this.setSupplierForPayment(supplier)
      this.setShowAddSupplierPaymentModal(true)
    },
    getColorBalance (value) {
      return helper.getColorBalance(value)
    },
    routeTo (id) {
      router.push({ name: 'Waybills', params: { id: id } })
    }
  },
  created () {
    this.prepare()
  }
}
</script>

<style scoped>

</style>
