<template>
  <div class="popup_box" data-id="add_comment" :class="{ active: show }" @click.self="close">>
    <div class="popup add_payment" v-if="invoice_to_change_comment">
      <span class="page_title" v-if="invoice_to_change_comment.comment">Изменить комментарий</span>
      <span class="page_title" v-else>Добавить комментарий</span>
      <span class="close_popup" @click="close"></span>
      <div class="inp_wrap">
        <p>Комментарий:</p>
        <textarea name="" v-model="comment"></textarea>
      </div>
      <button class="btn" @click="updateComment" v-if="invoice_to_change_comment.comment">Изменить</button>
      <button class="btn" @click="updateComment" v-else>Добавить</button>
    </div>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/uk'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ChangeWaybillCommentModal',
  data () {
    return {
      comment: null
    }
  },
  computed: {
    ...mapState('incoming', ['show_change_waybill_comment_modal', 'invoice_to_change_comment']),
    show: {
      get () {
        return this.show_change_waybill_comment_modal
      },
      set (val) {
        this.setShowChangeWaybillCommentModal(val)
      }
    }
  },
  methods: {
    ...mapMutations('incoming', ['setShowChangeWaybillCommentModal', 'clearInvoiceToChangeComment']),
    ...mapActions('incoming', ['updateInvoiceComment']),
    updateComment () {
      this.updateInvoiceComment([this.invoice_to_change_comment.id, this.comment])
        .then(() => {
          this.$emit('invoice-changed')
          this.close()
        })
    },
    close () {
      this.clearInvoiceToChangeComment()
      this.comment = null
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>
