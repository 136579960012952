<template>
  <div v-show="show" class="filters_box open_box" style="display: block">
    <div class="filters_head flx">
      <div class="wrap_title flx">
        <span class="page_title">Фильтры</span>
        <span class="result">Результат {{ statePagination.from }} - {{ statePagination.to }} из {{ statePagination.total }}</span>
      </div>
      <div class="flx">
        <div class="reset" @click="clearFilters()">Сбросить фильтры</div>
      </div>
    </div>
    <div class="filters_table flx">
      <div class="column flx">
        <p>Название</p>
        <input type="text" placeholder="Название" v-model="filters.name" @input="applyFilters()">
        <p>Наличие</p>
        <div class="select select-full">
          <brew-select
            @input="applyFilters()"
            class="vue-select"
            label="name"
            v-model="filters.remains_code"
            :options="getRemainsList()"
            :reduce="item => item.code"
          />
        </div>
      </div>
      <div class="column flx">
        <p>Артикул</p>
        <input type="text" placeholder="Артикул" v-model="filters.vendor_code" @input="applyFilters()">
        <p>Цена (Дроп)</p>
        <div class="wrap_from_to price flx">
          <input type="text" v-model="filters.price_from" placeholder="От" @input="applyFilters()">
          <input type="text" v-model="filters.price_to" placeholder="До" @input="applyFilters()">
        </div>
      </div>
      <div class="column flx">
        <p>Категория</p>
        <div class="select-full">
          <brew-select
            class="vue-select"
            label="path_name"
            v-model="filters.category_id"
            :options="childCategories"
            :reduce="item => item.id"
            @input="applyFilters()"
          />
        </div>
      </div>
      <div class="column flx">
        <p>Сезон</p>
        <div class="select select-full" >
          <brew-select
            class="vue-select"
            label="name"
            v-model="filters.season_id"
            :options="seasons"
            :reduce="item => item.id"
            @input="applyFilters()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ProductFilter',
  props: ['show'],
  data () {
    return {
      filters: {
        name: null,
        vendor_code: null,
        category_id: null,
        season_id: null,
        price_from: null,
        price_to: null,
        remains_code: null,
        search: null
      }
    }
  },
  computed: {
    ...mapState('product', { stateFilters: 'filters', statePagination: 'pagination' }),
    ...mapState('catalog', ['childCategories']),
    ...mapState('season', ['seasons']),
    ...mapState('config', ['config'])
  },
  methods: {
    ...mapActions('product', ['getProducts']),
    ...mapActions('catalog', ['getChildCategories']),
    ...mapMutations('product', [
      'setFilters',
      'setSearch',
      'setPage'
    ]),
    clearFilters () {
      this.filters.name = null
      this.filters.vendor_code = null
      this.filters.category_id = null
      this.filters.season_id = null
      this.filters.search = null
      this.filters.price_from = null
      this.filters.price_to = null
      this.filters.remains_code = null
      this.applyFilters()
    },
    applyFilters () {
      this.setPage(1)
      this.setSearch(null)
      this.setFilters(this.filters)
      this.getProducts()
      // this.close()
    },
    close () {
      this.$root.$emit('show_product_filters', false)
    },
    getRemainsList () {
      if (this.config.product && this.config.product.remains_codes) {
        return this.config.product.remains_codes
      }

      return []
    }
  },
  created () {
    this.filters.name = this.stateFilters.name
    this.filters.vendor_code = this.stateFilters.vendor_code
    this.filters.category_id = this.stateFilters.category_id
    this.filters.season_id = this.stateFilters.season_id
    this.filters.price_from = this.stateFilters.price_from
    this.filters.price_to = this.stateFilters.price_to
    this.filters.remains_code = this.stateFilters.remains_code
    this.getChildCategories()
  }
}
</script>

<style scoped>

</style>
