<template>
  <transition name="fade">
    <div style="display: block" class="popup_box" @click.self="$emit('close-group')">
      <div class="popup list_prod">
        <span class="page_title">Выплата № {{ group }}</span>
        <span class="close_popup" @click.self="$emit('close-group')"></span>
        <div class="table_body">
          <div class="headline_table flx">
            <div class="id_ttn">
              <p class="title">ID・ТТН</p>
            </div>
            <div class="client_info">
              <p>Клиент</p>
            </div>
            <div class="date_change_info">
              <p>ДАТА・Изм</p>
            </div>
            <div class="status_info">
              <p>СТАТУС</p>
            </div>
            <div class="drop_price_info">
              <p>Дроп・сумма</p>
            </div>
            <div class="calculations_info">
              <p>Сумма выплат/списаний</p>
            </div>
            <div class="calculations_info">
              <p>Описание</p>
            </div>
          </div>
          <div class="row_list" v-if="is_loading">
            <div style="padding: 20px">Загрузка данных....</div>
          </div>
          <div class="row_list" v-if="!is_loading">
            <div class="row flx" v-for="invoice in paymentInvoices" :key="invoice.id">
              <div class="id_ttn">
                <span class="orange"><b>{{ invoice.id }}</b></span> <span><b>{{ invoice.order.ttn }}</b></span>
              </div>
              <div class="client_info">
                <span>{{ invoice.order.client_data.first_name }} {{ invoice.order.client_data.last_name }}</span>
              </div>
              <div class="date_change_info">
                <span>{{ invoice.created_at }}</span>
                <span class="grey">{{ invoice.updated_at }}</span>
              </div>
              <div class="status_info">
              <span class="status " :class="getOrderStatusColor(invoice.order)">{{
                  invoice.order.status_name
                }}</span>
              </div>
              <div class="drop_price_info">
                <span>{{ invoice.order.total_discount }} грн</span> <br>
                <span class="grey">{{ invoice.order.total_final }} грн</span>
              </div>
              <div class="calculations_info">
                <span class="grey">{{ getOperationByBillingInfo(invoice.amount)}}:</span> &nbsp;
                <span :class="getBillingInfoColor(invoice.amount)"><b>{{ invoice.amount }} грн </b></span>
              </div>
              <div class="calculations_info">
                <span class="grey">{{invoice.comment}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import helper from '@/helpers/helper'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'PaymentDetailsModel',
  props: ['group'],
  computed: {
    ...mapState('invoices', ['paymentInvoices', 'is_loading'])
  },
  methods: {
    ...mapActions('invoices', ['getPaymentInvoices']),
    ...mapMutations('invoices', ['setPaymentInvoices']),
    getOrderStatusColor (order) {
      return helper.getOrderStatusColor(order.status)
    },
    getOperationByBillingInfo (value) {
      return helper.getOperationByBillingInfo(value)
    },
    getBillingInfoColor (value) {
      return helper.getBillingInfoColor(value)
    }
  },
  watch: {
    group: function (value) {
      if (value) {
        this.setPaymentInvoices([])
        this.getPaymentInvoices(this.group)
      }
    }
  },
  created () {
    this.getPaymentInvoices(this.group)
  }
}
</script>

<style scoped>

</style>
