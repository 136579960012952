<template>
  <section class="table_box new_order inner">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title">Изменить заказ ID:{{order.id}}</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="store">Сохранить</div>
<!--        <div class="btn" @click="$router.go(-1)" v-else>Закрыть</div>-->
      </div>
    </div>
    <div class="container order_form flx">
      <div class="column">
        <order-dropshipper></order-dropshipper>
        <order-client></order-client>
      </div>
      <order-delivery></order-delivery>
      <div class="column">
        <order-payment></order-payment>
      </div>
    </div>
    <div class="container has_table">
      <order-products></order-products>
    </div>
    <div v-if="order.status === 3" class="container has_table">
      <OrderProductsReturn></OrderProductsReturn>
    </div>
    <div class="container has_table">
      <add-order-item></add-order-item>
    </div>
    <delivery-declaration-delete-modal></delivery-declaration-delete-modal>
  </section>
</template>

<script>
import { prices } from '../../../helpers/prices'
import { strings } from '../../../helpers/strings'
import { phones } from '../../../helpers/phones'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import OrderDropshipper from './Moodules/OrderDropshipper'
import OrderClient from './Moodules/OrderClient'
import OrderDelivery from './Moodules/OrderDelivery'
import OrderPayment from './Moodules/OrderPayment'
import OrderProducts from './Moodules/OrderProducts'
import OrderProductsReturn from './Moodules/OrderProductsReturn'
import AddOrderItem from './Moodules/AddOrderItem'
import DeliveryDeclarationDeleteModal from '../../Modals/DeliveryDeclarationDeleteModal'
import { eventBus } from '../../../main'

export default {
  name: 'EditOrder',
  mixins: [
    prices,
    strings,
    phones
  ],
  components: {
    OrderDropshipper,
    OrderClient,
    OrderDelivery,
    OrderPayment,
    OrderProducts,
    AddOrderItem,
    DeliveryDeclarationDeleteModal,
    OrderProductsReturn
  },
  computed: {
    ...mapState('orders', ['order']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapState('users', ['dropshippers']),
    order_data: {
      get () {
        return this.order
      },
      set (val) {
        this.setOrder(val)
      }
    }
  },
  methods: {
    ...mapActions('orders', ['getOrder', 'updateOrder']),
    ...mapMutations('orders', ['clearOrder', 'setOrder']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapActions('delivery', ['getDeliveries']),
    loadOrder () {
      if (Object.keys(this.order_data).length === 0 || (Object.keys(this.order_data).length > 0 && this.order_data.id !== this.$route.params.id)) {
        this.getOrder(this.$route.params.id)
      }
    },
    store () {
      this.updateOrder()
    }
  },
  watch: {
    '$route.params.id': function (id) {
      if (this.$route.name === 'EditOrder') {
        this.loadOrder()
      }
    }
  },
  created () {
    this.clearErrors()
    this.loadOrder()
    this.$nextTick(() => {
      this.getDeliveries()
    })
    eventBus.$on('order_item:updated', () => {
      this.getOrder(this.$route.params.id)
      eventBus.$root.$emit(
        'send_notify',
        'success',
        'Данные обновлены'
      )
    })
  },
  beforeDestroy () {
    eventBus.$off('order_item:updated')
  },
  beforeRouteLeave (from, to, next) {
    this.clearErrors()
    next()
  }
}
</script>

<style scoped>

</style>
