<template>
  <span class="editor">
      <span @click="showInput">
        <svg data-id="decl_drop1" width="10" height="10" viewBox="0 0 10 10" fill="none"
             xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.22065 1.33967C7.67354 0.886778 8.40782 0.886778 8.86071 1.33967C9.3136 1.79255 9.3136 2.52683 8.86071 2.97972L3.66721 8.17322L1.48047 8.71991L2.02715 6.53317L7.22065 1.33967Z"
            stroke="#ADB8C6" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
      </span>
      <input
          v-if="show"
          v-model="edit"
          type="number"
          @keydown.enter="send"
      >
    <select
        v-if="show"
        v-model="discount"
    >
      <option value="%">%</option>
      <option value="грн">грн</option>
    </select>
     <div v-if="show" class="hover-layout" @click.self="hideInput()"></div>
  </span>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FieldDiscountEditor',
  props: [
    'id',
    'field',
    'value',
    'discount_type'
  ],
  data () {
    return {
      show: false,
      edit: null,
      discount: null
    }
  },
  methods: {
    ...mapActions('product', ['updateField']),
    send () {
      this.show = false
      this.updateField({
        id: this.id,
        field: this.field,
        value: this.edit,
        discount: this.discount
      })
    },
    showInput () {
      this.show = true
      this.edit = this.value
      this.discount = this.discount_type
    },
    hideInput () {
      this.send()
      this.show = false
      this.edit = this.value
      this.discount = this.discount_type
    }
  }
}
</script>

<style scoped>
.editor input {
  position: absolute;
  margin-top: -10px;
  width: 100px;
  z-index: 20;
  height: 36px;
  padding: 10px 12px;
  margin-bottom: 17px;
  background-color: #FFFFFF;
  border: 1px solid #E4EBF6;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.editor select {
  position: absolute;
  margin-top: 24px;
  width: 100px;
  z-index: 20;
  background-color: #FFFFFF;
  border: 1px solid #E4EBF6;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 36px;
  padding: 7px;
  color: #3c434a;
}

.hover-layout {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
