<template>
  <section class="table_box new_edit_sender">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title">Новый отправитель</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="store">Сохранить</div>
      </div>
    </div>
    <div class="container tbl_box flx">
      <div class="info_sender_form box_shadow">
        <div class="top">
          <span class="page_subtitle">Информация отправителя</span>
        </div>
        <div class="row_list">
          <div class="row flx">
            <div class="inp_wrap">
              <p>Фамилия:<span>*</span></p>
              <input type="text" id="surname_sender" :class="{required:hasError('last_name')}" required
                     v-model="edit_sender.last_name">
            </div>
            <div class="inp_wrap">
              <p>Имя:<span>*</span></p>
              <input type="text" id="name_sender" :class="{required:hasError('first_name')}" required
                     v-model="edit_sender.first_name">
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap">
              <p>Отчество:</p>
              <input type="text" :class="{required:hasError('middle_name')}" required v-model="edit_sender.middle_name">
            </div>
            <div class="inp_wrap">
              <p>Телефон:<span>*</span></p>
              <input type="tel" id="phone" required :class="{required:hasError('phone')}" v-model="edit_sender.phone"
                     v-mask="phone_mask">
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap">
              <p>Доставка:</p>
              <brew-select
                  class="select-delivery vue-select select-search"
                  :class="{required:hasError('delivery_id')}"
                  :clearable="false"
                  :options="deliveries"
                  label="name"
                  :reduce="item => item.id"
                  v-model="edit_sender.delivery_id"
              ></brew-select>
            </div>
            <div class="inp_wrap">
              <p>Город<span>*</span></p>
              <auto-select
                :class="{required:hasError('city_id')}"
                :items="cities"
                :default-id="edit_sender.city_id"
                :default-name="edit_sender.city_name"
                @search="fetchCities"
                @select="selectCity"
                @active-input="fetchCities"
              ></auto-select>
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap warehouse w-100">
              <p>Склад<span>*</span></p>
              <auto-select
                :class="{required:hasError('branch_id')}"
                :items="branches"
                :default-id="edit_sender.branch_id"
                :default-name="edit_sender.branch_name"
                @search="fetchBranches"
                @select="selectBranch"
                @active-input="fetchBranches"
              ></auto-select>
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap api_key w-100">
              <p>Api ключ:</p>
              <input type="text" id="api_key" :class="{required:hasError('api_key')}" v-model="edit_sender.api_key">
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap api_key w-100">
              <p>Ref контакта:</p>
              <input type="text" id="ref" :class="{required:hasError('ref')}" v-model="edit_sender.ref">
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap email">
              <p>Email:</p>
              <input type="email" id="email" :class="{required:hasError('email')}" v-model="edit_sender.email">
            </div>
            <p class="necessary"><span>*</span>- поля обязательные для заполнения</p>
          </div>
        </div>
      </div>
      <div class="sales_volumes_form box_shadow">
        <div class="top flx">
          <span class="page_subtitle">Объём продаж</span>
        </div>
        <div class="row_list">
          <div class="row flx mb-1">
            <label class="custom-checkbox">
              <input type="checkbox" :checked="edit_sender.is_fop" @change="toggleFop">
              <span></span>
              <span>ФОП</span>
            </label>
          </div>
          <div class="row flx mb-1">
            <label class="custom-checkbox">
              <input type="checkbox" :checked="edit_sender.is_enable_pay_control" @change="togglePayControl">
              <span></span>
              <span>Контроль оплат (Новая Почта)</span>
            </label>
          </div>
          <div class="row flx">
            <div class="inp_wrap w-100">
              <p>Обьем продаж:<span>*</span></p>
              <input type="text" :class="{required:hasError('volume')}" required v-model.number="edit_sender.volume">
              <p>Цвет</p>
              <input type="color" v-model="edit_sender.color" style="padding: 0;width: 100px">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import AutoSelect from '../../Helpers/AutoSelect'

export default {
  name: 'CreateSender',
  directives: {
    mask
  },
  components: {
    AutoSelect
  },
  data () {
    return {
      timer: null,
      is_ua: null,
      phone_mask: '+38(0##) ###-##-##',
      is_fop: false
    }
  },
  computed: {
    ...mapState('senders', ['sender']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapState('delivery', ['cities', 'branches', 'deliveries']),
    edit_sender: {
      get () {
        return this.sender
      },
      set (val) {
        this.setSender(val)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    }
  },
  methods: {
    ...mapActions('senders', ['createSender', 'clearSender']),
    ...mapMutations('senders', ['setSender']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapActions('delivery', ['getCities', 'getBranches', 'getDeliveries']),
    ...mapMutations('delivery', ['setCities', 'setBranches', 'setSearchBranch']),
    store () {
      this.createSender()
        .then(path => {
          this.$router.push({ name: path })
        })
    },
    fetchCities (search) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getCities(search)
      }, 200)
    },
    fetchBranches (search) {
      this.$set(this.edit_sender, 'branch_id', null)
      this.$set(this.edit_sender, 'branch_name', null)

      if (this.edit_sender.city_id) {
        this.setSearchBranch(search)
        this.getBranches({
          city_id: this.edit_sender.city_id,
          is_ua: this.is_ua
        })
      }
    },
    toggleFop (e) {
      if (e.target.checked) {
        this.edit_sender.volume = 5000000
        this.edit_sender.is_fop = true
      } else {
        this.edit_sender.volume = 500000
        this.edit_sender.is_fop = false
      }
    },
    togglePayControl (e) {
      if (e.target.checked) {
        this.edit_sender.is_enable_pay_control = true
      } else {
        this.edit_sender.is_enable_pay_control = false
      }
    },
    selectCity (data) {
      this.edit_sender.city_id = data.id
      this.edit_sender.city_name = data.name
      this.is_ua = data.is_ua
      this.fetchBranches(data)
    },
    selectBranch (data) {
      this.edit_sender.branch_id = data.id
      this.edit_sender.branch_name = data.name
    }
  },
  created () {
    this.clearSender()
    this.clearErrors()
    this.clearSender()
    this.$nextTick(() => {
      this.getDeliveries()
    })
  },
  beforeRouteLeave (to, from, next) {
    this.clearSender()
    this.clearErrors()
    this.clearSender()
    next()
  }
}
</script>

<style scoped>

</style>
