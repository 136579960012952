import { eventBus } from '../../main'

export default {
  namespaced: true,
  state: {
    errors: {}
  },
  getters: {
    hasErrors: state => {
      return Object.keys(state.errors).length > 0
    },
    hasError: state => field => {
      return Object.prototype.hasOwnProperty.call(state.errors, [field])
    }
  },
  mutations: {
    setErrors (state, errors) {
      state.errors = errors
      if (Object.keys(state.errors).length > 0) {
        let errorDescription = ''
        for (const [key, value] of Object.entries(state.errors)) {
          console.log(`${key}: ${value}`)
          errorDescription += value[0] + '<br>'
        }

        eventBus.$root.$emit(
          'send_notify',
          'error',
          'Ошибки:',
          errorDescription
        )
      }
    },
    clearErrors (state) {
      state.errors = {}
    }
  }
}
