<template>
  <div class="filters_box open_box" :class="{active: show_client_filters}" data-id="filters_box">
    <div class="filters_head flx">
      <div class="wrap_title flx">
        <span class="page_title">Фильтры</span>
        <span class="result">Результат {{ pagination.from }} - {{ pagination.to }} из {{ pagination.total }}</span>
      </div>
      <div class="flx">
        <div class="reset" @click="clearFilters" >Сбросить фильтры</div>
        <button class="btn empty"  @click="getClients" :disabled="count_client_filters === 0">Применить</button>
      </div>
    </div>
    <div class="filters_table flx">
      <div class="column flx">
        <p>ID <span class="info_grey"> </span></p>
        <input type="text" placeholder="ID" id="client_id" v-model="filters.client_id" @click.enter="getClients" v-format.integer>
        <p>Город</p>
        <input type="text" placeholder="Город" id="city" v-model="filters.city" @click.enter="getClients">
      </div>
      <div class="column flx">
        <p>Фамилия</p>
        <input type="text" placeholder="Все" v-model="filters.last_name" @click.enter="getClients">
        <p>Дата последнего заказа</p>
        <div class="wrap_from_to datepicker_to flx">
          <VueDatePicker v-model="filters.last_order_from"  placeholder="От" value-type="YYYY-MM-DD" format="От YYYY-MM-DD" lang="ru" />
<!--          <input type="text"  placeholder="От" v-model="filters.last_order_from" v-mask="date_mask" @click.enter="getClients">-->
          <VueDatePicker v-model="filters.last_order_to"  placeholder="До" value-type="YYYY-MM-DD" format="До YYYY-MM-DD" lang="ru" />
<!--          <input type="text"  placeholder="До" v-model="filters.last_order_to" v-mask="date_mask" @click.enter="getClients">-->
        </div>
      </div>
      <div class="column flx">
        <p>Имя</p>
        <input type="text" placeholder="Имя" id="surname" v-model="filters.first_name" @click.enter="getClients">
        <p>Добавил клиента</p>
        <div class="select select-full" >
          <i class="ti-angle-down"></i>
          <brew-select
              name="select"
              class="vue-select"
              v-model="filters.created_id"
              :clearable="false"
              :options="creators"
              :reduce="item => item.id"
              label="name"
          >
          </brew-select>
        </div>
      </div>
      <div class="column flx">
        <p>Номер телефона</p>
        <input type="tel" placeholder="Номер телефона" v-model="filters.phone" v-mask="phone_mask" @click.enter="getClients">
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { format } from '../../../helpers/format'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/uk'
export default {
  name: 'ClientFilter',
  directives: {
    format,
    mask
  },
  components: {
    VueDatePicker: () => import('vue2-datepicker')
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##',
      date_mask: '##-##-20##'
    }
  },
  computed: {
    ...mapState('clients', ['show_client_filters', 'client_filters', 'pagination']),
    ...mapGetters('clients', ['count_client_filters']),
    ...mapState('users', ['creators']),
    filters: {
      get () {
        return this.client_filters
      },
      set (val) {
        this.setClientFilters(val)
      }
    }
  },
  methods: {
    ...mapActions('clients', ['toggleClientFilters', 'getClients', 'clearClientFilters']),
    ...mapMutations('clients', ['setPage', 'setClientFilters']),
    ...mapActions('users', ['getCreators']),
    clearFilters () {
      this.clearClientFilters()
      this.$nextTick(() => {
        this.setPage(1)
        this.getClients()
        this.toggleClientFilters()
      })
    }
  },
  created () {
    this.getCreators()
  }
}
</script>

<style scoped>

</style>
