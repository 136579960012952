import axios from 'axios'
import { eventBus } from '../../main'
import router from '../../router'
// import { eventBus } from '@/main'

const defaultBanner = {
  name: null,
  type: null,
  is_active: false,
  banner: '',
  link: '#'
}

const baseUrl = 'api/banners/'

export default {
  namespaced: true,
  state: {
    banner_is_load: false,
    position_list: {
      1: 'Главная'
    },
    banners: [],
    create_banner: defaultBanner,
    edit_banner: defaultBanner,
    filters: {
      search: null,
      page: 1,
      per_page: localStorage.getItem('banner-per-page') || 10
    },
    pagination: {
      last_page: 1
    },
    show_delete: false
  },
  mutations: {
    setIsLoad (state, v) {
      this.banner_is_load = v
    },
    setBanners (state, payload) {
      state.banners = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPage (state, v) {
      state.filters.page = v
    },
    setSearch (state, v) {
      state.filters.search = v
    },
    setPerPage (state, v) {
      state.filters.per_page = v
      localStorage.setItem('banner-per-page', v)
    },
    setEditBanner (state, payload) {
      state.edit_banner = payload
    },
    clearEditDate (state) {
      state.edit_banner = defaultBanner
    },
    setShowDelete (state, v) {
      state.show_delete = v
    }
  },
  actions: {
    getBanners ({ commit, state }) {
      axios.get(baseUrl,
        {
          params: state.filters
        }
      )
        .then(res => {
          commit('setBanners', res.data.data)
          commit('setPagination', res.data.meta)
        })
    },
    getBanner ({ commit }, id) {
      axios.get(baseUrl + id).then(res => {
        commit('setEditBanner', res.data.data)
      })
    },
    createBanner ({ state }, data) {
      if (state.banner_is_load) {
        return
      }
      state.banner_is_load = true
      axios.post(baseUrl, data).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Сохранено'
        )
        state.banner_is_load = false
        router.go(-1)
      }).catch(e => {
        state.banner_is_load = false
      })
    },
    updateBanner ({ state }, formData) {
      if (state.banner_is_load) {
        return
      }
      state.banner_is_load = true
      axios.post(baseUrl + state.edit_banner.id, formData).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Обновлено'
        )
        state.banner_is_load = false
        router.go(-1)
      }).catch(e => {
        state.banner_is_load = false
      })
    },
    updatePublish ({ state }, data) {
      axios.put(baseUrl + data.id, {
        is_active: data.value
      }).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Обновлено'
        )
      })
    },
    updateSort ({ state }, data) {
      axios.put(baseUrl + data.id, {
        sort: data.value
      }).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Обновлено'
        )
      })
    },
    deleteBanner ({ dispatch, state }) {
      axios.delete(baseUrl + state.edit_banner.id).then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Удалено'
        )
        dispatch('getBanners')
      })
    }
  }
}
