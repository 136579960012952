<template>
  <section class="table_box all_roles">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Список чеков
        <p v-if="pagination.from">Результат: {{ pagination.from }} - {{ pagination.to }} из {{ pagination.total }}</p>
        </span>
      </div>
    </div>
    <div class="container has_table">
      <div class="table_body table_all_roles">
        <div class="top_table flx">
          <div class="choose_seller flx">
            <p>Статус:</p>
            <select v-model="filterStatus" @change="confirmFilter" class="select-pagination select-users-per_page">
              <option value="">Все</option>
              <option value="1">Ожидает отправку</option>
              <option value="2">В процессе</option>
              <option value="3">Успешно</option>
              <option value="4">Ошибка</option>
            </select>
          </div>
          <div class="wrap_search flx">
            <!--            <button type="submit"></button>-->
            <!--            <input type="text" v-model="search" placeholder="Поиск..." id="search">-->
          </div>
          <div class="pagination flx">
            <div class="flx">
              <p class="desctop_v">Строк:</p>
              <brew-select
                  class="select-pagination select-users-per_page"
                  style="margin-left:10px;width: 100px"
                  v-model="perPage"
                  :clearable="false"
                  :options="page_sizes"
              >
              </brew-select>
            </div>
            <paginate
                style="margin-left: 20px"
                class="flx"
                v-model="page"
                :page-count="pagination.last_page"
                :page-range="3"
                :margin-pages="2"
                :prev-text="'<'"
                :next-text="'>'"
            >
            </paginate>
          </div>
        </div>
        <div class="bot_table">
          <headline :headers="headers"></headline>
          <div class="row_list">
            <div class="row flx" style="cursor: auto" v-for="check in checkboxList" :key="'check-' + check.id">
              <div class="id_role">
                {{ check.id }}
              </div>
              <div class="name_role">
                <p>
                  <a target="_blank"
                     :href="'/dashboard/administration/senders/edit/' + check.sender_id">[{{ check.sender_id }}]</a>
                  {{ check.sender_name }}
                </p>
              </div>
              <div class="name_role">
                <p><a target="_blank" :href="'/dashboard/orders/orders/' + check.order_id">{{ check.order_id }}</a></p>
              </div>
              <div class="name_role">
                <p><a target="_blank" :href="'https://check.checkbox.ua/'+check.check_id">{{ check.check_id }}</a></p>
              </div>
              <div class="name_role">
                <div v-html="getStatus(check.status)"></div>
                <span v-if="check.description" style="font-size: 12px;color:#3C434A">{{ check.description }}</span>
              </div>
              <div class="date_of_creation_role">
                <span>{{ check.date }}</span>
              </div>
              <div class="">
                <div><img
                    v-if="check.status === 4"
                    style="cursor: pointer"
                    @click="sendRefreshStatus(check.id)" src="/images/refresh.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { dates } from '../../../helpers/dates'
import Headline from '../../Helpers/Headline'

let timer
export default {
  name: 'CheckList',
  mixins: [
    dates
  ],
  components: {
    Headline
  },
  data () {
    return {
      page_sizes: [10, 50, 100],
      timer: null,
      headers: [
        {
          title: 'ID',
          sortable: false,
          class: 'id_role',
          value: 'id'
        },
        {
          title: 'Кассир',
          class: 'name_role',
          sortable: false,
          value: 'name'
        },
        {
          title: 'Заказ №',
          class: 'name_role',
          sortable: false,
          value: 'order'
        },
        {
          title: 'Ссылка (ID Чека)',
          class: 'name_role',
          sortable: false,
          value: 'link'
        },
        {
          title: 'Статус',
          class: 'name_role',
          sortable: false,
          value: 'status'
        },
        {
          title: 'Дата создания',
          class: 'date_of_creation_role',
          sortable: false,
          value: 'date'
        },
        {
          title: 'Действие',
          class: '',
          sortable: false,
          value: ''
        }
      ]
    }
  },
  computed: {
    ...mapState('checkboxes', [
      'checkboxList',
      'pagination',
      'filters',
      'is_load'
    ]),
    search:
        {
          get: function () {
            return this.filters.search
          },
          set: function (value) {
            clearTimeout(timer)
            this.setSearch(value)
            timer = setTimeout(() => {
              this.setPage(1)
              this.getCheckList()
            }, 500)
          }
        },
    page: {
      get: function () {
        return this.filters.page
      },
      set: function (value) {
        this.setPage(value)
        this.getCheckList()
      }
    },
    filterStatus: {
      get () {
        return this.filters.status
      },
      set (v) {
        this.setFilterStatus(v)
      }
    },
    perPage: {
      get () {
        return this.filters.per_page
      },
      set (perPage) {
        this.setPage(1)
        this.setPerPage(perPage)
        this.getCheckList()
      }
    }
  },
  methods: {
    ...mapActions('checkboxes', ['getCheckList', 'refreshStatus']),
    ...mapMutations('checkboxes', ['setPage', 'setSearch', 'setPerPage', 'setFilterStatus']),
    sendRefreshStatus (id) {
      const p = this.refreshStatus(id)
      p.then(r => {
        this.getCheckList()
      })
    },
    confirmFilter () {
      this.getCheckList()
    },
    getStatus (id) {
      let h = ''
      switch (id) {
        case 1:
          h = '<span style="color: #37afff">Ожидает отправку</span>'
          break
        case 2:
          h = '<span style="color: #55e7ad">В процессе</span>'
          break
        case 3:
          h = '<span style="color: green">Успешно</span>'
          break
        case 4:
          h = '<span style="color: red">Ошибка</span>'
          break
        default:
      }

      return h
    }
  },
  created () {
    this.getCheckList()
  }
}
</script>

<style scoped>

</style>
