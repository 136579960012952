<template>
  <transition name="fade">
    <div v-if="show" class="popup_box" style="display: block">
      <div class="popup delete_category">
        <span class="page_title">Удалить пользователя [{{user_on_delete.id}}] {{user_on_delete.name}}  ?</span>
        <span class="close_popup" @click="close()"></span>
        <button class="btn" @click="close()">Отмена</button>
        <button class="btn empty" @click="confirm()">Удалить</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'DeleteUserModal',
  computed: {
    ...mapState('users', ['user_on_delete', 'show_user_delete_modal']),
    show: {
      get () {
        return this.show_user_delete_modal
      },
      set (value) {
        this.setShowUserDeleteModal(value)
      }
    }
  },
  methods: {
    ...mapActions('users', ['deleteUser']),
    ...mapMutations('users', ['setShowUserDeleteModal', 'setUserOnDelete']),
    close () {
      this.setUserOnDelete(null)
      this.show = false
    },
    confirm () {
      this.deleteUser(this.user_on_delete.id)
      this.close()
    }
  }
}
</script>

<style scoped>

</style>
