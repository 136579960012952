<template>
  <div class="table_body">
    <div class="top_table flx">
      <div class="search_box flx">
        <div v-if="invoice_user && invoice_user.card">
          <span style="margin-right: 30px">Карта: <b style="color: darkorange">{{invoice_user.card}}</b></span>
        </div>
        <div v-if="invoice_user && invoice_user.score">
          <span>Номер расчетного счета: <b style="color: darkorange">{{invoice_user.score}}</b></span>
        </div>
      </div>
    </div>
    <div class="top_table flx">
      <div class="search_box flx">
        <p>Продавец:</p>
        <div class="flx">
          <user-select
            class="mini-select"
            style="min-width: 225px;margin-right: 10px"
            :default-id="edit_user.id"
            :default-name="edit_user.name"
            :items="dropshippers"
            @search="fetchDropshippers"
            @select="setSelectUserId"
            @active-input="fetchDropshippers"
          ></user-select>
          <div class="wrap_pay flx" v-if="invoice_user">
            <span v-if="invoice_user.billing_info.total !== 0">{{ invoice_user.billing_info.debit | asPriceNoCurrency }} {{ invoice_user.billing_info.pay | asPriceNoCurrency }}</span>
            <span v-if="invoice_user.billing_info.total !== 0">{{  getOperationByBillingInfo(invoice_user.billing_info.total) }}: <b :class="getBillingInfoColor(invoice_user.billing_info.total)">{{ invoice_user.billing_info.total | asPrice }}</b></span>
          </div>
        </div>
      </div>
      <div class="pagination flx">
        <div class="flx">
          <p class="desctop_v">Строк:</p>
          <div class="select" id="sellers">
            <brew-select
                class="select-pagination select-dropshipper-per_page"
                style="margin-left:10px;width: 100px"
                v-model="perPage"
                :clearable="false"
                :options="page_sizes"
            >
            </brew-select>
          </div>
        </div>
        <paginate
            style="margin-left: 20px"
            class="flx"
            v-model="page"
            :page-count="opened_pagination.last_page"
            :page-range="3"
            :margin-pages="2"
            :prev-text="'<'"
            :next-text="'>'"
        >
        </paginate>
      </div>
    </div>
    <div class="bot_table">
      <headline :headers="headers" :selected-all.sync="isSelectAll" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
      <div class="row_list">
        <div class="row flx"
             :class="[{ checked: selected && selected.indexOf(invoice.id) !== -1 }]"
             :rel="invoice.id"
             v-for="invoice in opened_invoices"
             :key="'invoice-'+invoice.id"
        >
          <div
             v-if="invoice.order"
            class="id_ttn"
               @click="$router.push({ name: 'Order', params: { id: invoice.order.id } })"
          >
            <a>{{ invoice.order.id }}</a>
            <span v-if="invoice.order.ttn"><b>{{ invoice.order.ttn | asTTN }}</b></span>
          </div>
          <div v-else class="id_ttn">Заказ удален</div>
          <div class="client_info" v-if="invoice.order">
            <span>[<router-link class="edit_ico" :to="{name: 'ShowClient', params: {id: invoice.order.client_data.id}}">{{ invoice.order.client_data.id }}</router-link>]</span>
            <span>{{ invoice.order.client_data.last_name }} {{ invoice.order.client_data.first_name | firstLetter }} {{ invoice.order.client_data.middle_name | firstLetter }}</span>
          </div>
          <div class="client_info" v-else> - </div>
          <div class="date_change_info" >
            <span>{{ invoice.created_at }}</span>
            <span class="grey">{{ invoice.updated_at }}</span>
          </div>
          <div class="status_info" v-if="invoice.order">
            <span class="status" :class="getOrderStatusColor(invoice.order)">{{ invoice.order.status_name }}</span>
          </div>
          <div class="status_info" v-else> - </div>
          <div class="drop_price_info" v-if="invoice.order">
            <span>{{ invoice.order.total_discount | asPrice }}</span>
            <span class="grey">{{ invoice.order.total_final | asPrice }}</span>
          </div>
          <div class="drop_price_info" v-else> - </div>
          <div class="delivery_status_info" v-if="invoice.order">
            <span class="status" :class="getOrderDeliveryColor(invoice.order)">{{ invoice.order.delivery_status_name }}</span>
          </div>
          <div class="delivery_status_info" v-else> - </div>
          <div class="calculations_info" >
            <div class="item flx">
              <span v-if="invoice.amount !== 0">{{  getOperationByBillingInfo(invoice.amount) }}:</span>
              <span class="line" v-if="invoice.amount !== 0"> </span>
              <p :class="getBillingInfoColor(invoice.amount)"><b>{{ invoice.amount | asPrice }}</b></p>
<!--              <span>Забрать:</span>-->
<!--              <span class="line"></span>-->
<!--              <p class="green"><b>74.00 грн </b></p>-->
            </div>
          </div>
          <div class="documents_info flx">
            <label class="custom-checkbox">
              <input type="checkbox" name="remembered" v-model="selected"  :value="invoice.id">
              <span></span>
            </label>
            <div>
              <span>[<b>{{ invoice.id }}</b>] {{ invoice.comment }}</span>
            </div>
          </div>
<!--          TODO invoices comment -->
          <div class="icon_wrap">
            <svg class="open_drop" data-id="list_payment_info2" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.24443 3.93334C3.86397 3.93334 3.55554 4.24176 3.55554 4.62222C3.55554 5.00269 3.86397 5.31111 4.24443 5.31111H9.75554C10.136 5.31111 10.4444 5.00269 10.4444 4.62222C10.4444 4.24176 10.136 3.93334 9.75554 3.93334H4.24443Z" fill="#ADB8C6"/>
              <path d="M4.24443 6.68889C3.86397 6.68889 3.55554 6.99732 3.55554 7.37778C3.55554 7.75824 3.86397 8.06667 4.24443 8.06667H7.68888C8.06934 8.06667 8.37777 7.75824 8.37777 7.37778C8.37777 6.99732 8.06934 6.68889 7.68888 6.68889H4.24443Z" fill="#ADB8C6"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.799988 1.86667C0.799988 1.10682 1.41792 0.488892 2.17777 0.488892H11.8222C12.5821 0.488892 13.2 1.10682 13.2 1.86667V10.1333C13.2 10.8932 12.5821 11.5111 11.8222 11.5111H2.17777C1.41792 11.5111 0.799988 10.8932 0.799988 10.1333V1.86667ZM10.8669 10.1333H3.13306H2.17777V9.17804V2.82196V1.86667H3.13306H10.8669H11.8222V2.82196V9.17805V10.1333H10.8669Z" fill="#ADB8C6"/>
            </svg>
            <div class="menu_drop" data-id="list_payment_info2">
              <div class="dropdown">
                <div class="row flx">
                  <div class="left">
                    <div class="item flx">
                      <span>Отдать:</span>
                      <span class="line"></span>
                      <p>-149.00 грн</p>
                    </div>
                    <div class="item flx">
                      <span>Забрать:</span>
                      <span class="line"></span>
                      <p>149.00 грн</p>
                    </div>
                    <div class="item flx">
                      <span>Забрать:</span>
                      <span class="line"></span>
                      <p class="green"><b>149.00 грн</b></p>
                    </div>
                  </div>
                  <div class="right">
                    <span>Doc: <b>18810</b> [Выплата продавцу]</span>
                    <span>Doc: <b>26602</b> [Doc: <b>18810</b>] [Аннуляция]</span>
                    <div class="flx checkbox">
                      <label class="custom-checkbox">
                        <input type="checkbox" name="remembered" value="remembered">
                        <span></span>
                      </label>
                      <span>Doc: <b>3268</b> [Компенсация доставки]</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { strings } from '../../../../helpers/strings'
import helper from '../../../../helpers/helper'
import { prices } from '../../../../helpers/prices'
import { numbers } from '../../../../helpers/numbers'
import _ from 'lodash'
import Headline from '../../../Helpers/Headline'
import UserSelect from '../../../Helpers/UserSelect'

export default {
  name: 'OpenInvoices',
  mixins: [
    strings,
    prices,
    numbers
  ],
  components: {
    Headline,
    UserSelect
  },
  data () {
    return {
      page_sizes: [10, 50, 100, 1000],
      headers: [
        {
          title: 'ID・ТТН',
          title_class: 'title',
          sortable: true,
          class: 'id_ttn',
          value: 'order_id'
        },
        {
          title: 'Клиент',
          class: 'client_info'
        },
        {
          title: 'ДАТА・Изм',
          class: 'date_change_info',
          value: 'created_at',
          sortable: true
        },
        {
          title: 'СТАТУС',
          class: 'status_info'
        },
        {
          title: 'Дроп・сумма',
          class: 'drop_price_info'
        },
        {
          title: 'Состояние доставки',
          class: 'delivery_status_info'
        },
        {
          title: 'Расчёт',
          class: 'calculations_info'
        },
        {
          title: 'Документы',
          class: 'documents_info flx',
          check_all: true,
          wrapper: true
        },
        {
          title: '',
          class: 'icon_wrap'
        }
      ]
    }
  },
  computed: {
    ...mapState('invoices', ['opened_invoices', 'opened_filters', 'opened_pagination', 'invoice_user', 'invoice_users', 'invoice_user', 'selected_invoices']),
    ...mapState('users', ['dropshippers', 'edit_user']),
    ...mapGetters('users', ['getDefaultDropshipperFilters']),
    selected: {
      get () {
        return this.selected_invoices
      },
      set (val) {
        this.setSelectedInvoices(val)
      }
    },
    filters: {
      get () {
        return this.opened_filters
      }
    },
    page: {
      get: function () {
        return this.opened_filters.page
      },
      set: function (value) {
        this.setOpenedPage(value)
        this.getOpenedInvoices()
      }
    },
    perPage: {
      'get' () {
        return this.opened_filters.per_page
      },
      'set' (perPage) {
        this.setOpenedPerPage(perPage)
        this.setOpenedPage(1)
        this.getOpenedInvoices()
      }
    },
    isSelectAll: {
      get () {
        if (this.opened_invoices.length === 0) {
          return false
        }
        return this.selected && this.selected.length === this.opened_invoices.length && this.selected.length > 0
      },
      set (value) {
        if (value) {
          this.selected = _.map(this.opened_invoices, 'id')
        } else {
          this.selected = []
        }
      }
    },
    sortBy: {
      get () {
        return this.filters.order_by
      },
      set (val) {
        this.setOpenedInvoiceOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.filters.order_direction
      },
      set (val) {
        this.setOpenedInvoiceOrderDirection(val)
      }
    }
  },
  methods: {
    ...mapActions('invoices', ['getOpenedInvoices', 'getInvoiceUser']),
    ...mapMutations('invoices', ['setOpenedPage', 'setOpenedPerPage', 'setOpenedSearch', 'setSelectedInvoices', 'setOpenedInvoiceOrderBy', 'setOpenedInvoiceOrderDirection']),
    ...mapActions('users', ['getDropshippersForSelect', 'getUser']),
    ...mapMutations('users', ['setDropshipperFilters', 'setSearch', 'setDropshippers']),
    setUserId (userId) {
      if (userId !== undefined && userId !== 'all') {
        this.$set(this.opened_filters, 'user_id', +userId)
        this.getInvoiceUser(+userId)
      }
      this.getOpenedInvoices()
    },
    setSelectUserId (value) {
      if (value.id && value.id !== 0) {
        this.$set(this.opened_filters, 'user_id', +value.id)
        this.$router.push({ name: 'DropshipperOrders', params: { id: value.id } })
        this.setSelectedInvoices([])
      }
      this.setDropshippers([])
    },
    getOrderStatusColor (order) {
      return helper.getOrderStatusColor(order.status)
    },
    getOrderDeliveryColor (order) {
      return helper.getOrderDeliveryColor(order.delivery_status)
    },
    getOrderClassByStatus (order) {
      return helper.getOrderClassByStatus(order.status)
    },
    getOperationByBillingInfo (value) {
      return helper.getOperationByBillingInfo(value)
    },
    getBillingInfoColor (value) {
      return helper.getBillingInfoColor(value)
    },
    fetchDropshippers (search) {
      this.setSearch(search)
      this.getDropshippersForSelect()
    }
  },
  watch: {
    $route (to, from) {
      if (to.params.id !== from.params.id) {
        this.setUserId(to.params.id)
        this.getUser(to.params.id)
      }
    },
    sortBy () {
      this.getOpenedInvoices()
    },
    sortDesc () {
      this.getOpenedInvoices()
    }
  },
  created () {
    this.setUserId(this.$route.params.id)
    this.getUser(this.$route.params.id)
    this.setSelectedInvoices([])
    const dropshipperFilters = this.getDefaultDropshipperFilters
    dropshipperFilters.per_page = 99999
    this.setDropshipperFilters(dropshipperFilters)
    this.$nextTick(() => {
      this.getDropshippersForSelect()
      // this.getOpenedInvoices()
    })
  }
}
</script>

<style scoped>

</style>
