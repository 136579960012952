<template>
    <transition name="fade">
        <div v-if="show" class="popup_box" style="display: block">
            <div class="popup delete_category">
                <span class="page_title">Удалить скидку продавца: {{discount.user.name}}?</span>
                <span class="close_popup" @click="close()"></span>
                <button class="btn" @click="close()">Отмена</button>
                <button class="btn empty" @click="confirm()">Удалить</button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'DiscountDeleteItemModal',
  computed: {
    ...mapState('discount', ['show_discount_delete_modal', 'discount_on_delete']),
    show: {
      get () {
        return this.show_discount_delete_modal
      },
      set (value) {
        this.setShowDiscountDeleteModal(value)
      }
    },
    discount: {
      get () {
        return this.discount_on_delete
      },
      set (value) {
        this.setDiscountOnDelete(value)
      }
    }
  },
  methods: {
    ...mapActions('discount', {
      remove: 'deleteDiscount'
    }),
    ...mapMutations('discount', ['setShowDiscountDeleteModal', 'setDiscountOnDelete']),
    confirm () {
      this.remove(this.discount)
      this.close()
    },
    close () {
      this.show = false
      this.discount = null
    }
  }
}
</script>

<style scoped>

</style>
