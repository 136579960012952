<template>
  <div class="customer_form box_shadow">
    <div class="top flx">
      <span class="page_subtitle">Получатель <span>(Клиент)</span></span>
      <span class="note red" v-if="error_client">Выберите клиента!</span>
    </div>
    <div class="bot" v-if="order_data.client_data">
      <div class="row flx">
        <div class="inp_wrap">
          <p>Фамилия:<span>*</span></p>
          <div class="select">
            <input type="text" id="last_name"
                   :class="{required:hasError('client_data.last_name'), disabled: disabled_field }" required
                   v-model="order_data.client_data.last_name" :disabled="disabled_field">
          </div>
        </div>
        <div class="inp_wrap">
          <p>Имя:<span>*</span></p>
          <input type="text" id="surname"
                 :class="{required:hasError('client_data.first_name'), disabled: disabled_field}" required
                 v-model="order_data.client_data.first_name" :disabled="disabled_field">
        </div>
      </div>
      <div class="row flx">
        <div class="inp_wrap">
          <p>Телефон:<span>*</span></p>
          <input type="tel" id="number_phone" required
                 :class="{required:hasError('client_data.phone'), disabled: disabled_field}"
                 v-model="order_data.client_data.phone" v-mask="phone_mask" :disabled="disabled_field">
        </div>
        <div class="inp_wrap">
          <p>Отчество:</p>
          <input type="text" id="patronymic"
                 :class="{required:hasError('client_data.middle_name'), disabled: disabled_field}" required
                 v-model="order_data.client_data.middle_name" :disabled="disabled_field">
        </div>
      </div>
      <div class="row flx">
        <div class="inp_wrap">
          <p>Доставка:</p>
          <div class="select" id="delivery_select">
            <super-select
              key="delivery-select"
              :class="{required:hasError('client_data.delivery_id'), disabled: disabled_field}"
              item-key="order_delivery"
              field-name="name"
              v-model="order_data.client_data.delivery_id"
              :items="deliveries"
              searchable="false"
            />
          </div>
        </div>
        <div class="inp_wrap" v-if="order_data.client_data.delivery_id == 1">
          <p>Тип доставки:</p>
          <div class="select" id="delivery_select2">
            <super-select
              key="delivery-type-select"
              item-key="order_type_delivery"
              :class="{required:hasError('delivery_method_id'), disabled: disabled_field}"
              field-name="name"
              v-model="order_data.delivery_method_id"
              :items="delivery_methods"
              searchable="false"
            />
          </div>
        </div>
      </div>
      <div class="row flx" v-if="order_data.client_data.delivery_id == 1">
        <div class="inp_wrap w-100">
          <p>Город:<span>*</span></p>
          <div class="select">
            <super-select
              key="city-select"
              item-key="order_city"
              field-name="name"
              field-name-alt="name_ua"
              :lang-toggle="order_data.client_data.is_ua"
              :items="cities"
              :init-selected="order_data.client_data.city"
              @selected="selectCity"
              @search="fetchCities"
              clearable="true"
            />
          </div>
        </div>
      </div>
      <div class="row flx" v-if="order_data.client_data.delivery_id == 1 && order_data.delivery_method_id == 1">
        <div class="inp_wrap w-100">
          <p>Склад:<span>*</span></p>
          <div class="select">
            <super-select
              key="branch-select"
              item-key="order_branch"
              field-name="name"
              field-name-alt="name_ua"
              :lang-toggle="order_data.client_data.is_ua"
              :items="branches"
              :init-selected="order_data.client_data.branch"
              @search="fetchBranches"
              @selected="selectBranch"
              clearable="true"
            />
          </div>
        </div>
      </div>
      <div class="row flx" v-if="order_data.client_data.delivery_id == 1 && order_data.delivery_method_id == 2">
        <div class="inp_wrap w-100">
          <p>Адрес:<span>*</span></p>
          <div class="select">
            <super-select
              key="address-select"
              item-key="order_address"
              field-name="name"
              :items="addresses"
              :init-selected="order_data.client_data.address.street"
              @search="fetchAddresses"
              @selected="selectAddress"
              clearable="true"
            />
<!--            <auto-select-->
<!--              key="addressx"-->
<!--              :default-name="(order_data.client_data.address && order_data.client_data.address.street) ? order_data.client_data.address.street.name : '' "-->
<!--              :items="addresses"-->
<!--              @search="fetchAddresses"-->
<!--              @select="selectAddress"-->
<!--              @active-input="fetchAddresses"-->
<!--            ></auto-select>-->
          </div>
        </div>
      </div>
      <div class="row flx" v-if="order_data.client_data.delivery_id == 1 && order_data.delivery_method_id == 2">
        <div class="inp_wrap">
          <p>Дом:<span>*</span></p>
          <input type="text" id="patronymicd" :class="{required:hasError('client_data.address.house')}" required
                 v-model="order_data.client_data.address.house">
        </div>
        <div class="inp_wrap">
          <p>Квартира:</p>
          <input type="text" id="patronymicdf" :class="{required:hasError('client_data.address.appt')}"
                 v-model="order_data.client_data.address.appt">
        </div>
      </div>
      <div class="row flx">
        <p><span>*</span>- поля обязательные для заполнения</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import { strings } from '../../../../helpers/strings'
import _ from 'lodash'
import SuperSelect from '../../../Helpers/SuperSelect/SuperSelect'

export default {
  name: 'OrderClient',
  directives: {
    mask
  },
  components: {
    SuperSelect
  },
  mixins: [
    strings
  ],
  data () {
    return {
      delivery_methods: [
        { id: 1, name: 'На отделение' }, { id: 2, name: 'Адресная доставка' }
      ],
      isUa: false,
      timer: null,
      phone_mask: '+38(0##) ###-##-##',
      show_password: false
    }
  },
  watch: {
    'order_data.client_data.delivery_id' (value) {
      this.order_data.delivery_data.delivery_id = value
    }
  },
  computed: {
    ...mapState('orders', ['order']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapState('clients', ['clients', 'edit_client']),
    ...mapState('delivery', ['cities', 'branches', 'deliveries', 'addresses']),
    order_data: {
      get () {
        return this.order
      },
      set (val) {
        this.setOrder(val)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    },
    error_client () {
      return this.hasError('client_data') ||
        this.hasError('client_data.first_name') ||
        this.hasError('client_data.last_name') ||
        this.hasError('client_data.dropshipper_id') ||
        this.hasError('client_data.branch_id') ||
        this.hasError('client_data.city_id')
    },
    client: {
      get () {
        return this.edit_client
      }
    },
    disabled_field () {
      return false
      // return this.order_data.ttn !== null || this.order_data.delivery_status > 1 || this.order_data.status > 1
    }
  },
  methods: {
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapActions('users', ['getDropshippers']),
    ...mapActions('clients', ['searchClients']),
    ...mapActions('delivery', ['getCities', 'getBranches', 'getDeliveries', 'getAddresses']),
    ...mapMutations('clients', ['setEditClient', 'clearEditClient']),
    ...mapMutations('delivery', ['setCities', 'setBranches', 'setSearchBranch', 'setAddresses']),
    getBranchName () {
      if (this.order_data.client_data.branch && this.order_data.client_data.is_ua) {
        return this.order_data.client_data.branch.name_ua ? this.order_data.client_data.branch.name_ua : ''
      }

      if (this.order_data.client_data.branch) {
        return this.order_data.client_data.branch.name ? this.order_data.client_data.branch.name : ''
      }
    },
    selectCity (data) {
      if (data && data.id !== this.order_data.client_data.city_id) {
        this.order_data.client_data.city_id = data.id
        this.order_data.client_data.is_ua = data.is_ua
        this.order_data.client_data.city = data
        this.order_data.client_data.city.name_ua = data.name
        this.order_data.client_data.branch_id = null
        this.order_data.client_data.branch = {
          city_id: null,
          id: null,
          name: null,
          name_ua: null,
          ref: null
        }
        this.fetchBranches(data.id)
      } else {
        this.order_data.client_data.city_id = null
        this.order_data.client_data.city = null
        this.order_data.client_data.branch_id = null
        this.order_data.client_data.branch = {
          city_id: null,
          id: null,
          name: null,
          name_ua: null,
          ref: null
        }
        this.order_data.client_data.address = {
          street: null,
          house: null,
          appt: null
        }
      }

      // this.setCities([])
    },
    selectBranch (data) {
      const fined = _.find(this.branches, item => { return item.id === data.id })
      this.order_data.client_data.branch_id = data.id
      this.order_data.client_data.branch = fined
    },
    fetchClients (search, loading) {
      if (search.length >= 3) {
        this.searchClients(search)
      }
    },
    fetchCities (search) {
      this.getCities(search)
    },
    fetchBranches (search) {
      if (this.order_data.client_data.city_id) {
        this.clearEditClient()
        this.setSearchBranch(search)
        this.$set(this.edit_client, 'city_id', this.order_data.client_data.city_id)

        this.getBranches({
          city_id: this.order_data.client_data.city_id,
          is_ua: this.order_data.client_data.is_ua
        })
      }
    },
    fetchAddresses (search) {
      if (this.order_data.client_data.address === undefined) {
        this.order_data.client_data.address = {
          street: null,
          house: null,
          appt: null
        }
      }
      this.order_data.client_data.address.street = {}
      const data = {
        street: search,
        city_ref: this.order_data.client_data.city.ref
      }
      this.getAddresses(data)
    },
    selectAddress (data) {
      this.order_data.client_data.address.street = data
    }
  },
  created () {
    this.setCities([])
    this.setBranches([])
    this.setAddresses([])
    this.clearErrors()
  },
  beforeDestroy () {
    this.clearEditClient()
    this.setCities([])
    this.setBranches([])
    this.setAddresses([])
  }
}
</script>

<style scoped>

</style>
