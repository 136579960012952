<template>
  <div class="popup_box" data-id="calculate_checked" :class="{ active: show_calculate_modal }">
    <div class="popup calculate">
      <span class="page_title" v-if="selected_invoices.length > 0">Рассчитать отмеченные</span>
      <span class="page_title" v-else>Рассчитать все</span>
      <span class="close_popup" @click="setShowCalculateModal(false)"></span>
      <span class="qty_orders" v-if="selected_invoices.length > 0">Заказов: <span>{{ selected_invoices.length }}</span></span>
      <div class="wrap flx">
        <div class="inp_wrap pick_up">
          <p>Забрать:</p>
          <input type="text" disabled :value="getInvoicesCalcField('debit') | asPrice">
        </div>
        <div class="inp_wrap give_away">
          <p>Отдать:</p>
          <input type="text" :value="getInvoicesCalcField('pay') | asPrice">
        </div>
      </div>
      <div class="inp_wrap payment">
        <p>Размер выплаты: </p>
        <span class="amount_payment" :class="getBillingInfoColor(getInvoicesCalcTotal())">{{ getInvoicesCalcTotal() | asPrice }} <span class="info_grey"> </span></span>
      </div>
      <div class="inp_wrap textarea">
        <p>Комментарий:</p>
        <textarea name="" v-model="comment"></textarea>
      </div>
      <button class="btn" @click="store">Рассчитать</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { prices } from '../../helpers/prices'
import helper from '../../helpers/helper'
import { eventBus } from '@/main'

export default {
  name: 'CalculateInvoicesModal',
  mixins: [
    prices
  ],
  data () {
    return {
      comment: ''
    }
  },
  computed: {
    ...mapState('invoices', ['show_calculate_modal', 'selected_invoices', 'invoice_user']),
    ...mapGetters('invoices', ['getInvoicesCalcField', 'getInvoicesCalcTotal'])
  },
  methods: {
    ...mapActions('invoices', ['createPayment', 'getOpenedInvoices', 'getInvoiceUser']),
    ...mapMutations('invoices', ['setShowCalculateModal']),
    getBillingInfoColor (value) {
      return helper.getBillingInfoColor(value)
    },
    store () {
      const data = {
        invoices_id: this.invoice_user ? this.invoice_user.invoices_id || [] : [],
        comment: this.comment
      }
      if (this.selected_invoices.length > 0) {
        data.invoices_id = this.selected_invoices
      }
      console.log(data)
      this.createPayment(data)
        .then(res => {
          if (res.success) {
            eventBus.$root.$emit(
              'send_notify',
              'success',
              'Расчет произведен'
            )
            this.setShowCalculateModal(false)
            this.getOpenedInvoices()
            this.getInvoiceUser(this.$route.params.id)
          }
        })
    }
  },
  watch: {
    show_calculate_modal (val) {
      if (!val) {
        this.comment = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
