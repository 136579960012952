<template>
  <div :class="{active: show_dropshipper_filter}" class="filters_box open_box" data-id="filters_box">
    <div class="filters_head flx">
      <div class="wrap_title flx">
        <span class="page_title">Фильтры</span>
        <span class="result">Результат {{ pagination.from }} - {{ pagination.to }} из {{ pagination.total }}</span>
      </div>
      <div class="flx">
        <div class="reset" @click="clearFilters">Сбросить фильтры</div>
        <button class="btn empty" @click="getDropshippers" :disabled="count_dropshipper_filter === 0">Применить</button>
      </div>
    </div>
    <div class="filters_table flx">
      <div class="column flx">
        <p>ID <span class="info_grey"> </span></p>
        <input type="text" placeholder="ID продавца" id="seller_id" v-model="filters.user_id" v-format.integer @keypress.enter="getDropshippers">
        <p>Баланс</p>
        <div class="wrap_from_to flx">
          <input type="text" placeholder="От" v-model.number="filters.balance_from" v-format.number @keypress.enter="getDropshippers">
          <input type="text" placeholder="До" v-model="filters.balance_to" v-format.number @keypress.enter="getDropshippers">
        </div>
      </div>
      <div class="column flx">
        <p>Логин</p>
        <input type="text" placeholder="Логин продавца" id="seller_login" v-model="filters.login" @keypress.enter="getDropshippers">
        <p>Количество заказов</p>
        <div class="wrap_from_to flx">
          <input type="text" placeholder="От" v-model="filters.orders_count_from" v-format.integer @keypress.enter="getDropshippers">
          <input type="text" placeholder="До" v-model="filters.orders_count_to" v-format.integer @keypress.enter="getDropshippers">
        </div>
      </div>
      <div class="column flx">
        <p>Телефон</p>
        <input type="text" placeholder="Телефон продавца" id="seller_phone" v-model="filters.phone" v-mask="phone_mask" @keypress.enter="getDropshippers">
        <p>Количество заказов</p>
        <div class="wrap_from_to flx">
          <input type="text" placeholder="От" v-model="filters.returns_count_from" v-format.integer @keypress.enter="getDropshippers">
          <input type="text" placeholder="До" v-model="filters.returns_count_to" v-format.integer @keypress.enter="getDropshippers">
        </div>
      </div>
      <div class="column flx">
        <p>ФИО</p>
        <input type="text" placeholder="Фамилия, Имя или Отчество продавца" id="seller_full_name" v-model="filters.name" @keypress.enter="getDropshippers">
        <p>Общее количество скидок</p>
        <div class="wrap_from_to flx">
          <input type="text" placeholder="От" v-model="filters.specials_count_from" v-format.integer @keypress.enter="getDropshippers">
          <input type="text" placeholder="До" v-model="filters.specials_count_to" v-format.integer @keypress.enter="getDropshippers">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { format } from '../../../helpers/format'
import { mask } from 'vue-the-mask'

export default {
  name: 'DropshipperFilter',
  directives: {
    format,
    mask
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##'
    }
  },
  computed: {
    ...mapState('users', ['show_dropshipper_filter', 'dropshipper_filters', 'pagination']),
    ...mapGetters('users', ['count_dropshipper_filter']),
    filters: {
      get () {
        return this.dropshipper_filters
      },
      set (val) {
        this.setDropshipperFilters(val)
      }
    }

  },
  methods: {
    ...mapMutations('users', ['clearDropshipperFilters', 'setDropshipperFilters', 'setPage']),
    ...mapActions('users', ['getDropshippers', 'toggleDropshippersFilter']),
    clearFilters () {
      this.clearDropshipperFilters()
      this.$nextTick(() => {
        this.setPage(1)
        this.getDropshippers()
        this.toggleDropshippersFilter()
      })
    }
  },
  mounted () {

  }

}
</script>

<style scoped>

</style>
