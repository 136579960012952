<template>
  <form :class="{active: show_user_filter}" class="filters_box open_box" action="#" data-id="filters_box">
    <div class="filters_head flx">
      <div class="wrap_title flx">
        <span class="page_title">Фильтры</span>
        <span class="result">Результат {{ pagination.from }} - {{ pagination.to }} из {{ pagination.total }}</span>
      </div>
      <div class="flx">
        <div class="reset" @click="clearFilters">Сбросить фильтры</div>
        <button class="btn empty" @click="getUsers" :disabled="count_user_filter === 0">Применить</button>
      </div>
    </div>
    <div class="filters_table flx">
      <div class="column flx">
        <p>ID <span class="info_grey"> </span></p>
        <input type="text" placeholder="ID пользователя" id="user_id" v-model="filters.user_id" v-format.integer @keypress.enter="getUsers">
        <p>Роль</p>
        <div class="select select-full" >
          <i class="ti-angle-down"></i>
          <brew-select
              class="select-roles vue-select multiple "
              v-model="filters.roles"
              :clearable="false"
              :options="roles"
              multiple
              label="name"
              :reduce="item => item.id"
          >
          </brew-select>
<!--          <select name="select">-->
<!--            <option value="" selected>Все</option>-->
<!--            <option value="1">Option 1</option>-->
<!--            <option value="2">Option 2</option>-->
<!--            <option value="3">Option 3</option>-->
<!--          </select>-->
        </div>
      </div>
      <div class="column flx">
        <p>Логин</p>
        <input type="text" placeholder="Логин пользователя" v-model="filters.login" @keypress.enter="getUsers">
        <p>Последний раз в сети</p>
        <div class="wrap_from_to flx  datepicker_to">
          <VueDatePicker v-model="filters.last_visited_from"  placeholder="От" value-type="YYYY-MM-DD" format="От YYYY-MM-DD" lang="ru" />
          <VueDatePicker v-model="filters.last_visited_to"  placeholder="До" value-type="YYYY-MM-DD" format="До YYYY-MM-DD" lang="ru" />
        </div>
      </div>
      <div class="column flx">
        <p>Телефон</p>
        <input type="text" placeholder="Телефон пользователя" id="seller_phone" v-model="filters.phone" v-mask="phone_mask" @keypress.enter="getUsers">
      </div>
      <div class="column flx">
        <p>ФИО</p>
        <input type="text" placeholder="Фамилия, Имя или Отчество пользователя" id="seller_full_name" v-model="filters.name" @keypress.enter="getUsers">
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { format } from '../../../helpers/format'
import { mask } from 'vue-the-mask'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/uk'
export default {
  name: 'UserFilter',
  directives: {
    format,
    mask
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##'
    }
  },
  components: {
    VueDatePicker: () => import('vue2-datepicker')
  },
  computed: {
    ...mapState('users', ['show_user_filter', 'user_filters', 'pagination']),
    ...mapGetters('users', ['count_user_filter']),
    ...mapState('roles', ['roles']),
    filters: {
      get () {
        return this.user_filters
      },
      set (val) {
        this.setUserFilters(val)
      }
    }

  },
  methods: {
    ...mapMutations('users', ['clearUserFilters', 'setUserFilters', 'setUserPage']),
    ...mapActions('users', ['getUsers', 'toggleUserFilter']),
    ...mapActions('roles', ['getRoles']),
    clearFilters () {
      this.clearUserFilters()
      this.$nextTick(() => {
        this.setUserPage(1)
        this.getUsers()
      })
    }
  },
  created () {
    this.getRoles()
  }
}
</script>

<style scoped>

</style>
