import axios from 'axios'

const baseUrl = 'api/sizes'

export default {
  namespaced: true,
  state: {
    sizes: []
  },
  mutations: {
    setSizes (state, data) {
      state.sizes = data
    }
  },
  actions: {
    getSizes (action) {
      axios.get(baseUrl).then(response => {
        action.commit('setSizes', response.data.data)
      })
    }
  }
}
