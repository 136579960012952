import axios from 'axios'

const baseUrl = 'api/seasons'

export default {
  namespaced: true,
  state: {
    seasons: []
  },
  mutations: {
    setSeasons (state, data) {
      state.seasons = data
    }
  },
  actions: {
    getSeasons (action) {
      axios.get(baseUrl).then(response => {
        action.commit('setSeasons', response.data.data)
      })
    }
  }
}
