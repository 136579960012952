import axios from 'axios'

export default {
  namespaced: true,
  state: {
    count_images: 0,
    import_data: {},
    import_progress: 0,
    validate_progress: 0,
    is_load: false,
    is_import: false,
    is_import_finish: false,
    history: {}
  },
  mutations: {
    setCountImages (state, value) {
      state.count_images = value
    },
    setIsLoad (state, value) {
      state.is_load = value
    },
    setIsImport (state, value) {
      state.is_import = value
    },
    setIsImportFinish (state, value) {
      state.is_import_finish = value
    },
    setImportData (state, data) {
      state.import_data = data
    },
    setHistory (state, data) {
      state.history = data
    },
    setProgress (state, data) {
      state.is_import = true
      state.import_progress = data
      if (data === 100) {
        state.is_import = false
      }
    },
    setProgressValidate (state, data) {
      state.validate_progress = data
      if (data === 100) {
        state.is_load = false
      }
    }
  },
  actions: {
    getData (action) {
      axios.get('/api/products/import-data').then(response => {
        action.commit('setImportData', response.data)
      })
    },
    getImageLoader (action) {
      axios.get('/api/products/count-images').then(response => {
        action.commit('setCountImages', response.data.count)
      })
    },
    getHistory (action) {
      axios.get('/api/products/import-history').then(response => {
        action.commit('setHistory', response.data)
      })
    },
    uploadFile (action, file) {
      if (!file) {
        return
      }
      action.commit('setProgress', 0)
      action.commit('setProgressValidate', 0)
      action.commit('setIsLoad', true)
      action.commit('setIsImport', false)
      action.commit('setIsImportFinish', false)
      const formData = new FormData()
      formData.append('file', file)
      axios.post('/api/products/import-upload', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {})
    },
    confirmImport (action, data) {
      action.commit('setIsImport', true)
      axios.post('/api/products/import-confirm', {
        update_remains: data.remains,
        update_images: data.img,
        update_categories: data.categories
      }).then(response => {

      })
    }
  }
}
