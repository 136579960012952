<template>
  <section class="table_box all_roles">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Баннеры
        <p v-if="pagination.from">Результат: {{ pagination.from }} - {{ pagination.to }} из {{ pagination.total }}</p>
        </span>
      </div>
      <div class="wrap_btn flx">
        <router-link class="btn create" :to="{name: 'CreateBanner'}">Создать</router-link>
      </div>
    </div>
    <div class="container has_table">
      <div class="table_body table_all_roles">
        <div class="top_table flx">
          <div class="wrap_search flx">
            <button type="submit"></button>
            <input type="text" v-model="search" placeholder="Поиск..." id="search">
            <ul class="search_list_box">
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
              <li class="item"><a href="#">item</a></li>
            </ul>
          </div>
          <div class="pagination flx">
            <div class="flx">
              <p class="desctop_v">Строк:</p>
              <brew-select
                class="select-pagination select-users-per_page"
                style="margin-left:10px;width: 100px"
                v-model="perPage"
                :clearable="false"
                :options="page_sizes"
              >
              </brew-select>
            </div>
            <paginate
              style="margin-left: 20px"
              class="flx"
              v-model="page"
              :page-count="pagination.last_page"
              :page-range="3"
              :margin-pages="2"
              :prev-text="'<'"
              :next-text="'>'"
            >
            </paginate>
          </div>
        </div>
        <div class="bot_table">
          <headline :headers="headers"></headline>
          <div class="row_list">
            <div @click="$router.push('banners/'+banner.id)"  class="row flx" v-for="banner in banners" :key="'banner-' + banner.id">
              <div class="id_role">
                <a @click.stop="$router.push('banners/'+banner.id)">{{ banner.id }}</a>
              </div>
              <div class="name_role">
                <p><b>{{ banner.name }}</b></p>
              </div>
              <div class="name_role">
                <p><a
                    :title="banner.link"
                    @click.stop target="_blank" :href="banner.link">Ссылка</a>
                </p>
              </div>
              <div class="name_role">
                <p><b>{{ position_list[banner.type] || '-' }}</b></p>
              </div>
              <div class="name_role">
                <p>
                  <input
                    style="margin: 0;width: 80px;"
                    :value="banner.sort"
                    type="number"
                    @click.stop
                    @change.stop="event => updatePositionSort({id: banner.id, value: event.target.value})">
                </p>
              </div>
              <div class="name_role">
                <p>
                  <input
                    @click.stop
                    @change="event => updatePublish({id: banner.id, value: event.target.checked})"
                    style="width: auto;margin: 0;"
                    type="checkbox"
                    :checked="banner.is_active"
                  >
                </p>
              </div>
              <div class="date_of_creation_role">
                <span>{{ banner.date }}</span>
              </div>
              <div class="icon_wrap_role flx">
                <span class="close" @click.stop="showDeleteModal(banner)"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <delete-banner-modal/>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { dates } from '../../../helpers/dates'
import Headline from '../../Helpers/Headline'
import DeleteBannerModal from './DeleteBannerModal'
let timer
export default {
  name: 'Banners',
  mixins: [
    dates
  ],
  components: {
    Headline,
    DeleteBannerModal
  },
  data () {
    return {
      page_sizes: [10, 50, 100],
      timer: null,
      headers: [
        {
          title: 'ID',
          sortable: false,
          class: 'id_role',
          value: 'id'
        },
        {
          title: 'Название',
          class: 'name_role',
          sortable: false,
          value: 'name'
        },
        {
          title: 'Ссылка',
          class: 'name_role',
          sortable: false,
          value: 'link'
        },
        {
          title: 'Тип',
          class: 'name_role',
          sortable: false,
          value: 'type'
        },
        {
          title: 'Сортировка',
          class: 'name_role',
          sortable: false,
          value: 'sort'
        },
        {
          title: 'Активен',
          class: 'name_role',
          sortable: false,
          value: 'is_active'
        },
        {
          title: 'Дата создания',
          class: 'date_of_creation_role',
          sortable: false,
          value: 'date'
        },
        {
          title: '',
          class: 'icon_wrap_role'
        }
      ]
    }
  },
  computed: {
    ...mapState('banners', [
      'banners',
      'pagination',
      'filters',
      'position_list',
      'show_delete'
    ]),
    search:
      {
        get: function () {
          return this.filters.search
        },
        set: function (value) {
          clearTimeout(timer)
          this.setSearch(value)
          timer = setTimeout(() => {
            this.setPage(1)
            this.getBanners()
          }, 500)
        }
      },
    page: {
      get: function () {
        return this.filters.page
      },
      set: function (value) {
        this.setPage(value)
        this.getBanners()
      }
    },
    perPage: {
      get () {
        return this.filters.per_page
      },
      set (perPage) {
        this.setPage(1)
        this.setPerPage(perPage)
        this.getBanners()
      }
    }
  },
  methods: {
    ...mapActions('banners', ['getBanners', 'updatePublish', 'updateSort']),
    ...mapMutations('banners', ['setPage', 'setSearch', 'setPerPage', 'setEditBanner', 'setShowDelete']),
    showDeleteModal (content) {
      this.setEditBanner(content)
      this.setShowDelete(true)
    },
    updatePositionSort (data) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.updateSort(data)
      }, 500)
    }
  },
  created () {
    this.getBanners()
  }
}
</script>

<style scoped>

</style>
