<template>
<div class="page">
  <div class="flx" style="background: white; padding: 20px;justify-content: start">
    <div>
<!--    <span>Дата:</span><br>-->
<!--    <date-picker v-model="date" valueType="format"  format="D.M.Y" type="datetime"  lang="ru" range></date-picker>-->
    </div>
  </div>
  <table style="padding: 20px;margin-top: 20px;background: white;width: 100%">
    <tr style="background: silver" >
      <td style="padding: 20px">Артикул</td>
      <td style="padding: 20px">Название</td>
      <td style="padding: 20px">Количество</td>
    </tr>
    <tr style="border-bottom: 1px solid #e4ebf6;" v-for="(item,index) in tab_data" :key="index" >
      <td style="padding: 10px 20px">{{item.vendor_code}}</td>
      <td><b>{{item.name}}</b> {{item.color}} {{item.size}}</td>
      <td><b>{{item.qty}}</b></td>
    </tr>
  </table>
</div>
</template>

<script>
import 'vue2-datepicker/locale/ru'
import axios from 'axios'
export default {
  name: 'OrderItemList',
  data () {
    return {
      date: null,
      tab_data: []
    }
  },
  watch: {
    // date (value) {
    //   console.log(value)
    //   this.getOrderData(value)
    // }
  },
  methods: {
    getOrderData (ids) {
      axios.get('/api/orders/info', {
        params: {
          ids: ids
        }
      }).then(response => {
        this.tab_data = response.data
      })
    }
  },
  created () {
    this.getOrderData(this.$route.query.ids)
  }
}
</script>

<style scoped>
.page{
  padding: 20px;
  background: #eef1f5;
  min-height: 100vh;
}
</style>
