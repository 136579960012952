import axios from 'axios'
import { eventBus } from '@/main'

const discountUrl = 'api/discounts/'

export default {
  namespaced: true,
  state: {
    discount_pagination: {
      current_page: 1,
      last_page: 1
    },
    discount_list: [],
    show_discount_delete_modal: false,
    discount_on_delete: null,
    show_add_discount_dropshipper_modal: false,
    show_add_discount_product_modal: false,
    show_add_discount_category_modal: false,
    discount: {
      user_id: null,
      user_ids: [],
      model_type: 'product',
      model_id: null,
      value: 0,
      type: '%',
      params: {
        season_ids: [],
        season_names: []
      }
    }
  },
  mutations: {
    setDiscountPagination (state, payload) {
      state.discount_pagination = payload
    },
    setModelType (state, modelType) {
      state.discount.model_type = modelType
    },
    setModelId (state, modelId) {
      state.discount.model_id = modelId
    },
    setDiscountType (state, type) {
      state.discount.type = type
    },
    setDiscountValue (state, value) {
      state.discount.value = value
    },
    setDiscountUserId (state, userId) {
      state.discount.user_id = userId
    },
    setDiscountUserIds (state, userIds) {
      state.discount.user_ids = userIds
    },
    setDiscount (state, discount) {
      state.discount = discount
    },
    setDiscountList (state, discounts) {
      state.discount_list = discounts
    },
    setShowDiscountDeleteModal (state, isTrue) {
      state.show_discount_delete_modal = isTrue
    },
    setDiscountOnDelete (state, discount) {
      state.discount_on_delete = discount
    },
    setShowAddDiscountDropshipperModal (state, isTrue) {
      state.show_add_discount_dropshipper_modal = isTrue
    },
    setShowAddDiscountProductModal (state, isTrue) {
      state.show_add_discount_product_modal = isTrue
      if (isTrue) {
        state.show_add_discount_category_modal = false
      }
    },
    setShowAddDiscountCategoryModal (state, isTrue) {
      state.show_add_discount_category_modal = isTrue
      if (isTrue) {
        state.show_add_discount_product_modal = false
      }
    },
    setDiscountParamsSeasonIds (state, ids) {
      state.discount.params.season_ids = ids
    },
    setDiscountParamsSeasonNames (state, names) {
      state.discount.params.season_names = names
    }
  },
  actions: {
    getDiscountList (actions, modelId) {
      axios.get(discountUrl, {
        params: {
          model_type: actions.state.discount.model_type,
          model_id: modelId
        }
      })
        .then(res => {
          actions.commit('setDiscountList', res.data.data)
          actions.commit('setDiscountPagination', res.data.meta)
        })
        .catch(err => {
          console.log(err)
          actions.commit('setDiscountList', [])
        })
    },
    getDiscountListByUser ({ state, commit }, userId) {
      axios.get(discountUrl, {
        params: {
          page: state.discount_pagination.current_page,
          model_type: state.discount.model_type,
          user_id: userId
        }
      })
        .then(res => {
          commit('setDiscountList', res.data.data)
          commit('setDiscountPagination', res.data.meta)
        })
        .catch(err => {
          console.log(err)
          commit('setDiscountList', [])
        })
    },
    createDiscount (actions, discount) {
      actions.commit('errors/clearErrors', null, { root: true })
      axios.post(discountUrl, discount)
        .then(res => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Скидка добавлена'
          )
          actions.commit('setShowAddDiscountDropshipperModal', false)
          actions.commit('setShowAddDiscountProductModal', false)
          actions.commit('setShowAddDiscountCategoryModal', false)
          actions.commit('setDiscountType', '%')
          actions.commit('setDiscountValue', 0)
          actions.commit('setDiscountUserId', null)
          actions.commit('setDiscountUserIds', [])
          actions.dispatch('getDiscountList', discount.model_id)
          actions.commit('errors/clearErrors', null, { root: true })
        })
        .catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Скидка не добавлена!',
            'Проверьте данные'
          )
          actions.dispatch('getDiscountList', discount.model_id)
        })
    },
    createUserDiscount (actions, discount) {
      actions.commit('errors/clearErrors', null, { root: true })
      axios.post(discountUrl, discount)
        .then(res => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Скидка добавлена'
          )
          actions.commit('setShowAddDiscountDropshipperModal', false)
          actions.commit('setShowAddDiscountProductModal', false)
          actions.commit('setShowAddDiscountCategoryModal', false)
          actions.dispatch('getDiscountListByUser', discount.user_id)
          actions.commit('errors/clearErrors', null, { root: true })
        })
        .catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Скидка не добавлена!',
            'Проверьте данные'
          )
          actions.dispatch('getDiscountList', discount.model_id)
        })
    },
    deleteDiscount (actions, discount) {
      axios.delete(discountUrl + discount.id)
        .then(res => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Скидка успешно удалена'
          )
          actions.dispatch('getDiscountList', discount.model_id)
        })
        .catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Товар не обновлен!',
            'Не удалось удалить скидку, попробуйте еще раз'
          )
          actions.dispatch('getDiscountList', discount.model_id)
        })
    },
    deleteUserDiscount (actions, discount) {
      axios.delete(discountUrl + discount.id)
        .then(res => {
          eventBus.$root.$emit(
            'send_notify',
            'success',
            'Скидка успешно удалена'
          )
          actions.dispatch('getDiscountListByUser', discount.user_id)
        })
        .catch(err => {
          console.log(err)
          eventBus.$root.$emit(
            'send_notify',
            'error',
            'Товар не обновлен!',
            'Не удалось удалить скидку, попробуйте еще раз'
          )
          actions.dispatch('getDiscountListByUser', discount.user_id)
        })
    }
  }
}
