<template>
<span class="helper-info">
    <div class="menu_drop">
          <div class="dropdown">
              <slot name="description"></slot>
          </div>
    </div>
</span>
</template>

<script>
export default {
  name: 'HelperInfo'
}
</script>

<style scoped lang="scss">
  .helper-info{
    position: relative;
    margin-left: 10px;
    margin-right: 18px;
    width: 12px;
    height: 12px;
    background-image: url('~@/assets/images/info_green_ico.svg');
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 2;
  }

  .helper-info:hover .menu_drop{
    display: block;
    top: 25px;
  }
  .helper-info .menu_drop .dropdown{
    padding: 16px 19px;
    width: 291px;
    max-width: 100%;
  }

  .helper-info .menu_drop .dropdown:before{
    bottom: inherit;
    top: -9px;
  }

  .helper-info .menu_drop .dropdown:after{
    top: -11px;
    bottom: inherit;
  }
</style>
