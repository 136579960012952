<template>
  <section class="table_box">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title">Новый поставщик</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="store">Сохранить</div>
      </div>
    </div>
    <div class="container tbl_box flx">
      <div class="box_shadow new_supplier">
        <div class="row flx">
          <div class="inp_wrap">
            <p>Название поставщика::<span>*</span></p>
            <input type="text"  id="name_supplier" v-model="edit_supplier.name" :class="{required:hasError('name')}" required >
          </div>
          <div class="inp_wrap">
            <p>Задаток:</p>
            <input type="text"  v-model.number="edit_supplier.deposit" :class="{required:hasError('deposit')}" v-format.number>
          </div>
        </div>
        <div class="row">
          <p class="necessary"><span>*</span>- поля обязательные для заполнения</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { format } from '../../../helpers/format'

export default {
  name: 'CreateSupplier',
  directives: {
    format
  },
  computed: {
    ...mapState('suppliers', ['supplier']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    edit_supplier: {
      get () {
        return this.supplier
      },
      set (val) {
        this.setSupplier(val)
      }
    }
  },
  methods: {
    ...mapActions('suppliers', ['createSupplier']),
    ...mapMutations('suppliers', ['setSupplier', 'clearSupplier']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    store () {
      this.createSupplier()
        .then(path => {
          this.$router.push({ name: path })
        })
    }
  },
  create () {
    this.clearErrors()
    this.clearSupplier()
  },
  beforeRouteLeave (to, from, next) {
    this.clearErrors()
    this.clearSupplier()
    next()
  }
}
</script>

<style scoped>

</style>
