<template>
  <section class="table_box single_client">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Информация о клиенте: {{ client.last_name }} {{ client.first_name }}</span>
        <div class="btn empty open_pop" data-id="history_change" @click="toggleHistoryModal">История изменений</div>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
<!--        <router-link class="btn" :to="{ name: 'EditClient', params: { id: client.id }}">Редактировать</router-link>-->
      </div>
    </div>
    <div class="container tbl flx">
      <div class="box_shadow info_single_client">
        <div class="row">
          <span class="page_subtitle">Общее</span>
          <div class="item flx">
            <span class="name">Фамилия:</span>
            <span><b>{{ client.last_name }}</b></span>
          </div>
          <div class="item flx">
            <span class="name">Имя:</span>
            <span>{{ client.first_name }}</span>
          </div>
          <div class="item flx">
            <span class="name">Отчество:</span>
            <span>{{ client.middle_name }}</span>
          </div>
          <div class="item flx">
            <span class="name">Телефон:</span>
            <span>{{ client.phone }}</span>
          </div>
        </div>
        <div class="row" v-if="client.created_by">
          <span class="page_subtitle">Ведёт клиента</span>
          <span>[<a href="/">{{client.created_by.id}}</a>] · <b>{{ client.created_by.login }}</b> · {{ client.created_by.name }} </span>
        </div>
        <div class="row">
          <span class="page_subtitle">Данные доставки</span>
          <div class="item flx" v-if="client.delivery">
            <span class="name">Доставка:</span>
            <span>{{client.delivery.name}}</span>
          </div>
          <div class="item flx" v-if="client.city">
            <span class="name">Город:</span>
            <span><b>{{ client.city.name }}</b></span>
          </div>
          <div class="item flx" v-if="client.branch">
            <span class="name">Отделение:</span>
            <span>{{ client.branch.name }}</span>
          </div>
        </div>
      </div>
      <div class="table_body orders_client">
        <div class="top_table">
          <span class="page_subtitle">Заказы</span>
        </div>
        <div class="bot_table">
          <div class="headline_table flx">
            <div class="order_number">
              <p class="title">ЗАКАЗ</p>
            </div>
            <div class="declaration_info">
              <p class="title">Декларация</p>
            </div>
            <div class="order_status_info">
              <p class="title">СТАТУС</p>
            </div>
            <div class="payment_info">
              <p class="title">Оплата</p>
            </div>
            <div class="delivery_status_info">
              <p class="title">Состояние доставки</p>
            </div>
            <div class="order_info">
              <p class="title">Товары</p>
            </div>
          </div>
          <div class="row_list" v-if="client_orders.length > 0">
            <div class="row flx" :class="orderClass(order)" v-for="order in client_orders" :key="'client-order-' + order.id">
              <div class="order_number" @click="$router.push({ name: 'Order', params: { id: order.id } })">
                <a>{{ order.id }}</a>
                <span>{{ order.created_at | onlyDate }}</span>
              </div>
              <div class="declaration_info">
                <p v-if="order.ttn" ><b>{{ order.ttn | asTTN }}</b></p>
                <span class="grey" v-if="order.sender">Отпр: {{ order.sender.last_name }} {{ order.sender.first_name | firstLetter }} {{ order.sender.middle_name | firstLetter }}</span>
              </div>
              <div class="order_status_info">
                <span class="status" :class="getOrderStatusColor(order)">{{ order.status_name }}</span>
              </div>
              <div class="payment_info">
                <span class="pay_count" v-if="order.total_final !== order.total_discount">{{ order.total_discount | asPriceNoCurrency }}/{{ order.total_final | asPrice }}</span>
                <span class="pay_count" v-else>{{ order.total_drop | asPrice }}</span>
                <span class="grey">{{ order.pay_type_name }}</span>
              </div>
              <div class="delivery_status_info">
                <span class="status" :class="getOrderDeliveryColor(order)">{{ order.delivery_status_name }}</span>
              </div>
              <div class="order_info">
                <span>
                  <span v-for="(product, index ) in order.products" :key="'order-product-'+product.product_color_size_id">
                    <span v-if="index <=1">
                      <span v-if="index>0"> + </span>
                      {{ product.size }} · {{ product.name }} · {{ product.color }}
                    </span>
                  </span>
                  <span v-if="order.products.length > 2">...</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <history-modal>
      <template v-slot:title>
        История изменений клиента
      </template>
    </history-modal>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import HistoryModal from '../../Modals/HistoryModal'
import { dates } from '../../../helpers/dates'
import { prices } from '../../../helpers/prices'
import { strings } from '../../../helpers/strings'
import { numbers } from '../../../helpers/numbers'
import helper from '../../../helpers/helper'
export default {
  name: 'ShowClient',
  components: {
    HistoryModal
  },
  mixins: [
    dates,
    prices,
    strings,
    numbers
  ],
  computed: {
    ...mapState('clients', ['client', 'client_orders'])
  },
  methods: {
    ...mapActions('clients', ['getSingleClient']),
    ...mapMutations('clients', ['setClient']),
    ...mapActions('history', ['toggleHistoryModal']),
    loadClient () {
      if (Object.keys(this.client).length === 0 || (Object.keys(this.client).length > 0 && this.client.id !== this.$route.params.id)) {
        this.getSingleClient(this.$route.params.id)
      }
    },
    orderClass (order) {
      return helper.getOrderClassByStatus(order.status) + ' ' + helper.getOrderClassByDeliveryStatus(order.delivery_status)
    },
    getOrderStatusColor (order) {
      return helper.getOrderStatusColor(order.status)
    },
    getOrderDeliveryColor (order) {
      return helper.getOrderDeliveryColor(order.delivery_status)
    }
  },
  watch: {
    '$route.params.id': function (id) {
      if (this.$route.name === 'ShowClient') {
        this.loadClient()
      }
    }
  },
  created () {
    this.loadClient()
  },
  beforeRouteLeave (from, to, next) {
    this.setClient({})
    next()
  }
}
</script>

<style scoped>

</style>
