import axios from 'axios'
// import { eventBus } from '@/main'

const baseUrl = 'api/check-queue/'

export default {
  namespaced: true,
  state: {
    is_load: false,
    checkboxList: [],
    filters: {
      status: '',
      search: null,
      page: 1,
      per_page: localStorage.getItem('checks-per-page') || 10
    },
    pagination: {
      last_page: 1
    },
    show_delete: false
  },
  mutations: {
    setIsLoad (state, v) {
      this.is_load = v
    },
    setCheckboxList (state, payload) {
      state.checkboxList = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setPage (state, v) {
      state.filters.page = v
    },
    setSearch (state, v) {
      state.filters.search = v
    },
    setFilterStatus (state, v) {
      state.filters.status = v
    },
    setPerPage (state, v) {
      state.filters.per_page = v
      localStorage.setItem('checks-per-page', v)
    }
  },
  actions: {
    getCheckList ({ commit, state }) {
      axios.get(baseUrl,
        {
          params: state.filters
        }
      )
        .then(res => {
          commit('setCheckboxList', res.data.data)
          commit('setPagination', res.data.meta)
        })
    },
    refreshStatus ({ commit, state }, id) {
      return axios.put(baseUrl + id)
    }
  }
}
