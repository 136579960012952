<template>
  <div class="page">
    <div v-if="inc_invoice">
      <h1>Приходная накладная № {{ inc_invoice.id }}</h1>
      <div>
        <div>Поставщик: {{ inc_invoice.supplier ? inc_invoice.supplier.name : null }}</div>
        <div>Дата получения: {{ inc_invoice.received_date }}</div>
        <div>Комментарий: {{ inc_invoice.comment }}</div>
      </div>
    </div>
    <table v-if="inc_invoice.products && inc_invoice.products.length > 0">
      <tr>
        <td>Артикул</td>
        <td>Название</td>
        <td>Цвет</td>
        <td>Размер</td>
        <td>Цена</td>
        <td>Количество</td>
        <td>Сумма</td>
      </tr>
      <tr v-for="(item, index) in inc_invoice.products" :key="index">
        <td>{{ item.vendor_code }}</td>
        <td><b>{{ item.name }}</b> {{ item.color }} {{ item.size }}</td>
        <td>{{ item.color }}</td>
        <td>{{ item.size }}</td>
        <td>{{ item.price }}</td>
        <td>{{ item.remains }}</td>
        <td>{{ item.remains * item.price }}</td>
      </tr>
      <tr>
        <td colspan="5">Итого:</td>
        <td>{{ inc_invoice.product_count }}</td>
        <td>{{ inc_invoice.total * -1 }}</td>
      </tr>
    </table>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
  name: 'PrintWaybill',
  computed: {
    ...mapState('incoming', ['inc_invoice'])
  },
  methods: {
    ...mapActions('incoming', ['getIncInvoice'])
  },
  created () {
    this.getIncInvoice(this.$route.params.id)
  }
}
</script>

<style scoped>
.page {
  padding: 10px;
  width: 860px;
  margin: 0 auto;
  background: #ffffff;
  min-height: 100vh;
}

h1 {
  font-size: 18px;
}

table {
  margin-top: 20px;
  width: 100%;
}
td {
  border: 1px solid #ccc;
}

</style>
