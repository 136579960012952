import axios from 'axios'

const baseUrl = 'api/configs'

export default {
  namespaced: true,
  state: {
    config: {}
  },
  mutations: {
    setConfig (state, data) {
      state.config = data
    }
  },
  actions: {
    getConfig (action) {
      axios.get(baseUrl).then(response => {
        action.commit('setConfig', response.data)
      })
    }
  }
}
