export const prices = {
  filters: {
    asPrice: function (value) {
      let price = Number.prototype.toFixed.call(parseFloat(value) || 0, 2)
      price = price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      return price + ' грн'
    },
    asPriceNoCurrency: function (value) {
      let price = Number.prototype.toFixed.call(parseFloat(value) || 0, 2)
      price = price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      return price
    }
  }

}
