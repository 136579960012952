<template>
  <section class="table_box">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Новый баннер</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" :disabled="banner_is_load" @click="store">Сохранить <brew-loader :show="banner_is_load"></brew-loader></div>
      </div>
    </div>
    <div class="container tbl_box flx">
      <div class="column">
        <div class="edit_description box_shadow">
          <p>Название:</p>
          <input v-model="name" :class="{required:hasError('name')}" type="text" required>

          <p>Ссылка:</p>
          <input v-model="link" :class="{required:hasError('link')}" type="text">
          <p>Сортировка:</p>
          <input v-model="sort" :class="{required:hasError('sort')}" type="number">
          <p>Тип:</p>
          <brew-select
            class="vue-select"
            label="name"
            v-model="type_banner"
            :options="options"
            :reduce="item => item.id"
          />
        </div>
      </div>
      <div class="edit_characteristics">
        <div class="box_shadow general_change">
          <div class="row">
            <div class="inp_wrap category_prod x-edit">
              <input
                  style="height: initial"
                  :class="{required:hasError('banner')}"
                  @change="uploadFile"
                  ref="file"
                  name="file"
                  id="exampleFile"
                  type="file"
                  class="form-control-file"
              >
              <div
                  class="img-container"
                  :style="{'background-image': 'url(' + url + ')'}"
              >
<!--                <img-->
<!--                  class="img"-->
<!--                  v-if="url"-->
<!--                  :src="url"-->
<!--                  alt="">-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BrewLoader from '../../../helpers/components/BrewLoader'

export default {
  name: 'CreateBanner',
  components: { BrewLoader },
  data () {
    return {
      name: '',
      link: '',
      formData: null,
      type_banner: 1,
      sort: 0,
      url: null,
      options: [
        { id: 1, name: 'Главная' }
      ]
    }
  },
  computed: {
    ...mapState('banners', ['position_list', 'banner_is_load']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    }
  },
  methods: {
    ...mapActions('banners', ['createBanner']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    store () {
      this.formData.append('name', this.name)
      this.formData.append('link', this.link)
      this.formData.append('type', this.type_banner)
      this.formData.append('sort', this.sort)
      this.createBanner(this.formData)
    },
    uploadFile () {
      const formData = new FormData()
      const file = this.$refs.file.files[0]
      this.url = URL.createObjectURL(file)
      formData.append('banner', this.$refs.file.files[0])
      this.formData = formData
    }
  },
  created () {
    this.formData = new FormData()
    this.clearErrors()
  }
}
</script>

<style lang="scss">
.img-container {
  margin-top: 20px;
  width: 826px;
  height: 220px;
  background-size: cover;
}
</style>
