import axios from 'axios'

const baseUrl = 'api/deductions'

export default {
  namespaced: true,
  state: {
    deduction_count: 0
  },
  mutations: {
    setDeductionCount (state, data) {
      state.deduction_count = data
    }
  },
  actions: {
    getDeductionCount (action) {
      axios.get(baseUrl + '/counters').then(response => {
        action.commit('setDeductionCount', response.data)
      })
    }
  }
}
