<template>
  <section class="table_box all_category inner">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">404 Страница не найдена</span>
      </div>
    </div>
    <div class="container">
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>

<style scoped>

</style>
