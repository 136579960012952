<template>
  <section class="table_box new_edit_user">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title">Редактировать пользователя ID: {{user.id}}</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)"  class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="store">Сохранить</div>
      </div>
    </div>
    <div class="container tbl_box flx">
      <div class="info_user_form box_shadow">
        <div class="top">
          <span class="page_subtitle">Информация пользователя</span>
        </div>
        <div class="middle">
          <div class="row flx">
            <div class="inp_wrap">
              <p>Логин:<span>*</span></p>
              <input type="text" id="login" :class="{required:hasError('login')}" required v-model="user.login" autocomplete="off">
            </div>
            <div class="inp_wrap">
              <span class="red">Данные для входа в систему</span>
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap">
              <p>Пароль:<span>*</span></p>
              <input :type="password_type"   :class="{required:hasError('password')}" required v-model="user.password" autocomplete="off">
              <span class="show_pass" v-if="!show_password" @click="show_password = !show_password">Показать</span>
              <span class="show_pass" v-else @click="show_password = !show_password">Скрыть</span>
            </div>
            <div class="inp_wrap">
              <p>Повторите пароль:<span>*</span></p>
              <input type="password"  :class="{required:hasError('password_confirmation')}" required v-model="user.password_confirmation" autocomplete="off">
            </div>
          </div>
        </div>
        <div class="bot">
          <div class="row flx">
            <div class="inp_wrap">
              <p>ФИО:<span>*</span></p>
              <input type="text"  id="full_name_user" :class="{required:hasError('name')}" required v-model="user.name" autocomplete="off">
            </div>
            <div class="inp_wrap">
              <p>Телефон:<span>*</span></p>
              <input type="tel"  :class="{required:hasError('phone')}" required v-model="user.phone" v-mask="phone_mask" autocomplete="off">
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap email">
              <p>Email:</p>
              <input type="email"  id="email" v-model="user.email" autocomplete="off">
            </div>
            <p class="necessary"><span>*</span>- поля обязательные для заполнения</p>
          </div>
        </div>
      </div>
      <user-roles></user-roles>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import UserRoles from './UserRoles'
export default {
  name: 'EditUser',
  directives: {
    mask
  },
  components: {
    UserRoles
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##',
      show_password: false
    }
  },
  computed: {
    ...mapState('users', ['edit_user']),
    ...mapState('roles', ['roles']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    user: {
      get () {
        return this.edit_user
      },
      set (val) {
        this.setEditUser(val)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    },
    password_type () {
      return this.show_password ? 'text' : 'password'
    }
  },
  methods: {
    ...mapActions('users', ['updateUser', 'getUser']),
    ...mapMutations('users', ['setEditUser', 'clearEditUser']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    store () {
      this.updateUser(this.edit_user)
    }
  },
  created () {
    this.clearErrors()
    this.getUser(this.$route.params.id)
  },
  beforeRouteUpdate (to, from, next) {
    this.getUser(this.$route.params.id)
    this.clearErrors()
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.clearEditUser()
    this.clearErrors()
    next()
  }
}
</script>

<style scoped>

</style>
