<template>
  <section class="table_box">
    <div class="container flx">
      <div class="table_head flx">
        <span class="page_title">Редактировать роль пользователя ID: {{ role.id }}</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z"
                  fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="store">Сохранить</div>
      </div>
    </div>
    <div class="container tbl_box flx">
      <div class="info_role_form box_shadow">
        <div class="top">
          <span class="page_subtitle">Общее</span>
        </div>
        <div class="row flx">
          <div class="inp_wrap">
            <p>Название роли:<span>*</span></p>
            <input type="text" id="name_role" :class="{required:hasError('name')}" required v-model="role.name">
          </div>
        </div>
      </div>
      <role-rules :can-edit="true"></role-rules>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import RoleRules from './RoleRules'
export default {
  name: 'EditRole',
  components: {
    RoleRules
  },
  computed: {
    ...mapState('roles', ['edit_role']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    role: {
      get () {
        return this.edit_role
      },
      set (role) {
        this.setEditRole(role)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    }
  },
  methods: {
    ...mapMutations('roles', ['setEditRole', 'clearRole', 'toggleSelectAllRules', 'setRoleModelRules']),
    ...mapActions('roles', ['getRole', 'updateRole']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    store () {
      this.updateRole()
    }
  },
  created () {
    this.clearErrors()
    this.getRole(this.$route.params.id)
  },
  beforeRouteUpdate (to, from, next) {
    this.getRole(this.$route.params.id)
    this.clearErrors()
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.clearRole()
    this.clearErrors()
    next()
  }
}
</script>

<style scoped>

</style>
