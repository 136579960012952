import Vue from 'vue'
import axios from 'axios'
import BrewSelect from './helpers/components/BrewSelect'
import lodash from 'lodash'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import Notifications from 'vue-notification'
import Paginate from 'vuejs-paginate'
import router from './router'
import store from './store'
import App from './App.vue'
import Echo from 'laravel-echo'
import loader from 'vue-ui-preloader'
import 'vue-select/dist/vue-select.css'

import VueClickOutsideElement from 'vue-click-outside-element'

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
Vue.use(VueClickOutsideElement)
Vue.use(loader)

window.io = require('socket.io-client')

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token') || ''}`
axios.defaults.headers.crossdomain = true
axios.interceptors.response.use((response) => {
  store.commit('errors/setErrors', [])
  return response
}, (error) => {
  switch (error.response.status) {
    case 401: {
      router.push('/login')
      break
    }
    case 403:
    case 404:
    case 500: {
      eventBus.$root.$emit(
        'send_notify',
        'error',
        error.response.data.message
      )
      break
    }
    // case 404: {
    //   router.push({ name: 'NotFound' })
    //   break
    // }
    case 422: {
      store.commit('errors/setErrors', error.response.data.errors)
    }
  }
  return Promise.reject(error)
})

Vue.config.productionTip = true
Vue.use(Notifications)
Vue.use(lodash)
Vue.use(CKEditor)
Vue.component('brew-select', BrewSelect)
Vue.component('paginate', Paginate)

console.log(process.env)

window.Echo = new Echo({
  broadcaster: 'socket.io',
  host: process.env.VUE_APP_SOCKET_HOST,
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token') || ''}`
    }
  }
})

window.Echo.channel('import')
  .listen('.import.info', (e) => { console.log(e) }
  )

// example
window.Echo.channel('admin-notification')
  .listen('.billing', (e) => { console.log('bill ' + e) }
  )
  .listen('.deduction-processed', (e) => { console.log('processed ' + e) }
  )

export const eventBus = new Vue()

new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
