<template>
    <transition name="fade">
        <div v-if="show" class="popup_box" style="display: block">
            <div class="popup delete_category">
                <span class="page_title">Удалить выбранные товары?</span>
                <span class="close_popup" @click="close()"></span>
                <button class="btn" @click="close()">Отмена</button>
                <button class="btn empty" @click="confirm()">Удалить</button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProductDeleteModal',
  props: {
    show: Boolean,
    products: Array
  },
  methods: {
    ...mapActions('product', {
      remove: 'deleteProducts'
    }),
    confirm () {
      this.remove(this.products)
      this.close()
    },
    close () {
      this.$root.$emit('show_product_delete', false)
    }
  }
}
</script>

<style scoped>

</style>
