<template>
  <div class="actions_cont export">
    <div class="btn actions_btn empty" data-id="actions_box" :class="{ active: active}" @click="active = !active">
      <span>Действия</span>
      <svg width="9" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L4 4L7 1" stroke="#F5A32A" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="actions_box open_box flx" data-id="actions_box" :class="{ active: active}">
      <p>Действия с товарами:</p>
      <div class="btn empty">Экспорт XLS <span class="grey">(prom.ua)</span></div>
      <button class="btn empty" :disabled="export_in_process" :class="{ disabled:export_in_process }" @click="exportProducts">Экспорт CSV <brew-loader :show="export_in_process"></brew-loader></button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BrewLoader from '../../../helpers/components/BrewLoader'
export default {
  name: 'ProductActions',
  data () {
    return {
      active: false
    }
  },
  components: { BrewLoader },
  computed: {
    ...mapState('product', ['export_in_process'])
  },
  methods: {
    ...mapActions('product', ['exportProducts'])
  }
}
</script>

<style scoped>

</style>
