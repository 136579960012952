<template>
  <div class="actions_box open_box flx" :class="{ active: show_dropshipper_actions }" data-id="actions_box">
    <div class="btn empty">
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.07755C0 2.05297 0.830588 1.22238 1.85517 1.22238H5.88881C6.2769 1.22238 6.59151 1.53699 6.59151 1.92508C6.59151 2.31317 6.2769 2.62778 5.88881 2.62778H1.85517C1.60677 2.62778 1.4054 2.82915 1.4054 3.07755V11.1448C1.4054 11.3932 1.60677 11.5946 1.85517 11.5946H9.92245C10.1709 11.5946 10.3722 11.3932 10.3722 11.1448V7.11119C10.3722 6.7231 10.6868 6.40849 11.0749 6.40849C11.463 6.40849 11.7776 6.7231 11.7776 7.11119V11.1448C11.7776 12.1694 10.947 13 9.92245 13H1.85517C0.830588 13 0 12.1694 0 11.1448V3.07755Z" fill="#F5A32A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4424 1.55761C11.2394 1.35467 10.9104 1.35467 10.7074 1.55761L5.37082 6.89421L5.12585 7.87412L6.10576 7.62914L11.4424 2.29255C11.6453 2.0896 11.6453 1.76056 11.4424 1.55761ZM9.71365 0.563843C10.4654 -0.187948 11.6843 -0.187948 12.4361 0.563843C13.1879 1.31563 13.1879 2.53453 12.4361 3.28632L6.9619 8.76054C6.87184 8.8506 6.759 8.91449 6.63544 8.94538L4.33051 9.52161C4.09104 9.58148 3.83773 9.51131 3.66319 9.33678C3.48865 9.16224 3.41849 8.90893 3.47835 8.66946L4.05459 6.36452C4.08548 6.24097 4.14937 6.12813 4.23942 6.03807L9.71365 0.563843Z" fill="#F5A32A"/>
      </svg>
      <span>Написать уведомление TODO</span>
    </div>
    <div class="btn empty" @click="toggleLockDropshipper(dropshipper)">
      <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.83337 5.83337V4.27782C2.83337 2.44331 4.18681 0.833374 6.00004 0.833374C7.81328 0.833374 9.16671 2.44331 9.16671 4.27782V5.83337H9.50004C10.4205 5.83337 11.1667 6.57957 11.1667 7.50004V11.5C11.1667 12.4205 10.4205 13.1667 9.50004 13.1667H2.50004C1.57957 13.1667 0.833374 12.4205 0.833374 11.5V7.50004C0.833374 6.57957 1.57957 5.83337 2.50004 5.83337H2.83337ZM4.16671 4.27782C4.16671 3.04408 5.05185 2.16671 6.00004 2.16671C6.94823 2.16671 7.83337 3.04408 7.83337 4.27782V5.83337H4.16671V4.27782ZM2.50004 7.16671C2.31595 7.16671 2.16671 7.31595 2.16671 7.50004V11.5C2.16671 11.6841 2.31595 11.8334 2.50004 11.8334H9.50004C9.68414 11.8334 9.83337 11.6841 9.83337 11.5V7.50004C9.83337 7.31595 9.68414 7.16671 9.50004 7.16671H2.50004Z" fill="#F5A32A"/>
      </svg>
      <span  v-if="!dropshipper.locked">Заблокировать</span>
      <span  v-else>Разблокировать</span>
    </div>
    <span class="del_wrap flx"><span class="close"> </span><span class="red">Удалить продавца</span></span>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: 'EdirDropshipperActions',
  computed: {
    ...mapState('users', ['edit_dropshipper', 'show_dropshipper_actions']),
    dropshipper: {
      get () {
        return this.edit_dropshipper
      },
      set (value) {
        this.setEditDropshipper(value)
      }
    }
  },
  methods: {
    ...mapActions('users', ['updateDropshipperLocked', 'toggleLockDropshipper']),
    ...mapMutations('users', ['setEditDropshipper', 'setShowDropshipperDeleteModal', 'setDropshipperOnDelete']),
    toggleLockDropshipper (dropshipper) {
      dropshipper.locked = !dropshipper.locked
      this.$nextTick(() => {
        this.updateDropshipperLocked(dropshipper)
      })
    },
    deleteDropshipper (dropshipper) {
      this.setDropshipperOnDelete(dropshipper)
      this.setShowDropshipperDeleteModal(true)
    }
  }

}
</script>

<style scoped>

</style>
