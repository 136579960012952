<template>
  <div class="roles_user_form box_shadow">
    <div class="top flx">
      <span class="page_subtitle">Роль пользователя</span>
      <div class="inp_wrap">
        <brew-select
            class="select-roles vue-select "
            v-model="user.role"
            :options="roles"
            label="name"
            @option:selected="setUserRules"
        >
        </brew-select>
      </div>
    </div>
    <user-role-rules :is_edit="user.id!==null"></user-role-rules>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import UserRoleRules from './UserRoleRules'
import _ from 'lodash'
export default {
  name: 'UserRoles',
  components: {
    UserRoleRules
  },
  computed: {
    ...mapState('users', ['edit_user']),
    ...mapState('roles', ['roles']),
    user: {
      get () {
        return this.edit_user
      },
      set (val) {
        this.setEditUser(val)
      }
    }
  },
  methods: {
    ...mapActions('roles', ['getRoles']),
    ...mapMutations('users', ['setEditUser']),
    ...mapMutations('roles', ['setWithoutRolesField']),
    setUserRules () {
      this.$set(this.user, 'rules', this.user.role.rules)
    }
  },
  created () {
    this.setWithoutRolesField([3])
    this.getRoles()
      .then(res => {
        if (!this.edit_user.id) {
          this.$set(this.user, 'role', _.find(this.roles, r => { return r.id === this.edit_user.role.id }))
          this.$set(this.user, 'rules', _.find(this.roles, r => { return r.id === this.edit_user.role.id }).rules)
        }
      })
  }
}
</script>

<style scoped>

</style>
