<template>
<!--TODO Edit waybill -->
  <section class="table_box new_edit_invoice">
    <div class="container reverse short flx">
      <div class="table_head flx">
        <span class="page_title" v-if="type === 'edit'">Редактировать накладную ID: {{ invoice.id }}</span>
        <span class="page_title" v-else>Новая накладная</span>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="save">Сохранить</div>
      </div>
    </div>
    <div class="container inner">
      <div class="box_shadow general_info_invoice">
        <div class="top flx">
          <span class="page_subtitle">Общая информация</span>
          <div class="wrap_file_add flx" >
            <span class="grey" v-if="type === 'edit' && !invoice.url"><b>Прикрепить файл:</b></span>
            <span class="grey" v-if="type === 'edit' && invoice.url"><b>Прикрепить другой файл:</b></span>
            <label class="custom_upload_file" v-if="type === 'edit'">
              <input
                  type="file"
                  style="display: none"
                  ref="pic_file_inc"
                  accept=".pdf,.xls,.xlsx,.docx,.txt,.csv"
                  @change="onFilePicked($event)"
              />
              <div class="btn">Выберите файл</div>
            </label>
            <span v-if="!invoice.url">Файл не выбран</span>
            <a :href="invoice.url" download v-else>{{ invoice.file_name }}</a>
          </div>
        </div>
        <div class="bot flx">
          <div class="inp_wrap invoice_id">
            <p>ID накладной:</p>
            <input type="text" placeholder="ID накладной" id="invoice_id" :value="invoice.id" disabled>
          </div>
          <div class="inp_wrap invoice_date">
            <p>Дата получения:</p>
            <VueDatePicker v-model="invoice.received_at" value-type="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD" lang="ru" class="w-100"/>
<!--            <input type="text" id="/" value="07.04.2021">-->
          </div>
          <div class="inp_wrap seller">
            <p>Поставщик:</p>
            <brew-select
                name="dropshipper"
                class="select vue-select"
                v-model="invoice.supplier_id"
                :options="inc_suppliers"
                label="name"
                :reduce="item => item.id"
                :class="{ required: hasError('supplier_id') }"
                :disabled="type !== 'edit'"
            >
              <template #selected-option="{ name, id }">
                <div :key="id" v-if="id">
                  [{{id}}] - {{name}}
                </div>
              </template>
            </brew-select>
          </div>
          <div class="inp_wrap comment">
            <p>Комментарий:</p>
            <textarea v-model="invoice.comment"></textarea>
          </div>
        </div>
      </div>
      <invoice-products></invoice-products>
    </div>
    <add-invoice-products></add-invoice-products>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/uk'
import InvoiceProducts from './modules/InvoiceProducts'
import AddInvoiceProducts from './modules/AddInvoiceProducts'
export default {
  name: 'EditWaybill',
  components: {
    VueDatePicker: () => import('vue2-datepicker'),
    InvoiceProducts,
    AddInvoiceProducts
  },
  data () {
    return {
      image_file: null,
      file_name: null
    }
  },
  computed: {
    type () {
      return this.inc_invoice.id ? 'edit' : 'create'
    },
    ...mapState('incoming', ['inc_invoice']),
    ...mapState('suppliers', ['inc_suppliers']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    invoice: {
      get () {
        return this.inc_invoice
      },
      set (val) {
        this.setIncInvoice(val)
      }
    }
  },
  methods: {
    ...mapActions('incoming', ['getIncInvoice', 'createIncomingInvoice', 'updateIncomingInvoice', 'uploadFile']),
    ...mapActions('suppliers', ['getIncSuppliers']),
    ...mapMutations('incoming', ['setIncInvoice', 'clearIncInvoice', 'setInvoiceSupplierId']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    loadInvoice () {
      if (!this.inc_invoice.id || (this.inc_invoice.id !== this.$route.params.waybill_id)) {
        this.getIncInvoice(this.$route.params.waybill_id)
      }
    },
    save () {
      if (this.type === 'edit') {
        this.updateIncomingInvoice(this.invoice)
          .then(() => {
            this.getIncInvoice(this.invoice.id)
          })
      } else {
        this.createIncomingInvoice(this.invoice)
          .then((id) => {
            this.getIncInvoice(id)
          })
      }
    },
    pickFile () {
      this.$refs.pic_file_inc.click()
    },
    onFilePicked (e) {
      if (this.type !== 'edit') {
        return false
      }
      console.log(e.target.files)
      const files = e.target.files
      if (files[0] !== undefined) {
        this.image_name = files[0].name
        if (this.image_name.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.image_file = files[0] // this is an image file that can be sent to server...
          this.$nextTick(() => {
            const formData = new FormData()
            if (this.image_file !== '') {
              formData.append('file', this.image_file)
              this.uploadFile([this.invoice.id, formData])
                .then(res => {
                  this.getIncInvoice(this.invoice.id)
                })
            }
          })
        })
      } else {
        this.image_file = null
        this.image_name = null
      }
    }
  },
  watch: {
    '$route.params.waybill_id': function (id) {
      if (this.$route.name === 'EditWaybill') {
        this.loadInvoice()
      }
    },
    'invoice.supplier.id': function (id) {
      if (this.invoice.supplier && this.$route.name === 'EditWaybill' && parseInt(this.$route.params.id) !== id) {
        this.$router.push({ name: 'EditWaybill', params: { id: id, waybill_id: this.invoice.id } })
      }
    }
  },
  created () {
    this.clearErrors()
    this.getIncSuppliers()
  },
  mounted () {
    if (this.$route.params.waybill_id) {
      this.loadInvoice()
    } else {
      this.clearIncInvoice()
      this.setInvoiceSupplierId(this.$route.params.id)
    }
  },
  beforeDestroy () {
    this.clearIncInvoice()
  }
}
</script>

<style scoped>

</style>
