<template>
  <section class="table_box new_seller">
    <div class="container flx" v-if="dropshipper">
      <div class="table_head flx">
        <span class="page_title">Редактировать продавца ID: {{dropshipper.id}}</span>
        <div class="btn empty open_pop" data-id="history_change" @click="toggleHistoryModal">История изменений TODO</div>
        <div class="actions_cont">
          <div class="btn actions_btn empty" :class="{ active: show_dropshipper_actions }" data-id="actions_box" @click="toggleDropshipperActions">
            <span>Действия</span>
            <svg width="9" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L4 4L7 1" stroke="#F5A32A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <edit-dropshipper-actions></edit-dropshipper-actions>
        </div>
      </div>
      <div class="wrap_btn flx">
        <a @click="$router.go(-1)" class="back flx cursor-pointer">
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.97814 0.796948C6.28105 1.09986 6.28105 1.59098 5.97814 1.89389L1.87267 5.99936L5.97814 10.1048C6.28105 10.4077 6.28105 10.8989 5.97814 11.2018C5.67522 11.5047 5.18411 11.5047 4.88119 11.2018L0.227251 6.54783C-0.0756619 6.24492 -0.0756619 5.7538 0.227251 5.45089L4.88119 0.796948C5.18411 0.494035 5.67522 0.494035 5.97814 0.796948Z" fill="#F5A32A"/>
          </svg>
          <span>Назад</span>
        </a>
        <div class="btn" @click="save">Сохранить</div>
      </div>
    </div>
    <div class="container tbl_box flx" v-if="dropshipper">
      <div class="info_seller_form">
        <div class="top">
          <span class="page_subtitle">Информация о продавце</span>
        </div>
        <div class="middle">
          <div class="row flx">
            <div class="inp_wrap">
              <p>Логин:<span>*</span></p>
              <input type="text" id="login" :class="{required:hasError('login')}" required v-model="dropshipper.login" autocomplete="off">
            </div>
            <div class="inp_wrap">
              <span class="red">Данные для входа в систему</span>
            </div>
          </div>
          <div class="row flx">
            <div class="inp_wrap">
              <p>Пароль:<span>*</span></p>
              <input :type="password_type"   :class="{required:hasError('password')}" required v-model="dropshipper.password" autocomplete="off">
              <span class="show_pass" v-if="!show_password" @click="show_password = !show_password">Показать</span>
              <span class="show_pass" v-else @click="show_password = !show_password">Скрыть</span>
            </div>
            <div class="inp_wrap">
              <p>Повторите пароль:<span>*</span></p>
              <input type="password"  :class="{required:hasError('password_confirmation')}" required v-model="dropshipper.password_confirmation" autocomplete="off">
            </div>
          </div>
        </div>
        <div class="bot">
          <div class="row flx">
            <div class="inp_wrap">
              <p>ФИО:<span>*</span></p>
              <input type="text"  id="full_name_seller" :class="{required:hasError('name')}" required v-model="dropshipper.name" autocomplete="off">
            </div>
            <div class="inp_wrap">
              <p>Телефон:<span>*</span></p>
              <input type="tel"  id="phone" :class="{required:hasError('phone')}" required v-model="dropshipper.phone" v-mask="phone_mask" autocomplete="off">
            </div>
          </div>
          <div class="row flx">
            <p>Email:</p>
            <input type="email"  id="email" v-model="dropshipper.email" autocomplete="off">
          </div>
          <div class="row flx">
            <p>Кредитный лимит:</p>
            <input type="number" id="credit" v-model="dropshipper.credit" autocomplete="off">
            <p><span>*</span>- поля обязательные для заполнения</p>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="balance_seller_form flx">
          <span class="page_subtitle">Баланс: <span :class="getColorBalance(dropshipper.balance)">{{ dropshipper.balance | asPrice }} </span></span>
          <div class="btn empty" @click="$router.push({ name: 'DropshipperOrders', params: { id: dropshipper.id }})">Перейти в Расчёты</div>
        </div>
        <div class="discount_seller_form table_body">
          <div class="top_table flx">
            <span class="page_subtitle">Скидки</span>
            <div class="btn_wrap">
              <div class="btn add_discount" data-id="to_category" :class="{ active: show_add_discount_category_modal }" @click="setShowAddDiscountCategoryModal(!show_add_discount_category_modal)">
                <span class="plus_light"></span>
                <span>Добавить скидку на категорию</span>
              </div>
              <div class="btn add_discount flx" data-id="to_product" :class="{ active: show_add_discount_product_modal }" @click="setShowAddDiscountProductModal(!show_add_discount_product_modal)">
                <span class="plus_light"></span>
                <span>Добавить скидку на товар</span>
              </div>
            </div>
          </div>
          <div class="wrap_tbl overflow-x-unset">
            <div class="headline_table flx">
              <div class="product_category">
                <p>Товар・Категория</p>
              </div>
              <div class="discount">
                <p>Скидка грн</p>
              </div>
              <div class="discount">
                <p>Скидка %</p>
              </div>
              <div class="cross"></div>
            </div>
            <add-discount-category-modal :id_user="dropshipper.id"></add-discount-category-modal>
            <add-discount-product-modal :id_user="dropshipper.id"></add-discount-product-modal>
            <div class="row_list">
              <div class="row flx" v-for="discount in discount_list" :key="'discount-'+discount.id">
                <div class="product_category" v-html="discount.name">
                </div>
                <div class="discount">
                  <span class="green" v-if="discount.type==='грн'">{{ discount.value }} {{ discount.type }}</span>
                </div>
                <div class="discount">
                  <span class="green" v-if="discount.type==='%'">{{ discount.value }} {{ discount.type }}</span>
                </div>
                <div class="cross close" @click="deleteDiscount(discount)"></div>
              </div>
              <!--      pagination        -->
              <div class="pagination flx w-100" style="justify-content: center; padding: 10px">
                <paginate
                    style="margin-left: 20px"
                    class="flx"
                    v-model="page"
                    :page-count="pagination.last_page"
                    :page-range="3"
                    :margin-pages="2"
                    :prev-text="'<'"
                    :next-text="'>'"
                >
                </paginate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dropshipper-delete-item-modal></dropshipper-delete-item-modal>
    <history-modal>
      <template v-slot:title>
        История изменений продавца
      </template>
    </history-modal>
    <discount-delete-user-modal></discount-delete-user-modal>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import helper from '@/helpers/helper'
import { mask } from 'vue-the-mask'
import { prices } from '../../../helpers/prices'
import DropshipperDeleteItemModal from './DropshipperDeleteItemModal'
import EditDropshipperActions from './EditDropshipperActions'
import HistoryModal from '../../Modals/HistoryModal'
import AddDiscountProductModal from '../../Modals/AddDiscountProductModal'
import AddDiscountCategoryModal from '../../Modals/AddDiscountCategoryModal'
import DiscountDeleteUserModal from '../../Modals/DiscountDeleteUserModal'

export default {
  name: 'EditDropshipper',
  mixins: [
    prices
  ],
  directives: {
    mask
  },
  components: { DropshipperDeleteItemModal, EditDropshipperActions, HistoryModal, AddDiscountProductModal, AddDiscountCategoryModal, DiscountDeleteUserModal },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##',
      show_password: false
    }
  },
  computed: {
    ...mapState('users', ['default_dropshipper', 'edit_dropshipper', 'show_dropshipper_actions']),
    ...mapState('errors', ['errors']),
    ...mapState('history', ['show_history_modal']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapState('discount', [
      'show_add_discount_product_modal',
      'show_add_discount_category_modal',
      'discount_list',
      'discount_pagination'
    ]),
    pagination: {
      get () { return this.discount_pagination },
      set (v) {
        this.setDiscountPagination(v)
        this.getDiscountListByUser(this.$route.params.id)
      }
    },
    page: {
      get () { return this.discount_pagination.current_page },
      set (v) {
        this.discount_pagination.current_page = v
        this.getDiscountListByUser(this.$route.params.id)
      }
    },
    dropshipper: {
      get () {
        return this.edit_dropshipper
      },
      set (value) {
        this.setEditDropshipper(value)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    },
    password_type () {
      return this.show_password ? 'text' : 'password'
    }
  },
  methods: {
    ...mapActions('users', ['updateDropshipper', 'getDropshipper', 'toggleDropshipperActions']),
    ...mapActions('history', ['toggleHistoryModal']),
    ...mapMutations('users', ['setEditDropshipper', 'setShowDropshipperActions']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    ...mapActions('discount', ['getDiscountListByUser']),
    ...mapMutations('discount', [
      'setDiscountOnDelete',
      'setShowDiscountDeleteModal',
      'setShowAddDiscountProductModal',
      'setShowAddDiscountCategoryModal',
      'setDiscountUserId',
      'setDiscountPagination'
    ]),
    save () {
      this.updateDropshipper(this.dropshipper)
    },
    getColorBalance (value) {
      return helper.getColorBalance(value)
    },
    deleteDiscount (discount) {
      this.setDiscountOnDelete(discount)
      this.setShowDiscountDeleteModal(true)
    }
  },
  mounted () {
    this.clearErrors()
    this.setDiscountUserId(this.$route.params.id)
  },
  created () {
    this.getDropshipper(this.$route.params.id)
  },
  beforeRouteUpdate (to, from, next) {
    this.getDropshipper(this.$route.params.id)
    this.setDiscountUserId(this.$route.params.id)
    this.clearErrors()
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.dropshipper = this.default_dropshipper
    this.clearErrors()
    this.setShowDiscountDeleteModal(false)
    this.setShowAddDiscountProductModal(false)
    this.setShowAddDiscountCategoryModal(false)
    this.setDiscountUserId(null)
    next()
  }
}
</script>

<style scoped>

</style>
