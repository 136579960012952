<template>
  <div class="payment_form box_shadow">
    <div class="top">
      <span class="page_subtitle">Оплата</span>
    </div>
    <div class="customer_price flx" style="padding: 0">
      <!--      <p>Укажите цену продажи клиенту:<span>*</span></p>-->
      <!--      <div class="inp_wrap">-->
      <!--        <input type="number" :class="{required:hasError('total_final'), disabled: disabled_field}" :disabled="disabled_field" v-model.number="order_data.total_final" placeholder="0.00" v-format.number>-->
      <!--      </div>-->
    </div>
    <div class="row flx">
      <div class="inp_wrap">
        <p>Скидка:</p>
        <input type="number" id="discount" placeholder="0" :min="0" v-if="order_data.discount"
               :class="{required:hasError('discount.value'), disabled: disabled_field}" :disabled="disabled_field"
               v-model.number="order_data.discount.value">
      </div>
      <div class="select" id="discount_value">
        <brew-select
          v-if="order_data.discount"
          class="select-discount-type vue-select"
          :class="{required:hasError('discount.type'), disabled: disabled_field}"
          :clearable="false"
          :options="discount_types"
          v-model="order_data.discount.type"
          :disabled="disabled_field"
        ></brew-select>
      </div>
    </div>
    <div class="row flx" v-if="show_pay_from_balance">
      <label class="custom-checkbox">
        <input type="checkbox" name="/" :checked="pay_from_balance" @change="payloadOrderPayFromBalance"
               :disabled="disabled_field" :class="{disabled: disabled_field}">
        <span></span>
        <span>Снять с баланса {{ Math.abs(get_drop_pay()) | asPrice }}</span>
      </label>
    </div>
    <div class="summary_wrap">
      <div class="item flx">
        <p>Сумма дроп:</p>
        <span>{{ get_total_drop() | asPrice }}</span>
      </div>
      <div class="item flx">
        <p class="name">Выплата продавцу:</p>
        <span class="summary red" v-if="!show_pay_from_balance">{{ get_drop_pay() | asPrice }} </span>
        <span class="summary red" v-if="show_pay_from_balance && !pay_from_balance">{{ get_drop_pay() | asPrice }}</span>
        <span class="summary red" v-if="show_pay_from_balance && pay_from_balance">{{ 0 | asPrice }}</span>
      </div>
      <span class="note red" v-if="order_data.total_final <= 0">Укажите цену продажи клиенту!</span>
      <span class="note red" v-if="get_drop_pay() <= 0 && !show_pay_from_balance">Укажите цену продажи клиенту выше суммы дроп!</span>
    </div>
    <div class="row flx">
      <p class="name">Комментарий:
        <label class="custom-checkbox">
          <input type="checkbox" v-model="order.is_custom_comment"  name="check">
          <span></span>
        </label>
      </p>
      <textarea
        v-if="order.is_custom_comment"
        name="comment"
        cols="30"
        rows="10"
        v-model="order_data.custom_comment"
      />
      <textarea
        v-else
        name="comment"
        cols="30"
        rows="10"
        v-model="order_data.comment"
        :disabled="true"
      />
      <!--      <small class="helper">{{this.order_data.comment ? this.order_data.comment.length : 0}} - {{comment_max_length}}</small>-->
      <small v-if="!valid_comment" class="note red">Подписать!</small>
    </div>
    <div class="customer_price flx">
      <p>
        Дроп сумма:
        <label class="custom-checkbox">
          <input type="checkbox" v-model="order.is_custom_drop_price"  name="check">
          <span></span>
        </label>
      </p>
      <div class="inp_wrap">
        <input type="number"
               :disabled="!order.is_custom_drop_price"
               v-model.number="order_data.custom_drop_price"
               placeholder="0.00"
               v-format.number
        >
      </div>
    </div>
    <div class="customer_price flx">
      <p>Финальная цена продажи:<span>*</span></p>
      <div class="inp_wrap">
        <input type="number" :class="{required:hasError('total_final'), disabled: disabled_field}"
               :disabled="disabled_field" v-model.number="order_data.total_final" placeholder="0.00" v-format.number>
      </div>
    </div>
    <div class="customer_price flx" v-if="order_data.pay_type !== 1">
      <p>Сумма продажи в чеке:<span>*</span></p>
      <div class="inp_wrap">
        <input type="number"
               :class="{required:hasError('total_check')}"
               v-model.number="order_data.total_check" placeholder="0.00" v-format.number>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { format } from '../../../../helpers/format'
import { prices } from '../../../../helpers/prices'

export default {
  name: 'OrderPayment',
  data () {
    return {
      discount_types: [
        '%', 'грн'
      ],
      comment_max_length: 100
    }
  },
  mixins: [
    prices
  ],
  directives: {
    format
  },
  computed: {
    ...mapState('orders', ['order']),
    ...mapState('errors', ['errors']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    ...mapGetters('orders', ['get_total_drop', 'get_drop_pay', 'get_comment_text']),
    order_data: {
      get () {
        return this.order
      },
      set (val) {
        this.setOrder(val)
      }
    },
    form_errors: {
      get () {
        return this.errors
      },
      set (err) {
        this.setErrors(err)
      }
    },
    pay_from_balance: {
      get () {
        return this.order_data.pay_from_balance
      }
    },
    show_pay_from_balance () {
      if (!this.order_data.dropshipper || this.get_drop_pay() >= 0) {
        return false
      }
      if ((this.order_data.dropshipper.balance - this.get_drop_pay()) > 0 && this.get_drop_pay() < 0 && this.order_data.pay_type === 1) {
        return true
      }
      return false
    },
    disabled_field () {
      if (this.order_data.pay_type === 3 || this.order_data.pay_type === 2) {
        return true
      }
      return false
      // return this.order_data.ttn !== null || this.order_data.delivery_status > 1 || this.order_data.status > 1
    },
    valid_comment () {
      if (this.order_data.comment) {
        return this.order_data.comment.length <= this.comment_max_length
      } else if (this.get_comment_text()) {
        return this.get_comment_text().length <= this.comment_max_length
      }
      return true
    }
  },
  watch: {
    'order_data.pay_type' (value) {
      if (value === 3 || value === 2) {
        this.order_data.total_final = 0
      }
    },
    'order_data.discount.value' (value) {
      if (typeof value !== 'number') {
        this.$set(this.order_data.discount, 'value', 0)
      }
    },
    'order_data.products' (value) {
      this.$set(this.order_data, 'comment', this.get_comment_text())
    },
    'order.is_custom_drop_price' (value) {
      if (!value) {
        this.$nextTick(() => {
          this.order.custom_drop_price = 0
        })
      }
    }
  },
  methods: {
    ...mapMutations('orders', ['setOrder']),
    ...mapMutations('errors', ['setErrors', 'clearErrors']),
    payloadOrderPayFromBalance (e) {
      this.order_data.pay_from_balance = e.target.checked
    }
    // changeOrderComment (e) {
    //   this.$set(this.order_data, 'comment', e.target.value)
    // }
  }
}
</script>
