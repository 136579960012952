<template>
  <div class="change_sum_wrap flx" :class="{ active: change }">
    <span>{{ price | asPrice }}</span>
    <svg class="pencil open_drop" data-id="change_sum1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" @click="change = !change">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.22065 1.33967C7.67354 0.886778 8.40782 0.886778 8.86071 1.33967C9.3136 1.79255 9.3136 2.52683 8.86071 2.97972L3.66721 8.17322L1.48047 8.71991L2.02715 6.53317L7.22065 1.33967Z" stroke="#ADB8C6" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div class="menu_drop" data-id="change_sum1" :class="{ active: change }">
      <span class="close" @click="change = !change"></span>
      <div class="dropdown">
        <p>Изменить  сумму</p>
        <input type="text" value="7 120.00" v-model.number="change_price" :ref="'input_price_' + id" @keyup.enter="updatePrice">
        <button class="btn" @click="updatePrice">Изменить</button>
      </div>
    </div>
  </div>
</template>

<script>
import { prices } from '../../../../helpers/prices'

export default {
  name: 'ProductPrice',
  data () {
    return {
      change: false,
      change_price: this.price
    }
  },
  mixins: [prices],
  props: {
    price: Number,
    id: Number
  },
  methods: {
    updatePrice () {
      this.$emit('update:price', this.change_price)
      this.$emit('update-price', this.id)
      this.change = false
    }
  },
  watch: {
    change (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs['input_price_' + this.id].focus()
        })
      }
    },
    price (val) {
      this.change_price = val
    }
  }
}
</script>

<style scoped lang="scss">
.close {
  position: absolute;
  right: 7px;
  top: 6px;
  z-index: 10;
}
</style>
