<template>
  <transition name="fade">
    <div class="popup_box" :class="{ active: show_return_modal}" data-id="list_product">
      <div class="popup delete_category">
        <span class="page_title">Создание возврата</span>
        <span class="close_popup" @click="show = !show"></span>

        <div class="inp_wrap">
          <p>Количество товара:</p>
          <input type="number" v-model="qty">
        </div>
        <button class="btn" @click="confirm()">Создать</button>
        <button class="btn empty" @click="show = !show">Отмена</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ReturnModal',
  props: ['orderItem'],
  data () {
    return {
      qty: 1
    }
  },
  watch: {
    qty: function (v) {
      if (v > this.orderItem.qty) {
        this.qty = this.orderItem.qty
      }
    },
    orderItem: {
      immediate: true,
      handler (v) {
        this.qty = 1
      }
    }
  },
  computed: {
    ...mapState('orders', ['show_return_modal']),
    show: {
      get () {
        return this.show_return_modal
      },
      set (val) {
        this.setReturnModal(val)
      }
    }
  },
  methods: {
    ...mapActions('returnItems', ['createReturnItem']),
    ...mapMutations('orders', ['setReturnModal']),
    confirm () {
      this.createReturnItem({
        order_item_id: this.orderItem.id,
        qty: this.qty
      })
      this.setReturnModal(false)
    }
  }
}
</script>

<style scoped>

</style>
