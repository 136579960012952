<template>
<!--  TODO supply header + calculated -->
  <div>
    <section class="sub_header">
      <div class="container">
        <nav class="sub_menu flx">
          <router-link :to="{ name: 'SelectSupplier' }" class="item">
            <p>Накладные  <span class="red">({{ suppliers_counters.debit | asPrice }})</span></p>
          </router-link>
        </nav>
      </div>
    </section>
    <router-view :key="$router.path" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { prices } from '../../helpers/prices'
export default {
  name: 'Supply',
  mixins: [prices],
  computed: {
    ...mapState('suppliers', ['suppliers_counters'])
  },
  methods: {
    ...mapActions('suppliers', ['getSuppliersCounters'])
  },
  created () {
    this.getSuppliersCounters()
  }
}
</script>

<style scoped>

</style>
